import styled from "styled-components";
import {media} from "../../atoms/theme";
import SaleforsSmallMobileIMG from "../../../../assets/images/salefors-small-img-mobile.png";
import SaleforsSmallIMG from "../../../../assets/images/salesforce/banner_second.webp";
import IconConsulring from "../../../../assets/icons/icon-consulting.svg";
import { IBanner } from './interface'

export const ContainerCompetence = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 120px;
  h2 {
    margin-top: 0;
  }

  ${media.up('md')} {
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    margin-top: 160px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
    margin: 0;
  }
`
export const WrapperImgCompetence = styled.div<IBanner>`
  width: 100%;
  margin-top: 60px;
  height: 250px;
  background-image: url("${(props) => props.image}");
  background-size: cover;
  background-repeat: no-repeat;

  ${media.up('md')} {
    background-image: url("${(props) => props.image}");
    position: absolute;
    bottom: -471px;
    width: 50%;
    max-width: 608px;
    height: 471px;
  }

  ${media.up('lg')} {
    bottom: -361px;
  }
`
export const WrapperCompetenceDesc = styled.div`


  ${media.up('md')} {
    width: 50%;
    padding-bottom: 50px;

    .content {
      max-width: 488px;
    }
  }

`
export const WrapperCertificatesDesc = styled.div`
  width: 100%;
  margin: 60px 0;


  ${media.up('md')} {
    margin: 140px 0 120px 0;
    display: flex;
    flex-direction: column;
    align-items: end;

    .content {
      width: 41%;
      min-height: 230px;
      h2 {
        margin-top: 0;
      }

      p, h2 {
        max-width: 488px;
      }
    }
  }

  ${media.up('xl')} {
    .content {
      width: 50%;
    }
  }

  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const WrapperItemsIcons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  p {
    margin-top: 10px;
    margin-bottom: 0;
    word-wrap: break-word;
  }

  .item-icon {
    width: 100%;
    max-width: calc(50% - 18px);

    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    padding: 20px 9px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-right: 5px;
    margin-top: 5px;
  }

  ${media.up('md')} {
    width: 50%;
    justify-content: flex-end;
    align-content: flex-start;
  }

  ${media.up('lg')} {
    justify-content: flex-start;

    .item-icon {
      max-width: 183px;

      padding: 40px 20px;
      border-radius: 5px;
      border: 1px solid #D1D8DB;
      margin: 20px;
    }
  }
  ${media.down('md')} {
    .item-icon{
      display: table;
    }
  }
`
export const IconSaleforce = styled.div`

  background-image: url("${IconConsulring}");
  background-repeat: no-repeat;
  width: 56px;
  height: 64px;
  background-size: 54px;
  float: left;
  margin: 0 auto;
  ${media.down('md')} {
    float: none;
  }
`
