import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container";
import { useTranslation } from "react-i18next";
import {CompanyCultureBreadcrumb} from "../../breadcrumbs/company-culture/company-culture";
import { HeadCompetences } from "../../ui/organisms/head-competences/head-competences";
import { ImgBackgroundFullLower } from "../../ui/atoms/banner-full-lower/styles";
import { AboutMore } from "../../ui/molecules/about-more";
import { OurTeams } from "../../ui/molecules/our-teams";
import { CompanyMission } from "../../ui/molecules/company-mission";
import { CompanyHistory } from "../../ui/molecules/company-history/company-history";
import { CompanyTigerbig } from "../../ui/molecules/company-tigerbig";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import generateLink from "../../../core/api/assets"
import getSlug from "../../../core/router/getSlug";

export const CompanyCulturePage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [letters, setLetters] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/letters/page/${pageid}`)
                .then(response => {
                    setLetters(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    return <>
        <BiContainer >
            <CompanyCultureBreadcrumb title={content['title']} slug={slug}/>
            <HeadCompetences 
                title={content['header_-_title']}
            />
        </BiContainer>
        <ImgBackgroundFullLower BackgroundMobile={generateLink(content['header_-_banner'])} BackgroundDesktop={generateLink(content['header_-_banner'])} />
        <BiContainer >
            <CompanyMission 
                title={content['section_1_-_title']}
                content={content['section_1_-_content']}
            />
            <CompanyHistory 
                title={content['section_2_-_title']}
                content={content['section_2_-_content']}
                banner={generateLink(content['section_2_-_image'])}
            />
            <CompanyTigerbig 
                title={content['section_3_-_title']}
                content={content['section_3_-_content']}
                image={generateLink(content['section_3_-_image'])}
                letters={letters}
            />
            <OurTeams
                banner={generateLink(content['section_4_-_image'])}
            />
        </BiContainer>
        <AboutMore 
            title={content['next_page_-_title']}
            link={content['next_page_-_link']}
            link_text={content['next_page_-_link_text']}
        />
        <ContactPersonPopup
            title={content['contact_popup_title']}
            place={content['contact_popup_position']}
            content={content['contact_popup_content']}
            name={content['contact_popup_person_name']}
            image={generateLink(content['contact_popup_avatar'])}
            send_to={content['contact_send_to']}
            pardot_link={content['pardot_link']}
        />
    </>
}
