import styled from "styled-components";
import {media} from "../../atoms/theme";

export const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: table;
    margin: 26px 0 80px 0;
    h4{
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0 0 30px 0;
    }
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0 0 80px 0;
        max-width: 692px;
    }
    ${media.down('lg')} {
        margin: 60px 0;
    }
    ${media.down('md')} {
        h4{
            font-size: 30px;
            line-height: 38px;
            margin-bottom: 20px;
        }
        p{
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 20px;
        }
    }
`

export const WrapperItems = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: table;
`