import React, {FunctionComponent, useState} from "react";
import { ContainerItems, WrapperSoloItem, WrapperItems, FormWrapper, WrapperContact, WrapperContactIn, ContactLeft, ContactRight, BackArrow} from "./styles";
import { IContactBoxes } from "./interface";
import Tech from "../../../../assets/icons/tech.svg";
import Like from "../../../../assets/icons/like-w.svg";
import Human from "../../../../assets/icons/human.svg";
import { Contact } from "../../organisms/contact/contact";
import Person from "../../../../assets/images/soft-dev/avatar.png";
import { ContactForm } from "../contact-form";
import generateLink from "../../../../core/api/assets";

export const ContactBoxes: FunctionComponent<IContactBoxes> = ({title, box_1_title, box_1_name, box_1_avatar, box_1_content, box_1_position, box_2_title, box_2_name, box_2_avatar, box_2_content, box_2_position, box_3_title, box_3_name, box_3_avatar, box_3_content, box_3_position}) => {
    const [isContactOpen, setContactOpen] = useState(false);
    const [name, setName] = useState(box_1_name);
    const [avatar, setAvatar] = useState(box_1_avatar);
    const [content, setConten] = useState(box_1_content);
    const [position, setPosition] = useState(box_1_position);

    const openContactBox = (number) => {
        if(number === '1'){
            setName(box_1_name);
            setAvatar(box_1_avatar);
            setConten(box_1_content);
            setPosition(box_1_position);
        }else if(number === '2'){
            setName(box_2_name);
            setAvatar(box_2_avatar);
            setConten(box_2_content);
            setPosition(box_2_position);
        }else{
            setName(box_3_name);
            setAvatar(box_3_avatar);
            setConten(box_3_content);
            setPosition(box_3_position);
        }
        setContactOpen(prev => !prev)
    }

    return <>
        <ContainerItems id="contact">
            <h4>{title}</h4>
            <WrapperItems>
                {!isContactOpen &&<WrapperSoloItem>
                    <img src={Tech} />
                    <p>{box_1_title}</p>
                    <a onClick={() => openContactBox('1')}>Contact us</a>
                </WrapperSoloItem>}
                {!isContactOpen &&<WrapperSoloItem>
                    <img src={Like} />
                    <p>{box_2_title}</p>
                    <a onClick={() => openContactBox('2')}>Contact us</a>
                </WrapperSoloItem>}
                {!isContactOpen &&<WrapperSoloItem>
                    <img alt="Human" src={Human} />
                    <p>{box_3_title}</p>
                    <a onClick={() => openContactBox('3')}>Contact us</a>
                </WrapperSoloItem>}
                {isContactOpen &&<WrapperContact>
                    <WrapperContactIn>
                        <ContactLeft>
                            <BackArrow onClick={() => setContactOpen(prev => !prev)}>
                                <p>Back</p>
                            </BackArrow>
                            <div className="person-info">
                                <div className="person-image">
                                    <img alt={name} src={generateLink(avatar)} />
                                </div>
                                <div className="person-content">
                                    <span>{position}</span>
                                    <p>{name}</p>
                                </div>
                            </div>
                            <p className="under-person">{content}</p>
                        </ContactLeft>
                        <ContactRight>
                            <ContactForm />
                        </ContactRight>
                    </WrapperContactIn>
                </WrapperContact>}
            </WrapperItems>
            <FormWrapper>
                <Contact 
                    title="Let’s talk about your needs"
                    content="We usually design systems tailored to your needs but."
                    button_text="Contact us"
                />
            </FormWrapper>
        </ContainerItems>
    </>
}
