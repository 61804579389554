import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerAbout = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 114px;
  ${media.down('lg')} {
    display: table;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const SoloGif = styled.div`
  max-width: 385px;
  display: none;
  box-sizing: border-box;
  position: absolute;
  top: 20px;
  right: 0;
  img{
    width: 100%;
  }
  ${media.down('lg')} {
    display: table;
    position: static;
    margin-bottom: 40px;
  }
  ${media.down('md')} {
    widht: 100%;
    margin-bottom: 20px;
  }
`

export const Content = styled.div`
  display: table;
  box-sizing: border-box;
  float: left;
  width: calc(100% - 385px);
  position: relative;
  h4{
    margin: 0 0 40px 0;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
  }
  p{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0 0 30px 0;
    max-width: 692px;
  }
  ${media.down('xl')} {
    p{
      max-width: 400px;
    }
  }
  ${media.down('lg')} {
    width: 100%;
    p{
      max-width: 100%;
    }
  }
  ${media.down('md')} {
    h4{
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 20px;
    }
    p{
      margin-bottom: 20px;
    }
  }
`

export const Image = styled.div`
  max-width: 385px;
  display: flex;
  justify-content: center;
  align-items: center; 
  float: right;
  img{
    width: 100%;
  }
  ${media.down('lg')} {
    display: none;
    position: static;
    margin-bottom: 40px;
  }
  ${media.down('md')} {
    widht: 100%;
    margin-bottom: 20px;
  }
`