import React, {FunctionComponent} from "react";
import { ContainerItems, WrapperSoloItem, WrapperItems } from "./styles";
import { IPercentValues } from "./interface";

export const PercentValuesCareer: FunctionComponent<IPercentValues> = ({value_1, title_1, value_2, title_2, value_3, title_3, value_4, title_4, value_5, title_5, value_6, title_6}) => {
    return <>
        <ContainerItems>
            <WrapperItems>
                <WrapperSoloItem>
                    <span>{value_1}</span>
                    <p>{title_1}</p>
                </WrapperSoloItem>
                <WrapperSoloItem>
                    <span>{value_2}</span>
                    <p>{title_2}</p>
                </WrapperSoloItem>
                <WrapperSoloItem>
                    <span>{value_3}</span>
                    <p>{title_3}</p>
                </WrapperSoloItem>
                <WrapperSoloItem>
                    <span>{value_4}</span>
                    <p>{title_4}</p>
                </WrapperSoloItem>
                <WrapperSoloItem>
                    <span>{value_5}</span>
                    <p>{title_5}</p>
                </WrapperSoloItem>
                <WrapperSoloItem>
                    <span>{value_6}</span>
                    <p>{title_6}</p>
                </WrapperSoloItem>
            </WrapperItems>
        </ContainerItems>
    </>
}
