import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerItems = styled.div`
  display: table;
  box-sizing: border-box;
  margin: 0 0 160px 0;
  width: 100%;
  h4{
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 45px 0;
  }
  ${media.down('md')} {
    marign-bottom: 75px;
    h4{
        margin-bottom: 40px;
        font-size: 30px;
        line-height: 38px;
    }
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperItems = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 0 20px;
  ${media.down('lg')} {
    gap: 40px 20px;
  }
  ${media.down('md')} {
    gap: 25px 0;
  }
`

export const WrapperSoloItem = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    flex: 1 0 25%;
    max-width: calc((100% / 4) - 20px);
    ${media.down('lg')} {
        flex: 1 0 50%;
        max-width: calc((100% / 2) - 20px);
    }
    ${media.down('md')} {
        flex: 1 0 100%;
        max-width: 100%;
    }
`

export const ImageWrapper = styled.div`
    width: 100%;
    margin: 0 0 40px 0;
    img{
        width: 100%;
        margin: 0;
    }
    ${media.down('md')} {
        width: 120px;
        margin: 0 20px 0 0;
        float: left;
    }
`

export const ContentWrapper = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    div{
        .wrapper-arrow{
            margin: 5px 5px -5px 0;
        }
    }
    ${media.down('md')} {
        width: calc(100% - 140px);
        float: left;
    }
`

export const Title = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    margin: 0 0 20px 0;
    p{
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0 0 5px 0;
    }
    span{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0;
    }
    ${media.down('md')} {
        margin-bottom: 10px;
        p{
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 10px;
        }
        span{
            font-size: 14px;
            line-height: 20px;
        }
    }
`