import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperJobOfferList = styled.div`
  display: table;
  box-sizing: border-box;
  max-width: 692px;
  padding-bottom: 60px;
  p{
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #25282B;
    margin: 0 0 20px 0;
  }
  ul{
    padding: 0 0 0 20px;
    li{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0;
    }
  }
  ${media.down('xl')} {
    max-width: 100%;
  }
  ${media.down('md')} {
    padding-bottom: 20px;
    p{
      font-size: 24px;
      line-height: 30px;
    }
    ul{
      li{
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`