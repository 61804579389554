import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container";
import { useTranslation } from "react-i18next";
import {CompanyBoardPersonBreadcrumb} from "../../breadcrumbs/company-board-person/company-board-person";
import { HeaderCaseStudies } from "../../ui/molecules/header-case-studies/header-case-studies";
import { PersonContent } from "../../ui/molecules/person-content/person-content";
import { QuotesBox } from "../../ui/molecules/quotes-box/quotes-box";
import { AboutMore } from "../../ui/molecules/about-more";
import { PersonList } from "../../ui/molecules/person-list";
import { persons } from "./data";
import quotes from '../../../assets/icons/quotes.svg'
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import getSlug from "../../../core/router/getSlug";
import generateLink from '../../../core/api/assets';

export const CompanyBoardPersonPage: FunctionComponent = () => {
    const { t } = useTranslation()
    const [content, setContent] = useState([]);
    const [people, setPeople] = useState([]);
    const [pageid, setPageId] = useState('');
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/people-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/people-list-connect/${pageid}`)
                .then(response => {
                    setPeople(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    return <>
        <BiContainer >
            <CompanyBoardPersonBreadcrumb 
                title={content['title']}
                slug={slug}
            />
            <HeaderCaseStudies
                title={content['title']}
                link={content['linkedin_profile']}
                position={content['position']}
            />
            <PersonContent 
                title_1={content['section_1_-_title']}
                content_1={content['section_1_-_content']}
                title_2={content['section_2_-_title']}
                content_2={content['section_2_-_content']}
                title_3={content['section_3_-_title']}
                content_3={content['section_3_-_content']}
                title_4={content['section_4_-_title']}
                content_4={content['section_4_-_content']}
                image={generateLink(content['image'])}
            />
            <QuotesBox 
                icon={quotes}
                content={content['intro']}
            />
            <PersonList
                title="See other board members"
                items={people}
            />
        </BiContainer>
        <AboutMore 
            title="Read press releases"
            link="/company-press"
            link_text="See press news"
        />
        <ContactPersonPopup
            title={content['contact_popup_title']}
            place={content['contact_popup_position']}
            content={content['contact_popup_content']}
            name={content['contact_popup_person_name']}
            image={generateLink(content['contact_popup_avatar'])}
            send_to={content['contact_send_to']}
            pardot_link={content['pardot_link']}
        />
    </>
}
