import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container";
import { useTranslation } from "react-i18next";
import {CompanyPressNewsBreadcrumb} from "../../breadcrumbs/company-press-news/company-press-news";
import { PressContentBox } from '../company-press/styles';
import { HeaderCaseStudies } from "../../ui/molecules/header-case-studies/header-case-studies";
import { articles } from "./data";
import { CompanyPress } from "../../ui/molecules/company-press/company-press";
import { PressContent } from "../../ui/molecules/press-content";
import { JobOfferPerson } from "../../ui/molecules/job-offer-person/job-offer-person";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import getSlug from "../../../core/router/getSlug";
import generateLink from '../../../core/api/assets';

export const CompanyPressNewsPage: FunctionComponent = () => {
    const { t } = useTranslation()
    const [content, setContent] = useState([]);
    const [recruiter, setRecruiter] = useState([]);
    const [press, setPress] = useState([]);
    const [pageid, setPageId] = useState('');
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/press-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/press-list-connect/${pageid}`)
                .then(response => {
                    setPress(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
        if(content['recruiter'] !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/recruiter-by-id/${content['recruiter']}`)
                .then(response => {
                    console.log(response.data.data);
                    setRecruiter(response.data.data ? response.data.data : []);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    console.log(recruiter);

    return <>
        <BiContainer >
            <PressContentBox>
                <CompanyPressNewsBreadcrumb />
                <HeaderCaseStudies
                    title={content['title']}
                    content={content['date_when']}
                />
                <PressContent 
                    content_1={content['section_1_-_content']}
                    content_2={content['section_2_-_content']}
                    image_2={generateLink(content['section_2_-_image'])}
                    content_3={content['section_3_-_content']}
                />
                <CompanyPress
                    title="Read press releases"
                    items={press}
                />
                <JobOfferPerson 
                    name={recruiter['title'] ? recruiter['title'] : ''}
                    position={recruiter['position'] ? recruiter['position'] : ''}
                    avatar={recruiter['avatar'] ? generateLink(recruiter['avatar']) : ''}
                    phone={recruiter['phone'] ? recruiter['phone'] : ''}
                    email={recruiter['e-mail'] ? recruiter['e-mail'] : ''}
                />
            </PressContentBox>
            <ContactPersonPopup
                title={content['contact_popup_title']}
                place={content['contact_popup_position']}
                content={content['contact_popup_content']}
                name={content['contact_popup_person_name']}
                image={generateLink(content['contact_popup_avatar'])}
                send_to={content['contact_send_to']}
                pardot_link={content['pardot_link']}
            />
        </BiContainer>
    </>
}
