import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container";
import { useTranslation } from "react-i18next";
import {CompanyPressBreadcrumb} from "../../breadcrumbs/company-press/company-press";
import { PressContentBox } from './styles';
import { CompanyBoardTitle } from "../../ui/molecules/company-board-title/company-board-title";
import { PressList } from "../../ui/molecules/press-list";
import { articles } from "./data";
import { JobOfferPerson } from "../../ui/molecules/job-offer-person/job-offer-person";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import getSlug from "../../../core/router/getSlug";
import generateLink from '../../../core/api/assets';

export const CompanyPressPage: FunctionComponent = () => {
    const { t } = useTranslation()
    const [content, setContent] = useState([]);
    const [articles, setArticles] = useState([]);
    const [pageid, setPageId] = useState('');
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection-press_releases`)
        .then(response => {
            setArticles(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    console.log(content);

    return <>
        <BiContainer >
            <PressContentBox>
                <CompanyPressBreadcrumb />
                <CompanyBoardTitle 
                    title={content['header_-_title']}
                />
                <PressList
                    title={content['header_-_undertitle']}
                    download_file={content['file_to_download']}
                    items={articles}
                />
                <JobOfferPerson 
                    name={content['contact_person_name'] ? content['contact_person_name'] : ''}
                    position={content['contact_position'] ? content['contact_position'] : ''}
                    avatar={content['contact_avatar'] ? generateLink(content['contact_avatar']) : ''}
                    phone={content['contact_-_phone'] ? content['contact_-_phone'] : ''}
                    email={content['contact_-_email'] ? content['contact_-_email'] : ''}
                />
            </PressContentBox>
        </BiContainer>
        <ContactPersonPopup
            title={content['contact_popup_title']}
            place={content['contact_popup_position']}
            content={content['contact_popup_content']}
            name={content['contact_popup_person_name']}
            image={generateLink(content['contact_popup_avatar'])}
            send_to={content['contact_send_to']}
            pardot_link={content['pardot_link']}
        />
    </>
}
