import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerConsulting = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  margin:  0 auto 160px auto;
  ${media.down('xl')} {
    margin: 80px auto;
  }
  ${media.down('xl')} {
    margin: 60px auto;
  }
  ${media.up('lg')} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
  ${media.down('md')} {
    padding: 0 20px;
  }
`

export const DescConsulting = styled.div`
  max-width: 42%;
  display: table;
  box-sizing: border-box;
  float: right;

  h2 {
    font-size: 34px;
    line-height: 42px;
    font-weight: 400;
    margin: 0 0 20px;
  }

  p {
    font-size: 22px;
    line-height: 32px;
    margin: 0;
  }

  ${media.down('xl')} {
    padding-top: 0;
    padding-bottom: 40px;
    max-width: 100%;
  }

  ${media.down('md')} {
    h2 {
      font-size: 30px;
      line-height: 38px;
      font-weight: 600;
    }

    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
`

export const ImageConsulting = styled.div`
    float: left;
    width: 710px;
    display: table;
    box-sizing: border-box;
    position: relative;
    img{
        width: 100%;
    }
  ${media.down('nav')} {
    width: 50%;
  }
  ${media.down('xl')} {
    width: 100%;
    float: none;
  }
    
`