import React, {FunctionComponent} from "react";
import {WrapperJobOffer, LeftContent, Mobile} from "./styles";
import { JobOfferApply } from "../../molecules/job-offer-apply/job-offer-apply";
import { JobOfferLogos } from "../../molecules/job-offer-logos/job-offer-logos";
import { JobOfferList } from "../../molecules/job-offer-list/job-offer-list";
import { JobOfferPerson } from "../../molecules/job-offer-person/job-offer-person";
import { section_1, section_2, section_3, section_4 } from "./data";
import { IJobContent } from "./interface";

export const JobOfferContent: FunctionComponent<IJobContent> = ({content, what_will_happen_next, our_offer, your_role, your_skills_and_experience, name, avatar, position, phone, email, technologies, team, level, location, link}) => {

    return <>
        <WrapperJobOffer>
            <LeftContent>
                <JobOfferLogos 
                    content={content}
                    technologies={technologies}
                    team={team}
                    level={level}
                    location={location}
                    link={link}
                />
                <JobOfferList 
                    title="Our offer"
                    items={our_offer}
                />
                <JobOfferList 
                    title="Your role"
                    items={your_role}
                />
                <JobOfferList 
                    title="Your skills & experience"
                    items={your_skills_and_experience}
                />
                <JobOfferList 
                    title="What will happen next?"
                    items={what_will_happen_next}
                />
                <Mobile>
                    <JobOfferApply 
                        team={team}
                        level={level}
                        location={location}
                        link={link}
                    />
                </Mobile>
                <JobOfferPerson 
                    title="Any questions? Ask our recruiter!"
                    name={name}
                    position={position}
                    avatar={avatar}
                    phone={phone}
                    email={email}
                />
            </LeftContent>
        </WrapperJobOffer>
    </>

}
