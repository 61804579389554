import React, {FunctionComponent} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow/action-arrow-atom";
import {WrapperBeforeFooter, DesceforeFooter, ListBeforeFooter, WrapperSoloItem} from './styles'
import { IBeforeFooter } from "./interface";
import BiContainer from "../../atoms/container";

export const BeforeFooter: FunctionComponent<IBeforeFooter> = ({title, content, item_1, item_2, item_3, item_1_url, item_2_url, item_3_url}) => {
    return <>
        <WrapperBeforeFooter>
            <BiContainer>
                <DesceforeFooter>
                    <h4>{title}</h4>
                    <p>{content}</p>
                </DesceforeFooter>
                <ListBeforeFooter>
                    {item_1 &&<WrapperSoloItem>
                        <ActionArrowAtom  link={item_1_url ? item_1_url : ""} text={item_1}/>
                    </WrapperSoloItem>}
                    {item_2 &&<WrapperSoloItem>
                        <ActionArrowAtom  link={item_2_url ? item_2_url : ""} text={item_2}/>
                    </WrapperSoloItem>}
                    {item_3 &&<WrapperSoloItem>
                        <ActionArrowAtom  link={item_3_url ? item_3_url : ""} text={item_3}/>
                    </WrapperSoloItem>}
                </ListBeforeFooter>
            </BiContainer>
        </WrapperBeforeFooter>
    </>
}