import styled from "styled-components";
import {media} from "../../atoms/theme";
import Check from '../../../../assets/icons/check.svg'
import { ICaseTop } from "./interface";

export const WrapperCaseContent = styled.div<ICaseTop>`
  width: 100%;
  display: table;
  box-sizing: border-box;
  width: 794px;
  margin: 0 auto ${(props) => props.bottom}px auto;
  h4{
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 30px 0;
  }
  p{
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0;
    color: #25282B;
  }
  ul{
      list-style: none;
      margin: 30px 0 0 0;
      padding: 0;
      li{
          width: 100%;
          font-family: Billennium;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0.02em;
          text-align: left;
          margin: 0 0 10px 0;
          padding: 0 0 0 39px;
          position: relative;
          &:before{
            content: "";
            width: 24px;
            height: 24px;
            display: inline-block;
            background-image: url("${Check}");
            background-size: 24px;
            margin: 1px 15px -4px 0;
            position: absolute;
            left: 0;
          }
      }
  }
  ${media.down('lg')} {
    width: 100%;
    margin-bottom: ${(props) => props.bottom / 3}px;
    h4{
      font-size: 24px;
      line-height: 30px;
    }
    p{
      font-size: 16px;
      line-height: 24px;
    }
    ul{
      li{
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`
