import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container";
import {HeadIndustries} from "../../ui/organisms/head-industries/head-industries";
import {IndustriesBreadcrumb} from "../../breadcrumbs/industries/industries";
import {IndustriesList} from "../../ui/organisms/list-industries/industries-list";
import {useTranslation} from "react-i18next";
import { PopupContact } from "../../ui/molecules/popup-contact";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import generateLink from "../../../core/api/assets"
import getSlug from "../../../core/router/getSlug";

export const IndustriesPage: FunctionComponent = () => {
    const {t} = useTranslation();
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [industries, setIndustries] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection-industries`)
        .then(response => {
            setIndustries(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    return <>
        <BiContainer>
            <IndustriesBreadcrumb/>
            <HeadIndustries
                title={content['header_-_title']}
                content={content['header_-_content']}
            />
            <IndustriesList
                items={industries}
            />
            <PopupContact
                content={`<p>${content['contact_title']}</p>`}
                text_link="Contact us"
            />
            <ContactPersonPopup
                title={content['contact_popup_title']}
                place={content['contact_popup_position']}
                content={content['contact_popup_content']}
                name={content['contact_popup_person_name']}
                image={generateLink(content['contact_popup_avatar'])}
                send_to={content['contact_send_to']}
                pardot_link={content['pardot_link']}
            />
        </BiContainer>

    </>
}
