import styled from "styled-components";
import {media} from "../../atoms/theme";

export const Container = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    margin-top: -6px;
    margin-bottom: 160px;
    ${media.down('xl')} {
        margin: 0 0 60px 0;
        border-left: 6px solid ${(props) => props.theme.colors.primaryOrange};
        padding-left: 25px;
    }
    ${media.down('md')} {
        width: calc(100% - 20px);
        margin-left: 20px;
        padding-right: 20px;
    }
`

export const EmptySpace = styled.div`
    width: 42%;
    height: 160px;
    border-right: 6px solid ${(props) => props.theme.colors.primaryOrange};
    ${media.down('xl')} {
        display: none;
    }
`

export const SoloItem = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    ${media.down('xl')} {
        padding-bottom: 80px;
    }

`
export const SoloItemRight = styled.div`
    width: calc(42% - 6px);
    display: table;
    box-sizing: border-box;
    float: left;
    ${media.down('xl')} {
        width: 100%;
        padding-bottom: 40px;
    }
    ${media.down('md')} {
        padding-bottom: 20px;
    }
`

export const Number = styled.div`
    display: table;
    box-sizing: border-box;
    width: 80px;
    float: left;
    margin-right: 20px;
    p{
        font-size: 100px;
        font-style: normal;
        font-weight: 600;
        line-height: 83px;
        letter-spacing: 0em;
        text-align: center;
        color: ${(props) => props.theme.colors.primaryOrange};
        margin: 0;
    }
    ${media.down('md')} {
        width: 54px;
        margin-right: 10px;
        p{
            font-size: 65px;
            line-height: 55px;
        }
    }
`

export const Title = styled.div`
    display: table;
    box-sizing: border-box;
    width: calc(100% - 100px);
    float: left;
    h5{
        max-width: 146px;
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0 0 5px 0;
    }
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0;
        max-width: 111px;
    }
    ${media.down('xl')} {
        h5{
            max-width: 100%;
        }
    }
    ${media.down('md')} {
        min-height: 113px;
        h5{
            max-width: 120px;
            font-size: 24px;
            line-height: 30px;
        }
        p{
            font-size: 16px;
            line-height: 24px;
        }
    }
`

export const SoloItemLeft = styled.div`
    display: table;
    box-sizing: border-box;
    width: 58%;
    float: left;
    border-left: 6px solid ${(props) => props.theme.colors.primaryOrange};
    padding-left: 127px;
    position: relative;
    padding-bottom: 100px;
    p{
        margin: 0 0 20px 0;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        max-width: 651px;
    }
    ul{
        padding: 0 0 0 20px;
        li{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.02em;
            text-align: left;
            margin: 0;
        }
    }
    img{
        width: 128px;
        height: 128px;
        position: absolute;
        left: -64px;
        top: -38px;
    }
    ${media.down('xl')} {
        width: 100%;
        border: none;
        padding: 0;
        img{
            width: 62px;
            height: 62px;
            top: -124px;
            left: calc(100% - 62px);
        }
    }
    ${media.down('md')} {
        img{
            top: -134px;
        }
        p, ul li{
            font-size: 16px;
            line-height: 24px;
        }
    }
`

export const SoloItemLeftLast = styled(SoloItemLeft)`
    padding-bottom: 0;
    border: none;
    width: calc(58% + 6px);
    img{
        left: -58px;
    }
    ${media.down('xl')} {
        img{
            left: calc(100% - 62px);
        }
    }
`