import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container";
import { useTranslation } from "react-i18next";
import HeadCompetences from "../../ui/organisms/head-competences";
import {InnovationLabBreadcrumb} from "../../breadcrumbs/innovation-lab/innovation-lab";
import {ImgBackgroundFull} from "../../ui/atoms/banner-full/styles";
import Banner from "../../../assets/images/innovation-lab/banner.png";
import Banner_two from "../../../assets/images/innovation-lab/banner-two.png";
import { UnderBanner } from "../../ui/molecules/under-banner";
import { ContentProjects } from "../../ui/molecules/content-projects/content-projects";
import { CheckList } from "../../ui/molecules/check-list/check-list";
import {items, technologies} from './data';
import { Technologies } from "../../ui/molecules/technolgies/technologies";
import { ContactShort } from "../../ui/molecules/contact-short";
import { OurTeams } from "../../ui/molecules/our-teams";
import { OurStats } from "../../ui/molecules/our-stats";
import Banner_third from "../../../assets/images/soft-dev/banner-two.png";
import Loader from "../../../assets/images/innovation-lab/loader.png";
import { BeforeFooter } from "../../ui/molecules/before-footer";
import { PopupContact } from "../../ui/molecules/popup-contact";
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import generateLink from "../../../core/api/assets"
import getSlug from "../../../core/router/getSlug";

export const InnovationLabPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [items, setItems] = useState([]);
    const [technologies, setTechnologies] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/check_list/page/${pageid}`)
                .then(response => {
                    setItems(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/technologies/page/${pageid}`)
                .then(response => {
                    setTechnologies(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    return <>
        <BiContainer>
            <InnovationLabBreadcrumb title={content['title']} slug={slug}/>
            <HeadCompetences 
                title={content['header_-_title']}
            />
        </BiContainer>
        <ImgBackgroundFull BackgroundMobile={generateLink(content['header_-_banner'])} BackgroundDesktop={generateLink(content['header_-_banner'])} />
        <UnderBanner
            content={content['section_1_-_content']}
        />
        <BiContainer >
            <ContentProjects
                title={content['section_2_-_title']}
                content={content['section_2_-_content']}
                image={generateLink(content['section_2_-_image'])}
            />
            <CheckList
                title={content['section_3_-_title']}
                items={items}
            />
            <Technologies 
                title={content['section_4_-_title']}
                content={content['section_4_-_content']}
                items={technologies}
            />
            <ContactShort 
                title={content['section_5_-_title']}
            />
            <OurTeams 
                title={content['section_6_-_title']}
                content={content['section_6_-_content']}
                banner={generateLink(content['section_6_-_image'])}
            />
            <OurStats
                title={content['section_7_-_title']}
                content={content['section_7_-_content']}
            />
        </BiContainer>
        <BeforeFooter
            title={content['before_footer_-_title']}
            content={content['before_footer_-_content']}
            item_1={content['before_footer_-_title_link_1']}
            item_1_url={content['before_footer_-_link_1']}
            item_2={content['before_footer_-_title_link_2']}
            item_2_url={content['before_footer_-_link_2']}
            item_3={content['before_footer_-_title_link_3']}
            item_3_url={content['before_footer_-_link_3']}
        />
        <BiContainer>
            <PopupContact
                content={`<p>${content['contact_title']}</p>`}
                text_link="Contact us"
                top={true}
            />
            <ContactPersonPopup
                title={content['contact_popup_title']}
                place={content['contact_popup_position']}
                content={content['contact_popup_content']}
                name={content['contact_popup_person_name']}
                image={generateLink(content['contact_popup_avatar'])}
                send_to={content['contact_send_to']}
                pardot_link={content['pardot_link']}
            />
        </BiContainer>
    </>
}
