import React, {FunctionComponent} from "react";
import { ContainerItems, WrapperSoloItem, WrapperItems } from "./styles";
import { IPercentValues } from "./interface";
import { ActionArrowAtomOut } from "../../atoms/action-arrow-out";
import generateLink from "../../../../core/api/assets";

export const PercentValuesIndustry: FunctionComponent<IPercentValues> = ({title, content, value_1, title_1, value_2, title_2, value_3, title_3, file_link}) => {
    return <>
        <ContainerItems>
            {title &&<h4>{title}</h4>}
            {content &&<p>{content}</p>}
            {file_link &&<ActionArrowAtomOut link={generateLink(file_link)} text="download a report" />}
            {(value_1 || title_1 || value_2 || title_2 || value_3 || title_3) &&<WrapperItems>
                <WrapperSoloItem>
                    <span>{value_1}</span>
                    <p>{title_1}</p>
                </WrapperSoloItem>
                <WrapperSoloItem>
                    <span>{value_2}</span>
                    <p>{title_2}</p>
                </WrapperSoloItem>
                <WrapperSoloItem>
                    <span>{value_3}</span>
                    <p>{title_3}</p>
                </WrapperSoloItem>
            </WrapperItems>}
        </ContainerItems>
    </>
}
