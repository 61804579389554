import styled from "styled-components";
import {media} from "../../atoms/theme";


export const ContainerItems = styled.div`
  display: table;
  box-sizing: border-box;
  margin: 0 0 120px 0;
  width: 100%;
  h4{
    font-size: 46px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 120px 0;
  }
  ${media.down('md')} {
    margin-bottom: 60px;
    h4{
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 40px;
    }
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperItems = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 0 123px;
  ${media.down('lg')} {
    gap: 40px 0;
  }
`

export const WrapperSoloItem = styled.div`
  flex: 1 0 50%;
  max-width: calc((100% / 2) - 123px);
  display: table;
  box-sizing: border-box;
  span{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0 0 20px 0; 
  }
  h5{
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 10px 0;
  }
  p{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0 0 20px 0;
  }
  div a span{
    font-weight: 600;
  }
  ${media.down('lg')} {
    flex: 1 0 100%;
    max-width: 100%;
  }
  ${media.down('md')} {
    span{
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
    }
    h5{
      font-size: 24px;
      line-height: 30px;
    }
    p{
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }
`
