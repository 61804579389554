import React, {FunctionComponent} from "react";
import { Wrapper, BorderOrange } from "./styles";
import { ICompanyBoardTitle } from "./interface";

export const CompanyBoardTitle: FunctionComponent<ICompanyBoardTitle> = ({title}) => {
    return <>
        <Wrapper>
            <h1>{title}</h1>
            <BorderOrange />
        </Wrapper>
    </>
}
