import React, {FunctionComponent} from "react";
import {Wrapper, WrapperItems} from "./styles";
import { IListGroup } from "./interface";
import { SoloGroup } from "../../molecules/solo-group";
import generateLink from "../../../../core/api/assets";

export const ListGroups: FunctionComponent<IListGroup> = ({title, content, items}) => {

    return <>
        <Wrapper>
            <h4>{title}</h4>
            <p>{content}</p>
            <WrapperItems>
                {Object.keys(items).map((keyName, i) => (
                    <SoloGroup 
                        title={items[keyName].data['title']} 
                        content={items[keyName].data['content']} 
                        image={generateLink(items[keyName].data['logo'])} 
                    />
                ))}
            </WrapperItems>
        </Wrapper>
    </>

}
