import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import { ContactCv } from "../../ui/molecules/contact-cv/contact-cv";
import BiContainer from "../../ui/atoms/container/BiContainer";
import { CareerBreadcrumb } from "../../breadcrumbs/career/career";
import { HeadCompetences } from "../../ui/organisms/head-competences/head-competences";
import { ImgBackgroundFull } from "../../ui/atoms/banner-full/styles";
import { PercentValuesCareer } from "../../ui/molecules/percent-values-career/percent-values-career";
import {values, checks, jobs} from './data'
import { OurTeams } from "../../ui/molecules/our-teams";
import Map from "../../ui/organisms/map";
import { ContentCareer } from "../../ui/molecules/content-career/content-career";
import { CareerWhy } from "../../ui/molecules/career-why";
import { CareerProcess } from "../../ui/molecules/career-process";
import { CareerJobs } from "../../ui/molecules/career-jobs";
import { useTranslation } from "react-i18next";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import generateLink from "../../../core/api/assets"
import getSlug from "../../../core/router/getSlug";

export const CareerPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [checkList, setCheckList] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/check_list/page/${pageid}`)
                .then(response => {
                    setCheckList(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    return <>
        <BiContainer>
            <CareerBreadcrumb />
            <HeadCompetences 
                title={content['header_-_title']}
            />
        </BiContainer>
        <ImgBackgroundFull BackgroundMobile={generateLink(content['header_-_banner'])} BackgroundDesktop={generateLink(content['header_-_banner'])} />
        <BiContainer>
            <ContentCareer
                title={content['section_1_-_title']}
                content_one={content['section_1_-_content']}
                content_two=""
                image={generateLink(content['section_1_-_image'])}
            />
            <PercentValuesCareer 
                value_1={content['values_long_box_1_-_title']}
                title_1={content['values_long_box_1_-_content']}
                value_2={content['values_long_box_2_-_title']}
                title_2={content['values_long_box_2_-_content']}
                value_3={content['values_long_box_3_-_title']}
                title_3={content['values_long_box_3_-_content']}
                value_4={content['values_long_box_4_-_title']}
                title_4={content['values_long_box_4_-_content']}
                value_5={content['values_long_box_5_-_title']}
                title_5={content['values_long_box_5_-_content']}
                value_6={content['values_long_box_6_-_title']}
                title_6={content['values_long_box_6_-_content']}
            />
            <OurTeams 
                title={content['section_2_-_title']}
                content={content['section_2_-_content']}
                banner={generateLink(content['section_2_-_image'])}
                text_link={content['section_2_-_link_text']}
                url={content['section_2_-_link']}
            />
            <Map 
                title={content['section_3_-_title']}
                content={content['section_3_-_content']}
                url={content['section_3_-_link']}
                text_link={content['section_3_-_link_text']}
            />
            <CareerJobs
                title={content['section_4_-_title']}
                items={jobs}
                url={content['section_4_-_link']}
                text_link={content['section_4_-_link_text']}
            />
            <CareerWhy
                title={content['section_5_-_title']}
                content={content['section_5_-_content']}
                items={checkList}
                url={content['section_5_-_link']}
                text_link={content['section_5_-_link_text']}
            />
            <CareerProcess
                title={content['section_6_-_title']}
                content={content['section_6_-_content']}
                link={content['section_6_-_link']}
                link_text={content['section_6_-_link_text']}
            />
            <ContactCv
                title={content['contact_title']}
                content={content['contact_content']}
            />
            <ContactPersonPopup
                title={content['contact_popup_title']}
                place={content['contact_popup_position']}
                content={content['contact_popup_content']}
                name={content['contact_popup_person_name']}
                image={generateLink(content['contact_popup_avatar'])}
                send_to={content['contact_send_to']}
                pardot_link={content['pardot_link']}
            />
        </BiContainer>
    </>
}
