import styled from "styled-components";
import LogoImage from "../../../../assets/icons/logo-black.svg";
import {media} from "../theme";

export const Logo = styled.div`
  background-image: url("${LogoImage}");
  background-repeat: no-repeat;
  width: 160px;
  height: 40px;
  background-size: 160px;
  margin: 20px 0;
  float: left;
  position: relative;

  a{
    position: absolute;
    height: 40px;
    width: 160px;
  }

  ${media.up('lg')} {
    background-image: url("${LogoImage}");
    width: 160px;
    height: 40px;
    background-size: 160px;
    margin-right: 60px;

    a{
      width: 160px;
      height: 40px;
    }
  }
`;
