import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import { Wrapper, WrapperSoloItem, Image, Content, Title, Info, ContentIn, WrapperItems } from "./styles";
import { IArticleOthers } from "./interface";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGlobalLang } from '../lang-header/useGlobalLang'
import Moment from 'react-moment';
import generateLink from "../../../../core/api/assets"

export const ArticleOthers: FunctionComponent<IArticleOthers> = ({title, items}) => {    
    const { t } = useTranslation();
    const [articles, setArticles] = useState([]);
    const [lang, setLang] = useGlobalLang();

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection-articles`)
        .then(response => {
            setArticles(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    const getDataArticle = (id, type) => {
        const indexEl = Object.keys(articles).find((keyName) => articles[keyName].id == id)
        if(indexEl){
            if(type == 'slug'){
                return articles[indexEl].slug
            }else if(type == 'created_at'){
                return articles[indexEl].created_at
            }else{
                return articles[indexEl].data[type]
            }
        }
    }

    return <>
        <Wrapper>
            <h4>{title}</h4>
            {items &&<WrapperItems>
                {items.map((item) => (
                    <WrapperSoloItem>
                        <Image>
                            <Link to={`/blog/${getDataArticle(item,'slug')}`}>
                                <img src={generateLink(getDataArticle(item,'thumb'))} />
                            </Link>
                        </Image>
                        <Content>
                            <Title>
                                <p><Moment format="DD.MM.YYYY">{getDataArticle(item,'created_at')}</Moment></p>
                                <h5><Link to={`/blog/${getDataArticle(item,'slug')}`}>{getDataArticle(item,'title')}</Link></h5>
                            </Title>
                            <Info>
                                <p>{getDataArticle(item,'category')}</p>
                                <span></span>
                                <p>read in {getDataArticle(item,'time_to_read')} minutes</p>
                            </Info>
                            <ContentIn>
                                <p>{(getDataArticle(item,'intro')) ? `${getDataArticle(item,'intro').substring(0, 130)} ...` : getDataArticle(item,'intro')}</p>
                            </ContentIn>
                        </Content>
                    </WrapperSoloItem>
                ))}
            </WrapperItems>}
        </Wrapper>
    </>
}
