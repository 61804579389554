import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerNewsletter = styled.div`
  background: #EDEFF1;
  text-align: center;
  padding: 50px 0 40px 0 ;
  ${media.up("lg")} {
    padding: 120px 0;
  }
`
export const WrapperNewsletter = styled.div`
  .title {
    font-size: 24px;
    line-height: 30px;
    margin: 0;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    max-width: 295px;
    margin: 10px auto auto;

  }

  .button {
    cursor: pointer;
    background: ${(props) => props.theme.colors.primaryOrange};
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
    max-width: 141px;
    color: ${(props) => props.theme.colors.white};
    padding: 11.5px 20px;
    margin-top: 20px;
    outline: unset;
    border: unset;

    &:hover {
      opacity: 0.8;
    }
  }

  .input {
    border: 1px solid ${(props) => props.theme.colors.borderColorMain};
    box-sizing: border-box;
    border-radius: 6px;
    max-width: 285px;
    padding: 6.5px 15px;
    margin-top: 20px;
    line-height: 27px;
    font-size: 15px;

  }


  ${media.up("lg")} {
    .title {
      font-size: 34px;
      line-height: 42px;
      margin: 0;
    }

    .description {
      font-size: 22px;
      line-height: 32px;
      max-width: 613px;
      margin: 20px auto auto;
    }

    .input {
      max-width: 386px;
      padding: 16.5px 20px;
      font-size: 18px;
    }

    .button {
      font-size: 18px;
      max-width: 141px;
      padding: 19px 26px;
    }
  }

  .action-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`