import styled from "styled-components";
import {media} from "../../atoms/theme";
import BurgerIcon from "../../../../assets/icons/burger.svg";
import LogoPartnership from "../../../../assets/icons/logo-black.svg";
import CloseMenu from "../../../../assets/icons/close-menu.svg";
import Arrow from "../../../../assets/icons/right-down-arrow-orange.svg";

export const MobileHeaderWrapper = styled.div`
    position: fixed;
    background: #ffffff;
    width: 100%;
    height: 100vh;
    z-index: 20;
    top: 0;
    left: 0;
    ${media.up('nav')} {
        display: none;
    }
`

export const Burger = styled.div`
    background-image: url("${BurgerIcon}");
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    background-size: 32px;
    margin: 24px 0;
    cursor: pointer;
    float: right;

    ${media.up('nav')} {
    display: none;
    }
`

export const MobileHeaderTop = styled.div`
    height: 80px;
    padding: 20px;
    width: 100%;
`

export const Logo = styled.div`
    background-image: url("${LogoPartnership}");
    width: 141px;
    height: 35px;
    background-size: 141px;
    float: left;
    margin: 2.5px 0;
    a{
        display: block;
        width: 141px;
        height: 35px;
    }
`

export const Close = styled.div`
    background-image: url("${CloseMenu}");
    width: 32px;
    height: 32px;
    background-size: 32px;
    float: right;
    cursor: pointer;
    margin: 4px 0;
`

export const MobileHeaderBottomOut = styled.div`
    position: absolute;
    background: #fff;
    z-index: 10;
    bottom: 30px;
    left: 0;
    width: 100%;
`

export const Button = styled.div`
    display: none;
    width: 170px;
    height: 40px;
    float: left;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    background: ${(props) => props.theme.colors.primaryOrange};
    margin: 20px 0;
    border-radius: 5px;
    transition: background 0.2s;
    cursor: pointer;
    a{
    display: block;
    width: 170px;
    height: 40px;
    color: #fff;
    text-decoration: none;
    }
    &:hover{
        background: #000000;
        transition: background 0.2s;
    }


    ${media.up('nav')} {
    display: block;
    }
`

export const MobileHeaderBottom = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    padding: 0 20px;
    margin: auto;
    div{
        display: block;
        &:nth-child(1){
            float: right;
        }
        &:nth-child(2){
            float: left;
        }
    }
    ${media.up('xs')} {
        width: 370px;
    }
    ${media.up('sm')} {
        width: 540px;
    }
    ${media.up('md')} {
        width: 800px;
    }
`

export const MobileHeaderCenterOut = styled.div`
    display: table;
    box-sizing: border-box;
    padding: 0 20px;
    background: #ffffff;
`

export const MobileHeaderCenter = styled.div`
    width: 100%;
    height: calc(100vh - 190px);
    overflow: auto;
    div{
        display: table;
        box-sizing: border-box;
        width: 100%;
        ul{
            width: 100%;
            li{
                width: 100%;
                a{
                    width: 100%;
                    display: block;
                    font-size: 22px;
                    line-height: 26px;
                    font-weight: 600;
                    padding: 20px 0;
                    border-bottom: 1px solid #D1D8DB;
                    i{
                        position: absolute;
                        right: 0;
                    }
                }
                .dropdown-list{
                    width: 100%;
                    position: relative;
                    box-shadow: none;
                    padding: 0;
                    margin-top: -2px;
                    a{
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: 400;
                        border-bottom: none;
                        padding: 0 0 20px 0;
                    }
                    .dropdown-list-title{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
`