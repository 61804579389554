import styled from "styled-components";
import {WrapperBorder} from "../../atoms/line-border-header/styles";
import {media} from "../../atoms/theme";


export const WrapperBorderIndustries = styled(WrapperBorder)`
  border-right: 0;
  height: 6px;



  ${media.up('lg')} {
    width: 79%;
    max-width: 881.34px;
  }

  ${media.up('xl')} {
    width: 100%;
    max-width:1000px;
  }

`

export const WrapperDescriptionHeadIndustries = styled.div`
  max-width: 770px;

  ${media.up('lg')} {
    font-size: 22px;
    line-height: 32px;
  }
`

export const WrapperHeadIndustries = styled.div`
  position: relative;
  margin-bottom: 20px;

  ${media.up('lg')} {
    margin-bottom: 80px;
  }

  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`
