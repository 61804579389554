import { HomePage } from '../../components/pages/home';
import { ProductsServices } from '../../components/pages/products-services';
import { IndustriesPage } from '../../components/pages/industries';
import { SaleforcePage } from '../../components/pages/saleforce';
import { TechnologyPage } from '../../components/pages/technology';
import { CompetencesPage } from '../../components/pages/competences';
import { SoftDevPage } from '../../components/pages/soft-dev';
import { InnovationLabPage } from '../../components/pages/innovation-lab';
import { DevOpsPage } from '../../components/pages/devops';
import { OutsourcingPage } from '../../components/pages/outsourcing';
import { PharmacyPage } from '../../components/pages/pharmacy';
import { CaseStudiesPage } from '../../components/pages/case-studies';
import { CaseStudiesCategoryPage } from '../../components/pages/case-studies-category';
import { CaseStudiesDetailsPage } from '../../components/pages/case-studies-details';
import { CareerPage } from '../../components/pages/career';
import { CareerTeamsPage } from '../../components/pages/career-teams';
import { HowToJoinPage } from '../../components/pages/how-to-join';
import { AboutPage } from '../../components/pages/about';
import { JobOffersPage } from '../../components/pages/job-offers';
import { JobOfferPage } from '../../components/pages/job-offer';
import {PeopleLocationsPage}  from '../../components/pages/people-locations';
import { CompanyPage } from '../../components/pages/company';
import { CompanyCulturePage } from '../../components/pages/company-culture';
import { CompanyBoardPage } from '../../components/pages/company-board';
import { CompanyBoardPersonPage } from '../../components/pages/company-board-person';
import { CompanyPressPage } from '../../components/pages/company-press';
import { CompanyPressNewsPage } from '../../components/pages/company-press-news';
import { CompanyHistoryPage } from '../../components/pages/company-history';
import { CompanyGroupPage } from '../../components/pages/company-group';
import { InperlyPage } from '../../components/pages/inperly';
import { PaperlessPage } from '../../components/pages/paperless';
import { PrivacyPolicyPage } from "../../components/pages/privacy-policy/privacy-policy";
import { BlogPage } from "../../components/pages/blog/blog";
import { Category } from "../../components/pages/blog/category/category";
import { ArticlePage } from "../../components/pages/article/article";

const getPageComponent = (component) => {
    if(component === 'home'){
        return HomePage;
    }else if(component === 'products-services'){
        return ProductsServices;
    }else if(component === 'industries'){
        return IndustriesPage;
    }else if(component === 'competences'){
        return CompetencesPage;
    }else if(component === 'sale-force'){
        return SaleforcePage;
    }else if(component === 'technology'){
        return TechnologyPage;
    }else if(component === 'soft-dev'){
        return SoftDevPage;
    }else if(component === 'innovation-lab'){
        return InnovationLabPage;
    }else if(component === 'devops'){
        return DevOpsPage;
    }else if(component === 'outsourcing'){
        return OutsourcingPage;
    }else if(component === 'pharmacy'){
        return PharmacyPage;
    }else if(component === 'case-studies'){
        return CaseStudiesPage;
    }else if(component === 'case-studies-category'){
        return CaseStudiesCategoryPage;
    }else if(component === 'case-studies-details'){
        return CaseStudiesDetailsPage;
    }else if(component === 'career'){
        return CareerPage;
    }else if(component === 'career-teams'){
        return CareerTeamsPage;
    }else if(component === 'how-to-join'){
        return HowToJoinPage;
    }else if(component === 'about-billennium'){
        return AboutPage;
    }else if(component === 'job-offers'){
        return JobOffersPage;
    }else if(component === 'job-offer'){
        return JobOfferPage;
    }else if(component === 'company'){
        return CompanyPage;
    }else if(component === 'company-culture'){
        return CompanyCulturePage;
    }else if(component === 'people-locations'){
        return PeopleLocationsPage;
    }else if(component === 'company-board'){
        return CompanyBoardPage;
    }else if(component === 'company-board-person'){
        return CompanyBoardPersonPage;
    }else if(component === 'company-press'){
        return CompanyPressPage;
    }else if(component === 'company-press-news'){
        return CompanyPressNewsPage;
    }else if(component === 'company-history'){
        return CompanyHistoryPage;
    }else if(component === 'company-group'){
        return CompanyGroupPage;
    }else if(component === 'inperly'){
        return InperlyPage;
    }else if(component === 'paperless'){
        return PaperlessPage;
    } else if (component === 'privacy-policy') {
        return PrivacyPolicyPage;
    } else if (component === 'blog') {
        return BlogPage;
    } else if (component === 'blog-category') {
        return Category;
    } else if (component === 'article') {
        return ArticlePage;
    }
}

export default getPageComponent;