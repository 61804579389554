import React, {FunctionComponent} from "react";
import {WrapperHomeCompetences, DescCompetences} from './styles'
import { IHomeCompetences } from "./interface";
import { SoloHomeCompetences } from "../../molecules/solo-home-competences";
import generateLink from "../../../../core/api/assets"

export const HomeCompetences: FunctionComponent<IHomeCompetences> = ({title, content, items}) => {

    return<>
        <WrapperHomeCompetences>
            <DescCompetences>
                {title &&<h4>{title}</h4>}
                {content &&<p>{content}</p>}
            </DescCompetences>
            {Object.keys(items).map((keyName, i) => (
                <SoloHomeCompetences 
                    title={items[keyName].data['title']}
                    content={items[keyName].data['content']}
                    gif={generateLink(items[keyName].data['animation'])}
                    items={items[keyName].data['list']}
                    link={items[keyName].data['link']}
                />
            ))}
        </WrapperHomeCompetences>
    </>
}
