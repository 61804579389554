import React, {FunctionComponent, useState} from "react";
import {SoloHomeCompetencesWrapper} from './styles'
import { ActionArrowAtom } from "../../atoms/action-arrow";
import { ISoloHomeCompetences } from "./interfaces";
import { useTranslation } from "react-i18next";

export const SoloHomeCompetences: FunctionComponent<ISoloHomeCompetences> = ({title, content, gif, items, link}) => {
    const [openContent, setOpenContent] = useState(false);
    const { t } = useTranslation();

    return<>
        <SoloHomeCompetencesWrapper>
            <div className="toggl-open" onClick={() => setOpenContent(prev => !prev)}>
                <p className={openContent ? 'active' : ''}>{title}</p>
            </div>
            <div className={openContent ? 'show-mobile' : 'hide-mobile'}>
                <div className="gif-wrapper">
                    <img alt="Gif" src={gif} />
                </div>
                <div className="content-competences">
                    <h4>{title}</h4>
                    <p>{content}</p>
                    <ul>
                        {items.map(item => (
                            <li>{item}</li>
                        ))}
                    </ul>
                    <ActionArrowAtom  link={link ? link : ""} text={t('read_more')}/>
                </div>
            </div>
        </SoloHomeCompetencesWrapper>
    </>
}
