import getPageComponent from './getPageComponent';

const checkHomeSlug = (slug) => {
  if(slug === 'home'){
    return '';
  }else{
    return slug;
  }
};

export const getApiRoutesPeople = (routes) => {
  let pages = {};
  Object.keys(routes).map((keyName, i) => (
      pages = {
        ...pages,
        [routes[keyName].id]: {
          id: routes[keyName].id,
          name: routes[keyName].title,
          slug: '/company-board/' + routes[keyName].slug,
          component: getPageComponent('company-board-person'),
          undertitle: routes[keyName].undertitle,
        }
      }
  ))
  const pagesArray = Object.entries(pages).map(([_, v]) => v);
  return pagesArray;
}

export const getApiRoutesPress = (routes) => {
  let pages = {};
  Object.keys(routes).map((keyName, i) => (
      pages = {
        ...pages,
        [routes[keyName].id]: {
          id: routes[keyName].id,
          name: routes[keyName].title,
          slug: '/company-press/' + routes[keyName].slug,
          component: getPageComponent('company-press-news'),
          undertitle: routes[keyName].undertitle,
        }
      }
  ))
  const pagesArray = Object.entries(pages).map(([_, v]) => v);
  return pagesArray;
}

export const getApiRoutesJob = (routes) => {
  let pages = {};
  Object.keys(routes).map((keyName, i) => (
      pages = {
        ...pages,
        [routes[keyName].id]: {
          id: routes[keyName].id,
          name: routes[keyName].title,
          slug: '/job-offer/' + routes[keyName].id,
          component: getPageComponent('job-offer'),
          undertitle: routes[keyName].undertitle,
        }
      }
  ))
  const pagesArray = Object.entries(pages).map(([_, v]) => v);
  return pagesArray;
}

export const getApiRoutesCaseStudyCategory = (routes) => {
  let pages = {};
  Object.keys(routes).map((keyName, i) => (
      pages = {
        ...pages,
        [routes[keyName].id]: {
          id: routes[keyName].id,
          name: routes[keyName].title,
          slug: '/case-studies-category/' + routes[keyName].slug,
          component: getPageComponent('case-studies-category'),
          undertitle: routes[keyName].undertitle,
        }
      }
  ))
  const pagesArray = Object.entries(pages).map(([_, v]) => v);
  return pagesArray;
}

export const getApiRoutesCaseStudy = (routes) => {
  let pages = {};
  Object.keys(routes).map((keyName, i) => (
      pages = {
        ...pages,
        [routes[keyName].id]: {
          id: routes[keyName].id,
          name: routes[keyName].title,
          slug: '/case-study/' + routes[keyName].slug,
          component: getPageComponent('case-studies-details'),
          undertitle: routes[keyName].undertitle,
        }
      }
  ))
  const pagesArray = Object.entries(pages).map(([_, v]) => v);
  return pagesArray;
}

export const getApiRoutesIndustries = (routes) => {
  let pages = {};
  Object.keys(routes).map((keyName, i) => (
      pages = {
        ...pages,
        [routes[keyName].id]: {
          id: routes[keyName].id,
          name: routes[keyName].title,
          slug: '/industries/' + routes[keyName].slug,
          component: getPageComponent('pharmacy'),
          undertitle: routes[keyName].undertitle,
        }
      }
  ))
  const pagesArray = Object.entries(pages).map(([_, v]) => v);
  return pagesArray;
}

export const getApiRoutesArticles = (routes) => {
  let pages = {};
  Object.keys(routes).map((keyName, i) => (
      pages = {
        ...pages,
        [routes[keyName].id]: {
          id: routes[keyName].id,
          name: routes[keyName].title,
          slug: '/blog/' + routes[keyName].slug,
          component: getPageComponent('article'),
          undertitle: routes[keyName].undertitle,
        }
      }
  ))
  const pagesArray = Object.entries(pages).map(([_, v]) => v);
  return pagesArray;
}

export const getApiRoutes = (routes) => {
    let pages = {};
    /*Object.keys(routes).map((keyName, i) => (
      pages = {
        ...pages,
        [routes[keyName].id]: {
          id: routes[keyName].id,
          name: routes[keyName].title,
          slug: '/' + checkHomeSlug(routes[keyName].slug),
          component: getPageComponent(routes[keyName].template.value),
          undertitle: routes[keyName].undertitle,
        }
      }
    ))*/
    pages = {
      ...pages,
      //add static page
      'Home': {
        id: 'home',
        name: 'Home',
        slug: '/',
        component: getPageComponent('home'),
        undertitle: '',
      },
      'Competences': {
        id: 'competences',
        name: 'Competences',
        slug: '/competencies',
        component: getPageComponent('competences'),
        undertitle: '',
      },
      'BusinessAnalysis': {
        id: 'soft-dev',
        name: 'Business Analysis',
        slug: '/business-analysis',
        component: getPageComponent('soft-dev'),
        undertitle: '',
      },
      'UX-design': {
        id: 'ux-design',
        name: 'UX Design',
        slug: '/ux-design',
        component: getPageComponent('soft-dev'),
        undertitle: '',
      },
      'SoftwareDeveloUpment': {
        id: 'soft-dev',
        name: 'Software Development',
        slug: '/software-development',
        component: getPageComponent('soft-dev'),
        undertitle: '',
      },
      'QualityAssurance': {
        id: 'quality-assurance',
        name: 'Quality Assurance',
        slug: '/quality-assurance',
        component: getPageComponent('soft-dev'),
        undertitle: '',
      },
      'Integration': {
        id: 'integration',
        name: 'Integration',
        slug: '/integration',
        component: getPageComponent('soft-dev'),
        undertitle: '',
      },
      'Automation': {
        id: 'automation',
        name: 'Automation',
        slug: '/automation',
        component: getPageComponent('soft-dev'),
        undertitle: '',
      },
      'DevOpsCloud': {
        id: 'devops-and-cloud',
        name: 'DevOps & cloud',
        slug: '/devops-and-cloud',
        component: getPageComponent('soft-dev'),
        undertitle: '',
      },
      'ModernWorkplace': {
        id: 'modern-workplace',
        name: 'Modern Workplace',
        slug: '/modern-workplace',
        component: getPageComponent('soft-dev'),
        undertitle: '',
      },
      'IdentityManagement': {
        id: 'identity-management',
        name: 'Identity Management',
        slug: '/identity-management',
        component: getPageComponent('soft-dev'),
        undertitle: '',
      },
      'SoftwareLifeCycleTools': {
        id: 'software-lifecycle-tools',
        name: 'Software LifeCycle Tools',
        slug: '/software-lifecycle-tools',
        component: getPageComponent('soft-dev'),
        undertitle: '',
      },
      'OperationServices': {
        id: 'operation-services',
        name: 'Operation Services',
        slug: '/operation-services',
        component: getPageComponent('soft-dev'),
        undertitle: '',
      },
      'Workshops': {
        id: 'workshops',
        name: 'Workshops',
        slug: '/workshops',
        component: getPageComponent('soft-dev'),
        undertitle: '',
      },
      'ProjectManagementSCRUM': {
        id: 'project-management-and-scrum',
        name: 'Project Management & SCRUM',
        slug: '/project-management-and-scrum',
        component: getPageComponent('soft-dev'),
        undertitle: '',
      },
      'LaboratoryInformatics': {
        id: 'laboratory-informatics',
        name: 'Laboratory Informatics',
        slug: '/laboratory-informatics',
        component: getPageComponent('soft-dev'),
        undertitle: '',
      },
      'BigDataAndAI': {
        id: 'big-data-and-ai',
        name: 'Big Data & AI',
        slug: '/big-data-and-ai',
        component: getPageComponent('soft-dev'),
        undertitle: '',
      },
      'CRM': {
        id: 'crm',
        name: 'CRM',
        slug: '/crm',
        component: getPageComponent('soft-dev'),
        undertitle: '',
      },
      'BI': {
        id: 'bi',
        name: 'BI',
        slug: '/bi',
        component: getPageComponent('soft-dev'),
        undertitle: '',
      },
      'NET': {
        id: 'net',
        name: 'NET',
        slug: '/net',
        component: getPageComponent('sale-force'),
        undertitle: '',
      },
      'Java': {
        id: 'java',
        name: 'Java',
        slug: '/java',
        component: getPageComponent('sale-force'),
        undertitle: '',
      },
      'Azure': {
        id: 'azure',
        name: 'Azure',
        slug: '/azure',
        component: getPageComponent('sale-force'),
        undertitle: '',
      },
      'Google': {
        id: 'google',
        name: 'Google',
        slug: '/google',
        component: getPageComponent('sale-force'),
        undertitle: '',
      },
      'Amazon': {
        id: 'amazon',
        name: 'Amazon',
        slug: '/aws',
        component: getPageComponent('sale-force'),
        undertitle: '',
      },
      'SalesForce': {
        id: 'salesforce',
        name: 'Salesforce',
        slug: '/salesforce',
        component: getPageComponent('sale-force'),
        undertitle: '',
      },
      'PHP': {
        id: 'php',
        name: 'PHP',
        slug: '/php',
        component: getPageComponent('sale-force'),
        undertitle: '',
      },
      'Angular': {
        id: 'angular',
        name: 'Angular',
        slug: '/angular',
        component: getPageComponent('sale-force'),
        undertitle: '',
      },
      'React': {
        id: 'react',
        name: 'React',
        slug: '/react',
        component: getPageComponent('sale-force'),
        undertitle: '',
      },
      'MobileDevelopment': {
        id: 'mobile-development',
        name: 'Mobile Development',
        slug: '/mobile-development',
        component: getPageComponent('sale-force'),
        undertitle: '',
      },
      'Python': {
        id: 'python',
        name: 'Python',
        slug: '/python',
        component: getPageComponent('sale-force'),
        undertitle: '',
      },
      'ProductsServices': {
        id: 'products-services',
        name: 'Products Services',
        slug: '/products-and-services',
        component: getPageComponent('products-services'),
        undertitle: '',
      },
      'Industries': {
        id: 'industries',
        name: 'Industries',
        slug: '/industries',
        component: getPageComponent('industries'),
        undertitle: '',
      },
      'InnovationLab': {
        id: 'innovation-lab',
        name: 'InnovationLab',
        slug: '/innovation-lab',
        component: getPageComponent('innovation-lab'),
        undertitle: '',
      },
      'Outsourcing': {
        id: 'outsourcing',
        name: 'outsourcing',
        slug: '/outsourcing',
        component: getPageComponent('outsourcing'),
        undertitle: '',
      },
      'Consulting': {
        id: 'consulting',
        name: 'consulting',
        slug: '/consulting',
        component: getPageComponent('outsourcing'),
        undertitle: '',
      },
      'Project': {
        id: 'project',
        name: 'project',
        slug: '/project',
        component: getPageComponent('outsourcing'),
        undertitle: '',
      },
      'CaseStudies': {
        id: 'case-studies',
        name: 'CaseStudies',
        slug: '/case-studies',
        component: getPageComponent('case-studies'),
        undertitle: '',
      },
      'Career': {
        id: 'career',
        name: 'Career',
        slug: '/career',
        component: getPageComponent('career'),
        undertitle: '',
      },
      'CareerTeams': {
        id: 'career-teams',
        name: 'CareerTeams',
        slug: '/career-teams',
        component: getPageComponent('career-teams'),
        undertitle: '',
      },
      'HowToJoin': {
        id: 'how-to-join',
        name: 'HowToJoin',
        slug: '/how-to-join',
        component: getPageComponent('how-to-join'),
        undertitle: '',
      },
      'AboutBillennium': {
        id: 'about-billennium',
        name: 'AboutBillennium',
        slug: '/about-billennium',
        component: getPageComponent('about-billennium'),
        undertitle: '',
      },
      'JobOffers': {
        id: 'job-offers',
        name: 'JobOffers',
        slug: '/job-offers',
        component: getPageComponent('job-offers'),
        undertitle: '',
      },
      'PeopleLocations': {
        id: 'people-locations',
        name: 'People Locations',
        slug: '/people-locations',
        component: getPageComponent('people-locations'),
        undertitle: '',
      },
      'Company': {
        id: 'company',
        name: 'Company',
        slug: '/company',
        component: getPageComponent('company'),
        undertitle: '',
      },
      'CompanyHistory': {
        id: 'company-history',
        name: 'CompanyHistory',
        slug: '/company-history',
        component: getPageComponent('company-history'),
        undertitle: '',
      },
      'CompanyCulture': {
        id: 'company-culture',
        name: 'CompanyCulture',
        slug: '/company-culture',
        component: getPageComponent('company-culture'),
        undertitle: '',
      },
      'CompanyBoard': {
        id: 'company-board',
        name: 'CompanyBoard',
        slug: '/company-board',
        component: getPageComponent('company-board'),
        undertitle: '',
      },
      'CompanyBoardPerson': {
        id: 'company-board-person',
        name: 'CompanyBoardPerson',
        slug: '/company-board-person',
        component: getPageComponent('company-board-person'),
        undertitle: '',
      },
      'CompanyPress': {
        id: 'company-press',
        name: 'CompanyPress',
        slug: '/company-press',
        component: getPageComponent('company-press'),
        undertitle: '',
      },
      'CompanyPressNews': {
        id: 'company-press-news',
        name: 'CompanyPressNews',
        slug: '/company-press-news',
        component: getPageComponent('company-press-news'),
        undertitle: '',
      },
      'Inperly': {
        id: 'inperly',
        name: 'Inperly',
        slug: '/inperly',
        component: getPageComponent('inperly'),
        undertitle: '',
      },
      'Paperless': {
        id: 'paperless',
        name: 'Paperless',
        slug: '/paperless',
        component: getPageComponent('paperless'),
        undertitle: '',
      },
      'PrivacyPolicy': {
        id: 'privacy-policy',
        name: 'Privacy Policy',
        slug: '/privacy-policy',
        component: getPageComponent('privacy-policy'),
        undertitle: '',
      },
      'Blog': {
        id: 'blog',
        name: 'Blog',
        slug: '/blog',
        component: getPageComponent('blog'),
        undertitle: '',
      },
      'Article': {
        id: 'article',
        name: 'Article',
        slug: '/blog/solo-article',
        component: getPageComponent('article'),
        undertitle: '',
      },
      'Development': {
        id: 'development',
        name: 'Development',
        slug: '/blog/category/development',
        component: getPageComponent('blog-category'),
        undertitle: '',
      },
      'Data': {
        id: 'data',
        name: 'Data',
        slug: '/blog/category/data',
        component: getPageComponent('blog-category'),
        undertitle: '',
      },
      'Design': {
        id: 'design',
        name: 'Design',
        slug: '/blog/category/design',
        component: getPageComponent('blog-category'),
        undertitle: '',
      },
      'ProjectManagment': {
        id: 'project-managment',
        name: 'Project Managment',
        slug: '/blog/category/project-management',
        component: getPageComponent('blog-category'),
        undertitle: '',
      },
      'HR': {
        id: 'hr',
        name: 'HR',
        slug: '/blog/category/hr',
        component: getPageComponent('blog-category'),
        undertitle: '',
      },
      'Business': {
        id: 'business',
        name: 'Business',
        slug: '/blog/category/business',
        component: getPageComponent('blog-category'),
        undertitle: '',
      }
    }
  const pagesArray = Object.entries(pages).map(([_, v]) => v);
  return pagesArray;
}