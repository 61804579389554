import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperInperlySections = styled.div`
    display: table;
    box-sizing: border-box;
    width: 100%;
    margin: 0 0 160px 0;
    ${media.down('lg')} {
        margin-bottom: 60px;
    }
    ${media.down('md')} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const Title = styled.div`
    display: table;
    box-sizing: border-box;
    width: 100%;
    h4{
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0;
        color: #25282B;
    }
    p{
       max-width: 692px;
       font-size: 18px;
       font-style: normal;
       font-weight: 400;
       line-height: 26px;
       letter-spacing: 0.02em;
       text-align: left;
       color: #25282B;
       margin: 40px 0 0 0;
    }
    ${media.down('lg')} {
        h4{
            font-size: 30px;
            line-height: 38px;
            color: #000;
        }
        p{
            font-size: 16px;
            line-height: 24px;
            color: #000;
            margin-top: 20px;
        }
    }
`

export const Sections = styled.div`
    display: table;
    box-sizing: border-box;
    width: 100%;
`

export const SoloSection = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    margin-top: 120px;
    ${media.down('xl')} {
        display: table;
        box-sizing: border-box;
    }
    ${media.down('lg')} {
        margin-top: 40px;
    }
`

export const ImageLeft = styled.div`
    order: 1;
    max-width: 508px;
    img{
        width: 100%;
    }
    ${media.down('xl')} {
        margin-bottom: 20px;
    }
    ${media.down('lg')} {
        max-width: 250px;
    }
`

export const ImageRight = styled.div`
    order: 2;
    max-width: 508px;
    img{
        width: 100%;
    }
    ${media.down('xl')} {
        margin-bottom: 20px;
    }
    ${media.down('lg')} {
        max-width: 250px;
    }
`

export const ContentLeft = styled.div`
    order: 1;
    display: flex;
    justify-content: center;
    align-items: center; 
    max-width: 488px;
    ${media.down('xl')} {
        width: 100%;
        max-width: 100%;
        display: table;
        box-sizing: border-box;
    }
`

export const ContentRight = styled.div`
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center; 
    max-width: 488px;
    ${media.down('xl')} {
        width: 100%;
        max-width: 100%;
        display: table;
        box-sizing: border-box;
    }
`

export const Content = styled.div`
    max-width: 488px;
    h6{
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #000000;
        margin: 0 0 20px 0;
    }
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #000000;
        margin: 0;
    }
    ul{
        padding: 0 0 0 20px;
        li{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.02em;
            text-align: left;
            color: #000000;
            margin: 0;
        }
    }
    ${media.down('xl')} {
        max-width: 100%;
    }   
    ${media.down('lg')} {
        h6{
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 10px;
        }
        p, ul li{
            font-size: 16px;
            line-height: 24px;
        }
    }
`