import styled from "styled-components";
import ArrowIcon from "../../../../assets/icons/arrow-left-b.svg";

export const Arrow = styled.div`
  background-image: url("${ArrowIcon}");
  background-repeat: no-repeat;
  width: 4px;
  height: 9px;
  background-size: 4px;
  marging: 5.5px 10px;
  float: left;
`;
