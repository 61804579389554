import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container";
import { useTranslation } from "react-i18next";
import HeadCompetences from "../../ui/organisms/head-competences";
import { CaseStudies } from "../../ui/organisms/case-studies/case-studies";
import {OutsourcingBreadcrumb} from "../../breadcrumbs/outsourcing/outsourcing";
import {ImgBackgroundFull} from "../../ui/atoms/banner-full/styles";
import { UnderBanner } from "../../ui/molecules/under-banner";
import { BeforeFooter } from "../../ui/molecules/before-footer";
import { HomeCompetences } from "../../ui/organisms/home-competences/home-competences";
import { Models } from "../../ui/molecules/models";
import { NumberList } from "../../ui/molecules/number-list";
import { PopupContact } from "../../ui/molecules/popup-contact";
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import generateLink from "../../../core/api/assets"
import getSlug from "../../../core/router/getSlug";

export const OutsourcingPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [competencies, setCompetencies] = useState([]);
    const [caseStudy, setCaseStudy] = useState([]);
    const [models, setModels] = useState([]);
    const [servicesBox, setServicesBox] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/competencies/page/${pageid}`)
                .then(response => {
                    setCompetencies(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/models/page/${pageid}`)
                .then(response => {
                    setModels(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/services_box/page/${pageid}`)
                .then(response => {
                    setServicesBox(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/case_study/page/${pageid}`)
                .then(response => {
                    setCaseStudy(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    return <>
        <BiContainer>
            <OutsourcingBreadcrumb title={content['title']} slug={slug}/>
            <HeadCompetences 
                title={content['header_-_title']}
            />
        </BiContainer>
        <ImgBackgroundFull BackgroundMobile={generateLink(content['header_-_banner'])} BackgroundDesktop={generateLink(content['header_-_banner'])} />
        <UnderBanner
            content={content['section_1_-_content']}
        />
        <BiContainer >
            <HomeCompetences 
                title={content['section_2_-_title']}
                content={content['section_2_-_content']}
                items={competencies}
            />
            <Models 
                title={content['section_3_-_title']}
                content={content['section_3_-_content']}
                items={models}
            />
            <NumberList
                title={content['section_4_-_title']}
                steps={servicesBox}
            />
            <CaseStudies 
                title={content['section_5_-_title']}
                items={caseStudy}
            />
        </BiContainer>
        <BeforeFooter
            title={content['before_footer_-_title']}
            content={content['before_footer_-_content']}
            item_1={content['before_footer_-_title_link_1']}
            item_1_url={content['before_footer_-_link_1']}
            item_2={content['before_footer_-_title_link_2']}
            item_2_url={content['before_footer_-_link_2']}
            item_3={content['before_footer_-_title_link_3']}
            item_3_url={content['before_footer_-_link_3']}
        />
        <BiContainer>
            <PopupContact
                content={`<p>${content['contact_title']}</p>`}
                text_link="Contact us"
                top={true}
            />
            <ContactPersonPopup
                title={content['contact_popup_title']}
                place={content['contact_popup_position']}
                content={content['contact_popup_content']}
                name={content['contact_popup_person_name']}
                image={generateLink(content['contact_popup_avatar'])}
                send_to={content['contact_send_to']}
                pardot_link={content['pardot_link']}
            />
        </BiContainer>
    </>
}
