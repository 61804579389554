import React, {FunctionComponent} from "react";
import { Container, RightContent, LeftContent, Dot, Bottom} from "./styles";
import { IHistory } from "./interface";

export const ContentHistory: FunctionComponent<IHistory> = ({
    section_1_year,
    section_1_content,
    section_1_image,
    section_2_year,
    section_2_content,
    section_2_image,
    section_3_year,
    section_3_content,
    section_3_list,
    section_4_year,
    section_4_content,
    section_4_image,
    section_5_year,
    section_5_content,
    section_6_year,
    section_6_content,
    section_7_year,
    section_7_content,
    section_7_image,
    out_section_1_title,
    out_section_1_content
}) => {

    console.log(section_3_list);

    return <>
        <Container>
            <RightContent />
            <LeftContent>
                <div className="solo-history">
                    <Dot />
                    <span>{section_1_year}</span>
                    <p>{section_1_content}</p>
                    <img alt="banner_1" src={section_1_image} />
                </div>
                <div className="solo-history solo-history-right r1">
                    <Dot />
                    <span>{section_2_year}</span>
                    <p>{section_2_content}</p>
                    <img alt="banner_2" src={section_2_image} />
                </div>
                <div className="solo-history l2">
                    <Dot />
                    <span>{section_3_year}</span>
                    <p>{section_3_content}</p>
                    {section_3_list && section_3_list.length > 0 &&<ul>
                        {section_3_list.map(item => (
                            <li>{item}</li>
                        ))}
                    </ul>}
                </div>
                <div className="solo-history solo-history-right">
                    <Dot />
                    <span>{section_4_year}</span>
                    <p>{section_4_content}</p>
                    <img alt="banner_3" src={section_4_image} />
                </div>
                <div className="solo-history l3">
                    <Dot />
                    <span>{section_5_year}</span>
                    <p>{section_5_content}</p>
                </div>
                <div className="solo-history l4">
                    <Dot />
                    <span>{section_6_year}</span>
                    <p>{section_6_content}</p>
                </div>
                <div className="solo-history l5">
                    <Dot />
                    <span>{section_7_year}</span>
                    <p>{section_7_content}</p>
                    <img alt="banner_4" src={section_7_image} />
                </div>
            </LeftContent>
        </Container>
        <Bottom>
            <Dot />
            <span>{out_section_1_title}</span>
            <p>{out_section_1_content}</p>
        </Bottom>
    </>
}
