import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperLogoPaperless = styled.div`
  position: absolute;
  right: 136px;
  top: 131px;
  img{
    width: 312px;
  }
  ${media.down('xl')} {
    right: 20px;
    img{
      width: 210px;
    }
  }
  ${media.down('lg')} {
    width: 100%;
    position: static;
    right: auto;
    width: auto;
    margin-top: 40px;
    img{
      widht: 142px;
    }
  }
`
