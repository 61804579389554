import React, {FunctionComponent} from "react";
import styled from "styled-components";
import { media } from "../../atoms/theme/media";
import { IHowCanHelp } from './interface'

export const ContainerHowCanHelp =  styled.div`
  margin-top: 46px;
  margin-bottom: 60px;
  ${media.up('lg')} {
    margin: 160px 0;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const WrapperListHowCanHelp =  styled.div`
   ul {
     padding: 0;
     list-style-type: none;
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: space-between;
     li {
       width: 100%;
       max-width: 590px;
       padding: 15px 0;
       border-bottom: 1px solid #D1D8DB;
       margin: 5px 0;
     }
   }
`

export const HowCanHelp: FunctionComponent<IHowCanHelp> = ({title, content, items}) => {
    return<>
            <ContainerHowCanHelp >
                    <h2>{title}</h2>
                <WrapperListHowCanHelp >
                    <ul>
                      {Object.keys(items).map((keyName, i) => (
                        <li>{items[keyName].data['title']}</li>
                      ))}
                    </ul>
                </WrapperListHowCanHelp>
            </ContainerHowCanHelp>
    </>
}
