import React, {FunctionComponent} from "react";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {WrapperTabsMap, WrapperMap, WrapperMainMap} from './styles'
import { SoloLocation } from "../../molecules/solo-location";
import { ITabs } from "./interface";
import generateLink from "../../../../core/api/assets";

export const CountryTabsContent: FunctionComponent<ITabs> = ({title, items}) => {
    return <>
        <Tabs>
            <WrapperTabsMap>
                <h1>{title}</h1>
                <TabList>
                    {Object.keys(items).map((keyName, i) => (
                        <Tab>{items[keyName].data['title']}</Tab>
                    ))}
                </TabList>
            </WrapperTabsMap>
            {Object.keys(items).map((keyName, i) => (
                <TabPanel>
                    <SoloLocation
                        id={items[keyName].id}
                        countryTitle={items[keyName].data['undertitle']}
                        descriptionCountry={items[keyName].data['content']}
                        map={generateLink(items[keyName].data['map'])}
                        banner={generateLink(items[keyName].data['banner'])}
                        value_1={items[keyName].data['value_box_1_-_value']}
                        title_1={items[keyName].data['value_box_1_-_title']}
                        value_2={items[keyName].data['value_box_2_-_value']}
                        title_2={items[keyName].data['value_box_2_-_title']}
                        value_3={items[keyName].data['value_box_3_-_value']}
                        title_3={items[keyName].data['value_box_3_-_title']}
                        office_title={items[keyName].data['section_1_-_title']}
                        teams_title={items[keyName].data['section_2_-_title']}
                        contact_title={items[keyName].data['contact_-_title']}
                        contact_content={items[keyName].data['contact_-_content']}
                    />
                </TabPanel>
            ))}
        </Tabs>
    </>
}