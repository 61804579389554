import React, {FunctionComponent} from "react";
import { ContainerItems, WrapperSoloItem, WrapperItems } from "./styles";
import { ICheckListPaperless } from "./interface";

export const CheckListPaperless: FunctionComponent<ICheckListPaperless> = ({items, title}) => {

    console.log(items);

    return <>
        <ContainerItems>
            {title &&<h4>{title}</h4>}
            <WrapperItems>
                {Object.keys(items).map((keyName, i) => (
                    <WrapperSoloItem>
                        <p className="title">{items[keyName].data['title']}</p>
                        <p className="content">{items[keyName].data['content']}</p>
                    </WrapperSoloItem>
                ))}
            </WrapperItems>
        </ContainerItems>
    </>
}
