import React, {FunctionComponent} from "react";
import { Link, useHistory } from 'react-router-dom';
import {ArrowRightIcon} from '../../ui/atoms/arrow-right-icon'
import {WrapperBreadcrumb} from '../styles'

export const TechnologyBreadcrumb: FunctionComponent = () => {
    const history = useHistory();

    return <>
        <WrapperBreadcrumb>
            <Link to="/" onClick={() => { history.push('/');}}>Billennium</Link>
            <ArrowRightIcon />
            <Link to="/competencies" onClick={() => { history.push('/competencies');}}>Competencies</Link>
            <ArrowRightIcon />
            <Link to="/technology" onClick={() => { history.push('/technology');}}>Technology</Link>
        </WrapperBreadcrumb>
    </>
}
