import React, {FunctionComponent} from "react";
import {WrapperMap, MapImage, MapHeader} from "./styles";
import map_img from "../../../../assets/images/home/map.png";
import { ActionArrowAtom } from "../../atoms/action-arrow";
import { IMap } from "./interface";

export const Map: FunctionComponent<IMap> = ({title, content, url, text_link}) => {

    return <>
        <WrapperMap>
            <MapHeader>
                <div className="text-left">
                    {title &&<h4>{title}</h4>}
                    <p>{content}</p>
                    {text_link &&<ActionArrowAtom link={`${url}`} text={text_link} />}
                </div>
            </MapHeader>
            <MapImage>
                <img alt="Map img" src={map_img} />
            </MapImage>
        </WrapperMap>
    </>
}
