import styled from "styled-components";
import { media } from "../../atoms/theme/media";
import { IBanner } from "./interface";
import arrow from '../../../../assets/icons/arrow-right-white.svg'

export const WrapperHomeIndustries = styled.div`
    width: 100%;
    display: flex;
    margin: 0 0 160px 0;
    flex-wrap: wrap;
    justify-content: start;
    gap: 20px;
    ${media.down('md')} {
        gap: 5px 0;
        padding: 0 20px;
    }
    ${media.down('md')} {
        margin: 40px 0;
    }
`

export const IndustriesDesc = styled.div`
    min-width: 100%;
    box-sizing: border-box;
    display: table;
    h4{
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0 0 120px 0;
    }
`

export const SoloHomeIndustries = styled.div<IBanner>`
    height: 330px;
    flex: 1 0 25%;
    max-width: calc((100% / 4) - 20px);
    border-radius: 5px;
    overflow: hidden;
    .image-back{
        display: block;
        cursor: pointer;
        width: 100%;
        height: 100%;
        background-image: url(${(props) => props.banner});
        background-position: 50% 50%;
        background-size: cover;
        text-decoration: none;
        .image-back-shadow{
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.8) 100%);
            width: 100%;
            height: 100%;
            position: relative;
            box-sizing: border-box;
            display: table;
            padding-top: 100px;
            p{
                text-align: center;
                font-size: 24px;
                line-height: 34px;
                color: #fff;
                font-weight: 600;
            }
            span{
                position: absolute;
                display: block;
                width: 28px;
                height: 3px;
                background: #fff;
                bottom: 120px;
                left: calc((100% - 28px) / 2);
            }
        }
    }
    ${media.down('xl')} {
        flex: 1 0 33%;
        max-width: calc((100% / 3) - 20px);
    }
    ${media.down('lg')} {
        flex: 1 0 50%;
        max-width: calc((100% / 2) - 20px);
    }
    ${media.down('md')} {
        height: 92px;
        flex: 1 0 100%;
        max-width: 100%;
        margin-bottom: 0;
        .image-back{
            .image-back-shadow{
                display: flex;
                align-items: center;
                padding: 33px 30px;
                p{
                    float: left;
                    margin: 0;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: left;
                    width: calc(100% - 50px);
                }
                span{
                    float: right;
                    bottom: 30px;
                    left: calc(100% - 60px);
                    background: url(${arrow}) no-repeat;
                    background-size: 32px;
                    height: 32px;
                    width: 64px;
                }
            }
        }
    }
`