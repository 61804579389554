import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerItems = styled.div`
  display: table;
  box-sizing: border-box;
  margin: 120px 0 160px 0;
  width: 100%;
  h4{
    font-size: 46px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 80px 0;
  }
  ${media.down('md')} {
    margin: 60px 0;
    h4{
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 60px;
    }
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperItems = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 20px 20px;
  ${media.down('lg')} {
    gap: 0;
  }
`

export const WrapperSoloItem = styled.div`
  flex: 1 0 25%;
  max-width: calc((100% / 4) - 20px);
  border: 1px solid #D1D8DB;
  border-radius: 5px;
  display: table;
  box-sizing: border-box;
  padding: 62px 77.5px 62px 77.5px;
  img{
      width: 128px;
      display: block;
      margin: 0 auto 20px auto;
  }
  p{
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
  }
  ${media.down('xl')} {
    flex: 1 0 50%;
    max-width: calc((100% / 2) - 20px);
  }
  ${media.down('lg')} {
    border: none;
    max-width: calc((100% / 2));
    padding: 40px 0;
    img{
      width: 48px;
    }
    p{
      font-size: 14px;
      line-height: 20px;
    }
  }
`
