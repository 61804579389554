import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperJobOfferApply = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100%;
  padding: 30px;
  background: #EDEFF1;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 387px;
  span{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #94A3AD;
    margin: 0 0 5px 0;
  }
  p{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #283237;
    margin: 0 0 20px 0;
  }
  a{
    background: #FF5A00;
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: 0.02em;
    text-align: left;
    display: block;
    margin: 10px 0 0 0;
    height: 60px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    width: 100%;
  }
  ${media.down('xl')} {
    max-width: 100%;
    position: static;
    margin: 40px 0;
  }
`