import React, {FunctionComponent} from "react";
import {WrapperBorderIndustries, WrapperDescriptionHeadIndustries, WrapperHeadIndustries} from "./styles";
import { IHead } from "./interface";

export const HeadIndustries: FunctionComponent<IHead> = ({title, content}) => {
    return <>
        <WrapperHeadIndustries>
            <WrapperDescriptionHeadIndustries>
                <h1>{title}</h1>
                <p>{content}</p>
            </WrapperDescriptionHeadIndustries>
            <WrapperBorderIndustries/>

        </WrapperHeadIndustries>
    </>
}
