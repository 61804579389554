import React, {FunctionComponent} from "react";
import { ContainerItems, WrapperSoloItem, WrapperItems } from "./styles";
import { ITechnologies } from "./interface";
import generateLink from "../../../../core/api/assets";
import { Link } from "react-router-dom";

export const Technologies: FunctionComponent<ITechnologies> = ({items, title, content}) => {
    return <>
        <ContainerItems>
            <h4>{title}</h4>
            <p>{content}</p>
            {items && items.length > 0 &&<WrapperItems>
                {Object.keys(items).map((keyName, i) => (
                    <WrapperSoloItem>
                        {items[keyName].data['link'] ?
                        <Link to={items[keyName].data['link']}>
                            <img alt={items[keyName].data['title']} src={generateLink(items[keyName].data['image'])} />
                            <p>{items[keyName].data['title']}</p>
                        </Link> :
                        <div>
                            <img alt={items[keyName].data['title']} src={generateLink(items[keyName].data['image'])} />
                            <p>{items[keyName].data['title']}</p>
                        </div>}
                    </WrapperSoloItem>
                ))}
            </WrapperItems>}
        </ContainerItems>
    </>
}
