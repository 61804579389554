import React, {FunctionComponent, useState} from "react";
import { Container, WrapperContactPopup, SoloContent, Checkboxes, CheckboxesList, SoloCheck, BottomPopup, Inputs, Thanks} from "./styles";
import { IPopupContact } from "./interface";
import ReactHtmlParser from 'react-html-parser';
import { needs, teams, technologies } from './data';
import { Link, useHistory } from 'react-router-dom';
import { isOpenModalContact } from "../../atoms/button-header/button-header";

export const PopupContact: FunctionComponent<IPopupContact> = ({content, text_link, top}) => {
    const [isPopupOpen, setOpenPopup] = isOpenModalContact();
    const [isStep, setStep] = useState(0);
    const history = useHistory();

    const handleSubmit = (evt) => {
        history.push('/');
        setOpenPopup(prev => !prev)
        setStep(0)
    }

    return <>
        {top && top ? <Container top={'120px'}>
            {ReactHtmlParser(content)}
            <a onClick={() => setOpenPopup(prev => !prev)}>{text_link}</a>
        </Container> :
        <Container top={'0'}>
            {ReactHtmlParser(content)}
            <a onClick={() => setOpenPopup(prev => !prev)}>{text_link}</a>
        </Container>}
    </>
}
