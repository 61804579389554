import React, {FunctionComponent} from "react";
import {WrapperHeadCompetences, WrapperBorderCompetences} from "./styles";
import {IHeadCompetences} from './interaface';

export const HeadCompetences: FunctionComponent<IHeadCompetences> = ({title}) => {
    return <>
        <WrapperHeadCompetences>
            <div>
                <h1>
                    {title}
                </h1>
            </div>
            <WrapperBorderCompetences></WrapperBorderCompetences>
        </WrapperHeadCompetences>
    </>
}
