import axios from 'axios';
import React, {FunctionComponent, useEffect, useState} from "react";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import { ContainerSort } from "./styles";
import { CaseStudiesSection } from "../case-studies-section/case-studies-section";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useGlobalLang } from "../../molecules/lang-header/useGlobalLang";
import { useTranslation } from "react-i18next";
import { ISortCaseStudies } from './interfaces';

export const SortCaseStudies: FunctionComponent<ISortCaseStudies> = ({selected}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [categories, setCategories] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection-case_study_category`)
        .then(response => {
            setCategories(response.data);
            const isIdCorrect = (element) => {
                return element.slug == selected;
            }
            (selected) ? setTabIndex(response.data.findIndex(isIdCorrect)) : setTabIndex(0);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    return <>
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
            <ContainerSort>
                <p>Sort by:</p>
                <TabList>
                    {Object.keys(categories).map((keyName, i) => (
                        <Tab>{categories[keyName].data['title']}</Tab>
                    ))}
                </TabList>
            </ContainerSort>
            {Object.keys(categories).map((keyName, i) => (
                <TabPanel>
                    <CaseStudiesSection 
                        title={categories[keyName].data['title']}
                        id={categories[keyName].id}
                    />
                </TabPanel>
            ))}
        </Tabs>
    </>
}
