import React, {FunctionComponent} from "react";
import { WrapperInperlySteps, Title, Steps, SoloStep, Number, Content} from "./styles";
import { IInperlySteps } from "./interface";

export const InperlySteps: FunctionComponent<IInperlySteps> = ({title, items}) => {
    return <>
        <WrapperInperlySteps>
            <Title>
                <h4>{title}</h4>
            </Title>
            <Steps>
                {Object.keys(items).map((keyName, i) => (
                    <SoloStep>
                        <Number>
                            <p>Step {i + 1}</p>
                        </Number>
                        <Content>
                            <p>{items[keyName].data['content']}</p>
                        </Content>
                    </SoloStep>
                ))}
            </Steps>
        </WrapperInperlySteps>
    </>
}
