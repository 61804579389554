import styled from "styled-components";
import {media} from "../../atoms/theme";
import like from '../../../../assets/icons/like.svg'

export const ContainerBusinessTeams = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 120px;
  ${media.down('lg')} {
    margin-bottom: 60px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const DescBusinessTeams = styled.div`
    max-width: calc(100% - 832px);
    display: table;
    box-sizing: border-box;
    padding: 83px 0 100px 0;
    float: left;
    h2{
        font-size: 46px;
        line-height: 56px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 40px;
    }
    p{
        font-size: 18px;
        line-height: 26px;
        margin: 0;
    }
    ${media.down('xl')} {
        max-width: 300px; 
    }
    ${media.down('lg')} {
        max-width: 100%;
        padding: 0;
        h2{
            font-size: 30px;
            line-height: 38px; 
        }
        p{
            font-size: 16px;
            line-height: 24px;
        }
    }
`

export const ImageBusinessTeams = styled.div`
    width: 710px;
    display: table;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    img{
        width: 100%;
    }
    ${media.down('xl')} {
        width: 400px; 
    }
    ${media.down('lg')} {
        position: relative;
        width: 100%;
        margin-bottom: 40px;
    }
`

export const LikeIcon = styled.div`
    background-image: url("${like}");
    background-repeat: no-repeat;
    width: 109px;
    height: 126px;
    background-size: 109px 126px;
    position: absolute;
    top: -54.5px;
    left: calc((710px - 126px) / 2);
    ${media.down('xl')} {
        left: calc((400px - 126px) / 2);
    }
    ${media.down('lg')} {
        left: auto;
        right: 25px;
        width: 54px;
        height: 62px;
        top: -31px;
        background-size: 54px 62px;
    }
`