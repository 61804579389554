import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import { Wrapper, WrapperItems, WrapperSoloItem, Image, Content, Title, Info, ContentIn} from "./styles";
import { IOtherCaseStudies } from "./interface";
import { Link } from "react-router-dom";
import { useGlobalLang } from '../lang-header/useGlobalLang';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import getSlug from "../../../../core/router/getSlug";
import generateLink from '../../../../core/api/assets';
import Moment from 'react-moment';

export const OtherCaseStudies: FunctionComponent<IOtherCaseStudies> = ({title, items}) => {
    const { t } = useTranslation()
    const [caseStudies, setCaseStudies] = useState([]);
    const [categories, setCategories] = useState([]);
    const [pageid, setPageId] = useState('');
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection-case_study`)
        .then(response => {
            setCaseStudies(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection-case_study_category`)
        .then(response => {
            setCategories(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    const getDataCategory = (id, type) => {
        const indexEl = Object.keys(categories).find((keyName) => categories[keyName].id == id)
        if(indexEl) return categories[indexEl].data[type]
    }

    const getDataCaseStudy = (id, type) => {
        const indexEl = Object.keys(caseStudies).find((keyName) => caseStudies[keyName].id == id)
        if(indexEl){
            if(type == 'slug'){
                return caseStudies[indexEl].slug
            }else if(type == 'created_at'){
                return caseStudies[indexEl].created_at
            }else{
                return caseStudies[indexEl].data[type]
            }
        }
    }

    return <>
        <Wrapper>
            <h4>{title}</h4>
            {items && items.length > 0 &&<WrapperItems>
                {items.map(item => (
                    <WrapperSoloItem>
                        <Image>
                            <Link to={'/implementing-bank-loan-application'}>
                                <img src={generateLink(getDataCaseStudy(item, 'thumb'))} />
                            </Link>
                        </Image>
                        <Content>
                            <Title>
                                <p><Moment format="DD.MM.YYYY">{getDataCaseStudy(item, 'created_at')}</Moment></p>
                                <h5><Link to={`/case-study/${getDataCaseStudy(item, 'slug')}`}>{getDataCaseStudy(item, 'title')}</Link></h5>
                            </Title>
                            <Info>
                                <p>{getDataCategory(getDataCaseStudy(item, 'category'),'title')}</p>
                                <span></span>
                                <p>read in 4 minutes</p>
                            </Info>
                            <ContentIn>
                                <p>{(getDataCaseStudy(item, 'content')) ? `${getDataCaseStudy(item, 'content').substring(0, 130)} ...` : getDataCaseStudy(item, 'content')}</p>
                            </ContentIn>
                        </Content>
                    </WrapperSoloItem>
                ))}
            </WrapperItems>}
        </Wrapper>
    </>
}
