import styled from "styled-components";
import LogoImage from "../../../../assets/icons/logo-black.svg";
import {media} from "../theme";

export const Logo = styled.div`
  background-image: url("${LogoImage}");
  background-repeat: no-repeat;
  width: 128px;
  height: 32px;
  background-size: 128px;

  a{
    display: block;
    width: 100%;
    height: 100%;
  }

  ${media.up('md')} {
    width: 160px;
    height: 40px;
    background-size: 160px;
  }
`;
