import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperNews = styled.div`
  margin-bottom: 160px;
  h4{
    margin: 0 0 120px 0;
    font-size: 46px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
  }
  ${media.down('md')} {
    margin-bottom: 40px;
    h4{
      margin: 0 0 40px 0;
      font-size: 30px;
      line-height: 38px;
    }
    display: table;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const ContainerNews = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  ${media.up('md')} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`
export const ItemNews = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 387px;
  max-width: 387px;
  min-height: 160px;
  background: ${(props) => props.theme.colors.lightGray};
  padding: 20px;
  margin: 10px 0;

  &:hover {
    color: ${(props) => props.theme.colors.primaryOrange};
  }

  p {
    margin: 0 0 20px;
    font-weight: 600;
    font-size: 22px;

  }

  ${media.up('md')} {
    padding: 30px;
    min-width: 387px;
    max-width: 387px;
    min-height: 206px;
  }
  ${media.down('md')} {
    min-width: 100%;
    max-width: 100%;
  }
`
export const SeeMoreWrapper = styled.div`
  margin-left: auto;
`
