import React, {FunctionComponent} from "react";
import { Container, Content, BorderBox } from "./styles";
import squad from '../../../../assets/images/faq/squad.png'
import { IHead } from "./interface";

export const HowToJoinHead: FunctionComponent<IHead> = ({title, content, undertitle, logo}) => {
    return <>
        <Container>
            <Content>
                <h1>{title}</h1>
                <p>{content}</p>
                <h2>{undertitle}</h2>
            </Content>
            <BorderBox>
                <img src={logo} />
            </BorderBox>
        </Container>
    </>
}
