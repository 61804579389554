import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import { ContactCv } from "../../ui/molecules/contact-cv";
import BiContainer from "../../ui/atoms/container/BiContainer";
import { CareerJobs } from "../../ui/molecules/career-jobs";
import {jobs, faqs} from './data'
import { Faq } from "../../ui/molecules/faq/faq";
import { Recruters } from "../../ui/molecules/recruters/recruters";
import { CareerBreadcrumb } from "../../breadcrumbs/career/career";
import { HowToJoinHead } from "../../ui/molecules/how-to-join-head/how-to-join-head";
import { RecSteps } from "../../ui/molecules/rec-steps/rec-steps";
import { useTranslation } from "react-i18next";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import generateLink from "../../../core/api/assets"
import getSlug from "../../../core/router/getSlug";

export const HowToJoinPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [faq, setFaq] = useState([]);
    const [recruiters, setRecruiters] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/faq/page/${pageid}`)
                .then(response => {
                    setFaq(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/recruiters/page/${pageid}`)
                .then(response => {
                    setRecruiters(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    return <>
        <BiContainer>
            <CareerBreadcrumb 
                title={'How to join'}
                slug={slug}
            />
            <HowToJoinHead 
                title={content['header_-_title']}
                undertitle={content['header_-_undertitle']}
                content={content['header_-_content']}
                logo={generateLink(content['header_-_logo'])}
            />
            <RecSteps 
                title_1={content['section_1_-_title']}
                undertitle_1={content['section_1_-_undertitle']}
                content_1={content['section_1_-_content']}
                list_1={content['section_1_-_list']}
                image_1={generateLink(content['section_1_-_image'])}
                title_2={content['section_2_-_title']}
                undertitle_2={content['section_2_-_undertitle']}
                content_2={content['section_2_-_content']}
                list_2={content['section_2_-_list']}
                image_2={generateLink(content['section_2_-_image'])}
                title_3={content['section_3_-_title']}
                undertitle_3={content['section_3_-_undertitle']}
                content_3={content['section_3_-_content']}
                list_3={content['section_3_-_list']}
                image_3={generateLink(content['section_3_-_image'])}
                title_4={content['section_4_-_title']}
                undertitle_4={content['section_4_-_undertitle']}
                content_4={content['section_4_-_content']}
                list_4={content['section_4_-_list']}
                image_4={generateLink(content['section_4_-_image'])}
            />
            <Recruters 
                title={content['section_5_-_title']}
                items={recruiters}
            />
            <Faq
                title={content['section_6_-_title']}
                items={faq}
            />
            <CareerJobs
                title={content['section_7_-_title']}
                items={jobs}
            />
            <ContactCv
                title={content['contact_title']}
                content={content['contact_content']}
            />
            <ContactPersonPopup
                title={content['contact_popup_title']}
                place={content['contact_popup_position']}
                content={content['contact_popup_content']}
                name={content['contact_popup_person_name']}
                image={generateLink(content['contact_popup_avatar'])}
                send_to={content['contact_send_to']}
                pardot_link={content['pardot_link']}
            />
        </BiContainer>
    </>
}
