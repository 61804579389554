import React, {FunctionComponent} from "react";
import {WrapperContactShort, Title, Content, Buttons} from './styles'
import { IContactCv } from "./interface";

export const ContactCv: FunctionComponent<IContactCv> = ({title, content}) => {
    return <>
        <WrapperContactShort>
            <Title>
                <p>{title}</p>
            </Title>
            <Content>
                <p>{content}</p>
            </Content>
            <Buttons>
                <a className="border" href="https://billennium.traffit.com/public/form/a/d1cxZQ==" target="_blank">Add your CV</a>
                <p>or</p>
                <a href="https://www.linkedin.com/company/billennium/jobs/" target="_blank">Apply with LinkedIn</a>
            </Buttons>
        </WrapperContactShort>
    </>
}
