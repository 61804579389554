import styled from "styled-components";
import {media} from "../../atoms/theme";
import {IBackgroundProductImage, ILogoProductImage} from "./interface";

export const WrapperProductsDesc = styled.div`

  color: ${(props) => props.theme.colors.black};

  ${media.up('lg')} {
    max-width: 692px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const WrapperProducts = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;

  ${media.up('lg')} {
    justify-content: space-between;
    margin-top: 60px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const ProductItem = styled.div`
  max-width: calc(50% - 5px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #EDEFF1;
  padding: 68px 25px;
  margin-top: 20px;
  ${media.down('lg')} {
    max-width: 100%;
  }
`
export const LogoProduct = styled.div<ILogoProductImage>`
  background-image: url("${(props) => props.ProductLogoMobile}");
  background-repeat: no-repeat;
  width: 100%;
  max-width: 200px;
  background-size: contain;
  height: 30px;
  background-position-x: center;

  ${media.up('md')} {
    background-image: url("${(props) => props.ProductLogoDesktop}");
    height: 48px;
    max-width: 312px;
    background-position-x: unset;
  }
`
export const BackgroundProduct = styled.div<IBackgroundProductImage>`
  background-image: url("${(props) => props.BackgroundProductMobile}");
  background-repeat: no-repeat;
  width: 100%;
  background-size: contain;
  margin: 35px 0 40px 0;
  height: 138px;
  max-width: 306px;

  ${media.up('md')} {
    background-image: url("${(props) => props.BackgroundProductDesktop}");
    height: 243px;
    max-width: 540px;
    margin: 70px 0;

  }
`
