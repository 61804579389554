import React, {FunctionComponent} from "react";
import { WrapperHeaderCaseStudiesDetails, TopHeader, TopHeaderContent, BottomHeader } from "./styles";
import { IHeaderCaseStudiesDetails } from "./interface";
import generateLink from "../../../../core/api/assets";

export const HeaderCaseStudiesDetails: FunctionComponent<IHeaderCaseStudiesDetails> = ({title, content, title_top, image, banner}) => {
    return <>
        <WrapperHeaderCaseStudiesDetails>
            <TopHeader>
                <TopHeaderContent>
                    <span className="hide-mobile">{title_top}</span>
                    <h1>{title}</h1>
                    <span className="show-mobile">{title_top}</span>
                </TopHeaderContent>
            </TopHeader>
            <BottomHeader>
                <img alt="Banner" src={banner} />
                <p>{content}</p>
            </BottomHeader>
        </WrapperHeaderCaseStudiesDetails>
    </>
}
