import React, {FunctionComponent} from "react";
import {Logo} from './styles';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const LogoAtom: FunctionComponent = () => {
    const { t } = useTranslation();

    return <div>
        <Logo>
            <Link to={'/'} />
        </Logo>
    </div>
}
