import React, {FunctionComponent, useState, useRef} from "react";
import { Link, useHistory } from 'react-router-dom';
import useOutsideClick from '../../organisms/header/useOutsideClick';
import { ISoloLink } from "./interface";
import { BodyLink, Row } from './styles'

export const SoloLink: FunctionComponent<ISoloLink> = ({title, under_title, slug, items}) => {
    const history = useHistory();
    const [isNavItemOpen, setOpenNavItem] = useState(false);
    const dropdownRef = useRef<HTMLHeadingElement>(null);

    useOutsideClick(dropdownRef, () => {
        if(isNavItemOpen !== false){
          setOpenNavItem(prev => !prev)
        }
    });

    return <li onClick={() => setOpenNavItem(prev => !prev)}>
        <a>
            {title}
            <i className="dropdown-icon"></i>
        </a>
        {isNavItemOpen && <div ref={dropdownRef} className="dropdown-list">
            <Link className="dropdown-list-title" to={slug}>{under_title}</Link>
            <BodyLink>
                {items.map(item => (
                    <Row>
                        {item.row.map(row => (
                            <div className="solo-list-nav">
                                {row.title &&<p>{row.title}</p>}
                                {row.links.map(link => (
                                    <div>
                                        {link.out && link.out == true ? 
                                            <a href={link.slug}>{link.title}</a>
                                        :
                                            <Link to={link.slug}>{link.title}</Link>
                                        }
                                    </div>
                                ))}
                            </div>
                        ))}
                    </Row>
                ))}
            </BodyLink>
        </div>}
    </li>
}