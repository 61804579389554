import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperMap = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    margin: 0 auto 80px auto;
    ${media.down('md')} {
        margin-bottom: 40px;
        padding: 0 20px;
    }
`
export const MapHeader = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    margin-bottom: 120px;
    .text-left{
        display: table;
        box-sizing: border-box;
        width: 100%;
        h4{
            font-size: 46px;
            font-style: normal;
            font-weight: 600;
            line-height: 56px;
            letter-spacing: -0.01em;
            text-align: left;
            margin: 0 0 80px 0;
        }
        p{
            max-width: 691px;
            font-size: 18px;
            line-height: 26px;
            font-weight: 400;
            margin: 0 0 40px 0;
        }
    }
    ul{
        float: right;
        list-style: none;
        padding-top: 24px;
        li{
            float: left;
            padding-left: 20px;
            font-size: 20px;
            line-height: 28px;
            font-weight: 600;
        }
    }
    div{
        .wrapper-arrow{
            margin: 5px 5px -5px 0;
        }
    }
    ${media.down('md')} {
        margin-bottom: 40px;
        .text-left{
            h4{
                font-size: 30px;
                line-height: 38px;
                margin: 0 0 20px 0;
            }
            p{
                max-width: 100%;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 40px;
            }
        }
        ul{
            text-align: center;
            padding: 0;
            width: 100%;
            float: none;
            li{
                padding: 0 10px;
                font-size: 18px;
                line-height: 22px;
                display: inline-block;
            }
        }
    }
`

export const MapImage = styled.div`
    width: 1200px;
    margin: 0 auto;
    img{
        width: 100%;
    }
    ${media.down('xl')} {
        width: 100%;
    }
`