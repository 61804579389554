import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import {HeadSaleforce} from "../../ui/organisms/head-saleforce/head-saleforce";
import {CompetenceSaleforce} from "../../ui/organisms/competence-saleforce/competence-saleforce";
import {CertificateIconsSaleforce} from "../../ui/organisms/certificate-icons-saleforce/certificate-icons-saleforce";
import {HowCanHelp} from "../../ui/organisms/how-can-help/how-can-help";
import {CaseStudies} from "../../ui/organisms/case-studies/case-studies";
import BiContainer from "../../ui/atoms/container/BiContainer";
import { SaleforceBreadcrumb } from "../../breadcrumbs/saleforce/saleforce";
import { ImgBackgroundFull } from "../../ui/atoms/banner-full/styles";
import imgBackground from "../../../assets/images/salesforce/banner_top.webp";
import { PopupContact } from "../../ui/molecules/popup-contact";
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import generateLink from "../../../core/api/assets"
import getSlug from "../../../core/router/getSlug";

export const SaleforcePage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [competencies, setCompetencies] = useState([]);
    const [helps, setHelps] = useState([]);
    const [certifications, setCertifications] = useState([]);
    const [caseStudy, setCaseStudy] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/competencies/page/${pageid}`)
                .then(response => {
                    setCompetencies(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/certifications/page/${pageid}`)
                .then(response => {
                    setCertifications(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/helps/page/${pageid}`)
                .then(response => {
                    setHelps(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/case_study/page/${pageid}`)
                .then(response => {
                    setCaseStudy(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    return <>
        <BiContainer>
        <SaleforceBreadcrumb
            title={content['title']}
            slug={slug}
        />
        <HeadSaleforce
            title={content['header_-_title']}
            content={content['header_-_content']}
            image={generateLink(content['header_-_logo'])}
        />
        </BiContainer>
        <ImgBackgroundFull BackgroundMobile={generateLink(content['header_-_banner'])} BackgroundDesktop={generateLink(content['header_-_banner'])} />
        <BiContainer>
        <CompetenceSaleforce 
            title={content['section_1_-_title']}
            content={content['section_1_-_content']}
            title_2={content['section_2_-_title']}
            content_2={content['section_2_-_content']}
            image={content['section_2_-_image']}
            items={competencies}
        />
        <CertificateIconsSaleforce items={certifications}/>
        <HowCanHelp 
            title={content['section_3_-_title']}
            content={content['section_3_-_content']}
            items={helps}
        />
        <CaseStudies 
            title={content['section_4_-_title']}
            items={caseStudy}
        />
        <PopupContact
            content={`<p>${content['contact_title']}</p>`}
            text_link="Contact us"
        />
        <ContactPersonPopup
            title={content['contact_popup_title']}
            place={content['contact_popup_position']}
            content={content['contact_popup_content']}
            name={content['contact_popup_person_name']}
            image={generateLink(content['contact_popup_avatar'])}
            send_to={content['contact_send_to']}
            pardot_link={content['pardot_link']}
        />
        </BiContainer>

    </>
}
