import styled from "styled-components";
import ArrowIcon from "../../../../assets/icons/small-down-arrow.svg";

export const Arrow = styled.div`
  background-image: url("${ArrowIcon}");
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  background-size: 16px;
  float: left;
  margin: 12px 0 12px 6px;
`;
