import styled from "styled-components";
import { media } from "../../atoms/theme/media";
import ArrowLineDown from "../../../../assets/icons/arrow-line-down.svg";
import ArrowLineDownWhite from "../../../../assets/icons/arrow-line-down-white.svg";
import banner from "../../../../assets/images/home/home-banner.png";
import { IBackgroundImage } from './interface'

export const WrapperHeaderHome = styled.div`
    display: table;
    box-sizing: border-box;
    width: 100%;
    margin: 240px 0 280px 0;
    position: relative;
    ${media.down('xl')} {
        margin: 140px 0 120px 0;
    }
    ${media.down('md')} {
        margin-bottom: 60px;
    }
`

export const ImageHeaderHome = styled.div<IBackgroundImage>`
    float: right;
    width: 720px;
    height: 930px;
    display: block;
    background: url("${(props) => props.banner}") no-repeat;
    background-size: cover;
  
    ${media.down('xxl')} {
    width: 560px;
    }
    ${media.down('xl')} {
        width: 100%;
    }
    ${media.down('lg')} {
        height: 500px;
    }
    ${media.down('md')} {
        margin-top: 70px;
        height: 350px;
    }
`

export const BlackHeaderHome = styled.div`
    position: absolute;
    background: #1C1C1C;
    padding: 120px 0;
    display: table;
    box-sizing: border-box;
    height: 410px;
    width: 1117px;
    left: 0;
    bottom: -120px;
    .content-black{
        width: 700px;
        float: left;
        padding-left: 120px;
        img{
            width: 32px;
            float: left;
            margin-right: 77px;
            margin-top: 15px;
        }
        p{
            float: left;
            max-width: 442px;
            color: #ffffff;
            font-size: 34px;
            line-height: 42px;
            font-weight: 400;
            margin: 0;
        }
    }
    .gif-black{
        float: right;
        margin-right: 111px;
        img{
            width: 169px;
        }
    }
    ${media.down('xl')} {
        position: relative;
        width: 100%;
        height: auto;
        bottom: 0;
        top: -40px;
        .content-black{
            padding: 0 28px;
            width: 100%;
            box-sizing: border-box;
            display: table;
            img{
                margin-right: 29px;
            }
        }
        .gif-black{
            position: absolute;
            right: 28px;
            top: -84.5px;
            margin-right: 0;
        }
    }
    ${media.down('lg')} {
        .content-black{
            p{
                max-width: calc(100% - 61px);
            }
        }   
    }
    ${media.down('md')} {
        padding: 40px 0;
        .gif-black{
            width: 83px;
            height: 83px;
            top: -41.5px;
            img{
                width: 100%;
            }
        }
        .content-black{
            p{
                font-size: 18px;
                line-height: 26px;
                margin-top: 20px;
            }
        }
    }
`

export const OrangeBorderHeaderHome = styled.div`
    width: 1194px;
    position: absolute;
    top: 181px;
    left: calc((100% - 1194px) / 2);
    display: table;
    box-sizing: border-box;
    a {
        cursor: pointer;
        text-decoration: unset;
        color: black;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        height: 20px;
        display: block;
        .wrapper{
          width: 15px;
          height: 13px;
          margin: 2.5px 10px 0 0;
          float: left;
          .sliding-arrow{
            background: url("${ArrowLineDown}") no-repeat;
            height: 13px;
            width: 15px;
          }
        }
      }
    ${media.down('xl')} {
        position: relative;
        width: 90%;
        margin: 0;
        left: 5%;
        top: 0;
        margin-bottom: -250px;
        a {
            color: #FFFFFF;
            .wrapper{
                .sliding-arrow{
                    background: url("${ArrowLineDownWhite}") no-repeat;
                }
            }
        }
    }
`

export const OrangeBorderInHeaderHome = styled.div`
    width: 100%;
    height: 237px;
    border: 6px solid #FF5A00;
    border-top: none;
    position: relative;
    margin-bottom: 40px;
    .white-title-back{
        top: -20px;
        left: -20px;
        position: absolute;
        background: #fff;
        width: 360px;
        height: 150px;
        z-index: 4;
    }
    .orange-top-line{
        right: 0;
        position: absolute;
        background: #FF5A00;
        height: 6px;
        width: 100px;
    }
    h1{
        position: absolute;
        top: -85px;
        left: -6px;
        z-index: 5;
    }
  ${media.down('nav')} {
    width: 90%;
  }
    ${media.down('xl')} {
        height: 350px;
    }
    ${media.down('lg')} {
      width: 100%;
        .white-title-back{
            width: 290px;
            height: 110px;
        }
        h1{
            top: -45px;
        }
    }
    ${media.down('md')} {
        height: 231px;
        margin-bottom: 54px;
        .white-title-back{
            height: 90px;
            width: 230px;
        }
        h1{
            font-size: 36px;
            line-height: 48px;
            top: -52px;
            left: -8px;
        }
    }
`