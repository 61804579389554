import styled from "styled-components";
import {media} from "../../atoms/theme";
import BackgroundImgSaleforceMobile from "../../../../assets/images/background-saleforce-mobile.png";
import BackgroundImgSaleforce from "../../../../assets/images/background-saleforce.png";

export const WrapperBackgroundGalaImage = styled.div`
  margin-top: 60px;
  background-image: url("${BackgroundImgSaleforceMobile}");
  background-repeat: no-repeat;
  width: 100%;
  height: 250px;
  background-size: cover;
  position: relative;
  z-index: 10;
  margin-bottom: 40px;

  ${media.up('md')} {
    margin-top: 0px;
    background-position: 50% 50%;
    height: 400px;
 
    background-image: url("${BackgroundImgSaleforce}");
  }
  ${media.up('lg')} {
    margin-bottom: 160px;
  }
  
`
