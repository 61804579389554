import React, {FunctionComponent} from "react";
import MapIconImg from '../../../../assets/icons/map-icon.svg'
import MailIconImg from '../../../../assets/icons/mail-icon.svg'
import PhoneIconImg from '../../../../assets/icons/phone-icon.svg'
import {IAddressesValue} from "./interface";
import { ContainerAddresses, IconeImg, TextWrapper, WrapperItemOffice, WrapperOffices } from "./styles";
export const OfficesAddresses: FunctionComponent<IAddressesValue> = ({addresses, title}) => {
    return <ContainerAddresses>
        <h2>{title}</h2>
        <WrapperOffices>
            {Object.keys(addresses).map((keyName, i) => (
                <WrapperItemOffice>
                    <p className="city-name">{addresses[keyName].data['title']}</p>
                    <TextWrapper>
                        <IconeImg src={MapIconImg}/>
                        <p className="address-name">{addresses[keyName].data['street']}<br/> {addresses[keyName].data['postcode']} {addresses[keyName].data['city']}</p>
                    </TextWrapper>
                    <a href={addresses[keyName].data['google_maps_-_link']} target="_blank"  className="see-map">see on map</a>
                    <TextWrapper className="one-line">
                        <IconeImg src={PhoneIconImg}/>
                        <a href={`tel:${addresses[keyName].data['phone']}`} className="phone-number">{addresses[keyName].data['phone']}</a>
                    </TextWrapper>
                    <TextWrapper className="one-line">
                        <IconeImg src={MailIconImg}/>
                    <a href={`mailto:${addresses[keyName].data['e-mail']}`} className="email-name">{addresses[keyName].data['e-mail']}</a>
                    </TextWrapper>
                </WrapperItemOffice>
            ))}
        </WrapperOffices>
    </ContainerAddresses>
}