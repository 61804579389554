import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperContactShort = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  border: 6px solid ${(props) => props.theme.colors.primaryOrange};
  padding: 120px 0 116px 0;
  margin: 0 0 120px 0;
  ${media.down('lg')} {
    margin: 0;
    padding: 60px 0;
  }
`

export const Title = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  margin-bottom: 20px;
  p{
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: -0.01em;
    text-align: center;
    margin: 0;
    color: #000;
  }
  ${media.down('lg')} {
    margin-bottom: 10px;
    p{
      font-size: 24px;
      line-height: 30px;
    }
  }
`

export const Content = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  margin-bottom: 40px;
  p{
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;    
    margin: 0;
    color: #000;
  }
  ${media.down('lg')} {
    margin-bottom: 20px;
    p{
      font-size: 14px;
      line-height: 20px;
    }
  }
`

export const Buttons = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  text-align: center;
  a{
    padding: 17px 26px;
    background: ${(props) => props.theme.colors.primaryOrange};
    text-align: center:
    margin: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #ffffff;
    border: 2px solid ${(props) => props.theme.colors.primaryOrange};
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
    transition: background 0.2s, border-color 0.2s, color 0.2s;
    &:hover{
      background: #000000;
      border-color: #000000;
      transition: background 0.2s, border-color 0.2s;
    }
  }
  a.border{
    background: #ffffff;
    color: ${(props) => props.theme.colors.primaryOrange};
    &:hover{
      background: #000000;
      border-color: #000000;
      color: #ffffff;
      transition: background 0.2s, border-color 0.2s, color 0.2s;
    }
  }
  p{
    display: inline-block;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    padding: 24px 20px;
    margin: 0;
  }
  ${media.down('lg')} {
    p{
      display: none;
    }
    a{
      display: block;
      margin: 0 auto 20px auto;
      padding: 11.5px 26px;
      width: 153px;
      font-size: 16px;
      background: #ffffff;
      color: ${(props) => props.theme.colors.primaryOrange};
      width: 215px;
    }
    a.border{
      background: ${(props) => props.theme.colors.primaryOrange};
      color: #ffffff;
      width: 153px;
    }
  }
`