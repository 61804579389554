import React, {FunctionComponent, useState} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow/action-arrow-atom";
import { ContainerItems, WrapperSoloItem, WrapperItems } from "./styles";
import { ICompanyPress } from "./interface";
import ReactPaginate from "react-paginate";

export const CompanyPress: FunctionComponent<ICompanyPress> = ({items, title}) => {
    const [pageNumber, setPageNumber] = useState(0)

    const articlesPerPage = 2
    const pagesVisited = pageNumber * articlesPerPage

    const displayArticles = items
        .slice(pagesVisited, pagesVisited + articlesPerPage)
        .map((article) => {
            return (<WrapperSoloItem>
                <span>{article.data['date_when']}</span>
                <h5>{article.data['title']}</h5>
                <p>{article.data['section_1_-_content'].substring(0, 130)} ...</p>
                <ActionArrowAtom link={`/company-press/${article.slug}`} text="Read more" />
            </WrapperSoloItem>)
        })

    return <>
        <ContainerItems>
            <h4>{title}</h4>
            <WrapperItems>
                {displayArticles}
            </WrapperItems>
        </ContainerItems>
    </>
}
