import {IfooterItem} from './interfaces';

export const footerItems: IfooterItem[] = [
    {
    id:"first-row",
        row: [
            {
                id: 'competencies',
                header: 'Competencies',
                url: '/competencies',
                subitems: [
                    {
                        id: 'Development Lifecycle Teams',
                        subtitle: 'Development Lifecycle Teams',
                        items: [
                            {id: 'Software Development', name: 'Software Development', url: "/software-development"},
                            {id: 'Operation Services', name: 'Operation Services', url: "/operation-services"},
                            {id: 'Workshops', name: 'Workshops', url: "/workshops"},
                            {id: 'Business Analysis', name: 'Business Analysis', url: "/business-analysis"},
                            {id: 'UX - Design', name: 'UX - Design', url: "/ux-design"},
                            {id: 'Integration', name: 'Integration', url: "/integration"},
                            {id: 'Automation', name: 'Automation', url: "/automation"},
                            {id: 'Modern Workplace', name: 'Modern Workplace', url: "/modern-workplace"},
                            {id: 'Development LifeCycle Tools', name: 'Development LifeCycle Tools', url: "/software-lifecycle-tools"},
                            {id: 'Project Management', name: 'Project Management', url: "/project-management-and-scrum"},
                            {id: 'Quality Assurance', name: 'Quality Assurance', url: "/quality-assurance"},
                            {id: 'DevOps & Clouds', name: 'DevOps & Clouds', url: "/devops-and-cloud"},
                            {id: 'Identity Management', name: 'Identity Management', url: "/identity-management"},
                        ]
                    },
                    /*{
                        id: 'Partnership',
                        subtitle: 'Partnership',
                        items: [
                            {id: 'Microsoft', name: 'Microsoft', url: "https://microsoft-partnership.billennium.com/"},
                            {id: 'Salesforce', name: 'Salesforce', url: "http://salesforce-partnership.billennium.com/"},
                            {id: 'MuleSoft', name: 'MuleSoft', url: "https://front.billenium.forbetterfuture.pl/"},
                        ]
                    },*/
                ]
            },
        ]
    },
    {
        id:"second-row",
        row: [
            {
                specialClass:"second-row",
                subitems: [
                    {
                        id: 'Business Services Teams',
                        subtitle: 'Business Services Teams',
                        items: [
                            {id: 'Laboratory informatics', name: 'Laboratory informatics', url: "/laboratory-informatics"},
                            {id: 'Big Data & AI', name: 'Big Data & AI', url: "/big-data-and-ai"},
                            {id: 'CRM', name: 'CRM', url: "/crm"},
                            {id: 'BI', name: 'BI', url: "/bi"},
                        ]
                    },
                    {
                        id: 'Technology Teams',
                        subtitle: 'Technology Teams',
                        items: [
                            {id: '.NET', name: '.NET', url: "/net"},
                            {id: 'Azure', name: 'Azure', url: "/azure"},
                            {id: 'Google', name: 'Google', url: "/google"},
                            {id: 'AWS', name: 'Amazon', url: "/aws"},
                            {id: 'Salesforce', name: 'Salesforce', url: "/salesforce"},
                            {id: 'PHP', name: 'PHP', url: "/php"},
                            {id: 'Angular', name: 'Angular', url: "/angular"},
                            {id: 'React', name: 'React', url: "/react"},
                            {id: 'Mobile Development', name: 'Mobile Development', url: "/mobile-development"},
                            {id: 'Python', name: 'Python', url: "/python"},
                            {id: 'Java', name: 'Java', url: "/java"},
                        ]
                    }
                ]
            }
        ]
    },
    {
        id:"third-row",
        row: [
            {
                id: 'Products and services',
                header: 'Products and services',
                url: '/products-and-services',
                subitems: [
                    {
                        id: 'Products',
                        subtitle: 'Products',
                        items: [
                            {id: 'Inperly', name: 'Inperly', url: "/inperly"},
                            {id: 'Paperless office', name: 'Paperless office', url: "/paperless"}
                        ]
                    },
                    {
                        id: 'Services',
                        subtitle: 'Services',
                        items: [
                            {id: 'Outsourcing', name: 'Outsourcing', url: "/outsourcing"},
                            {id: 'Consulting', name: 'Consulting', url: "/consulting"},
                            {id: 'Project', name: 'Project', url: "/project"},
                            {id: 'Innovation Lab', name: 'Innovation Lab', url: "/innovation-lab"}
                        ]
                    }
                ]
            },
        ]
    },
    {
        id:"four-row",
        row: [
            {
                id: 'Case studies',
                header: 'Case studies',
                url: '/case-studies',
                subitems: [
                    {
                        id: 'case-studies',
                        subtitle: '',
                        items: [
                        ]
                    }
                ]
            },
            {
                id: 'Company',
                header: 'Company',
                url: '/company',
                subitems: [
                    {
                        id: 'company',
                        subtitle: '',
                        items: [
                            {id: 'About Billennium', name: 'About Billennium', url: "/about-billennium"},
                            {id: 'Culture', name: 'Culture', url: "/company-culture"},
                            {id: 'History', name: 'History', url: "/company-history"},
                            {id: 'Board', name: 'Board', url: "/company-board"},
                            {id: 'Press releases', name: 'Press releases', url: "/company-press"}
                        ]
                    },
                ]
            },
            {
                id: 'Career',
                header: 'Career',
                url: '/career',
                subitems: [
                    {
                        items: [
                            {id: 'Teams', name: 'Teams', url: "/career-teams"},
                            {id: 'How to join', name: 'How to join', url: "/how-to-join"},
                            {id: 'Job offers', name: 'Job offers', url: "/job-offers"},
                            {id: 'Locations', name: 'Locations', url: "/people-locations"},
                        ]
                    },
                ]
            },
            {
                id: 'Blog',
                header: 'Blog',
                url: '/blog',
                subitems: [

                ]
            },
            {
                id: 'Privacy Policy',
                header: 'Privacy Policy',
                url: '/privacy-policy',
                subitems: [
                    {
                        id: 'privacy-policy',
                        subtitle: '',
                        items: [
                        ]
                    },
                ]
            },
            {
                id: 'Whistleblowers Portal',
                header: 'Whistleblowers Portal',
                url: 'https://app.convercent.com/en-US/LandingPage/28be810f-cf2d-ec11-a985-000d3ab9f062',
                subitems: [
                    {
                        id: 'whistleblowers-portal',
                        subtitle: '',
                        items: [
                        ]
                    },
                ]
            },
        ]
    },



]
