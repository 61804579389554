import styled from "styled-components";
import {media} from "../../atoms/theme";


export const WrapperDescriptionInperly = styled.div`
  max-width: 490px;
  h1{
    position: absolute;
    background: #fff;
    padding-right: 60px;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -0.015em;
    text-align: left;
    top: -50px;
    margin: 0;
    color: #25282B;
  }
  h2{
    margin: 100px 0 0 0;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #25282B;
  }
  ${media.down('lg')} {
    h1{
      position: static;
      top: auto;
      margin: 0;
      padding-bottom: 20px;
      font-size: 36px;
      line-height: 48px;
      color: #000000;
      border-bottom: 6px solid #4260D1;
    }
    h2{
      color: #000000;
      font-size: 30px;
      line-height: 38px;
      margin-top: 40px;
    }
  }
`
