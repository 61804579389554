import React, {FunctionComponent} from "react";
import { ContainerItems, WrapperSoloItem, WrapperItems } from "./styles";
import { IOurSolutions } from "./interface";
import { ActionArrowAtom } from "../../atoms/action-arrow";
import { useTranslation } from "react-i18next";

export const OurSolutions: FunctionComponent<IOurSolutions> = ({items, title, content}) => {
    const { t } = useTranslation();

    return <>
        {title && content && items.length > 0 &&<ContainerItems>
            <h4>{title}</h4>
            <p>{content}</p>
            <WrapperItems>
                {items.map(item => (
                    <WrapperSoloItem>
                        <img alt="Solo Img" src={item.image} />
                        <p>{item.content}</p>
                        <ActionArrowAtom  link={item.link} text={t("see_more")}/>
                    </WrapperSoloItem>
                ))}
            </WrapperItems>
        </ContainerItems>}
    </>
}
