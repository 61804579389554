import React, {FunctionComponent} from "react";
import {WrapperBorderProductsServices, WrapperHeadProductsServices} from "./styles";
import { IHead } from "./interface";

export const HeadProductsServices: FunctionComponent<IHead> = ({title}) => {
    return <>
        <WrapperHeadProductsServices>
            <div>
                <h1>
                    {title}
                </h1>
            </div>
            <WrapperBorderProductsServices/>

        </WrapperHeadProductsServices>
    </>
}
