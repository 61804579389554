import styled from "styled-components";
import {media} from "../../atoms/theme";
import {WrapperBorder, WrapperHeadPowerApp} from "../head-power-app/styles";


export const WrapperBorderCompetences = styled(WrapperBorder)`
  ${media.up('lg')} {
    width: 70%;
    height: 120px;
    margin-top: 10px;
  }
  ${media.down('lg')} {
    display: none;
  }
`

export const WrapperHeadCompetences = styled(WrapperHeadPowerApp)`
  position: relative;
  margin-bottom: 70px;
  margin-top: 50px;
  min-height: 70px;
  h1{
    position: absolute;
    background: #fff;
    top: -42px;
    z-index: 10;
    padding-right: 35px;
    line-height: 70px;
  }

  ${media.up('lg')} {
    p {
      font-size: 22px;
      max-width: 692px;
      margin-top: 40px;
      margin-bottom: 120px;
    }
  }
  ${media.down('xl')} {
    h1{
      width: 70%;
    }
  }
  ${media.down('lg')} {
    width: 100%;
    border-bottom: 6px solid #FF5A00;
    h1{
      width: 100%;
      line-height: 48px;
    }
  }
  ${media.down('md')} {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    h1{
      position: static;
    }
  }
`
