import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperTabsMap = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: start;
    list-style-type: none;
    padding: 0;

  }

  .react-tabs__tab {
    cursor: pointer;

    &.react-tabs__tab--selected {
      position: relative;
      color: black;

      &:after {
        content: '';
        display: block;
        height: 6px;
        width: 100%;
        background: #FF5A00;
        position: absolute;

      }
    }
  }

  li {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    margin-right: 20px;
    color: #B3BEC4;

    &:last-child {
      margin: 0;
    }
  }

  ${media.up("lg")} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 0px;

    li {

      margin-right: 40px;
      font-size: 34px;
      line-height: 42px;
    }
  }
  ${media.down("md")} {
    padding-left: 20px;
    padding-right: 20px;
    ul {
      overflow-x: scroll;
      min-height: 50px;
    }
  }
`
export const WrapperMap = styled.div`

  width: 100%;

  img {
    width: 100%;
  }
`
export const WrapperMainMap = styled.div`
  ${media.up("md")} {
    max-width: 86%;
    margin: auto;
  }
`