import React, {FunctionComponent} from "react";
import { ContainerContentProjects, DescContentProjects, ImageContentProjects } from "./styles";
import { IContentProjects } from "./interaface";
import HTMLReactParser from "html-react-parser";

export const ContentProjects: FunctionComponent<IContentProjects> = ({title, content, image}) => {
    return <>
        <ContainerContentProjects>
            <ImageContentProjects>
                <img src={image} />
            </ImageContentProjects>
            <DescContentProjects>
                <h2>{title}</h2>
                <p>{HTMLReactParser(content ? content : '')}</p>
            </DescContentProjects>
        </ContainerContentProjects>
    </>
}
