import React, {FunctionComponent} from "react";
import { ContainerItems, WrapperSoloItem, WrapperItems } from "./styles";
import { ICareerBoxes } from "./interface";
import generateLink from "../../../../core/api/assets";

export const CareerBoxes: FunctionComponent<ICareerBoxes> = ({items, title}) => {
    return <>
        <ContainerItems>
            <h4>{title}</h4>
            <WrapperItems>
                {Object.keys(items).map((keyName, i) => (
                    <WrapperSoloItem>
                        <img alt={items[keyName].data['title']} src={generateLink(items[keyName].data['thumb'])} />
                        <p>{items[keyName].data['title']}</p>
                    </WrapperSoloItem>
                ))}
            </WrapperItems>
        </ContainerItems>
    </>
}
