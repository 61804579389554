import React, {FunctionComponent} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow/action-arrow-atom";
import {WrapperAboutMore} from './styles'
import {IAboutMore} from './interface'

export const AboutMore: FunctionComponent<IAboutMore> = ({title, link, link_text}) => {
    return <>
        <WrapperAboutMore>
            <p>{title}</p>
            <ActionArrowAtom link={link} text={link_text} />
        </WrapperAboutMore>
    </>
}