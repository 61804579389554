import styled from "styled-components";
import {media} from "../../atoms/theme";
import { IPopupTop } from "./interface";
import CloseIcon from "../../../../assets/icons/close-icon.svg";

export const Container = styled.div<IPopupTop>`
  display: table;
  box-sizing: border-box;
  width: 100%;
  border: 6px solid #FF5A00;
  padding: 108px 0;
  text-align: center;
  margin: ${(props) => props.top} 0 80px 0;
  p{
    display: inline-block;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: -0.01em;
    text-align: center;
    margin: 0 0 40px 0;
    max-width: 424px;
    span{
      color: #FF5A00;
    }
  }
  a{
    display: block;
    margin 0 auto;
    padding: 19px 0;
    width: 148px;
    border-radius: 5px;
    color: #ffffff;
    background: #FF5A00;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: center;
    cursor: pointer;
    transition: background 0.2s;
    &:hover{
      background: #000000;
      transition: background 0.2s;
    }
  }
  ${media.down('lg')} {
    margin: 0;
    padding: 60px 0;
    p{
        font-size: 24px;
        line-height: 30px;
    }
    a{
        font-size: 16px;
        padding: 11.5px 0;
        width: 123px;
    }
  }
`


export const WrapperContactPopup = styled.div`
    position: fixed;
    background: #fff;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    .close-icon{
        background-image: url("${CloseIcon}");
        background-repeat: no-repeat;
        width: 64px;
        height: 64px;
        background-size: 64px;
        position: absolute;
        top: 50px;
        right: 50px;
        cursor: pointer;
        z-index: 10;
        ${media.down('lg')} {
            background-size: 32px;
            width: 32px;
            height: 32px;
            top: 20px;
            right: 20px;
        }
    }
`

export const SoloContent = styled.div`
    position: relative;
    padding: 60px 120px;
    box-sizing: border-box;
    display: table;
    width: 100%;
    h4{
      font-size: 46px;
      font-style: normal;
      font-weight: 600;
      line-height: 56px;
      letter-spacing: -0.01em;
      text-align: left;
      margin: 0;
    }
    p{
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      margin: 24px 0 0 0;
    }
`

export const Checkboxes = styled.div`
    margin-top: 80px;
    box-sizing: border-box;
    display: table;
    width: 100%;
    h5{
      font-size: 34px;
      font-style: normal;
      font-weight: 400;
      line-height: 42px;
      letter-spacing: -0.01em;
      text-align: left;
      margin: 0;
    }
    textarea{
      width: 100%;
      resize: none;
      height: 318px;
      border: 1px solid #596C79;
      border-radius: 5px;
      padding: 20px;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
      margin: 40px 0 0 0;
    }
`

export const CheckboxesList = styled.div`
    margin-top: 40px;
    display: flex; 
    flex-wrap: wrap;
    justify-content: start;
    gap: 10px 20px;
    width: 100%;
`

export const SoloCheck = styled.div`
    display: table;
    box-sizing: border-box;
    flex: 1 0 calc((100% / 2) - 20px);
    max-width: calc((100% / 2) - 20px);
    border: 1px solid #D1D8DB;
    border-radius: 5px;
    padding: 20px 24px;
    label{
      width: 100%;
      height: 24px;
      display: table;
      box-sizing: border-box;
      cursor: pointer;
      padding-left: 44px;
      position: relative;
      span{
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        &:before{
          position: absolute;
          content: '';
          border: 2px solid #FF5A00;
          border-radius: 3px;
          width: 24px;
          height: 24px;
          left: 0;
        }
      }
      input{
        display: none;
      }
      input[type=checkbox]:checked + span{
        &:before{
          background: #FF5A00;
        }
      }
    }
`

export const BottomPopup = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100%;
  border-top: 6px solid #FF5A00;
  padding-top: 80px;
  margin-top: 80px;
  a{
    float: right;
    padding: 19px 121.5px;
    background: #FF5A00;
    border-radius: 5px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #ffffff;
    border: 2px solid #FF5A00;
    cursor: pointer;
    transition: background 0.2s, border-color 0.2s, color 0.2s;
    &:hover{
      background: #000000;
      border-color: #000000;
      transition: background 0.2s, border-color 0.2s;
    }
  }
  a.empty{
    background: #ffffff;
    color: #FF5A00;
    float: left;
    &:hover{
      background: #000000;
      border-color: #000000;
      color: #ffffff;
      transition: background 0.2s, border-color 0.2s, color 0.2s;
    }
  }
  a.link{
    float: left;
    text-decoration: none;
  }
`
export const Inputs = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100%;
  margin-top: 20px;
  input{
    display: table;
    box-sizing: border-box;
    width: 590px;
    padding: 0 20px;
    height: 70px;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #283237;
    margin-top: 20px;
  }
`

export const Thanks = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100%;
  padding-top: 190px;
  h5{
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #000000;
    margin: 0 0 40px 0;
  }
  p{
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0;
    color: #000000;
  }
`