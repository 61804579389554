import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperAboutMore = styled.div`
    box-sizing: border-box;
    display: table;
    width: 100%;
    padding: 120px 0;
    background: #EDEFF1;
    text-align: center;
    p{
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: center;
        margin: 0 0 21px 0;
        color: #25282B;
    }
    div{
        margin: 0 auto;
        display: inline-block;
        .wrapper-arrow{
            margin: 2px 5px -2px 0;
        }
    }
    ${media.down('md')} {
        padding: 60px 0;
        p{
            font-size: 24px;
            line-height: 30px;
        }
    }
`