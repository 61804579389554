import styled from "styled-components";
import {media} from "../theme";
import {IBackgroundImage} from "./interface";

export const ImgBackgroundFullLower = styled.div<IBackgroundImage>`
  background-image: url("${(props) => props.BackgroundMobile}");
  background-repeat: no-repeat;
  max-width: 1640px;
  height: 250px;
  background-size: cover;
  position: relative;
  z-index: 10;
  margin: 20px auto 60px auto;

  ${media.up('md')} {
    margin-top: 0px;
    background-image: url("${(props) => props.BackgroundDesktop}");
    background-position: 50% 50%;
    height: 400px;
    margin-bottom: 114px;
  }
`
