import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container";
import { useTranslation } from "react-i18next";
import HeadCompetences from "../../ui/organisms/head-competences";
import {PharmacyBreadcrumb} from "../../breadcrumbs/pharmacy/pharmacy";
import {ImgBackgroundFull} from "../../ui/atoms/banner-full/styles";
import { IndustriesDesc } from "../../ui/molecules/industries-desc/industries-desc";
import { FireList } from "../../ui/molecules/fire-list/fire-list";
import { News } from "../../ui/organisms/news/news";
import { CaseStudies } from "../../ui/organisms/case-studies/case-studies";
import { PercentValuesIndustry } from "../../ui/molecules/percent-values-industry/percent-values-industry";
import { OurSolutions } from "../../ui/molecules/our-solutions/our-solutions";
import { PopupContact } from "../../ui/molecules/popup-contact";
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import getSlug from "../../../core/router/getSlug";
import generateLink from '../../../core/api/assets';

export const PharmacyPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [caseStudy, setCaseStudy] = useState([]);
    const [solutions, setSolutions] = useState([]);
    const [fires, setFires] = useState([]);
    const [articles, setArticles] = useState([]);
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/industry/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/case_study/industry-${pageid}`)
                .then(response => {
                    setCaseStudy(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                })
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/solutions/industry-${pageid}`)
                .then(response => {
                    setSolutions(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                })
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/fire_list/industry-${pageid}`)
                .then(response => {
                    setFires(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                })
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/articles/page/${pageid}`)
                .then(response => {
                    setArticles(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                })
        }
    }, [pageid])

    return <>
        <BiContainer>
            <PharmacyBreadcrumb 
                title={content['title']}
                slug={slug}
            />
            <HeadCompetences 
                title={content['title']}
            />
        </BiContainer>
        <ImgBackgroundFull BackgroundMobile={generateLink(content['banner'])} BackgroundDesktop={generateLink(content['banner'])} />
        <BiContainer >
            <IndustriesDesc
                title={content['section_1_-_title']}
                content={content['section_1_-_content']}
            />
            <FireList
                title={content['section_2_-_title']}
                items={fires}
            />
            <PercentValuesIndustry
                title={content['section_3_-_title']}
                content={content['section_3_-_content']}
                file_link={content['section_3_-_file']}
                value_1={content['box_1_-_value']}
                title_1={content['box_1_-_title']}
                value_2={content['box_2_-_value']}
                title_2={content['box_2_-_title']}
                value_3={content['box_3_-_value']}
                title_3={content['box_3_-_title']}
            />
            <News 
                title={content['section_4_-_title']}
                articles={articles} 
                show_more={false}
            />
            <CaseStudies 
                title={content['section_5_-_title']}
                items={caseStudy}
            />
            <OurSolutions
                title={content['section_6_-_title']}
                content={content['section_6_-_content']}
                items={solutions}
            />
            <PopupContact
                content={`<p>${content['contact_-_title']}`}
                text_link="Contact us"
            />
            <ContactPersonPopup
                title={content['contact_popup_-_title']}
                place={content['contact_popup_-_position']}
                content={content['contact_popup_-_content']}
                name={content['contact_popup_-_person_name']}
                image={generateLink(content['contact_popup_-_avatar'])}
                send_to={content['contact_send_to']}
                pardot_link={content['pardot_link']}
            />
        </BiContainer>
    </>
}
