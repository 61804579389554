import React, {FunctionComponent} from "react";
import { Link, useHistory } from 'react-router-dom';
import {ArrowRightIcon} from '../../ui/atoms/arrow-right-icon'
import {WrapperBreadcrumb} from '../styles'

export const PaperlessBreadcrumb: FunctionComponent = () => {
    const history = useHistory();

    return <>
        <WrapperBreadcrumb>
            <Link to="/" onClick={() => { history.push('/');}}>Billennium</Link>
            <ArrowRightIcon />
            <Link to="/products-and-services" onClick={() => { history.push('/products-and-services');}}>Products and services</Link>
            <ArrowRightIcon />
            <Link to="/paperless" onClick={() => { history.push('/inperly');}}>Paperless office</Link>
        </WrapperBreadcrumb>
    </>
}
