import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { useBreakpoints } from './hooks'
import { IContainer } from './interfaces';

const Container = styled.div<IContainer>`
  width: 100%;
  margin: auto;
  ${(props) => `max-width: ${props.maxWidth}px;`}
`;

const BiContainer: FunctionComponent = ({ children }) => {
  const { size } = useBreakpoints();
  return <Container maxWidth={size.container['default']}
  >{children}</Container>
};

BiContainer.defaultProps = {
  type: 'default'
}
export default BiContainer;
