import React, {FunctionComponent} from "react";
import {IconCertigicate, WrapperIconsCertigicates} from "./styles";
import { ICert } from "./interface";
import generateLink from "../../../../core/api/assets";
export const CertificateIconsSaleforce: FunctionComponent<ICert> = ({items}) => {
    return <>
        {items && items.length > 0 &&<WrapperIconsCertigicates >
            {Object.keys(items).map((keyName, i) => (
                <IconCertigicate image={generateLink(items[keyName].data['image'])} />
            ))}
        </WrapperIconsCertigicates>}

        </>


}
