import React, {FunctionComponent} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow/action-arrow-atom";
import { ContainerItems, WrapperSoloItem, WrapperItems } from "./styles";
import { ICompanyBoard } from "./interface";
import { Link } from "react-router-dom";
import generateLink from "../../../../core/api/assets";
import { useTranslation } from "react-i18next";

export const CompanyBoard: FunctionComponent<ICompanyBoard> = ({items, title, link, url}) => {
    const { t } = useTranslation();
    return <>
        <ContainerItems>
            <h4>{title}</h4>
            <WrapperItems>
                {Object.keys(items).map((keyName, i) => (
                    <WrapperSoloItem>
                        <Link to={`/company-board/${items[keyName].slug}`}>
                            <img alt={items[keyName].data['title']} src={generateLink(items[keyName].data['image'])} />
                            <p>{items[keyName].data['title']}</p>
                            <span>{items[keyName].data['position']}</span>
                        </Link>
                        <ActionArrowAtom link={`/company-board/${items[keyName].slug}`} text={t('more_info')} />
                    </WrapperSoloItem>
                ))}
            </WrapperItems>
            <ActionArrowAtom link={url} text={link} />
        </ContainerItems>
    </>
}
