import styled from "styled-components";
import {media} from "../../atoms/theme";


export const ContainerBusinessTeams = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding-bottom: 169px;
  ${media.down('lg')} {
    display: table;
    box-sizing: border-box;
  }
  ${media.down('md')} {
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const DescBusinessTeams = styled.div`
    max-width: 488px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    h4{
        font-size: 46px;
        line-height: 56px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 40px;
    }
    p{
        font-size: 18px;
        line-height: 26px;
        margin: 0;
    }
    a{
        padding-top: 30px;
    }
    ${media.down('xl')} {
        max-width: 300px;
    }
    ${media.down('lg')} {
        max-width: 100%;
    }
    ${media.down('md')} {
        padding-top: 0;
        h4{
            font-size: 30px;
            line-height: 38px;
            margin-bottom: 20px;
        }
        p{
            font-size: 16px;
            line-height: 24px;
            margin: 0;
        }
    }
`

export const ImageBusinessTeams = styled.div`
    float: left;
    width: 608px;
    display: table;
    box-sizing: border-box;
    position: relative;
    img{
        width: 100%;
    }
    ${media.down('xl')} {
        width: 400px;
    }
    ${media.down('lg')} {
        width: 100%;
        margin-bottom: 60px;
    }
    ${media.down('md')} {
        margin-bottom: 60px;
    }
`

export const DescBusinessTeamsIn = styled.div`

`