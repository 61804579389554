import tech1 from '../../../assets/images/soft-dev/net.png'
import tech2 from '../../../assets/images/soft-dev/java.png'
import tech3 from '../../../assets/images/soft-dev/c++.png'
import tech4 from '../../../assets/images/soft-dev/c#.png'
import tech5 from '../../../assets/images/soft-dev/php.png'
import tech6 from '../../../assets/images/soft-dev/nasm.png'


export const items = [
    {
        'id': 1,
        'title': 'Cloud management',
        'content': 'Lorem ipsum dolor sit amet enim. Etiam ullamcorper pellentesque non felis.',
    },
    {
        'id': 2,
        'title': 'Data management',
        'content': 'Lorem ipsum dolor sit amet enim. Etiam ullamcorper pellentesque non felis.',
    },
    {
        'id': 3,
        'title': 'Data integration',
        'content': 'Lorem ipsum dolor sit amet enim. Etiam ullamcorper pellentesque non felis.',
    },
    {
        'id': 4,
        'title': 'Cloud migration',
        'content': 'Lorem ipsum dolor sit amet enim. Etiam ullamcorper pellentesque non felis.',
    },
    {
        'id': 5,
        'title': 'Microservices',
        'content': 'Lorem ipsum dolor sit amet enim. Etiam ullamcorper pellentesque non felis.',
    },
    {
        'id': 6,
        'title': 'Seemless design',
        'content': 'Lorem ipsum dolor sit amet enim. Etiam ullamcorper pellentesque non felis.',
    }
]

export const technologies = [
    {
        'id': 1,
        'image': tech1,
        'title': '.NET',
    },
    {
        'id': 2,
        'image': tech2,
        'title': 'Java',
    },
    {
        'id': 3,
        'image': tech3,
        'title': 'C++',
    },
    {
        'id': 4,
        'image': tech4,
        'title': 'C#',
    },
    {
        'id': 5,
        'image': tech5,
        'title': 'PHP',
    },
    {
        'id': 6,
        'image': tech6,
        'title': 'Asembler',
    }
]