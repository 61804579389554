import React, {FunctionComponent, useState} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow/action-arrow-atom";
import { ContainerItems, WrapperSoloItem, WrapperItems, LeftContent, RightContent, ContentIn } from "./styles";
import { IPressList } from "./interface";
import book from '../../../../assets/icons/book.svg'
import ReactPaginate from "react-paginate";
import Moment from "react-moment";

export const PressList: FunctionComponent<IPressList> = ({items, download_file, title}) => {
    const [pageNumber, setPageNumber] = useState(0)

    const articlesPerPage = 7
    const pagesVisited = pageNumber * articlesPerPage

    const displayArticles = items
        .slice(pagesVisited, pagesVisited + articlesPerPage)
        .map((article) => {
            return (<WrapperSoloItem>
                <span><Moment format="DD.MM.YYYY">{article.created_at}</Moment></span>
                <h5>{article.data['title']}</h5>
                <p>{article.data['section_1_-_content'].substring(0, 130)} ...</p>
                <ActionArrowAtom link={`/company-press/${article.slug}`} text="Read more" />
            </WrapperSoloItem>)
        })

    const pageCount = Math.ceil(items.length / articlesPerPage)

    const changePage = ({selected}) => {
        setPageNumber(selected)
        window.scrollTo(0, 0);
    }
    
    return <>
        <ContainerItems>
            <ContentIn>
                <LeftContent>
                    <WrapperItems>
                        {displayArticles}
                    </WrapperItems>
                </LeftContent>
                <RightContent>
                    <img alt="Brandbook" src={book} />
                    <p>{title}</p>
                    <ActionArrowAtom link={download_file} text="Download now" />
                </RightContent>
            </ContentIn>
            <ContentIn>
                <ReactPaginate
                    previousLabel={"< Poprzednia"}
                    nextLabel={"Następna >"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={'activeBttn'}
                />
            </ContentIn>
        </ContainerItems>
    </>
}
