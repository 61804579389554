import styled from "styled-components";
import { media } from "../../atoms/theme/media";
import arrow from '../../../../assets/icons/big-down-arrow.svg';

export const SoloHomeCompetencesWrapper = styled.div`
    .toggl-open{
        display: none;
    }
    display: table;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 120px;
    .gif-wrapper{
        width: 387px;
        float: left;
        margin-right: 119px;
        img{
            width: 100%;
        }
    }
    .content-competences{
        max-width: 650px;
        display: table;
        box-sizing: border-box;
        h4{
            margin: 0;
            margin-bottom: 30px;
            font-size: 34px;
            line-height: 42px;
            font-weight: 400;
        }
        p{
            margin: 0;
            margin-bottom: 30px;
            font-size: 18px;
            line-height: 26px;
            font-weight: 400;
        }
        ul{
            list-type: dots;
            display: table;
            box-sizing: border-box;
            margin: 0;
            margin-bottom: 30px;
            padding-left: 20px;
            li{
                margin: 0;
                width: 50%;
                float: left;
                font-size: 18px;
                line-height: 26px;
                font-weight: 400;
            }
        }
    }
    ${media.down('xl')} {
        .content-competences{
            ul{
                li{
                    width: 100%;
                }
            }
        }
    }
    ${media.down('lg')} {
        .toggl-open{
            display: table;
            box-sizing: border-box;
            position: relative;
            width: 100%;
            cursor: pointer;
            padding-bottom: 17px;
            p{
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: -0.01em;
                text-align: left;
                margin: 0;
                max-width: 190px;
                &.active{
                    color: #FF5A00;
                }
            }
            &:after{
                content: '';
                width: 24px;
                height: 24px;
                display: inline-block;
                background-image: url("${arrow}");
                background-size: 24px;
                position: absolute;
                right: 0;
                top: 18px;
            }
        }
        .hide-mobile{
            display: none;
        }
        .show-mobile{
            padding-bottom: 30px;
        }
        border-bottom: 1px solid #D1D8DB;
        margin: 0 0 15px 0;
        .gif-wrapper{
            width: 100%;
            height: 100px;
            margin: 0 0 30px 0;
            img{
                height: 100px;
                width: auto;
            }
        }
        .content-competences{
            h4{
                display: none;
            }
            p{
                font-size: 16px;
                line-height: 24px;
            }
            ul{
                li{
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
`