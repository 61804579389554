import React, {FunctionComponent, useState} from "react";
import {MobileHeaderWrapper, Burger, MobileHeaderTop, Logo, Close, MobileHeaderCenter, MobileHeaderCenterOut, MobileHeaderBottomOut, MobileHeaderBottom, Button} from "./styles";
import { Link, useHistory } from 'react-router-dom';
import BiContainer from "../../../ui/atoms/container";
import {LangHeader} from "../../molecules/lang-header"
import {LinksHeader} from "../../molecules/links-header"
import { useTranslation } from "react-i18next";
import { isOpenModalContact } from "../../atoms/button-header/button-header";

export const MobileHeader: FunctionComponent = () => {
    const history = useHistory();
    const [isNavItemOpen, setOpenNavItem] = useState(false);
    const [isOpenModal, setOpenModal] = isOpenModalContact();
    const { t } = useTranslation();

    history.listen((location, action) => {
        setOpenNavItem(false)
    });

    return <>
        <Burger onClick={() => setOpenNavItem(prev => !prev)}/>
        {isNavItemOpen && <MobileHeaderWrapper>
            <MobileHeaderTop>
                <Logo>
                    <Link to="/" onClick={() => { history.push('/');}} />
                </Logo>
                <Close onClick={() => setOpenNavItem(prev => !prev)}/>
            </MobileHeaderTop>
            <BiContainer>
                <MobileHeaderCenterOut>
                    <MobileHeaderCenter>
                        <LinksHeader />
                    </MobileHeaderCenter>
                </MobileHeaderCenterOut>
            </BiContainer>
            <MobileHeaderBottomOut>
                <MobileHeaderBottom>
                    <Button>
                        <a onClick={() => setOpenModal(prev => !prev)}>{t('Contact us')}</a>
                    </Button>
                    <LangHeader />
                </MobileHeaderBottom>
            </MobileHeaderBottomOut>
        </MobileHeaderWrapper>}

    </>
}
