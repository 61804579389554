import styled from "styled-components";
import {media} from "../../atoms/theme";
import CloseIcon from "../../../../assets/icons/close-icon.svg";

export const WrapperPersonPopup = styled.div`
    position: fixed;
    background: #fff;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    .close-icon{
        background-image: url("${CloseIcon}");
        background-repeat: no-repeat;
        width: 64px;
        height: 64px;
        background-size: 64px;
        position: absolute;
        top: 50px;
        right: calc(((100% - 1400px) / 2) + 100px);
        cursor: pointer;
        z-index: 10;
        @media(max-width: 1400px) {
          right: 100px;
        }
        ${media.down('lg')} {
          right: 60px;
        }
        ${media.down('md')} {
            background-size: 32px;
            width: 32px;
            height: 32px;
            top: 20px;
        }
    }
`

export const WrapperContact = styled.div`
  width: 1400px;
  margin: 0 auto;
  display: table;
  box-sizing: border-box;
  h4{
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left; 
    margin: 50px 100px;
    color: #25282B; 
  }
  @media(max-width: 1400px) {
    width: 100%;
  }
  ${media.down('xl')} {
    h4{
      margin: 50px 60px;
      padding-right: 64px;
    }
  }
  ${media.down('lg')} {
    h4{
      margin-bottom: 0;
    }
  }
  ${media.down('md')} {
    h4{
      padding-right: 0;
    }
  }
`

export const WrapperContactIn = styled.div`
    display: table;
    box-sizing: border-box;
    width: 100%;
    padding: 60px 0;

    ${media.up('lg')} {
        padding: 100px 0;
    }

`

export const ContactLeft = styled.div`
    float: left;
    display: table;
    box-sizing: border-box;
    margin-left: 100px;
    max-width: 443px;
    .person-info{
      width: 100%;
      display: table;
      box-sizing: border-box;
      .person-image{
        width: 95px;
        height: 95px;
        border-radius: 95px;
        overflow: hidden;
        float: left;
        margin-right: 30px;
        img{
          width: 95px;
        }
      }
      .person-content{
        width: calc(100% - 125px);
        padding-top: 17.5px;
        float: left;
        span{
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0.02em;
          text-align: left;
          margin: 0;
          padding: 0;
        }
        p{
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 34px;
          letter-spacing: 0em;
          text-align: left;
          margin: 0;
          padding: 0;
        }
      }
    }
    .under-person{
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      margin: 40px 0 0 0;
    }
  }
  ${media.down('xl')} {
    max-width: calc(100% - 200px);
  }
  ${media.down('lg')} {
    max-width: calc(100% - 120px);
    margin-left: 60px;
  }
  ${media.down('md')} {
    .person-info{
      .person-image{
        float: none;
        img{
          width: 95px;
        }
      }
      .person-content{
        width: 100%;
        float: none;
      }
    }
  }
`

export const ContactRight = styled.div`
    width: 386px;
    float: right;
    display: table;
    box-sizing: border-box;
    margin-right: 100px;
    div{
      margin-top: 0;
    }
    ${media.down('xl')} {
      width: calc(100% - 200px);
      margin-top: 60px;
      div{
        max-width: 100%;
      }
    }
    ${media.down('lg')} {
      width: calc(100% - 120px);
      margin-right: 60px;
      margin-top: 60px;
    }
`
