import styled from "styled-components";
import {media} from "../../atoms/theme";
import Fire from "../../../../assets/icons/fire.svg";

export const ContainerItems = styled.div`
  display: table;
  box-sizing: border-box;
  margin: 0 0 160px 0;
  width: 100%;
  h4{
    font-size: 46px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 40px 0;
  }
  p{
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;   
    margin: 0;
    max-width: 692px;
  }
  ${media.down('md')} {
    margin: 0 0 60px 0;
    h4{
      font-size: 30px;
      line-height: 38px;
      margin: 0 0 20px 0;
    }
    p{
      font-size: 16px;
      line-height: 24px;
    }
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperItems = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 40px 84px;
  margin-top: 145px;
  ${media.down('lg')} {
    gap: 30px 45px;
  }
  ${media.down('md')} {
    margin-top: 50px;
    gap: 0;
  }
`

export const WrapperSoloItem = styled.div`
  flex: 1 0 calc(100% / 6);
  max-width: calc((100% / 6) - 84px);
  display: table;
  box-sizing: border-box;
  img{
      height: 70px;
      display: block;
      margin: 0 auto;
      margin-bottom: 25px;
  }
  p{
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.02em;
      text-align: center;
      margin: 0;
  }
  a{
    text-decoration: none;
    color: #25282B;
  }
  ${media.down('xl')} {
    flex: 1 0 calc(100% / 3);
    max-width: calc((100% / 3) - 84px);
    img{
      height: 70px;
      max-width: auto;
    }
  }
  ${media.down('lg')} {
    flex: 1 0 calc(100% / 3);
    max-width: calc((100% / 3) - 45px);
    img{
      height: 50px;
    }
  }
  ${media.down('md')} {
    width: calc((100% / 3) - 20px);
    display: block;
    margin: 20px 10px;
    float: left;
    max-width: calc((100% / 3) - 20px);
    a{
      display: table;
      box-sizing: border-box;
      width: 100%;
    }
    p{
      width: 100%;
      font-size: 12px;
      white-space: initial;
      line-break: anywhere;
    }
  }
`
