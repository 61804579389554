import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperContactShort = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  box-sizing: border-box;
  align-items: center;
  border: 7px solid ${(props) => props.theme.colors.primaryOrange};
  padding: 80px 128px 80px 102px;
  margin-bottom: 160px;
  p{
    font-size: 34px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: -0.01em;
    text-align: left;    
    float: left;
    margin: 0;
  }

  a{
      cursor: pointer;
      float: right;
      display: block;
      padding: 0 26px;
      background: ${(props) => props.theme.colors.primaryOrange};
      font-size: 18px;
      font-weight: 600;
      line-height: 60px;
      letter-spacing: 0.02em;
      height: 60px;
      color: #ffffff;
      border-radius: 5px;
      text-decoration: none;
      transition: background 0.2s;
      &:hover{
        background: #000000;
        transition: background 0.2s;
      }
      min-width: 150px;
  }
  ${media.down('lg')} {
    text-align: center;
    padding: 60px 20px;
    margin-bottom: 60px;
    display: table;
    p{
      font-size: 24px;
      line-height: 30px;
      width: 100%;
      float: none;
      text-align: center;
      margin: 0 0 20px 0;
    }
    a{
      float: none;
      display: inline-block;
      font-size: 16px;
      line-height: 45px;
      height: 45px;
      padding: 0 20px;
    }
  }
`
