import React, {FunctionComponent} from "react";
import { ContainerNewsletter, WrapperNewsletter } from "./styles";


export const NewsletterBlock: FunctionComponent = () => {
    return <>
        <ContainerNewsletter>
            <WrapperNewsletter>
                <p className="title">Subscribe our Newsletter</p>
                <p className="description">Adipiscing diam aliquam non leo lacus amet congue facilisi donec sit commodo
                    magna ac eu</p>
                <div className="action-wrapper">
                    <input className="input" placeholder="Your email"/>
                    <button className="button">Subscribe</button>
                </div>
            </WrapperNewsletter>
        </ContainerNewsletter>
    </>
}