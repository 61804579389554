import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerItems = styled.div`
  display: table;
  box-sizing: border-box;
  margin: 0 0 160px 0;
  width: 100%;
  h4{
    font-size: 46px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 80px 0;
  }
  ${media.down('md')} {
    margin: 0 0 60px 0;
    h4{
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 40px;
    }
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperItems = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 40px 22px;
  padding-bottom: 80px;
  ${media.down('md')} {
    gap: 20px 0;
    padding-bottom: 55px;
  }
`

export const WrapperSoloItem = styled.div`
  flex: 1 0 33%;
  max-width: calc((100% / 3) - 22px);
  display: table;
  box-sizing: border-box;
  a{
    text-decoration: none;
    color: #000000;
    img{
        width: 100%;
        margin: 0 0 40px 0;
    }
    p{
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 34px;
      letter-spacing: 0em;
      text-align: left;
      margin: 0 0 10px 0;
    }
    span{
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.02em;
      text-align: left;
      display: block;
      margin: 0 0 24px 0;
    }
  }
  div{
    width: 100%;
    display: block;
    .wrapper-arrow{
      margin: 5px 5px -5px 0;
    }
    a{
      span{
        font-weight: 600;
      }
    }
  }
  ${media.down('lg')} {
    flex: 1 0 50%;
    max-width: calc((100% / 2) - 22px);
  }
  ${media.down('md')} {
    flex: 1 0 100%;
    max-width: 100%;
    a{
      img{
        width: 120px;
        margin: 0 20px 0 0;
        float: left;
      }
      p{
        width: calc(100% - 140px);
        float: left;
        font-size: 22px;
        line-height: 26px;
      }
      span{
        width: calc(100% - 140px);
        float: left;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;
      }
    }
    div{
      width: calc(100% - 140px);
      float: left;
      a{
        width: 100%;
      }
    }
  }
`
