import React, {FunctionComponent, useEffect} from "react";
import {ContainerCompetence, IconSaleforce, WrapperCertificatesDesc, WrapperCompetenceDesc, WrapperImgCompetence, WrapperItemsIcons} from "./styles";
import { ICompetenciesSaleforce } from './interface'
import HTMLReactParser from "html-react-parser";
import generateLink from "../../../../core/api/assets";

export const CompetenceSaleforce: FunctionComponent<ICompetenciesSaleforce> = ({title, content, title_2, content_2, items, image}) => {

    useEffect(() => {
        const icons = document.getElementsByClassName('item-icon');
        let icon_height = 0;
        for(var j=0; j < icons.length; j++)
            if(icon_height < icons[j].clientHeight) icon_height = icons[j].clientHeight;
        for(var j=0; j < icons.length; j++)
            icons[j].setAttribute('style',`height: ${icon_height}px`)
    }, [items])

    return <>
        <ContainerCompetence>
            <WrapperCompetenceDesc>
                <div className="content">
                    <h2>{title}</h2>
                    <p>{HTMLReactParser(content ? content : "")}</p>
                </div>

            </WrapperCompetenceDesc>
            <WrapperItemsIcons>
                {Object.keys(items).map((keyName, i) => (
                    <div className="item-icon">
                        <IconSaleforce/>
                        <p>{items[keyName].data['title']}</p>
                    </div>
                ))}
            </WrapperItemsIcons>
            {(image !== null && image !== '') &&<WrapperImgCompetence image={generateLink(image)}/>}
        </ContainerCompetence>
        {(title_2 || content_2 || (image !== null && image !== '')) &&<WrapperCertificatesDesc>
            <div className="content">
                <h2>{title_2}</h2>
                <p>{content_2}</p>
            </div>
        </WrapperCertificatesDesc>}
    </>

}
