import React, {FunctionComponent} from "react";
import {WrapperLogoInperly } from "./styles";
import {ILogoInperly} from './interface'

export const LogoInperly: FunctionComponent<ILogoInperly> = ({image}) => {
    return <>
        <WrapperLogoInperly>
            <img alt="Logo Inperly" src={image} />
        </WrapperLogoInperly>
    </>
}
