import styled from "styled-components";
import {media} from "../../atoms/theme";
import Tech from "../../../../assets/icons/tech.svg";

export const WrapperOut = styled.div`
  max-width: 1640px;
  margin: 0 auto;
  ${media.down('lg')} {
    max-width: 540px;
  }
  ${media.down('md')} {
    max-width: 335px;
  }
`

export const WrapperUnderBanner = styled.div`
  padding: 120px 0 120px calc((100% - 1400px) / 2);
  display: table;
  box-sizing: border-box;
  border-right: 7px solid ${(props) => props.theme.colors.primaryOrange};
  border-bottom: 7px solid ${(props) => props.theme.colors.primaryOrange};
  width: calc(100% - ((100% - 1400px) / 2));
  margin-top: -160px;
  margin-bottom: 160px;
  margin-right: 66px;
  p{
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    float: left;
    max-width: 591px;
    margin: 0;
    color: #25282B;
  }
  @media(max-width: 1440px) {
    padding: 120px 0 120px calc((100% - 1200px) / 2);
  }
  @media(max-width: 1439px) {
    width: calc(100% - ((100% - 1200px) / 2) - 66px);
  }
  @media(max-width: 1200px) {
    padding: 120px 0 120px calc((100% - 800px) / 2);
    width: calc(100% - ((100% - 800px) / 2) - 66px);
    p{
      max-width: 340px;
    }
  }
  ${media.down('lg')} {
    border: none;
    position: relative;
    padding: 60px 0;
    margin: 0;
    width: 100%;
    p{
      max-width: 540px;
    }
    padding-top: 0;
    p{
      font-size: 18px;
      line-height: 26px;
    }
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
    p{
      max-width: 335px;
    }
  }
`

export const UnderBannerIcon = styled.div`
  display: block;
  float: left;
  width: 110px;
  height: 126px;
  margin-right: 187px;
  background-image: url("${Tech}");
  background-size: 110px;
  @media(max-width: 1200px) {
    margin-right: 50px;
  }
  ${media.down('lg')} {
    position: absolute;
    z-index: 10;
    top: -123px;
    right: 32px;
    margin: 0;
    width: 55px;
    height: 63px;
    top: -91.5px;
    background-size: 55px;
  }
`
