import React, {FunctionComponent} from "react";
import {Logo} from './styles'
import { Link, useHistory } from 'react-router-dom';
export const LogoHeader: FunctionComponent = () => {
    const history = useHistory();

    return <>
        <Logo>
            <Link to="/" onClick={() => { history.push('/');}} />
        </Logo>
    </>
}