import {IBackgroundIndustryImage} from "./interface";
import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperIndustryDesc = styled.div`
  bottom: 30px;
  left: 22px;
  position: absolute;
  color: white;
  .text-link {
    margin-top: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.01em;
    margin-bottom: 10px;
    color: white;
    &:hover {
      color: white;
    }
  }
  .description-industry {
    font-size: 14px;
    line-height: 20px;
    max-width: 295px;
    margin: 0;
    letter-spacing: 0.02em;
  }

  .name-industry {
    margin-top: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.01em;
    margin-bottom: 10px;
  }

  .wrapper-name-item {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    a{
      .wrapper-arrow {
        margin: 7px 5px 5px 0;
      }
    }
  }

  ${media.up('lg')} {
    top: 143px;
    left: 122px;
    .text-link {
      margin-bottom: 20px;
      font-size: 46px;
      line-height: 56px;
    }
    .wrapper-arrow {
      margin: 20px 5px -10px 0 !important;
    }
 
    .description-industry {
      font-size: 18px;
      line-height: 26px;
      max-width: 394px;
      margin: 0;
    }
  }

`
export const WrapperIndustry = styled.div`
  margin-bottom: 120px;
  ${media.down('lg')} {
    margin-bottom: 60px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const IndustryItem = styled.div`
  position: relative;
  margin-bottom: 20px;

  ${media.up('lg')} {
    margin-bottom: 50px;
  }
`
export const BackgroundIndustry = styled.div<IBackgroundIndustryImage>`
  //background: #098ce5 no-repeat;
    background: url(${(props) => props.IndustryBackgroundMobile}) no-repeat;
  width: 100%;
  height: 340px;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  color: white;

  ${media.up('lg')} {
      background: url(${(props) => props.IndustryBackground}) no-repeat;
    background-size: cover;
    height: 440px;
  }

`
