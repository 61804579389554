import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperHeaderCaseStudiesDetails = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100%;
  margin: 60px 0;
  ${media.down('md')} {
    margin: 20px 0;
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const TopHeader = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  border-bottom: 6px solid ${(props) => props.theme.colors.primaryOrange};
  padding-bottom: 80px;
  img{
    float: right;
    margin-top: 104px;
    height: 55px;
    display: block;
  }
  ${media.down('lg')} {
    img{
      float: none;
    }
  }
  ${media.down('md')} {
    img{
      height: 37px;
    }
  }
`

export const TopHeaderContent = styled.div`
  max-width: 100%;
  float: left;
  display: table;
  box-sizing: border-box;
  span{
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 20px 0;
  }
  h1{
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -0.015em;
    text-align: left;
    margin: 0;
    max-width: 100%;
  }
  .show-mobile{
    display: none;
  }
  ${media.down('lg')} {
    max-width: 100%;
    margin-bottom: 20px;
  }
  ${media.down('md')} {
    h1{
      font-size: 36px;
      line-height: 48px;
    }
    span{
      margin: 10px 0 0 0;
      font-size: 18px;
      line-height: 26px;
    }
    .show-mobile{
      display: inline-block;
    }
    .hide-mobile{
      display: none;
    }
  }
`

export const BottomHeader = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  img{
    width: 100%;
    margin: 0 0 120px 0;
  }
  p{
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #25282B;
    margin: 0;
    max-width: 794px;
    display: block;
    margin: 0 auto;
  }
  ${media.down('md')} {
    img{
      margin: 20px 0 60px 0;
    }
    p{
      font-size: 18px;
      line-height: 26px;
    }
  }
`