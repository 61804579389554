import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import { ContactCv } from "../../ui/molecules/contact-cv";
import BiContainer from "../../ui/atoms/container/BiContainer";
import { CareerBreadcrumb } from "../../breadcrumbs/career/career";
import { HeadCompetences } from "../../ui/organisms/head-competences/head-competences";
import { ImgBackgroundFull } from "../../ui/atoms/banner-full/styles";
import Banner from '../../../assets/images/career-teams/banner.png'
import Banner_second from '../../../assets/images/career-teams/banner_second.png'
import { IndustriesDesc } from "../../ui/molecules/industries-desc/industries-desc";
import { OurTeams } from "../../ui/molecules/our-teams";
import { Teams } from "../../ui/organisms/teams/teams";
import {teams, items, boxes} from './data'
import { CheckListCareer } from "../../ui/molecules/check-list-career/check-list-career";
import { ContactShort } from "../../ui/molecules/contact-short";
import { CareerBoxes } from "../../ui/molecules/career-boxes/career-boxes";
import { useTranslation } from "react-i18next";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import { useLocation } from 'react-router-dom';
import generateLink from "../../../core/api/assets"
import getSlug from "../../../core/router/getSlug";

export const CareerTeamsPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [teams, setTeams] = useState([]);
    const [checkList, setCheckList] = useState([]);
    const [perks, setPerks] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/teams/page/${pageid}`)
                .then(response => {
                    setTeams(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/check_list/page/${pageid}`)
                .then(response => {
                    setCheckList(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/perks/page/${pageid}`)
                .then(response => {
                    setPerks(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    return <>
        <BiContainer>
            <CareerBreadcrumb 
                title={content['header_-_title']}
                slug={slug}
            />
            <HeadCompetences 
                title={content['header_-_title']}
            />
        </BiContainer>
        <ImgBackgroundFull BackgroundMobile={generateLink(content['header_-_banner'])} BackgroundDesktop={generateLink(content['header_-_banner'])} />
        <BiContainer>
            <IndustriesDesc
                title={content['section_1_-_title']}
                content={content['section_1_-_content']}
            />
            <OurTeams
                title=""
                content=""
                banner={generateLink(content['section_1_-_image'])}
            />
            <Teams 
                title={content['section_2_-_title']}
                content={content['section_2_-_content']}
                items={teams}
                link_text={content['section_2_-_link_text']}
                link={content['section_2_-_link']}
                modals={true}
            />
            <CheckListCareer
                title={content['section_3_-_title']}
                items={checkList}
            />
            <CareerBoxes
                title={content['section_4_-_title']}
                items={perks}
            />
            <ContactCv
                title={content['contact_title']}
                content={content['contact_content']}
            />
            <ContactPersonPopup
                title={content['contact_popup_title']}
                place={content['contact_popup_position']}
                content={content['contact_popup_content']}
                name={content['contact_popup_person_name']}
                image={generateLink(content['contact_popup_avatar'])}
                send_to={content['contact_send_to']}
                pardot_link={content['pardot_link']}
            />
        </BiContainer>
    </>
}
