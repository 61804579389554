import React, {FunctionComponent} from "react";
import {WrapperContact} from "./styles";
import {BIContactProps} from "./interfaces";
import { ContactForm } from "../../molecules/contact-form";
import { useTranslation } from "react-i18next";

export const Contact: FunctionComponent<BIContactProps> = ({title, content}) => {
    const { t } = useTranslation();

    return <>
        <WrapperContact id="contact">
           <h6>{title}</h6>
           <p>{content}</p>
           <ContactForm />
        </WrapperContact>
    </>
}
