import styled from "styled-components";
import {media} from "../theme";

export const Button = styled.div`
  display: none;
  width: 170px;
  height: 40px;
  float: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  background: ${(props) => props.theme.colors.primaryOrange};
  margin: 20px 0;
  border-radius: 5px;
  transition: background 0.2s;
  cursor: pointer;
  a{
    display: block;
    width: 170px;
    height: 40px;
    color: #fff;
    text-decoration: none;
  }
  &:hover{
      background: #000000;
      transition: background 0.2s;
  }


  ${media.up('nav')} {
    display: block;
  }
`;
