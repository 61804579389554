import React, {FunctionComponent} from "react";
import {WrapperHeadCompetences, WrapperBorderCompetences} from "./styles";
import { ISoloHeader } from "./interface";
import HTMLReactParser from "html-react-parser";

export const SoloHeader: FunctionComponent<ISoloHeader> = ({title, margin}) => {
    return <>
        <WrapperHeadCompetences margin={margin}>
            <div>
                <h3>
                    {HTMLReactParser(title ? title : '')}
                </h3>
            </div>
            <WrapperBorderCompetences></WrapperBorderCompetences>
        </WrapperHeadCompetences>
    </>
}
