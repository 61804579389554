import styled from "styled-components";
import {media} from "../../atoms/theme";
import Cake from "../../../../assets/icons/cake.svg";

export const WrapperModels = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  margin: 0 0 160px 0;
  ${media.down('lg')} {
    margin-bottom: 60px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const DescModels = styled.div`
  h4{
    font-size: 46px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 40px 0;
  }
  p{
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0 0 120px 0;
    max-width: 692px;
  }
  ${media.down('lg')} {
    h4{
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 20px;
    }
    p{
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 40px 0;
    }
  }
`

export const WrapperItems = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 100px 90px;
  ${media.down('xl')} {
    gap: 20px 0;
  }
`

export const WrapperSoloItem = styled.div`
  flex: 1 0 calc(100% / 2);
  max-width: calc((100% / 2) - 90px);
  display: table;
  box-sizing: border-box;
  .icon-solo{
      width: 88px;
      height: 88px;
      margin-right: 40px;
      float: left;
      background-image: url("${Cake}");
      background-size: 88px;
  }
  .content{
      width: calc(100% - 128px);
      display: table;
      box-sizing: border-box;
      .title{
        font-size: 34px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0 0 20px 0;
      }
      .incontent{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0;
      }
  }
  ${media.down('xl')} {
    flex: 1 0 100%;
    max-width: 100%;
  }
  ${media.down('lg')} {
    .icon-solo{
      width: 48px;
      height: 48px;
      background-size: 48px;
      margin-right: 20px;
    }
    .content{
      width: calc(100% - 68px);
      .title{
        font-size: 24px;
        line-height: 30px;
        margin: 0 0 10px 0;
      }
      .incontent{
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
`
