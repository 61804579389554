import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerItems = styled.div`
  display: table;
  box-sizing: border-box;
  margin: 0 auto 120px auto;
  width: 794px;
  h4{
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 57px 0;
  }
  ${media.down('lg')} {
    width: 100%;
    margin-bottom: 60px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperItems = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 54px 20px;
  ${media.down('lg')} {
    gap: 20px;
  }
`

export const WrapperSoloItem = styled.div`
  flex: 1 0 calc(100% / 4);
  max-width: calc((100% / 4) - 20px);
  display: table;
  box-sizing: border-box;
  img{
    width: 36px;
    height: auto;
    float: left;
    margin-right: 10px;
  }
  p{
    width: calc(100% - 46px);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0;
    float: left;
  }
  ${media.down('lg')} {
    flex: 1 0 calc(100% / 2);
    max-width: calc((100% / 2) - 20px);
    img{
      width: 34px;
    }
    p{
      font-size: 16px;
      line-height: 24px;
    }
  }
`
