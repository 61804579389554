import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import {WrapperJobOffers, JobOffersTop, WrapperItems, SoloItem} from "./styles";
import { IJobOffersAll } from "./interface"
import { Link, useHistory } from 'react-router-dom';
import { useGlobalLang } from '../../molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import getSlug from "../../../../core/router/getSlug";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";

export const JobOffersAll: FunctionComponent<IJobOffersAll> = ({items}) => {
    const history = useHistory();
    const { t } = useTranslation()
    const [allJobs, setAllJobs] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [teams, setTeams] = useState([]);
    const [levels, setLevels] = useState([]);
    const [locations, setLocations] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));
    const [select_team, setSelectTeam] = useState('');
    const [select_level, setSelectLevel] = useState('');
    const [select_location, setSelectLocation] = useState('');
    const [pageNumber, setPageNumber] = useState(0)

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection-job_offers`)
        .then(response => {
            setAllJobs(response.data);
            setJobs(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/job-offers/teams`)
        .then(response => {
            setTeams({...response.data});
        }) 
        .catch(err => {
            console.log(err);
        })
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/job-offers/levels`)
        .then(response => {
            setLevels({...response.data});
        }) 
        .catch(err => {
            console.log(err);
        })
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/job-offers/locations`)
        .then(response => {
            setLocations({...response.data});
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    const getNameData = (id, type) => {
        if(type == 'teams'){
            const indexEl = Object.keys(teams).find((keyName) => teams[keyName].id == id)
            if(indexEl) return teams[indexEl].data['title']
        }
        if(type == 'levels'){
            const indexEl = Object.keys(levels).find((keyName) => levels[keyName].id == id)
            if(indexEl) return levels[indexEl].data['title']
        }
        if(type == 'locations'){
            const indexEl = Object.keys(locations).find((keyName) => locations[keyName].id == id)
            if(indexEl) return locations[indexEl].data['title']
        }
    }

    const filterJobsTeam = (e) => {
        setSelectTeam(e.target.value)
        setPageNumber(0)
        if(e.target.value !== '' && select_location !== '' && select_level !== ''){
            setJobs(allJobs.filter(job => job['data']['location'] == select_location && job['data']['level'] == select_level && job['data']['team'] == e.target.value))
        }else if(e.target.value == '' && select_location !== '' && select_level !== ''){
            setJobs(allJobs.filter(job => job['data']['location'] == select_location && job['data']['level'] == select_level))
        }else if(e.target.value !== '' && select_location == '' && select_level !== ''){
            setJobs(allJobs.filter(job => job['data']['team'] == e.target.value && job['data']['level'] == select_level))
        }else if(e.target.value !== '' && select_location !== '' && select_level == ''){
            setJobs(allJobs.filter(job => job['data']['team'] == e.target.value && job['data']['location'] == select_location))
        }else if(e.target.value == '' && select_location == '' && select_level !== ''){
            setJobs(allJobs.filter(job => job['data']['level'] == select_level))
        }else if(e.target.value !== '' && select_location == '' && select_level == ''){
            setJobs(allJobs.filter(job => job['data']['team'] == e.target.value))
        }else if(e.target.value == '' && select_location !== '' && select_level == ''){
            setJobs(allJobs.filter(job => job['data']['location'] == select_location))
        }else{
            setJobs(allJobs)
        }
    }

    const filterJobsLevel = (e) => {
        setSelectLevel(e.target.value)
        setPageNumber(0)
        if(select_team !== '' && select_location !== '' && e.target.value !== ''){
            setJobs(allJobs.filter(job => job['data']['location'] == select_location && job['data']['level'] == e.target.value && job['data']['team'] == select_team))
        }else if(select_team == '' && select_location !== '' && e.target.value !== ''){
            setJobs(allJobs.filter(job => job['data']['location'] == select_location && job['data']['level'] == e.target.value))
        }else if(select_team !== '' && select_location == '' && e.target.value !== ''){
            setJobs(allJobs.filter(job => job['data']['team'] == select_team && job['data']['level'] == e.target.value))
        }else if(select_team !== '' && select_location !== '' && e.target.value == ''){
            setJobs(allJobs.filter(job => job['data']['team'] == select_team && job['data']['location'] == select_location))
        }else if(select_team == '' && select_location == '' && e.target.value !== ''){
            setJobs(allJobs.filter(job => job['data']['level'] == e.target.value))
        }else if(select_team !== '' && select_location == '' && e.target.value == ''){
            setJobs(allJobs.filter(job => job['data']['team'] == select_team))
        }else if(select_team == '' && select_location !== '' && e.target.value == ''){
            setJobs(allJobs.filter(job => job['data']['location'] == select_location))
        }else{
            setJobs(allJobs)
        }
    }

    const filterJobsLocation = (e) => {
        setSelectLocation(e.target.value)
        setPageNumber(0)
        if(select_team !== '' && e.target.value !== '' && select_level !== ''){
            setJobs(allJobs.filter(job => job['data']['location'] == e.target.value && job['data']['level'] == select_level && job['data']['team'] == select_team))
        }else if(select_team == '' && e.target.value !== '' && select_level !== ''){
            setJobs(allJobs.filter(job => job['data']['location'] == e.target.value && job['data']['level'] == select_level))
        }else if(select_team !== '' && e.target.value == '' && select_level !== ''){
            setJobs(allJobs.filter(job => job['data']['team'] == select_team && job['data']['level'] == select_level))
        }else if(select_team !== '' && e.target.value !== '' && select_level == ''){
            setJobs(allJobs.filter(job => job['data']['team'] == select_team && job['data']['location'] == e.target.value))
        }else if(select_team == '' && e.target.value == '' && select_level !== ''){
            setJobs(allJobs.filter(job => job['data']['level'] == select_level))
        }else if(select_team !== '' && e.target.value == '' && select_level == ''){
            setJobs(allJobs.filter(job => job['data']['team'] == select_team))
        }else if(select_team == '' && e.target.value !== '' && select_level == ''){
            setJobs(allJobs.filter(job => job['data']['location'] == e.target.value))
        }else{
            setJobs(allJobs)
        }
    }

    const articlesPerPage = 7
    const pagesVisited = pageNumber * articlesPerPage

    const displayArticles = Object.keys(jobs)
        .slice(pagesVisited, pagesVisited + articlesPerPage)
        .map((keyName) => {
            return (<SoloItem>
                <p>{jobs[keyName].data['title']}</p>
                <ul>
                    <li><strong>Team: </strong>{getNameData(jobs[keyName].data['team'], 'teams')}</li>
                    <li><strong>Location: </strong>{getNameData(jobs[keyName].data['location'], 'locations')}</li>
                    <li><strong>Level: </strong>{getNameData(jobs[keyName].data['level'], 'levels')}</li>
                </ul>
                <Link to={`/job-offer/${jobs[keyName].id}`} onClick={() => { history.push(`/job-offer/${jobs[keyName].id}`);}}>Go to job offer</Link>
            </SoloItem>)
        })

    const pageCount = Math.ceil(jobs.length / articlesPerPage)

    const changePage = ({selected}) => {
        setPageNumber(selected)
        window.scrollTo(0, 0);
    }

    return <>
        <WrapperJobOffers>
            <JobOffersTop>
                <select onChange={e => filterJobsTeam(e)} value={select_team}>
                    <option value="">Team</option>
                    {Object.keys(teams).map((keyName, i) => (
                        <option value={teams[keyName].id}>{teams[keyName].data['title']}</option>
                    ))}
                </select>
                <select onChange={e => filterJobsLevel(e)} value={select_level}>
                    <option value="">Level</option>
                    {Object.keys(levels).map((keyName, i) => (
                        <option value={levels[keyName].id}>{levels[keyName].data['title']}</option>
                    ))}
                </select>
                <select onChange={e => filterJobsLocation(e)} value={select_location}>
                    <option value="">Location</option>
                    {Object.keys(locations).map((keyName, i) => (
                        <option value={locations[keyName].id}>{locations[keyName].data['title']}</option>
                    ))}
                </select>
            </JobOffersTop>
            <WrapperItems>
                {displayArticles}
                {pageCount > 1 &&<ReactPaginate
                    previousLabel={"< Poprzednia"}
                    nextLabel={"Następna >"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={'activeBttn'}
                />}
            </WrapperItems>
        </WrapperJobOffers>
    </>

}
