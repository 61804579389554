import {ILogoServiceImage} from "./interface";
import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperServicesDesc = styled.div`
  color: ${(props) => props.theme.colors.black};
  margin-top: 60px;

  ${media.up('lg')} {
    max-width: 692px;
    margin-top: 160px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const WrapperServices = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 40px;

  ${media.up('lg')} {
    margin-top: 70px;
    margin-bottom: 120px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const ServiceItem = styled.div`
  text-align: center;
  background: #EDEFF1;
  max-width: calc(50% - 5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  padding: 33px 26px 20px 26px;
  div{
    display: inline-block;
  }
  a{
    min-width: 150px;
    text-decoration: none;
    color: #000000;
    .name-service {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      margin-top: 12px;
      margin-bottom: 6px;
    }
  }

  ${media.up('lg')} {
    padding: 70px 52px 50px 52px;
    max-width: 285px;
    a{
      min-width: 170px;
      .name-service {
        font-size: 24px;
        line-height: 34px;
        margin-top: 24px;
        margin-bottom: 20px;
      }
    }
  }
`
export const LogoService = styled.div<ILogoServiceImage>`
  background-image: url("${(props) => props.ServiceLogo}");
  background-repeat: no-repeat;
  width: 68px;
  height: 68px;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;

  ${media.up('lg')} {
    width: 126px;
    height: 126px;
  }
`
