import React, {FunctionComponent} from "react";
import {Link, useHistory} from 'react-router-dom';
import {ArrowRightIcon} from '../../ui/atoms/arrow-right-icon'
import {WrapperBreadcrumb} from '../styles'


export const IndustriesBreadcrumb: FunctionComponent = () => {
    const history = useHistory();

    return <>
        <WrapperBreadcrumb>
            <Link to="/" onClick={() => {
                history.push('/');
            }}>Billennium</Link>
            <ArrowRightIcon/>
            <Link to="/industries" onClick={() => {
                history.push('/industries');
            }}>Industries</Link>
        </WrapperBreadcrumb>
    </>
}
