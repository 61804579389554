import styled from "styled-components";
import {media} from "../../atoms/theme";

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: stretch;
    .solo-history{
        width: 100%;
        box-sizing: border-box;
        display: table;
        position: relative;
        span{
            font-size: 46px;
            font-style: normal;
            font-weight: 600;
            line-height: 56px;
            letter-spacing: -0.01em;
            text-align: left;
            margin: 0 0 10px 0;
            color: #25282B;
        }
        p{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.02em;
            text-align: left;
            color: #25282B;
            max-width: 385px;
            margin: 0;
        }
        ul{
            padding: 0 0 0 20px;
            margin: 20px 0 0 0;
            li{
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                letter-spacing: 0.02em;
                color: #25282B;
                max-width: 385px;
            }
        }
        img{
            margin: 60px 0 0 0;
            width: 488px;
        }
    }
    ${media.down('xl')} {
        .solo-history{
            padding-top: 36px;
            span{
                line-height: 56px;
                top: -20px;
                position: absolute;
            }
            div{
                top: 0;
            }
        }
    }
    ${media.down('lg')} {
        .solo-history{
            width: 100%;
            img{
                width: 100%;
            }
        }
    }
    ${media.down('md')} {
        .solo-history{
            span{
                font-size: 30px;
                line-height: 38px;
            }
            p, ul li{
                font-size: 16px;
                line-height: 24px;
            }
            img{
                margin: 20px 0 0 0;
            }
        }
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const RightContent = styled.div`
    width: 50%;
    box-sizing: border-box;
    display: table;
    border-right: 6px solid #FF5A00;
    float: left;
    ${media.down('xl')} {
        display: none;
    }
`

export const LeftContent = styled.div`
    width: 50%;
    box-sizing: border-box;
    display: table;
    float: left;
    position: relative;
    .solo-history{
        padding-left: 112px;
    }
    .l2{
        margin-top: 160px;
    }
    .l3{
        margin-top: 230px;
    }
    .l4{
        margin-top: 112px;
    }
    .l5{
        margin-top: 308px;
        padding-bottom: 162px;
    }
    .solo-history-right{
        width: 100%;
        position: absolute;
        left: -100%;
        padding: 0 112px 0 0;
        text-align: right;
        span, p{
            width: 100%;
            display: block;
            float: right;
            text-align: right;
        }
        div{
            left: calc(100% - 17px);
        }
    }
    .r1{
        top: 263px;
    }
    ${media.down('xl')} {
        width: 100%;
        border-left: 6px solid #FF5A00;
        .l2, .l3, .l4, .l5{
            margin-top: 57px;
        }
        .solo-history-right{
            box-sizing: border-box;
            display: table;
            position: relative;
            padding: 0 0 0 112px;
            left: auto;
            top: auto;
            text-align: left;
            margin-top: 57px;
            padding-top: 36px;
            span, p{
                float: none;
                text-align: left;
            }
            div{
                left: -17px;
            }
            span{
                line-height: 56px;
                top: -20px;
                position: absolute;
            }
        }
    }
    ${media.down('md')} {
        .solo-history{
            padding-left: 20px;
            span{
                top: -5px;
            }
        }
        .l5{
            padding-bottom: 40px;
        }
    }
`

export const Dot = styled.div`
    width: 28px;
    height: 28px;
    box-sizing: border-box;
    display: table;
    border: 6px solid #FF5A00;
    border-radius: 28px;
    position: absolute;
    background: #fff;
    left: -17px;
    top: 14px;
    
`

export const Bottom = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: table;
    padding: 20px 0 147px 0;
    text-align: center;
    position: relative;
    div{
        top: -5px;
        left: calc(50% - 17px);
    }
    span{
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -0.01em;
        margin: 0 0 10px 0;
        color: #25282B;
    }
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        margin: 0 0 60px 0;
        color: #25282B;
        width: 100%;
        text-align: center;
    }
    ${media.down('xl')} {
        text-align: left;
        padding-left: 118px;
        padding-top: 36px;
        div{
            left: -11px;
        }
        span{
            line-height: 56px;
            top: -20px;
            position: absolute;
        }
        p{
            text-align: left;
        }
    }
    ${media.down('md')} {
        padding-left: 46px;
        padding-top: 26px;
        padding-bottom: 60px;
        div{
            left: 9px;
        }
        span{
            font-size: 30px;
            line-height: 38px;
            top: -10px;
        }
        p{
            font-size: 16px;
            line-height: 24px;
        }
    }
`