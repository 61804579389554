import axios from 'axios';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import BiContainer from "../../ui/atoms/container";
import Header from "../../ui/organisms/header";
import Footer from "../../ui/organisms/footer";
import ScrollToTop from './scroll-up-routes'
import { getApiRoutes, getApiRoutesPeople, getApiRoutesPress, getApiRoutesJob, getApiRoutesCaseStudyCategory, getApiRoutesCaseStudy, getApiRoutesIndustries, getApiRoutesArticles } from '../../../core/router/routes'
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'

export const BaseLayout: FunctionComponent = () => {

    const [routes, setRoutes] = useState(getApiRoutes([]));
    const [people, setPeople] = useState({});
    const [press, setPress] = useState({});
    const [jobs, setJobs] = useState({});
    const [industries, setIndustries] = useState({});
    const [caseStudies, setCaseStudies] = useState({});
    const [caseStudiesCategory, setCaseStudiesCategory] = useState({});
    const [articles, setArticles] = useState({});
    const [lang, setLang] = useGlobalLang();

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/all-routes`)
        .then(response => {
            setPeople(getApiRoutesPeople({...response.data.people}));
            setPress(getApiRoutesPress({...response.data.press_releases}));
            setJobs(getApiRoutesJob({...response.data.job_offers}));
            setCaseStudiesCategory(getApiRoutesCaseStudyCategory({...response.data.case_study_category}));
            setCaseStudies(getApiRoutesCaseStudy({...response.data.case_study}));
            setIndustries(getApiRoutesIndustries({...response.data.industries}));
            setArticles(getApiRoutesArticles({...response.data.articles}));
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    return <>
        <Router>
            <Header/>
                <ScrollToTop />
                <Switch>
                    {Object.keys(routes).map((keyName, i) => (
                        <Route key={routes[keyName].id} exact path={routes[keyName].slug} component={routes[keyName].component}/>
                    ))}
                    {Object.keys(people).map((keyName, i) => (
                        <Route key={people[keyName].id} exact path={people[keyName].slug} component={people[keyName].component}/>
                    ))}
                    {Object.keys(press).map((keyName, i) => (
                        <Route key={press[keyName].id} exact path={press[keyName].slug} component={press[keyName].component}/>
                    ))}
                    {Object.keys(jobs).map((keyName, i) => (
                        <Route key={jobs[keyName].id} exact path={jobs[keyName].slug} component={jobs[keyName].component}/>
                    ))}
                    {Object.keys(industries).map((keyName, i) => (
                        <Route key={industries[keyName].id} exact path={industries[keyName].slug} component={industries[keyName].component}/>
                    ))}
                    {Object.keys(caseStudiesCategory).map((keyName, i) => (
                        <Route key={caseStudiesCategory[keyName].id} exact path={caseStudiesCategory[keyName].slug} component={caseStudiesCategory[keyName].component}/>
                    ))}
                    {Object.keys(caseStudies).map((keyName, i) => (
                        <Route key={caseStudies[keyName].id} exact path={caseStudies[keyName].slug} component={caseStudies[keyName].component}/>
                    ))}
                    {Object.keys(articles).map((keyName, i) => (
                        <Route key={articles[keyName].id} exact path={articles[keyName].slug} component={articles[keyName].component}/>
                    ))}
                </Switch>
            <BiContainer>
                <Footer/>
            </BiContainer>
        </Router>
    </>;
};
