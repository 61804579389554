import styled from "styled-components";
import { media } from "../../atoms/theme/media";
export const ContainerItemsCountry = styled.div`
  color: ${(props) => props.theme.colors.black};;
  display: table;
  box-sizing: border-box;
  margin: 60px 0;
  width: 100%;
  ${media.up("md")} {
    margin-top: 80px;
    margin-bottom: 160px;
  }
  ${media.down("md")} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperItemsCountry = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 22px 28px;

  ${media.up("md")} {
    gap: 60px 22px;
  }
`

export const WrapperSoloItemCountry = styled.div`
  flex: 1 0 calc(100% / 2);
  max-width: calc((100% / 2) - 22px);
  min-width: calc((100% / 2) - 22px);
  display: table;
  box-sizing: border-box;
  border-left: 6px solid ${(props) => props.theme.colors.primaryOrange};
  height: 51px;
  padding-left: 20px;
  
  span{
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.015em;
    margin: 0;
    display: block;
    width: 100%;
    line-height: 40px;
  }
  p{
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;

    margin: 0;
  }
  
  ${media.up("md")} {
    flex: 1 0 calc(100% / 3);
    max-width: calc((100% / 3) - 22px);
    min-width: calc((100% / 3) - 22px);
    padding-left: 50px;
    height: 147px;
    span{

      text-align: left;
      font-size: 80px;
      line-height: 80px;
 
    }
    p{
      text-align: left;
      font-size: 34px;
      line-height: 42px;
    }
  }
`