import styled from "styled-components";
import {media} from "../../atoms/theme";

export const Wrapper = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 80px;
  position: relative;
  margin: 60px 0 80px 0;
  h1{
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -0.015em;
    text-align: left;
    position: absolute;
    background: #ffffff;
    padding-right: 40px;
    z-index: 10;
    margin: 0;
  }
  ${media.down('lg')} {
    margin-bottom: 0;
    h1{
      position: static;
      font-size: 36px;
      line-height: 48px;
    }
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const BorderOrange = styled.div`
  width: 100%;
  height: 6px;
  background: #FF5A00;
  position: absolute;
  top: 37px;
  ${media.down('lg')} {
    position: static;
    margin-top: 20px;
  }
`