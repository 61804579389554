import styled from "styled-components";
import {media} from "../../atoms/theme";
import Check from "../../../../assets/icons/check.svg";

export const ContainerItems = styled.div`
  display: table;
  box-sizing: border-box;
  margin: 120px 0 160px 0;
  width: 100%;
  h4{
    font-size: 46px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 120px 0;
  }
  ${media.down('md')} {
    margin: 60px 0;
    h4{
      font-size: 30px;
      line-height: 38px;
      margin: 0 0 40px 0;
    }
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperItems = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: flex-start;
  gap: 40px 60px;
  ${media.down('lg')} {
    gap: 40px 0;
  }
  ${media.down('md')} {
    gap: 20px 0;
  }
`

export const WrapperSoloItem = styled.div`
  flex: 1 0 50%;
  max-width: calc((100% / 2) - 60px);
  border-bottom: 1px solid #D1D8DB;
  display: table;
  box-sizing: border-box;
  padding-bottom: 40px;
  .title{
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    padding-left: 39px;
    margin: 0;
    &:before{
      content: "";
      width: 24px;
      height: 24px;
      display: inline-block;
      background-image: url("${Check}");
      background-size: 24px;
      margin: 1px 15px -4px 0;
      position: absolute;
      left: 0;
      top: 4px;
    }
  }
  .content{
    margin: 0;
    margin-top: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  ${media.down('lg')} {
    flex: 1 0 100%;
    max-width: 100%;
  }
  ${media.down('md')} {
    padding-bottom: 20px;
    .title{
      font-size: 22px;
      line-height: 26px;
      marign-bottom: 10px;
      &:before{
        top: 0;
      }
    }
  }
`
