import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container/BiContainer";
import { BlogBreadcrumb } from "../../breadcrumbs/blog/blog";
import { useTranslation } from "react-i18next";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import { Newsletter } from '../../ui/molecules/newsletter/newsletter';
import { ArticleOthers } from '../../ui/molecules/article-others';
import { ArticleContent } from '../../ui/molecules/article-content';
import generateLink from "../../../core/api/assets"
import getSlug from "../../../core/router/getSlug";
import { ArticleTitle } from '../../ui/molecules/article-title';

export const ArticlePage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/articles/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    return <>
        <BiContainer>
            <BlogBreadcrumb
                title={content['title']}
                slug={slug}
            />
            <ArticleTitle
                title={content['title']}
                category={content['category']}
                author={content['author']}
                author_position={content['author_position']}
            />
            <ArticleContent
                banner={content['banner']}
                intro={content['intro']}
                section_1_title={content['section_1_-_title']}
                section_1_content={content['section_1_-_content']}
                section_2_title={content['section_2_-_title']}
                section_2_content={content['section_2_-_content']}
                section_3_title={content['section_3_-_title']}
                section_3_content={content['section_3_-_content']}
            />
            <ArticleOthers
                title="Similiar articles"
                items={content['similar_articles']}
            />
        </BiContainer>
        <Newsletter 
            title="Subscribe our Newsletter"
            content="Adipiscing diam aliquam non leo lacus amet congue facilisi donec sit commodo magna ac eu"
        />
        <ContactPersonPopup
            title={content['contact_popup_title']}
            place={content['contact_popup_position']}
            content={content['contact_popup_content']}
            name={content['contact_popup_person_name']}
            image={generateLink(content['contact_popup_avatar'])}
            send_to={content['contact_send_to']}
            pardot_link={content['pardot_link']}
        />
    </>
}
