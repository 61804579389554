import styled from "styled-components";
import ArrowDash from "../../../../assets/icons/arrow-dash.svg";
import ArrowDashWhite from "../../../../assets/icons/arrow-dash-white.svg";
import {BIActionArrowProperty} from "./interfaces";
import {media} from "../theme/media";

export const WrapperActionArrow = styled.div<BIActionArrowProperty>`
  display: flex;
  flex-direction: row;

  a {
    cursor: pointer;
    text-decoration: unset;
    color: black;
    font-weight: 600;

    .wrapper-arrow {
      width: 16px;
      height: 16px;
      margin: 1.3px 5px 4.5px 0;
      overflow: hidden;
      float: left;

      ${media.up('md')} {
        margin: 4.3px 5px 4.5px 0;
      }

      .sliding-arrow {
        background: ${props => props.white ? `url("${ArrowDashWhite}") no-repeat;` : `url("${ArrowDash}") no-repeat;`};
        height: 16px;
        width: 32px;
        transform: translate3d(1px, 0, 0);

        ${media.up('md')} {
          transform: translate3d(-16px, 0, 0);
          transition: transform 0.4s;
        }
      }
    }

    &:hover {
      color: ${props => props.hover == false ? `#000000`: props.theme.colors.primaryOrange};

      .wrapper-arrow {
        .sliding-arrow {
          ${media.up('md')} {
            transform: ${props => props.hover == false ? `translate3d(-16px, 0, 0)` : `translate3d(0, 0, 0)`};
            transition: ${props => props.hover == false ? `none` : `transform 0.4s`};
          }
        }
      }
    }
  }
`
