import React, {FunctionComponent} from "react";
import {Wrapper, Input } from './styles'
import { INewsletter } from "./interface";

export const Newsletter: FunctionComponent<INewsletter> = ({title, content}) => {
    return <>
        <Wrapper>
            <h4>{title}</h4>
            <p>{content}</p>
            <Input>
                <input type="email" placeholder="Your email" />
            </Input>
            <a>Subscribe</a>
        </Wrapper>
    </>
}
