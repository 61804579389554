import axios from 'axios';
import React, {FunctionComponent, useState} from "react";
import { WrapperContactForm } from "./styles";
import {ButtonContact} from "../../atoms/button-contact"
import { useTranslation } from "react-i18next";
import ReactHtmlParser from 'react-html-parser';

export const ContactForm: FunctionComponent = () => {
    const { t } = useTranslation();
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [company_name,setCompanyName] = useState("");
    const [content,setContent] = useState("");
    const [errors,setErrors] = useState({});

    const handleSubmit = (evt) => {
        const { REACT_APP_API_HOST } = process.env;
        evt.preventDefault();
        const data = {
            name: name,
            email: email,
            company_name: company_name,
            content: content,
        };
        axios.post(`${REACT_APP_API_HOST}api/send-mail`, data)
            .then(res=>successSend(res.data))
            .catch(err => console.log(err));
    }

    const successSend = (res) => {
        if(res.status === 200){
            setName('')
            setEmail('')
            setCompanyName('')
            setContent('')
        }else{
            setErrors(res.validate_err);
            console.log(errors);
        }
    }

    return <>
        <WrapperContactForm>
            <form onSubmit={handleSubmit}>
                <div className="input-group">
                    <input type="text" name="name" value={name} onChange={e => setName(e.target.value)} placeholder={t ('contact_input_name') } />
                    <span>{errors['name']}</span>
                </div>
                <div className="input-group">
                    <input type="email" name="email" value={email} onChange={e => setEmail(e.target.value)} placeholder={t ('contact_input_email') } />
                    <span>{errors['email']}</span>
                </div>
                <div className="input-group">
                    <input type="text" name="company_name" value={company_name} onChange={e => setCompanyName(e.target.value)} placeholder={t ('contact_input_company') } />
                    <span>{errors['company_name']}</span>
                </div>
                <div className="input-group">
                    <textarea name="content" value={content} onChange={e => setContent(e.target.value)} placeholder={t ('contact_textarea') } />
                    <span>{errors['content']}</span>
                </div>
                <div className="contact-approved">
                    <p>{ReactHtmlParser(t ('contact_approved') )}</p>
                </div>
                <div className="input-group">
                    <ButtonContact content={t ('contact_button') } />
                </div>
            </form>
        </WrapperContactForm>

    </>
}
