import styled from "styled-components";
import {media} from "../../atoms/theme";

export const Wrapper = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  padding-bottom: 120px;
  margin-bottom: 120px;
  border-bottom: 6px solid #FF5A00;
  ${media.down('lg')} {
    padding: 0 20px 40px 20px;
    margin-bottom: 40px;
  }
`

export const Intro = styled.div`
    display: table;
    box-sizing: border-box;
    margin: 0 auto 62px auto;
    max-width: 794px;
    p{
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;
    }
    ${media.down('lg')} {
        margin-bottom: 0;
        p{
            font-size: 18px;
            line-height: 26px;
        }
    }
`

export const SoloContent = styled.div`
    display: table;
    box-sizing: border-box;
    margin: 0 auto 60px auto;
    max-width: 794px;
    h3{
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0;
    }
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 30px 0 0 0;
    }
    ul{
        padding: 0 0 0 20px;
        margin: 30px 0 0 0;
        li{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.02em;
            text-align: left;
            margin: 0;
        }
    }
    ${media.down('lg')} {
        margin: 20px 0 0 0;
        h3{
            font-size: 24px;
            line-height: 30px;
        }
        p{
            margin: 20px 0 0 0;
            font-size: 16px;
            line-height: 24px;
        }
    }
`

export const ImageArticle = styled.div`
    display: table;
    box-sizing: border-box;
    margin: 0 auto 60px auto;
    max-width: 996px;
    img{
        width: 100%;
    }
    ${media.down('lg')} {
        margin: 40px auto 20px auto;
    }
`