import styled from "styled-components";
import {media} from "../../atoms/theme";

export const Content = styled.div`
    display: table;
    box-sizing: border-box;
    width: 100%;
    padding: 120px 0 60px 0;
    margin: 0 0 80px 0;
    border-bottom: 2px solid #D1D8DB;
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0;
    }
    ${media.down('lg')} {
        padding: 40px 0 20px 0;
        border-bottom: none;
        margin: 0;
    }
    ${media.down('md')} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const SoloContent = styled.div`
    display: table;
    box-sizing: border-box;
    max-width: 794px;
    margin: 0 auto 60px auto;
    ${media.down('lg')} {
        max-width: 100%;
        margin: 0 0 40px 0;
    }
`

export const ImageContent = styled.div`
    display: table;
    box-sizing: border-box;
    width: 100%;
    margin: 0 0 60px 0;
    img{
        width: 447px;
        margin: 0 61px 0 0;
        float: left;
    }
    ${media.down('lg')} {
        margin: 0 0 40px 0;
        img{
            width: 100%;
            margin: 0 0 40px 0;
        }
    }
`

export const Text = styled.div`
    width: calc(100% - (447px + 61px));
    display: table;
    box-sizing: border-box;
    float: left;
    p{
        max-width: 590px;
    }
    ${media.down('lg')} {
        width: 100%;
        p{
            max-width: 100%; 
        }
    }
`