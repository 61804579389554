import React, {FunctionComponent} from "react";
import {WrapperHomeIndustries, SoloHomeIndustries, IndustriesDesc} from './styles'
import {IHomeIndustries} from './interface'
import generateLink from "../../../../core/api/assets"
import { Link } from "react-router-dom";

export const HomeIndustries: FunctionComponent<IHomeIndustries> = ({title, items}) => {
    return<>
        <WrapperHomeIndustries>
            {title &&<IndustriesDesc>
                <h4>{title}</h4>
            </IndustriesDesc>}
            {Object.keys(items).map((keyName, i) => (
                <SoloHomeIndustries banner={generateLink(items[keyName].data['image'])} key={i}>
                    <Link to={`/industries/${items[keyName].slug}`} className="image-back">
                        <div className="image-back-shadow">
                            <p>{items[keyName].data['title']}</p>
                            <span></span>
                        </div>
                    </Link>
                </SoloHomeIndustries>
            ))}
        </WrapperHomeIndustries>
    </>
}
