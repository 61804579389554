import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperCareerProcess = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  margin: 0 0 160px 0;
  div{
    .wrapper-arrow{
        margin: 5px 5px -5px 0;
    }
  }
  ${media.down('lg')} {
    margin-bottom: 40px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const DescCareerProcess = styled.div`
  display: table;
  box-sizing: border-box;
  margin-left: 121px;
  padding-top: 50px;
  float: left;
  h4{
    font-size: 46px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 40px 0;
  }
  p{
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0 0 40px 0;
    max-width: 617px;
  }
  ${media.down('xl')} {
    p, h4{
      max-width: 300px;
    }
  }
  ${media.down('lg')} {
    width: 100%;
    float: none;
    margin: 0;
    padding-top: 45px;
    p, h4{
      max-width: 100%;
    }
    h4{
      font-size: 30px;
      line-height: 38px;
      margin: 0 0 20px 0;
    }
    p{
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 20px 0;
    }
  }
`

export const WrapperItems = styled.div`
    display: table;
    box-sizing: border-box;
    float: left;
    border-left: 6px solid ${(props) => props.theme.colors.primaryOrange};
    padding-left: 50px;
    ${media.down('md')} {
      padding-left: 20px;
    }
`

export const WrapperSoloItem = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  margin-bottom: 10px;
  p{
    font-size: 80px;
    font-style: normal;
    font-weight: 600;
    line-height: 92px;
    letter-spacing: -0.015em;
    text-align: left;
    float: left;
    margin: 0 36px 0 0;
    min-width: 110px;
  }
  img{
    width: 92px;
    float: left;
    display: block;
  }
  ${media.down('lg')} {
    width: 50%;
    float: left;
  }
  ${media.down('md')} {
    p{
      font-size: 61px;
      line-height: 70px;
      margin: 0 20px 0 0;
      min-width: 80px;
    }
    img{
      width: 70px;
    }
  }
`
