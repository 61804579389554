import React, {FunctionComponent} from "react";
import {WrapperDescriptionInperly} from "./styles";
import { useTranslation } from "react-i18next";
import { BIHeadDesc} from "./interfaces";

export const HeadDescriptionInperly: FunctionComponent<BIHeadDesc> = ({title, under_title}) => {
    const { t } = useTranslation();

    return <>
        <WrapperDescriptionInperly>
            <h1>{title}</h1>
            <h2>{under_title}</h2>
        </WrapperDescriptionInperly>
    </>
}
