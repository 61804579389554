import styled from "styled-components";
import {media} from "../../atoms/theme";
import CloseIcon from "../../../../assets/icons/close-icon.svg";
import CloseIconWhite from "../../../../assets/icons/close-icon-white.svg";
import Banner from '../../../../assets/images/company-group/modal.png'

export const WrapperSoloGroup = styled.div`
    width: 100%;
    display: flex;
    align-item: stretch;
    margin-bottom: 80px;
    ${media.down('lg')} {
        box-sizing: border-box;
        display: table;
    }
    ${media.down('md')} {
        margin-bottom: 0;
    }
`

export const SoloGroupLogo = styled.div`
    width: 305px;
    box-sizing: border-box;
    display: table;
    float: left;
    border-right: 6px solid #FF5A00;
    min-height: 162px;
    line-height: 162px;
    img{
        width: 200px;
        vertical-align: middle;
        display: inline-block;
    }
    ${media.down('lg')} {
        width: 100%;
        border: none;
    }
`

export const Content = styled.div`
    width: calc(100% - 305px);
    box-sizing: border-box;
    display: table;
    float: left;
    padding-left: 60px;
    ${media.down('lg')} {
        width: 100%;
        padding: 0;
    }
`

export const SoloGroupContent = styled.div`
    max-width: calc(100% - 194px);
    float: left;
    box-sizing: border-box;
    display: table;
    h5{
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0 0 20px 0;
        max-width: 586px;
    }
    p{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0;
        max-width: 586px;
    }
    ${media.down('lg')} {
        max-width: 100%;
    }
    ${media.down('md')} {
        h5{
            font-size: 24px;
            line-height: 30px;
        }
        p{
            font-size: 14px;
            line-height: 20px;
        }
    }
`

export const SoloGroupLink = styled.div`
    float: right;
    box-sizing: border-box;
    display: table;
    width: 140px;
    padding-top: 65px;
    ${media.down('lg')} {
        float: none;
        width: 100%;
    }
    ${media.down('md')} {
        padding-top: 0;
    }
`

export const SoloLink = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: table;
    margin-bottom: 20px;
    ${media.down('md')} {
        margin: 20px 0 0 0;
    }
`

export const WrapperGroupPopup = styled.div`
    position: fixed;
    background: #fff;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    .close-icon{
        background-image: url("${CloseIcon}");
        background-repeat: no-repeat;
        width: 64px;
        height: 64px;
        background-size: 64px;
        position: absolute;
        top: 50px;
        right: 50px;
        cursor: pointer;
        z-index: 10;
        ${media.down('md')} {
            background-size: 32px;
            width: 32px;
            height: 32px;
            top: 20px;
            right: 20px;
        }
        ${media.down('lg')} {
            background-image: url("${CloseIconWhite}");
        }
    }
`

export const WrapperGroupPopupContent = styled.div`
    position: relative;
    padding: 90px 220px 90px 620px;
    box-sizing: table;
    display: table;
    ${media.down('xl')} {
        padding-right: 50px;
        padding-left: 520px;
    }
    ${media.down('lg')} {
        width: 100%;
        padding: 0 0 90px 0;
    }
    ${media.down('md')} {
        padding: 0 0 30px 0;
    }
`

export const WrapperGroupPopupImage = styled.div`
    width: 500px;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-image: url("${Banner}");
    img{
        display: none;
    }
    ${media.down('xl')} {
        width: 400px;
    }
    ${media.down('lg')} {
        position: relative;
        display: block;
        min-height: auto;
        margin-bottom: 60px;
        width: 100%;
        max-height: 400px;
        overflow: hidden;
        img{
            width: 100%;
            display: block;
        }
    }
    ${media.down('md')} {
        margin-bottom: 40px;
    }
`

export const WrapperGroupPopupContentIn = styled.div`
    max-width: 692px;
    box-sizing: border-box;
    img{
        width: 200px;
        margin: 0 0 42px 0;
    }
    h6{
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0 0 30px 0;
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.02em;
            text-align: left;
            margin: 0 0 10px 0;
            strong{
                font-weight: 600;
            }    
        }
    }
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 50px 0 0 0;
    }
    a{
        display: none;
    }
    ${media.down('lg')} {
        padding: 0 50px;
    }
    ${media.down('md')} {
        padding: 0 20px;
        img{
            width: 160px;
            margin: 40px 0;
        }
        h6{
            font-size: 30px;
            line-height: 38px;
        }
        ul li{
            font-size: 16px;
            line-height: 24px;
            strong{
                width: 100%;
                display: block;
            }
        }
        p{
            font-size: 16px;
            line-height: 24px;
            margin: 40px 0;
        }
        a{
            display: block;
            background: #FF5A00;
            height: 45px;
            width: 100%;
            text-align: center;
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 45px;
            letter-spacing: 0em;
            cursor: pointer;
        }
    }
`
