import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerFaq = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  margin-bottom: 160px;
  h4{
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 75px 0; 
  }
  ${media.down('lg')} {
    margin-bottom: 60px;
    h4{
        font-size: 30px;
        line-height: 38px;
        margin-bottom: 40px;
    }
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperItems = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    padding-left: 103px;
    border-left: 6px solid ${(props) => props.theme.colors.primaryOrange};
    ${media.down('lg')} {
        padding-left: 0;
        border: none;
    }
`