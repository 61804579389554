import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container";
import { useTranslation } from "react-i18next";
import {CompanyCultureBreadcrumb} from "../../breadcrumbs/company-culture/company-culture";
import { HeadCompetences } from "../../ui/organisms/head-competences/head-competences";
import { ImgBackgroundFull } from "../../ui/atoms/banner-full/styles";
import Banner from '../../../assets/images/company-group/banner.png'
import { AboutMore } from "../../ui/molecules/about-more";
import { CompanyMission } from "../../ui/molecules/company-mission";
import { ListGroups } from "../../ui/organisms/list-groups/list-groups";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import generateLink from "../../../core/api/assets"
import getSlug from "../../../core/router/getSlug";

export const CompanyGroupPage: FunctionComponent = () => {
    const { t } = useTranslation()
    const [content, setContent] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [pageid, setPageId] = useState('');
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/companies/page/${pageid}`)
                .then(response => {
                    setCompanies(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    return <>
        <BiContainer >
            <CompanyCultureBreadcrumb title={content['title']} slug={slug}/>
            <HeadCompetences 
                title="Billennium Group"
            />
        </BiContainer>
        <ImgBackgroundFull BackgroundMobile={generateLink(content['header_-_banner'])} BackgroundDesktop={generateLink(content['header_-_banner'])} />
        <BiContainer >
            <CompanyMission 
                title={content['section_1_-_title']}
                content={content['section_1_-_content']}
            />
            <ListGroups
                title={content['section_2_-_title']}
                content={content['section_2_-_content']}
                items={companies}
            />
        </BiContainer>
        <AboutMore 
            title={content['next_page_-_title']}
            link={content['next_page_-_link']}
            link_text={content['next_page_-_link_text']}
        />
    </>
}
