import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperTechnology = styled.div`
  width: 100%;
  margin-bottom: 160px;
  h1{
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left; 
    margin: 30px 0 0 0;
  }
  p.top-content{
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    max-width: 692px;
    margin: 0 0 80px 0;
  }

  ${media.down('lg')} {
    margin-bottom: 60px;
    h1{
      font-size: 30px;
      line-height: 38px;
    }
    p.top-content{
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperTechnologyList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin-top: 120px;
  gap: 22px;

  ${media.down('md')} {
    gap: 11px;
    margin-top: 40px;
  }
`

export const SoloTechnology = styled.div`
  height: 110px;
  flex: 1 0 21%;
  max-width: 24%;
  margin-bottom: 16px;
  background: #EDEFF1;
  border-radius: 5px;
  .technology-text{
    box-sizing: border-box;
    display: table;
    width: calc(100% - 109px);
    padding: 41px 0 0 35px;
    float: left;
    p{
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      margin: 0;
    }
  }
  .technology-icon{
    box-sizing: border-box;
    display: table;
    width: 109px;
    float: left;
    height: 110px;
    padding-left: 17.5px;
    vertical-align: middle
    img{
      height: 50px;
      margin-top: 30px;
    }
  }

  ${media.down('xl')} {
    flex: 1 0 33%;
    max-width: calc(33% - 22px);
    .technology-icon{
      width: 60px;
    }
  }
  ${media.down('lg')} {
    flex: 1 0 50%;
    max-width: calc(50% - 22px);
    height: 53px;
    position: relative;
    .technology-text{
      padding: 12.5px 0 0 15px;
      p{
        font-size: 12px;
      }
    }
    .technology-icon{
      height: 53px;
      float: right;
      padding-right: 11px;
      position: absolute;
      right: 0;
      img{
        height: 30px;
        margin-top: 11.5px;
      }
    }
  }
`
