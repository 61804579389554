import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperContactForm = styled.div`
    max-width: 275px;
    margin: 20px auto 0 auto;
    .input-group{
        width: 100%;
        margin-bottom: 10px;
        input{
            width: calc(100%);
            padding: 0 15px;
            height: 40px;
        }
        span{
            width: 100%;
            display: block;
            font-size: 14px;
            color: red;
            line-height: 18px;
            text-align: left;
        }
        textarea{
            width: calc(100%);
            padding: 6px 15px;
            height: 117px;
        }
    }
    .contact-approved{
        width: 100%;
        margin-bottom: 10px;
        p{
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            a{
                text-decoration: none;
                color: ${(props) => props.theme.colors.primaryOrange};
            }
        }
    }
    ${media.up('lg')} {
        max-width: 386px;
        margin-top: 60px;
    }
`