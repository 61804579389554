import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperOurTeams = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  margin: 0;
  h4{
    font-size: 46px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 40px 0;
  }
  p{
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0 0 120px 0;
    max-width: 692px;
  }
  img{
    &.mobile{
      display: none;
    }
    marign: 0;
    width: 100%;
  }
  a{
    margin: -80px 0 80px 0;
  }
  div{
    .wrapper-arrow{
        margin: 5px 5px -5px 0;
    }
  }
  ${media.down('lg')} {
    margin: 0 0 60px 0;
    h4{
      font-size: 30px;
      line-height: 38px;
      margin: 0 0 20px 0;
    }
    p{
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 40px 0;
    }
    a{
      margin: -20px 0 60px 0;
    }
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
    img{
      display: none;
      &.mobile{
        display: block;
      }
      marign: 0;
      width: 100%;
    }
  }
`
