import React, {FunctionComponent} from "react";
import { Wrapper, Intro, SoloContent, ImageArticle } from "./styles";
import { IArticleContent } from "./interface";
import generateLink from "../../../../core/api/assets";
import HTMLReactParser from "html-react-parser";

export const ArticleContent: FunctionComponent<IArticleContent> = ({banner, intro, section_1_content, section_1_title, section_2_content, section_2_title, section_3_content, section_3_title}) => {    
    return <>
        <Wrapper>
            <Intro>
                <p>{HTMLReactParser(intro ? intro : '')}</p>
            </Intro>
            <SoloContent>
                <h3>{section_1_title}</h3>
                <p>{HTMLReactParser(section_1_content ? section_1_content : '')}</p>
            </SoloContent>
            <SoloContent>
                <h3>{section_2_title}</h3>
                <p>{HTMLReactParser(section_2_content ? section_2_content : '')}</p>
            </SoloContent>
            <ImageArticle>
                <img src={generateLink(banner)} />
            </ImageArticle>
            <SoloContent>
                <h3>{section_3_title}</h3>
                <p>{HTMLReactParser(section_3_content ? section_3_content : '')}</p>
            </SoloContent>
        </Wrapper>
    </>
}
