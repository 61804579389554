import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerAddresses = styled.div`
          overflow-x: hidden;
          white-space: pre-wrap;
          h2 {
            color: ${(props) => props.theme.colors.black};
          }
        a {
          text-decoration: unset;
        }
          ${media.up('md')} {
            margin-top: 160px;
            margin-bottom: 120px;

            h2 {
              margin-bottom: 60px;
            }
          }
          ${media.down("md")} {
            padding-left: 20px;
            padding-right: 20px;
          }
  
    `,
    WrapperOffices = styled.div`
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 20px 0px;
      ${media.up('md')} {
        flex-direction: row;
        gap: 20px 18px;
      }
      ${media.up('xl')} {
        gap: 60px 81px;
      }
    `,

    WrapperItemOffice = styled.div`
      width: 100%;
      max-width: 387px;

      p {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
      }

      .city-name {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;
        margin-top: 0;
      }

      .address-name {
        font-size: 16px;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 5px;
        max-width: 123px;
       
      }

      .see-map {
        font-size: 14px;
        line-height: 20px;
        margin-left: 38px;
        color: ${(props) => props.theme.colors.primaryOrange};
      }

      .phone-number {
      }

      .email-name {
      }

      .phone-number, .email-name {
        color: ${(props) => props.theme.colors.primaryOrange};
        font-size: 16px;
        line-height: 24px;
        margin: 10px 0;
      }

      ${media.up('md')} {
        .city-name {
          font-size: 34px;
          line-height: 42px;
          margin-bottom: 30px;
        }

        .address-name {
          font-size: 18px;
          line-height: 26px;
          max-width: 230px;
        }

        .see-map {
          font-size: 14px;
          line-height: 20px;
        }

        .phone-number, .email-name {
          font-size: 18px;
          line-height: 26px;
        }
      }
    `,
    IconeImg = styled.img`
      width: 24px;
      max-width: 24px;
      height: 24px;
      margin-right: 14px;
    `,
    TextWrapper = styled.div`
      display: flex;
      flex-direction: row;
      &.one-line {
        align-items: center;
      }
    `