import React, {FunctionComponent} from "react";
import { ContainerItems, WrapperSoloItem, WrapperItems } from "./styles";
import { IPercentValues } from "./interface";

export const PercentValuesCaseStudy: FunctionComponent<IPercentValues> = ({value_1, title_1, value_2, title_2, value_3, title_3}) => {
    return <>
        {(value_1 || title_1 || value_2 || title_2 || value_3 || title_3) &&<ContainerItems>
            <WrapperItems>
                <WrapperSoloItem>
                    <span>{value_1}</span>
                    <p>{title_1}</p>
                </WrapperSoloItem>
                <WrapperSoloItem>
                    <span>{value_2}</span>
                    <p>{title_2}</p>
                </WrapperSoloItem>
                <WrapperSoloItem>
                    <span>{value_3}</span>
                    <p>{title_3}</p>
                </WrapperSoloItem>
            </WrapperItems>
        </ContainerItems>}
    </>
}
