import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperInperlySteps = styled.div`
    display: table;
    box-sizing: border-box;
    width: 100%;
    margin: 0 0 100px 0;
    ${media.down('lg')} {
        margin-bottom: 20px;
    }
    ${media.down('md')} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const Title = styled.div`
    display: table;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 120px;
    h4{
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0;
        color: #000000;
    }
    ${media.down('lg')} {
        margin-bottom: 40px;
        h4{
            font-size: 30px;
            line-height: 38px;
        }
    }
`

export const Steps = styled.div`
    display: table;
    box-sizing: border-box;
    width: 100%;
`

export const SoloStep = styled.div`
    display: table;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 60px;
    ${media.down('lg')} {
        margin-bottom: 40px;
    }
`

export const Number = styled.div`
    display: table;
    box-sizing: border-box;
    width: 315px;
    margin-right: 190px;
    float: left;
    p{
        font-size: 100px;
        font-style: normal;
        font-weight: 600;
        line-height: 70px;
        letter-spacing: 0em;
        text-align: center;
        color: #4260D1;
        margin: 0;
    }
    ${media.down('xl')} {
        width: 100%;
        margin: 0 0 20px 0;
        p{
            text-align: left;
        }
    }
    ${media.down('lg')} {
        p{
            font-size: 60px;
            line-height: 60px;
        }
    }
`

export const Content = styled.div`
    display: table;
    box-sizing: border-box;
    max-width: 590px;
    margin: 0;
    float: left;
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0;
        color: #000000;
    }
    ${media.down('lg')} {
        p{
            font-size: 16px;
            line-height: 24px;
        }
    }
`