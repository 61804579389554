import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerAbout = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 254px;
  position: relative;
  ${media.down('lg')} {
    padding-bottom: 60px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const SoloGif = styled.div`
  max-width: 385px;
  display: none;
  box-sizing: border-box;
  margin: 0 224px 0 0;
  left: 0;
  top: 58px;
  img{
    width: 100%;
  }
  ${media.down('xl')} {
    display: table;
    max-width: 200px;
  }
  ${media.down('lg')} {
    max-width: 335px;
    display: block;
    position: static;
    margin: 20px auto;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; 
  float: right;
  max-width: 488px;
  h4{
    margin: 0 0 30px 0;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
  }
  p{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0 0 30px 0;
  }
  ${media.down('lg')} {
    max-width: 100%;
    h4{
      margin: 58px 0 0 0;
    }
  }
  ${media.down('md')} {
    h4{
      font-size: 30px;
      line-height: 38px;
    }
    p{
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }
`

export const ContentIn = styled.div`
  width: 100%;
`

export const Image = styled.div`
  max-width: 385px;
  display: table;
  box-sizing: border-box;
  margin: 0 224px 0 0;
  left: 0;
  top: 58px;
  img{
    width: 100%;
  }
  ${media.down('xl')} {
    display: none;
    max-width: 200px;
  }
  ${media.down('lg')} {
    max-width: 335px;
    position: static;
    margin: 20px auto;
  }
`