import React, {FunctionComponent} from "react";
import { WrapperActionArrow } from "./styles";
import {BIActionArrowProps} from "./interfaces";
export const ActionArrowAtomOut: FunctionComponent<BIActionArrowProps> = ({text,link,white, hover}) => {
    return <>
        <WrapperActionArrow white={white} hover={hover}>
            {link !== '' ? <a href={link} target="_blank">
                <div className="wrapper-arrow">
                    <div className="sliding-arrow"></div>
                </div>
               <span className="text-link">{text}</span>
            </a> :
            <a>
                <div className="wrapper-arrow">
                    <div className="sliding-arrow"></div>
                </div>
                <span className="text-link">{text}</span>
            </a>
            }
        </WrapperActionArrow>
    </>
}
