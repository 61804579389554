import styled from "styled-components";
import {media} from "../../atoms/theme";

export const Container = styled.div`
    width: 100%;
    margin-top: 80px;
    box-sizing: border-box;
    display: table;
    ${media.down('xl')} {
        margin-top: 86px;
    }
    ${media.down('md')} {
        margin-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const Half = styled.div`
    padding-bottom: 60px;
    border-top: 6px solid #FF5A00;
    border-right: 6px solid #FF5A00;
    width: 50%;
    box-sizing: border-box;
    display: table;
    position: relative;
    h1{
        font-size: 60px;
        font-style: normal;
        font-weight: 600;
        line-height: 80px;
        letter-spacing: -0.015em;
        text-align: left;
        margin: 0;
        position: absolute;
        top: -50px;
        background: #ffffff;
        padding-right: 39px;
    }
    p{
        margin: 60px 0 0 0;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 488px;
    }
    ${media.down('xl')} {
        width: 100%;
        border: none;
    }
    ${media.down('md')} {
        h1{
            font-size: 36px;
            line-height: 48px;
            position: static;
        }
        p{
            font-size: 18px;
            line-height: 26px;
            margin: 10px 0 0 0;
        }
    }
`