import React, {FunctionComponent} from "react";
import { Link, useHistory } from 'react-router-dom';
import {ArrowRightIcon} from '../../ui/atoms/arrow-right-icon'
import {WrapperBreadcrumb} from '../styles'
import { IBlog } from './interface'

export const BlogBreadcrumb: FunctionComponent<IBlog> = ({title, slug}) => {
    const history = useHistory();

    return <>
        <WrapperBreadcrumb>
            <Link to="/" onClick={() => { history.push('/');}}>Billennium</Link>
            <ArrowRightIcon />
            <Link to="/blog" onClick={() => { history.push('/blog');}}>Blog</Link>
            {slug && title &&<ArrowRightIcon />}
            {slug && title &&<Link to={`/blog/${slug}`} onClick={() => { history.push('/blog');}}>{title}</Link>}
        </WrapperBreadcrumb>
    </>
}
