import React, {FunctionComponent} from "react";
import { ContainerBusinessTeams, DescBusinessTeams, ImageBusinessTeams, DescBusinessTeamsIn } from "./styles";
import { ActionArrowAtom } from "../../atoms/action-arrow";
import { ICompanyHistory } from "./interface";
import ReactHtmlParser from 'react-html-parser';

export const CompanyHistory: FunctionComponent<ICompanyHistory> = ({title, content, banner, link, url}) => {
    return <>
        <ContainerBusinessTeams>
            <ImageBusinessTeams>
                <img alt="Banner" src={banner} />
            </ImageBusinessTeams>
            <DescBusinessTeams>
                <DescBusinessTeamsIn>
                    <h4>{title}</h4>
                    <p>{ReactHtmlParser(content)}</p>
                    {link &&<ActionArrowAtom link={url ? url : ""} text={link} />}
                </DescBusinessTeamsIn>
            </DescBusinessTeams>
        </ContainerBusinessTeams>
    </>
}
