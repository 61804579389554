import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {WrapperHeaderHome, ImageHeaderHome, BlackHeaderHome, OrangeBorderHeaderHome, OrangeBorderInHeaderHome} from './styles'
import arrow_down from "../../../../assets/icons/arrow-line-long-down.svg";
import anime_1 from "../../../../assets/images/home/anime_3.gif";
import { IHeaderHome } from "./interface";
import HTMLReactParser from "html-react-parser";
import { useGlobalLang } from '../../molecules/lang-header/useGlobalLang'

export const HeaderHome: FunctionComponent<IHeaderHome> = ({title, content, banner}) => {
    const line = useRef<HTMLInputElement>(null);
    const titlebox = useRef<HTMLInputElement>(null);
    const [lineWidth, setLineWidth] = useState('100px');
    const [lang, setLang] = useGlobalLang();

    useEffect(() => {
        if (line && line.current && titlebox && titlebox.current) 
        setLineWidth(`calc(100% - ${titlebox.current.offsetWidth}px)`)
    },[title])

    return<>
        <WrapperHeaderHome>
            <OrangeBorderHeaderHome>
                <OrangeBorderInHeaderHome>
                    <div className="white-title-back"></div>
                    <div className="orange-top-line" ref={line} style={{width: lineWidth}}></div>
                    <h1 className="orange-top-title" ref={titlebox} id="orange-title">{HTMLReactParser(title ? title : '')}</h1>
                </OrangeBorderInHeaderHome>
                <a href="#lower">
                    <div className="wrapper">
                        <div className="sliding-arrow"></div>
                    </div>
                    WHAT WE DO
                </a>
            </OrangeBorderHeaderHome>
            <ImageHeaderHome banner={banner}/>
            <BlackHeaderHome>
                <div className="content-black" id="lower">
                    <img alt="arrow down" src={arrow_down} />
                    <p>{content}</p>
                </div>
                <div className="gif-black">
                    <img alt="animation" src={anime_1} />
                </div>
            </BlackHeaderHome>
        </WrapperHeaderHome>
    </>
}
