import React, {FunctionComponent} from "react";
import { Container, IconQuotes, Person } from "./styles";
import { IQuotesBox } from "./interface";

export const QuotesBox: FunctionComponent<IQuotesBox> = ({icon, content, name, company}) => {
    return <>
        {(content || name || company) &&<Container>
            <IconQuotes>
                <img alt="Icon" src={icon} />
            </IconQuotes>
            <p>{content}</p>
            {name && company &&<Person>
                <p>{name}</p>
                <p>{company}</p>
            </Person>}
        </Container>}
    </>
}
