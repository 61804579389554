import styled from "styled-components";
import { media } from "../ui/atoms/theme";

export const WrapperBreadcrumb = styled.div`
  height: 20px;
  width: 100%;
  overflow: hidden;
  a{
      float: left;
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #000;
      transition: color 0.2s;
      &:hover{
        color: ${(props) => props.theme.colors.primaryOrange};
        transition: color 0.2s;
      }
  }

  div{
      float: left;
      box-sizing: border-box;
      display: table;
      margin: 2.5px 5px;
  }
  ${media.down('md')} {
    a{
      font-size: 11px;
    }
    div{
      margin: 2.5px 2px;
    }
    padding: 0 20px;
  }
`;