import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerTiger = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 120px;
  ${media.down('lg')} {
    margin-bottom: 60px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const Content = styled.div`
  display: table;
  box-sizing: border-box;
  float: left;
  max-width: 488px;
  h4{
    margin: 0 0 30px 0;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
  }
  p{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0 0 144px 0;
  }
  img{
    width: 385px;
  }
  ${media.down('xl')} {
    max-width: 100%;
    img{
      display: none;
    }
  }
  ${media.down('lg')} {
    h4{
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 20px;
    }
    p{
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 60px;
    }
  }
`

export const List = styled.div`
  display: table;
  box-sizing: border-box;
  float: right;
  width: 610px;
  margin: 56px 0 0 0;
  ${media.down('xl')} {
    width: 100%;
    margin: 0;
  }
`

export const SoloItem = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 20px 0;
  span{
    display: block;
    width: 80px;
    height: 80px;
    float: left;
    margin: 0 31px 0 0;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -0.015em;
    text-align: center;
    color: #FF5A00;
  }
  .content{
    width: calc(100% - 111px);
    float: left;
    display: table;
    box-sizing: border-box;
    p.content-title{
      font-size: 46px;
      font-style: normal;
      font-weight: 400;
      line-height: 80px;
      letter-spacing: -0.015em;
      text-align: left;
      width: 100%;
      margin: 0;
    }
    p.content-content{
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.02em;
      text-align: left;
      width: 100%;
      margin: 0;
      color: #596C79;
    }
  }
  ${media.down('lg')} {
    span{
      width: 26px;
      height: 26px;
      line-height: 26px;
      font-size: 36px;
    }
    .content{
      p.content-title{
        line-height: 26px;
        font-size: 36px;
        margin-bottom: 15px;
      }
      p.content-content{
        line-height: 20px;
        font-size: 14px;
      }
    }
  }
`