import styled from "styled-components";
import {media} from "../../atoms/theme";
import {WrapperBorder, WrapperHeadPowerApp} from "../head-power-app/styles";
import { ISoloHeader } from './interface'

export const WrapperBorderCompetences = styled(WrapperBorder)`
  ${media.up('lg')} {
    width: 100%;
    height: 0;
    margin-top: -2px;
  }
  max-height: 0;
  ${media.down('md')} {
    position: absolute;
    width: calc(100% - 40px);
    top: 17px;
  }
  margin-right: 0 !important;
`

export const WrapperHeadCompetences = styled(WrapperHeadPowerApp)<ISoloHeader>`
  position: relative;
  margin-bottom: ${(props) => props.margin}px;
  h3{
    font-size: 46px;
    line-height: 56px;
    font-weight: 600;
    position: relative;
    z-index: 10;
    display: inline-block;
    margin: 0;
    background: #fff;
    padding-right: 37px;
    ${media.down('md')} {
      background: transparent;
      padding: 0;
      span{
        display: block;
        width: auto;
        float: left;
        margin-right: 100%;
        background: #fff;
        padding-right: 10px;
      }
    }
  }
  ${media.down('md')} {
    margin-bottom: 40px;
    padding: 0 20px;
    display: table;
    box-sizing: border-box;
    width: 100%;
    h3{
      font-size: 30px;
      line-height: 38px;
    }
  }
`
