import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperContact = styled.div`
  display: table;
  box-sizing: border-box;
  border: 6px solid ${(props) => props.theme.colors.primaryOrange};
  width: 100%;
  padding: 60px 0;
  text-align: center;
  margin: 0;
  h6, p{
      margin: 0;
      padding: 0;
  }
  h6{
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.01em;
    text-align: center;    
    margin-bottom: 10px;
  }
  p{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: center; 
    margin: 0 auto 20px auto;
    max-width: 320px;
    
  }

  ${media.up('lg')} {
    padding: 100px 0;
    margin: 0 0 120px 0;
    h6{
        font-size: 34px;
        line-height: 42px;
        margin-bottom: 20px;
    }
    p{
        max-width: 100%;
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 40px;
    }
  }
`
