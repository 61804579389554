import React, {FunctionComponent} from "react";
import { ContainerItems, SoloItem } from "./styles";
import { INumberListPaperless } from "./interface";

export const NumberListPaperless: FunctionComponent<INumberListPaperless> = ({title, items}) => {
    return <>
        <ContainerItems>
            <h4>{title}</h4>
            {Object.keys(items).map((keyName, i) => (
                <SoloItem>
                    <p className="number">0{i + 1}</p>
                    <p className="title">{items[keyName].data['title']}</p>
                    <p className="content">{items[keyName].data['content']}</p>
                </SoloItem>
            ))}
        </ContainerItems>
    </>
}
