import React, {FunctionComponent} from "react";
import { WrapperJobOfferApply} from "./styles";
import { IJobContent } from "./interface";

export const JobOfferApply: FunctionComponent<IJobContent> = ({team, location, level, link}) => {
    return <>
        <WrapperJobOfferApply>
            <span>Team:</span>  
            <p>{team}</p>
            <span>Level:</span>
            <p>{level}</p>
            <span>Location:</span>
            <p>{location}</p>
            <a href={link}>Apply now</a>
        </WrapperJobOfferApply>
    </>
}
