import React, {FunctionComponent} from "react";
import { WrapperHeaderJobOffer } from "./styles";
import { IHeaderSoloJobOffer } from "./interface";

export const HeaderSoloJobOffer: FunctionComponent<IHeaderSoloJobOffer> = ({title}) => {
    return <>
        <WrapperHeaderJobOffer>
            <h1>{title}</h1>
        </WrapperHeaderJobOffer>
    </>
}
