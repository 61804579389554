import styled from "styled-components";
import {media} from "../../atoms/theme";

export const Wrapper = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  padding-bottom: 60px;
  margin-bottom: 80px;
  border-bottom: 6px solid #FF5A00;
  h4{
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 30px 0;
  }
  ${media.down('lg')} {
    margin-bottom: 40px;
    padding: 0 20px 40px 20px;
    h4{
      margin-bottom: 20px;
    }
  }
`

export const ListCategories = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 0 20px;
  align-items: stretch;
  ${media.down('lg')} {
    gap: 10px;
  }
`

export const SoloCategory = styled.div`
    flex: 1 0 calc(100% / 6 - 20px);
    max-width: calc(100% / 6);
    background: #EDEFF1;
    border-radius: 5px;
    text-align: center;
    padding: 40px 0 30px 0;
    min-height: 196px;
    a{
      text-decoration: none;
      color: #25282B; 
    }
    img{
        width: 64px;
        height: 64px;
        display: block;
        margin: 0 auto 20px auto;
    }
    p{
        max-width: 144px;
        display: inline-block;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: center;
        margin: 0;
    }
    ${media.down('lg')} {
      flex: 1 0 calc(100% / 2 - 10px);
      max-width: calc(100% / 2);
      min-height: auto;
      img{
        width: 33px;
        height: 33px;
        margin: 0 auto;
      }
      p{
        font-size: 18px;
        line-height: 26px;
      }
    }
`