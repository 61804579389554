import React, {FunctionComponent} from "react";
import { ContainerDevelopmentTeams, DescDevelopmentTeams, ImageDevelopmentTeams} from "./styles";
import {IDevelopmentTeams} from './intereface';
import ReactHtmlParser from 'react-html-parser';
import circle_one from '../../../../assets/images/soft-dev/circle_one.png'
import circle_two from '../../../../assets/images/soft-dev/circle_two.png'
import circle_three from '../../../../assets/images/soft-dev/circle_three.png'
import circle_four from '../../../../assets/images/soft-dev/circle_four.png'
import circle_five from '../../../../assets/images/soft-dev/circle_five.png'
import circle_six from '../../../../assets/images/soft-dev/circle_six.png'

export const DevelopmentTeamsCircle: FunctionComponent<IDevelopmentTeams> = ({title, content, circle}) => {
    return <>
        <div id="development-teams">
            <ContainerDevelopmentTeams>
                    <DescDevelopmentTeams>
                        <h2>{ReactHtmlParser(title)}</h2>
                        {circle  &&<ImageDevelopmentTeams>
                            {circle == 'circle_six' ?
                                <img alt="Circle" src={circle_six} />
                            : circle == 'circle_five' ?
                                <img alt="Circle" src={circle_five} />
                            : circle == 'circle_four' ?
                                <img alt="Circle" src={circle_four} />
                            : circle == 'circle_three' ?
                                <img alt="Circle" src={circle_three} />
                            : circle == 'circle_two' ?
                                <img alt="Circle" src={circle_two} />
                            :
                                <img alt="Circle" src={circle_one} />
                            }
                        </ImageDevelopmentTeams>}
                        <p>{ReactHtmlParser(content)}</p>
                    </DescDevelopmentTeams>
            </ContainerDevelopmentTeams>
        </div>
    </>
}
