import React, {FunctionComponent} from "react";
import {WrapperCaseContent} from './styles'
import { ICaseContent } from "./interface";
import ReactHtmlParser from 'react-html-parser';

export const CaseContent: FunctionComponent<ICaseContent> = ({title, content, list, bottom}) => {
    return <>
        {(title || content || list) &&<div>
            {bottom && bottom ? <WrapperCaseContent bottom={120}>
                {title &&<h4>{title}</h4>}
                {content &&<p>{ReactHtmlParser(content)}</p>}
                {list && list.length > 0 &&<ul>
                    {list.map(item => (
                        <li>{item}</li>
                    ))}
                </ul>}
            </WrapperCaseContent>:
            <WrapperCaseContent bottom={60}>
                {title &&<h4>{title}</h4>}
                {content &&<p>{ReactHtmlParser(content)}</p>}
                {list && list.length > 0 &&<ul>
                    {list.map(item => (
                        <li>{item}</li>
                    ))}
                </ul>}
            </WrapperCaseContent>}
        </div>}
    </>
}
