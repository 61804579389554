import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperLangHeader = styled.div`
    display: none;
    float: left;
    height: 40px;
    margin: 20px 0 20px 0;
    cursor: pointer;
    position: relative;
    padding: 0 15px;
    background: #EDEFF1;
    border-radius: 5px;
    p{
        text-transform: uppercase; 
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;
        float: left;
        margin: 0;
    }
    div{
        float: left;
    }
    .dropdown-list{
      position: absolute;
      z-index: 10;
      top: -70px;
      box-sizing: border-box;
      display: table;
      padding: 10px 13px;
      background: #FFFFFF;
      box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);
      ul{
        list-style: none;
        padding: 0;
        margin: 0;
      }
        a{
          text-transform: uppercase;
          box-sizing: border-box;
          display: table;
          min-width: 100%;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          margin-bottom: 4px;
          &:hover{
            color: ${(props) => props.theme.colors.primaryOrange};
          }
        }
    }

  ${media.up('nav')} {
    display: table;
    margin-left: 45px;
    padding: 0;
    background: #ffffff;
    border-radius: 5px;
    .dropdown-list{
      top: 40px;
    }
  }
`
