import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperInperlyBanner = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 103px 0;
  img{
      width: 100%;
  }
  ${media.down('lg')} {
    margin-bottom: 35px;
  }
`