import styled from "styled-components";
import {media} from "../../atoms/theme";

export const Wrapper = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  padding-bottom: 61px;
  margin: 60px 0 59px 0;
  border-bottom: 6px solid #FF5A00;
  ${media.down('lg')} {
    padding: 0 20px 41px 20px;
    margin: 10px 0 40px 0;
  }
`

export const Title = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  margin-bottom: 59px;
  h2{
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 20px 0;
  }
  h1{
    margin: 0;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -0.015em;
    text-align: left;
  }
  ${media.down('lg')} {
    margin-bottom: 20px;
    h2{
      display: none;
    }
    h1{
      font-size: 36px;
      line-height: 48px;
    }
  }
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${media.down('lg')} {
    display: table;
  }
`

export const Info = styled.div`
  display: table;
  box-sizing: border-box;
  p{
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 26px 0 0;
    float: left;

    &.author{
      font-weight: 600;
    }
  }
  ${media.down('lg')} {
    p{
      width: 100%;
      font-size: 18px;
      line-height: 26px;
      margin: 0 0 10px 0;
    }
  }
`

export const Socials = styled.div`
  display: table;
  box-sizing: border-box;
  p{
    float: left;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 14.5px 0 0;
  }
  a{
    display: block;
    width: 24px;
    height: 24px;
    float: left;
    margin: 4px 5.5px;
    cursor: pointer;
  }
  ${media.down('lg')} {
    margin-top: 10px;
    p{
      font-size: 18px;
      line-height: 24px;
    }
    a{
      margin: 0 5.5px;
    }
  }
`