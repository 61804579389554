import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container";
import { useTranslation } from "react-i18next";
import {JobOfferBreadcrumb} from "../../breadcrumbs/job-offer/job-offer";
import { HeaderSoloJobOffer } from "../../ui/molecules/header-solo-job-offer/header-solo-job-offer";
import { JobOfferContent } from "../../ui/organisms/job-offer-content/job-offer-content";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import getSlug from "../../../core/router/getSlug";
import generateLink from '../../../core/api/assets';

export const JobOfferPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [recruiter, setRecruiter] = useState([]);
    const [technologies, setTechnologies] = useState([]);
    const [team, setTeam] = useState('');
    const [locationJob, setLocation] = useState('');
    const [level, setLevel] = useState('');
    const [pageid, setPageId] = useState('');
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/job-offer-solo/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(content['recruiter'] !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/recruiter-by-id/${content['recruiter']}`)
                .then(response => {
                    setRecruiter(response.data.data ? response.data.data : []);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/technologies/job/${pageid}`)
                .then(response => {
                    setTechnologies(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                })
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/job-offer-solo-item/job_teams/${content['team']}`)
                .then(response => {
                    setTeam(response.data.data.title)
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/job-offer-solo-item/city/${content['location']}`)
                .then(response => {
                    setLocation(response.data.data.title)
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/job-offer-solo-item/levels/${content['level']}`)
                .then(response => {
                    setLevel(response.data.data.title)
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    return <>
        <BiContainer >
            <JobOfferBreadcrumb />
            <HeaderSoloJobOffer
                title={content['title']}
            />
            <JobOfferContent 
                content={content['content']}
                what_will_happen_next={content['what_will_happen_next']}
                your_role={content['your_role']}
                your_skills_and_experience={content['your_skills_and_experience']}
                our_offer={content['our_offer']}
                name={recruiter['title'] ? recruiter['title'] : ''}
                position={recruiter['position'] ? recruiter['position'] : ''}
                avatar={recruiter['avatar'] ? generateLink(recruiter['avatar']) : ''}
                phone={recruiter['phone'] ? recruiter['phone'] : ''}
                email={recruiter['e-mail'] ? recruiter['e-mail'] : ''}
                technologies={technologies}
                team={team}
                location={locationJob}
                level={level}
                link={content['link_to_apply']}
            />
            <ContactPersonPopup
                title={content['contact_popup_title']}
                place={content['contact_popup_position']}
                content={content['contact_popup_content']}
                name={content['contact_popup_person_name']}
                image={generateLink(content['contact_popup_avatar'])}
                send_to={content['contact_send_to']}
                pardot_link={content['pardot_link']}
            />
        </BiContainer>
    </>
}
