export const values = [
    {
        'id': 1,
        'value': '1000',
        'title': 'IT profesionals',
    },
    {
        'id': 2,
        'value': '50+',
        'title': 'technologies',
    },
    {
        'id': 3,
        'value': '200%',
        'title': 'something',
    },
    {
        'id': 4,
        'value': '3',
        'title': 'continents',
    },
    {
        'id': 5,
        'value': '10',
        'title': 'offices',
    },
    {
        'id': 6,
        'value': '629',
        'title': 'projects',
    }
]

export const checks = [
    {
        'id': 1,
        'title': 'Variety of projects',
        'content': 'Informacja, że  w Billennnium robisz super projekty i że są one przeznaczone dla różnych branż. At Billennium, we work on solutions for clients who are changing the way we live through technology.'
    },
    {
        'id': 2,
        'title': 'You can change your position',
        'content': 'O tym, że w Billennium można zmienić swoje stanowisko, zacząć robić coś innego. At Billennium, we work on solutions for clients who are changing the way we live through technology.',
    },
    {
        'id': 3,
        'title': 'International enviroment',
        'content': 'O tym, że to międzynarodowa firma. At Billennium, we work on solutions for clients who are changing the way we live through technology. We provide them with technology consulting, help them with digital transformation.',
    },
    {
        'id': 4,
        'title': 'People are valid',
        'content': 'O tym, że Billennium tworzą ludzie, że są fajne więzi między nimi, że się lubią. At Billennium, we work on solutions for clients who are changing the way we live through technology.',
    }
]

export const jobs = [
    {
        'id': 1,
        'title': 'Junior New Business Specialist',
        'team': 'Management & Scrum Master',
        'location': 'Warsaw, Poland or Remotely',
        'level': 'Regular',
    },
    {
        'id': 2,
        'title': '.Net Developer',
        'team': 'Management & Scrum Master',
        'location': 'Warsaw, Poland or Remotely',
        'level': 'Regular',
    },
    {
        'id': 3,
        'title': 'Specjalista ds. wdrożeń',
        'team': 'Management & Scrum Master',
        'location': 'Warsaw, Poland or Remotely',
        'level': 'Regular',
    }
]