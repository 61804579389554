import React, {FunctionComponent} from "react";
import {WrapperPartnerShip, WrapperPartnerShipList, SoloPartnerShip} from "./styles";
import { IPartnership } from "./interface";
import generateLink from "../../../../core/api/assets";

export const PartnerShip: FunctionComponent<IPartnership> = ({title, content, items}) => {

    return <>
        <WrapperPartnerShip>
            <h2>{title}</h2>
            <p className="top-content">{content}</p>
            <WrapperPartnerShipList>
                {Object.keys(items).map((keyName, i) => (
                    <SoloPartnerShip>
                        {!items[keyName].data['link'] ?<img alt="partner1" src={generateLink(items[keyName].data['image'])} />:
                        <a href={items[keyName].data['link']} target="_blank">
                            <img alt="partner1" src={generateLink(items[keyName].data['image'])} />
                        </a>}
                    </SoloPartnerShip>
                ))}
            </WrapperPartnerShipList>
        </WrapperPartnerShip>
    </>
}
