import React, {FunctionComponent} from "react";
import {WrapperModels, DescModels, WrapperItems, WrapperSoloItem} from './styles'
import { IModels } from "./interface";

export const Models: FunctionComponent<IModels> = ({title, content, items}) => {
    return <>
        <WrapperModels>
            <DescModels>
                <h4>{title}</h4>
                <p>{content}</p>
            </DescModels>
            <WrapperItems>
                {Object.keys(items).map((keyName, i) => (
                    <WrapperSoloItem>
                        <div className="icon-solo"></div>
                        <div className="content">
                            <p className="title">{items[keyName].data['title']}</p>
                            <p className="incontent">{items[keyName].data['content']}</p>
                        </div>
                    </WrapperSoloItem>
                ))}
            </WrapperItems>
        </WrapperModels>
    </>
}
