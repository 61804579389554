import styled from "styled-components";
import {media} from "../../atoms/theme";
import like from '../../../../assets/icons/like.svg'

export const ContainerContentProjects = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  margin-bottom: 120px;
  ${media.down('lg')} {
    margin-bottom: 60px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const DescContentProjects = styled.div`
    max-width: 793px;
    display: table;
    box-sizing: border-box;
    float: left;
    h2{
        font-size: 46px;
        line-height: 56px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 40px;
    }
    p{
        font-size: 18px;
        line-height: 26px;
        margin: 0 0 20px 0;
    }
    ${media.down('xl')} {
        max-width: 500px;
    }
    ${media.down('lg')} {
        max-width: 100%;
        margin-bottom: 60px;
        h2{
            font-size: 30px;
            line-height: 38px;
            margin-bottom: 20px;
        }
        p{
            font-size: 16px;
            line-height: 24px;
        }
    }
`

export const ImageContentProjects = styled.div`
    float: right;
    width: auto;
    display: table;
    box-sizing: border-box;
    position: relative;
    max-width: 283px;
    width: 100%;
    img{
        width: 100%;
    }
    ${media.down('lg')} {
        margin: 60px auto 0 auto;
        float: none;
        max-width: 100%;
        img{
            width: 100%;
        }
    }
`