import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import { ContainerItems, WrapperSoloItem, WrapperItems } from "./styles";
import { ITechnologiesCase } from "./interface";
import { useLocation } from 'react-router-dom';
import getSlug from '../../../../core/router/getSlug';
import { useTranslation } from 'react-i18next';
import { useGlobalLang } from '../lang-header/useGlobalLang';
import generateLink from '../../../../core/api/assets';

export const TechnologiesCase: FunctionComponent<ITechnologiesCase> = ({items, title}) => {
    const { t } = useTranslation()
    const [technologies, setTechnologies] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection-technologies`)
        .then(response => {
            setTechnologies(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    const getDataTechnology = (id, type) => {
        const indexEl = Object.keys(technologies).find((keyName) => technologies[keyName].id == id)
        if(indexEl) return technologies[indexEl].data[type]
    }

    return <>
        <ContainerItems>
            <h4>{title}</h4>
            {items && items.length > 0 &&<WrapperItems>
                {items.map(item => (
                    <WrapperSoloItem>
                        {getDataTechnology(item, 'image') &&<img alt={getDataTechnology(item, 'title')} src={generateLink(getDataTechnology(item, 'image'))} />}
                        <p>{getDataTechnology(item, 'title')}</p>
                    </WrapperSoloItem>
                ))}
            </WrapperItems>}
        </ContainerItems>
    </>
}
