import React, {FunctionComponent} from "react";
import {WrapperContact, WrapperContactIn, ContactLeft, ContactRight, WrapperPersonPopup} from "./styles";
import {IContactPerson} from "./interface";
import { ContactForm } from "../../molecules/contact-form";
import { useTranslation } from "react-i18next";
import { isOpenModalContact } from "../../atoms/button-header/button-header";

export const ContactPersonPopup: FunctionComponent<IContactPerson> = ({title, content, place, name, image, send_to, pardot_link}) => {
    const { t } = useTranslation();
    const [isOpenModal, setOpenModal] = isOpenModalContact();

    return <>
        <div>
            {isOpenModal &&<WrapperPersonPopup>
                <div className="close-icon" onClick={() => setOpenModal(false)}></div>
                <WrapperContact>
                    <h4>{title}</h4>
                    <WrapperContactIn>
                        <ContactLeft>
                            <div className="person-info">
                                <div className="person-image">
                                    <img src={image} />
                                </div>
                                <div className="person-content">
                                    <span>{place}</span>
                                    <p>{name}</p>
                                </div>
                            </div>
                            <p className="under-person">{content}</p>
                        </ContactLeft>
                        <ContactRight>
                            <ContactForm />
                        </ContactRight>
                    </WrapperContactIn>
                </WrapperContact>
            </WrapperPersonPopup>}
        </div>
    </>
}
