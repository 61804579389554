import axios from 'axios';
import React, {FunctionComponent, useEffect, useState} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow";
import {SeeMoreWrapper} from "../news-about-partnership/styles";
import {ContainerCaseStudies, ItemStudies, ItemStudiesDescription, ItemStudiesImg, WrapperItemStudies } from "./styles";
import { ICaseStudies } from "./interface";
import { Link, useLocation, useHistory } from "react-router-dom";
import generateLink from "../../../../core/api/assets";
import { useGlobalLang } from "../../molecules/lang-header/useGlobalLang";
import { useTranslation } from "react-i18next";

export const CaseStudies: FunctionComponent<ICaseStudies> = ({title, items}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [categories, setCategories] = useState([]);
    const [pages, setPages] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection-case_study_category`)
        .then(response => {
            setCategories(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection-pages`)
        .then(response => {
            setPages(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [])

    const getDataCategory = (id, type) => {
        const indexEl = Object.keys(categories).find((keyName) => categories[keyName].id == id)
        if(indexEl) return categories[indexEl].data[type]
    }

    const getDataPage = (id, type) => {
        const indexEl = Object.keys(pages).find((keyName) => pages[keyName].id == id)
        if(indexEl) return (type !== 'slug') ? pages[indexEl].data[type] :pages[indexEl].slug
    }

    return <>
        {items && items.length > 0 &&<ContainerCaseStudies>
            {title &&<h2>{title}</h2>}
            <WrapperItemStudies>
                {Object.keys(items).map((keyName, i) => (
                    <ItemStudies>
                        <ItemStudiesImg image={generateLink(items[keyName].data['thumb'])}>
                            <span>
                                <img src={generateLink(items[keyName].data['category_small_logo'])} />
                            </span>
                        </ItemStudiesImg>
                        <ItemStudiesDescription>
                            <p className="title-division">{getDataCategory(items[keyName].data['category'], 'title')}</p>
                            <h3 className="title-studies">{items[keyName].data['title']}</h3>
                            <p className="description">{items[keyName].data['content'].substring(0, 130)} ...</p>
                            <div className="two-section">
                                {items[keyName].data['competencies'] && items[keyName].data['competencies'].length > 0 &&<div className="section">
                                    <p className="label-section">Competencies: </p>
                                    <div className="list-elements">
                                        {items[keyName].data['competencies'].map(item => (
                                            <Link to={getDataPage(item, 'slug')}>{getDataPage(item, 'title')}</Link>
                                        ))}
                                    </div>
                                </div>}
                                {items[keyName].data['technology'] && items[keyName].data['technology'].length > 0 &&<div className="section">
                                    <p className="label-section">Technology: </p>
                                    <div className="list-elements">
                                        {items[keyName].data['technology'].map(item => (
                                            <Link to={getDataPage(item, 'slug')}>{getDataPage(item, 'title')}</Link>
                                        ))}
                                    </div>
                                </div>}
                            </div>
                            <SeeMoreWrapper>
                                <ActionArrowAtom link={`/case-study/${items[keyName].slug}`} text={t("see_more")}/>
                            </SeeMoreWrapper>
                        </ItemStudiesDescription>
                    </ItemStudies>
                ))}
            </WrapperItemStudies>
        </ContainerCaseStudies>}
    </>
}
