import styled from "styled-components";
import {media} from "../../atoms/theme";

export const HeaderWrapper = styled.div`
    width: 100%;
    height: 100px;
    padding: 10px 30px 20px 30px;
    position: fixed;
    z-index: 20;
    background: #ffffff;
    top: 0;
    ${media.down('md')} {
        padding: 10px 20px 20px;
    }
`

export const HeaderWrapperIn = styled.div`
    width: 100%;
    padding: 0;
    margin: auto;
    ${media.down('md')} {
        max-width: 370px;
    }
    ${media.down('lg')} {
        max-width: 540px;
    }
`

export const HeaderWrapperRight = styled.div`
    float: right;
    height: 40px;
    display: none;

    div{
        float: left;
    }
    
    ${media.up('lg')} {
        display: table;   
    }
`
