import React, {FunctionComponent, useState, useEffect} from "react";
import ReactDOM from "react-dom";
import { ContainerRecruters, RecrutersContent, RecruterImage, RecruterContent, ContactOn, List} from "./styles";
import banner from '../../../../assets/images/faq/recruter.png'
import rec_min_1 from '../../../../assets/images/faq/rec_min_1.png'
import rec_min_2 from '../../../../assets/images/faq/rec_min_2.png'
import rec_min_3 from '../../../../assets/images/faq/rec_min_3.png'
import { IRecruter } from "./interface";
import generateLink from "../../../../core/api/assets";

export const Recruters: FunctionComponent<IRecruter> = ({title, items}) => {
    const [avatar, setAvatar] = useState('');
    const [name, setName] = useState('');
    const [position, setPosition] = useState('');
    const [content, setContent] = useState('');
    const [link, setLink] = useState('');

    useEffect(() => {
        if(items.length > 0){
            setAvatar(items[0].data['avatar'])
            setPosition(items[0].data['position'])
            setName(items[0].data['title'])
            setContent(items[0].data['content'])
            setLink(items[0].data['linkedin_-_link'])

        }
    }, [items])

    const changeRecruiter = (e, i) => {
        setAvatar(items[i].data['avatar'])
        setPosition(items[i].data['position'])
        setName(items[i].data['title'])
        setContent(items[i].data['content'])
        setLink(items[i].data['linkedin_-_link'])
        const avatars = document.getElementsByClassName('avatar-box');
        for(var j=0; j < avatars.length; j++)
            avatars[j].className = 'avatar-box';
        e.target.className += ' active';
    }

    return <>
        <ContainerRecruters>
            <h4>{title}</h4>
            <RecrutersContent>
                <RecruterImage>
                    <img alt="banner" src={generateLink(avatar)} />
                </RecruterImage>
                <RecruterContent>
                    <span>{position}</span>
                    <h5>{name}</h5>
                    <p>“{content}“</p>
                    <ContactOn>
                        <span>Have a question?</span>
                        <a href={link} target="_blank">Connect with me on</a>
                    </ContactOn>
                    <List>
                    {Object.keys(items).map((keyName, i) => (
                        <img key={i} alt="rec_min_1" src={items[keyName].data['small_avatar'] ? generateLink(items[keyName].data['small_avatar']) : rec_min_2} className={i === 0 ? 'avatar-box active' : 'avatar-box'} onClick={(e) => changeRecruiter(e, i)}/>
                    ))}
                    </List>
                </RecruterContent>
            </RecrutersContent>
        </ContainerRecruters>
    </>
}
