import styled from "styled-components";
import {media} from "../../atoms/theme";

export const Container = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    position: relative;
    margin-top: 90px;
    ${media.down('xl')} {
        margin-bottom: 60px;
    }
    ${media.down('md')} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const Content = styled.div`
    width: 700px;
    display: table;
    box-sizing: border-box;
    position: absolute;
    background: #ffffff;
    z-index: 10;
    padding: 0;
    h1{
        font-size: 60px;
        font-style: normal;
        font-weight: 600;
        line-height: 80px;
        letter-spacing: -0.015em;
        text-align: left;
        marign: 0 0 40px 0;
        max-width: 648px;
        margin-block-start: -40px;
    }
    p{
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0 0 80px 0;
        max-width: 590px;
    }
    h2{
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0;
        max-width: 488px;
    }
    ${media.down('xl')} {
        width: 100%;
        position: static;
        h2{
            display: none;
        }
    }
    ${media.down('md')} {
        h1{
            font-size: 36px;
            line-height: 48px;
        }
        p{
            font-size: 18px;
            line-height: 26px;
            margin: 0 0 60px 0;
        }
    }
`

export const BorderBox = styled.div`
    width: 58%;
    display: table;
    box-sizing: border-box;
    border: 6px solid ${(props) => props.theme.colors.primaryOrange};
    border-left: none;
    height: 519px;
    position: relative;
    float: right;
    img{
        height: 274px;
        display: block;
        position: absolute;
        right: 160px;
        top: 122px;
    }
    ${media.down('xl')} {
        width: 100%;
        position: static;
        border: none;
        height: auto;
        img{
            position: static;
        }
    }
    ${media.down('md')} {
        text-align: center;
        img{
            height: auto;
            width: 100%;
            display: inline-block;
        }
    }
`