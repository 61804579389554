import React, {FunctionComponent} from "react";
import { WrapperJobOfferPerson, Title, OrangeBox, Name, Contact } from "./styles";
import person from '../../../../assets/images/job-offer/person.png'
import { IOfferPerson } from "./interface";

export const JobOfferPerson: FunctionComponent<IOfferPerson> = ({title, name, position, avatar, phone, email}) => {
    return <>
        <WrapperJobOfferPerson>
            {title &&<Title>
                <p>{title}</p>
            </Title>}
            <OrangeBox>
                <div className="thumb">
                    <img alt={name ? name : ''} src={avatar ? avatar : ''} />
                </div>
                <div className="content">
                    <Name>
                        <p>{name ? name : ''}</p>
                        <span>{position ? position : ''}</span>
                    </Name>
                    <Contact>
                        <p>{phone ? phone : ''}</p>
                        <p>{email ? email : ''}</p>
                    </Contact>
                </div>
            </OrangeBox>
        </WrapperJobOfferPerson>
    </>
}
