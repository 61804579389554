import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerTiger = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 150px;
  ${media.down('lg')} {
    padding-bottom: 60px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const Content = styled.div`
  display: table;
  box-sizing: border-box;
  float: left;
  max-width: 488px;
  h4{
    margin: 58px 0 30px 0;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
  }
  p{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0 0 30px 0;
  }
  ${media.down('xl')} {
    max-width: 100%;
    margin-bottom: 60px;
  }
  ${media.down('md')} {
    h4{
      font-size: 30px;
      line-height: 38px;
      margin: 0 0 20px 0;
    }
    p{
      margin: 0 0 20px 0;
      font-size: 16px;
      line-height: 24px;
    }
  }
`

export const List = styled.div`
  display: table;
  box-sizing: border-box;
  float: right;
  margin: 0 0 0 122px;
  ${media.down('xl')} {
    float: none;
    width: 100%;
    margin: 60px 0 0 0;
  }
`

export const SoloItem = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100%;
  min-width: 488px;
  height: 80px;
  margin: 0 0 10px 0;
  p{
    width: 80px;
    height: 80px;
    float: left;
    margin: 0 31px 0 0;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -0.015em;
    text-align: center;
    color: #FF5A00;
  }
  span{
    height: 80px;
    float: left;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -0.015em;
    text-align: left;
    margin: 0;
  }
  ${media.down('xl')} {
    p{
      text-align: left;
      width: 50px;
    }
  }
  ${media.down('md')} {
    height: 45px;
    margin: 0 0 5px 0;
    min-width: 100%;
    p{
      font-size: 34px;
      line-height: 45px;
      width: 20px;
      height: 45px;
      text-align: center;
    }
    span{
      height: 45px;
      line-height: 45px;
      font-size: 34px;
    }
  }
`