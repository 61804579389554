import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import {HeadProductsServices} from '../../ui/organisms/head-products-services/head-products-services';
import BiContainer from "../../ui/atoms/container";
import { useTranslation } from "react-i18next";
import {ProductsServicesBreadcrumb} from "../../breadcrumbs/products-services/products-services";
import imgBackgroundHome from "../../../assets/images/baners/products-services-baner.png";
import {ImgBackgroundFull} from "../../ui/atoms/banner-full/styles";
import ImgBackgroundHomeMobile from "../../../assets/images/baners/products-services-baner-mobile.png";
import {ProductsPs} from "../../ui/organisms/products-ps/products-ps";
import {ServicesPs} from "../../ui/organisms/services-ps/services-ps";
import { PopupContact } from "../../ui/molecules/popup-contact";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import generateLink from "../../../core/api/assets"
import getSlug from "../../../core/router/getSlug";

export const ProductsServices: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [products, setProducts] = useState([]);
    const [services, setServices] = useState([]);
    const [pageid, setPageId] = useState('');
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/products/page/${pageid}`)
                .then(response => {
                    setProducts(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/services/page/${pageid}`)
                .then(response => {
                    setServices(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    return <>
        <BiContainer>
            <ProductsServicesBreadcrumb />
            <HeadProductsServices
                title={content['header_-_title']}
            />
        </BiContainer>
        <ImgBackgroundFull BackgroundMobile={generateLink(content['header_-_banner'])} BackgroundDesktop={generateLink(content['header_-_banner'])} />
        <BiContainer >
            <ProductsPs 
                title={content['section_1_-_title']}
                content={content['section_1_-_content']}
                products={products}
            />
            <ServicesPs 
                title={content['section_2_-_title']}
                content={content['section_2_-_content']}
                services={services}
            />
            <PopupContact
                content={`<p>${content['contact_title']}</p>`}
                text_link="Contact us"
            />
            <ContactPersonPopup
                title={content['contact_popup_title']}
                place={content['contact_popup_position']}
                content={content['contact_popup_content']}
                name={content['contact_popup_person_name']}
                image={generateLink(content['contact_popup_avatar'])}
                send_to={content['contact_send_to']}
                pardot_link={content['pardot_link']}
            />
        </BiContainer>
    </>
}
