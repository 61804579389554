import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container";
import { useTranslation } from "react-i18next";
import {CompanyCultureBreadcrumb} from "../../breadcrumbs/company-culture/company-culture";
import { CompanyBoardTitle } from "../../ui/molecules/company-board-title/company-board-title";
import { persons } from "./data";
import { BoardList } from "../../ui/molecules/board-list";
import { PopupContact } from "../../ui/molecules/popup-contact";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import { useLocation } from 'react-router-dom';
import generateLink from '../../../core/api/assets';
import getSlug from "../../../core/router/getSlug";

export const CompanyBoardPage: FunctionComponent = () => {
    const { t } = useTranslation()
    const [content, setContent] = useState([]);
    const [people, setPeople] = useState([]);
    const [pageid, setPageId] = useState('');
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/people/page/${pageid}`)
                .then(response => {
                    setPeople(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    return <>
        <BiContainer >
            <CompanyCultureBreadcrumb title={content['title']} slug={slug}/>
            <CompanyBoardTitle 
                title={content['header_-_title']}
            />
            <BoardList
                items={people}
            />
            <PopupContact
                content={`<p>${content['contact_title']}</p>`}
                text_link="Contact us"
            />
            <ContactPersonPopup
                title={content['contact_popup_title']}
                place={content['contact_popup_position']}
                content={content['contact_popup_content']}
                name={content['contact_popup_person_name']}
                image={generateLink(content['contact_popup_avatar'])}
                send_to={content['contact_send_to']}
                pardot_link={content['pardot_link']}
            />
        </BiContainer>
    </>
}
