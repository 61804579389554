import React, {FunctionComponent} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow/action-arrow-atom";
import { WrapperIndustriesDesc, WrapperIndustriesHead, WrapperIndustriesContent } from "./styles";
import { IIndustriesDesc } from "./interface";
import HTMLReactParser from "html-react-parser";

export const IndustriesDesc: FunctionComponent<IIndustriesDesc> = ({title, content}) => {
    return <>
        <WrapperIndustriesDesc>
            <WrapperIndustriesHead>
                <h4>{title}</h4>
            </WrapperIndustriesHead>
            <WrapperIndustriesContent>
                <p>{HTMLReactParser((content) ? content : '')}</p>
            </WrapperIndustriesContent>
        </WrapperIndustriesDesc>
    </>
}
