import React, {FunctionComponent} from "react";
import {LogoSaleforce, WrapperBorderSaleforce, WrapperHeadSaleforce} from "./styles";
import { IHeadSaleforce } from "./interface";
import ReactHtmlParser from 'react-html-parser';

export const HeadSaleforce: FunctionComponent<IHeadSaleforce> = ({title, content, image}) => {
    return <>
        <WrapperHeadSaleforce>
            <div>
                <h1>
                    {title}
                </h1>
                <p>{ReactHtmlParser(content)}</p>
            </div>
            <WrapperBorderSaleforce>
                <LogoSaleforce image={image}/>
            </WrapperBorderSaleforce>
        </WrapperHeadSaleforce>
    </>
}
