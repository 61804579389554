import styled from "styled-components";
import {media} from "../../atoms/theme";
import arrow from '../../../../assets/icons/arrow-nav.svg'

export const BodyLink = styled.div`
    display: table;
    box-sizing: border-box;
    marign: 20px 0 0 0;
`

export const Row = styled.div`
    display: table;
    box-sizing: border-box;
    margin-right: 60px;
    min-width: 180px;
    float: left;
    .solo-list-nav{
        margin-bottom: 20px;
    }
    p{
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #7E8E9B;
        margin: 0 0 6px 0;
    }
    ${media.down('lg')} {
        min-width: 100%;
    }
    ${media.down('nav')} {
        p{
            font-weight: 600;
            color: #FF5A00;
        }
        .solo-list-nav{
            a{
                position: relative;
                padding-left: 18px !important;
                &:before{
                    content: '';
                    width: 8px;
                    height: 16px;
                    display: inline-block;
                    background-image: url("${arrow}");
                    background-size: 8px;
                    position: absolute;
                    left: 0;
                    top: 5px;
                }
            }
        }
    }
`