import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerContentProjects = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const DescContentProjects = styled.div`
    max-width: 488px;
    display: table;
    box-sizing: border-box;
    float: left;
    h2{
        font-size: 46px;
        line-height: 56px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 40px;
    }
    p{
        font-size: 18px;
        line-height: 26px;
        margin: 0 0 20px 0;
    }
    ${media.down('xl')} {
        max-width: 300px;
    }
    ${media.down('lg')} {
        max-width: 100%; 
    }
    ${media.down('md')} {
        h2{
            font-size: 30px;
            line-height: 38px;
            margin-bottom: 20px;
        }
        p{
            font-size: 16px;
            line-height: 24px;
        }
    }
`

export const ImageContentProjects = styled.div`
    float: right;
    width: auto;
    display: table;
    box-sizing: border-box;
    position: relative;
    ${media.down('xl')} {
        width: 400px;
        img{
            width: 100%;
        }
    }
    ${media.down('lg')} {
        width: 100%; 
        margin-bottom: 60px;
    }
`