import React, {FunctionComponent} from "react";
import { WrapperInperlyBanner } from "./styles";
import { IInperlyBanner } from "./interface";

export const InperlyBanner: FunctionComponent<IInperlyBanner> = ({image}) => {
    return <>
        <WrapperInperlyBanner>
            <img src={image} />
        </WrapperInperlyBanner>
    </>
}
