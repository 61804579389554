import React, {FunctionComponent} from "react";
import { ContainerAbout, SoloGif, Content, Image } from "./styles";
import anime_1 from "../../../../assets/images/home/anime_2.gif";
import { ICompanyMission } from "./interface";
import ReactHtmlParser from 'react-html-parser';

export const CompanyMission: FunctionComponent<ICompanyMission> = ({title, content}) => {
    return <>
        <ContainerAbout>
            <Content>
                <h4>{title}</h4>
                <SoloGif>
                    <img alt="Animation" src={anime_1} />
                </SoloGif>
                <p>{ReactHtmlParser(content)}</p>
            </Content>
            <Image>
                <img alt="Animation" src={anime_1} />
            </Image>
        </ContainerAbout>
    </>
}
