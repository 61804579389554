import styled from "styled-components";
import {media} from "../../atoms/theme";
import Arrow from '../../../../assets/icons/arrow-back.svg'

export const ContainerItems = styled.div`
  display: table;
  box-sizing: border-box;
  margin: 160px 0 0 0;
  width: 100%;
  h4{
    font-size: 46px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 80px 0;
  }
  ${media.down('lg')} {
    margin-top: 60px;
    h4{
      font-size: 30px;
      line-height: 38px;
      margin: 0 0 40px 0;
    }
  }
  ${media.down('md')} {
    h4{
      margin: 0 20px 40px 20px;
    }
  }
`

export const WrapperItems = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 20px;
  ${media.down('lg')} {
    display: none;
  }
`

export const WrapperSoloItem = styled.div`
  flex: 1 0 calc(100% / 3);
  max-width: calc((100% / 3) - 20px);
  display: table;
  box-sizing: border-box;
  border: 7px solid ${(props) => props.theme.colors.primaryOrange};
  padding: 70px 0 80px 0;
  img{
      width: 128px;
      display: block;
      margin: 0 auto 31px auto;
  }
  p{
      font-size: 34px;
      font-weight: 400;
      line-height: 42px;
      letter-spacing: 0.02em;
      text-align: center;
      margin: 0 0 30px 0;
  }
  a{
    display: block;
    width: 148px;
    background: ${(props) => props.theme.colors.primaryOrange};
    font-size: 18px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: 0.02em;
    height: 60px;
    color: #ffffff;
    border-radius: 5px;
    text-decoration: none;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
    &:hover{
      background: #000000;
      transition: background 0.2s;
    }
  }
`

export const FormWrapper = styled.div`
  display: none;
  ${media.down('lg')} {
    display: table;
    width: 100%;
    box-sizing: border-box;
  }
`

export const WrapperContact = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
`

export const WrapperContactIn = styled.div`
    display: table;
    box-sizing: border-box;
    border: 6px solid ${(props) => props.theme.colors.primaryOrange};
    width: 100%;
    padding: 60px 0;

    ${media.up('lg')} {
        padding: 100px 0;
    }

`

export const ContactLeft = styled.div`
    float: left;
    display: table;
    box-sizing: border-box;
    margin-left: 100px;
    max-width: 443px;
    .person-info{
      width: 100%;
      display: table;
      box-sizing: border-box;
      .person-image{
        width: 95px;
        height: 95px;
        border-radius: 95px;
        overflow: hidden;
        float: left;
        margin-right: 30px;
        img{
          height: 95px;
        }
      }
      .person-content{
        width: calc(100% - 125px);
        padding-top: 17.5px;
        float: left;
        span{
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0.02em;
          text-align: left;
          margin: 0;
          padding: 0;
        }
        p{
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 34px;
          letter-spacing: 0em;
          text-align: left;
          margin: 0;
          padding: 0;
        }
      }
    }
    .under-person{
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      margin: 40px 0 0 0;
    }
  }
  ${media.down('xl')} {
    max-width: calc(100% - 200px);
  }
  ${media.down('lg')} {
    max-width: calc(100% - 120px);
    margin-left: 60px;
  }
  ${media.down('md')} {
    .person-info{
      .person-image{
        width: 100%;
        img{
          width: 95px;
        }
      }
      .person-content{
        width: 100%;
        float: none;
      }
    }
  }
`

export const BackArrow = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  margin: 0 0 40px 0;
  cursor: pointer;
  p{
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0;
    &:before{
      content: "";
      width: 32px;
      height: 32px;
      display: inline-block;
      background-image: url("${Arrow}");
      background-size: 32px;
      margin: 5px 15px -5px 0;
    }
  }
`

export const ContactRight = styled.div`
    width: 386px;
    float: right;
    display: table;
    box-sizing: border-box;
    margin-right: 100px;
    div{
      margin-top: 0;
    }
    ${media.down('xl')} {
      width: calc(100% - 200px);
      margin-top: 60px;
    }
    ${media.down('lg')} {
      width: calc(100% - 120px);
      margin-right: 60px;
      margin-top: 60px;
    }
`