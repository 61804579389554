import styled from "styled-components";
import {media} from "../../atoms/theme";
import FacebookImage from "../../../../assets/icons/facebook.svg";
import LinkdinImage from "../../../../assets/icons/linkedin.svg";

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 30px 20px 0 20px;
  border-top: 1px solid #EDEFF1;
  ul {
    display: none;
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }

  p {
    font-size: 18px;
  }

  li {
    margin: 2.5px 0px;
  }

  a {
    color: inherit;
    text-decoration: inherit;

    &:hover {
      color: ${(props) => props.theme.colors.primaryOrange};

    }
  }


  ${media.up('md')} {
    padding: 60px 0 0 0;
    flex-direction: row;

    ul {
      display: inherit;
    }

    li {
      line-height: 20px;
      margin: 5px 0px;
    }

    p {
      font-size: 14px;
    }
  }

`

export const FooterTitle = styled.p`
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  margin: 0;
  padding: 10px 0px;

  ${media.up('lg')} {
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 0;
    font-size: 14px;
  }
`
export const FooterSubTitle = styled.p`
  display: none;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #7E8E9B;

  ${media.up('md')} {
    display: block;
    margin: 10px 0;
  }
`
export const FooterWrapperRow = styled.div`

  ${media.up('sm')} {
    margin-right: 16px;
  }

  ${media.up('lg')} {
    margin-right: 130px;
    .second-row {
    padding-top: 40px;
    }
  }
`

export const FooterLogo = styled.div`
  margin: 10.5px 0;
`

export const FooterSocialMedia = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.borderColorMain};
  padding: 45px 20px 55px;
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  ${media.up('lg')} {
    margin-top: 75px;
    padding: 40px 0 55px;
  }
    
`



export const IconFacebook = styled.div`
  background-image: url("${FacebookImage}");
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  background-size: 32px;
  margin: 0 7.5px;
`
export const IconLinkdin = styled(IconFacebook)`
  background-image: url("${LinkdinImage}");
`
