import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../../ui/atoms/container/BiContainer";
import { BlogBreadcrumb } from "../../../breadcrumbs/blog/blog";
import { useTranslation } from "react-i18next";
import { useGlobalLang } from '../../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import { ContactPersonPopup } from '../../../ui/organisms/contact-person-popup/contact-person-popup';
import { Newsletter } from '../../../ui/molecules/newsletter/newsletter';
import { BlogList } from '../../../ui/molecules/blog-list';
import { HeaderCaseStudies } from '../../../ui/molecules/header-case-studies/header-case-studies';
import generateLink from "../../../../core/api/assets"
import getSlug from "../../../../core/router/getSlug";

export const Category: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [articles, setArticles] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/blog-category/${slug}`)
        .then(response => {
            setArticles(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    return <>
        <BiContainer>
            <BlogBreadcrumb
                title={`${slug[0].toUpperCase()}${slug.slice(1).replace('-',' ')}`}
                slug={`category/${slug}`}
            />
            <HeaderCaseStudies
                title={`${slug[0].toUpperCase()}${slug.slice(1).replace('-',' ')}`}
            />
            <BlogList
                items={articles}
            />
        </BiContainer>
        <Newsletter 
            title="Subscribe our Newsletter"
            content="Adipiscing diam aliquam non leo lacus amet congue facilisi donec sit commodo magna ac eu"
        />
        <ContactPersonPopup
            title={content['contact_popup_title']}
            place={content['contact_popup_position']}
            content={content['contact_popup_content']}
            name={content['contact_popup_person_name']}
            image={generateLink(content['contact_popup_avatar'])}
            send_to={content['contact_send_to']}
            pardot_link={content['pardot_link']}
        />
    </>
}
