import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerHeadPaperless = styled.div`
  margin-top: 90px;
  display: table;
  box-sizing: border-box;
  border-top: 6px solid #31A877;
  border-right: 6px solid #31A877;
  position: relative;
  width: 100%;
  padding-bottom: 130px;
  margin-bottom: -31px;
  z-index: 2;
  ${media.down('nav')} {
    width: calc(100% - 66px);
  }
  ${media.down('lg')} {
    border: none;
    margin-top: 10px;
    width: 100%;
  } 
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`
