import styled from "styled-components";
import {media} from "../../atoms/theme";

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: table;
  margin: 120px 0;
  h4{
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0;
  }
  ${media.down('md')} {
    margin: 60px 0;
    h4{
        font-size: 24px;
        line-height: 30px;
    }
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperItems = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: table;
`

export const WrapperSoloItem = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: table;
    margin: 60px 0 0 0;
    ${media.down('md')} {
        margin: 40px 0 0 0;
    }
`

export const Image = styled.div`
  width: 427px;
  box-sizing: border-box;
  display: table;
  float: left;
  margin: 0 80px 0 0;
  img{
      width: 100%;
  }
  ${media.down('lg')} {
    width: 100%;
  }
`
export const Content = styled.div`
  width: calc(100% - 507px);
  box-sizing: border-box;
  display: table;
  float: left;
  ${media.down('lg')} {
    width: 100%;
  }
`

export const Title = styled.div`
  padding-top: 50px;
  box-sizing: border-box;
  display: table;
  width: 100%;
  p{
    color: #596C79;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0 0 10px 0;
  }
  h5{
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0;
    a{
      text-decoration: none;
      color: #000000;
    }
  }
  ${media.down('lg')} {
    padding-top: 20px;
  }
  ${media.down('md')} {
    p{
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 5px 0;
    }
    h5{
        font-size: 24px;
        line-height: 30px;
    }
  }
`

export const Info = styled.div`
    box-sizing: border-box;
    display: table;
    width: 100%;
    margin: 10px 0;
    p{
        float: left;
        margin: 0;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
    }
    span{
        float: left;
        display: block;
        background: #000000;
        width: 1px;
        height: 14px;
        margin: 6px 20px;
    }
    ${media.down('md')} {
        margin: 5px 0;
        p{
          font-size: 16px;
          line-height: 24px;  
        }
        span{
            margin: 6px 10px;
        }
    }
`

export const ContentIn = styled.div`
    box-sizing: border-box;
    display: table;
    width: 100%;
    p{
        max-width: 632px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0;
    }
    ${media.down('md')} {
        p{
          font-size: 16px;
          line-height: 24px;  
        }
    }
`