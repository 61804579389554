import styled from "styled-components";
import {media} from "../../atoms/theme";
import arrow from '../../../../assets/icons/big-down-arrow.svg'

export const SoloItem = styled.div`
    width: 100%;
    padding: 5px 39px 22px 0;
    margin: 0 0 15px 0;
    border-bottom: 2px solid #D1D8DB;
    position: relative;
    cursor: pointer;
    transition: border-bottom 0.4s;
    &:after{
        content: '';
        width: 24px;
        height: 24px;
        display: inline-block;
        background-image: url("${arrow}");
        background-size: 24px;
        position: absolute;
        right: 0;
        top: 5px;
    }
    span{
        font-family: Billennium;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;
        transition: color 0.4s, border-bottom 0.4s;
    }
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 18px 0 0 0;
    }
    &:hover{
        border-bottom: 2px solid ${(props) => props.theme.colors.primaryOrange};
        transition: border-bottom 0.4s;
        span{
            color: ${(props) => props.theme.colors.primaryOrange};
            transition: color 0.4s;
        }
    }
    ${media.down('lg')} {
        span{
            font-size: 18px;
            line-height: 26px;
        }
    }
`