import styled from "styled-components";
import InstagramImage from "../../../../assets/icons/icon-instagram.png";

export const IconInstagram = styled.div`
  background-image: url("${InstagramImage}");
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  background-size: 32px;
  margin: 0 7.5px;
`
