import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import {BackgroundSaleforceImage} from "../../ui/molecules/background-saleforce-image";
import {HeadSaleforce} from "../../ui/organisms/head-saleforce/head-saleforce";
import {CompetenceSaleforce} from "../../ui/organisms/competence-saleforce/competence-saleforce";
import {CertificateIconsSaleforce} from "../../ui/organisms/certificate-icons-saleforce/certificate-icons-saleforce";
import {HowCanHelp} from "../../ui/organisms/how-can-help/how-can-help";
import Contact from "../../ui/organisms/contact";
import {CaseStudies} from "../../ui/organisms/case-studies/case-studies";
import BiContainer from "../../ui/atoms/container/BiContainer";
import { TechnologyBreadcrumb } from "../../breadcrumbs/technology/technology";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import generateLink from "../../../core/api/assets"
import getSlug from "../../../core/router/getSlug";

export const TechnologyPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [competencies, setCompetencies] = useState([]);
    const [helps, setHelps] = useState([]);
    const [certifications, setCertifications] = useState([]);
    const [caseStudy, setCaseStudy] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/competencies/page/${pageid}`)
                .then(response => {
                    setCompetencies(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/certifications/page/${pageid}`)
                .then(response => {
                    setCertifications(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/helps/page/${pageid}`)
                .then(response => {
                    setHelps(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/case_study/page/${pageid}`)
                .then(response => {
                    setCaseStudy(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    return <>
        <BiContainer>
        <TechnologyBreadcrumb />
        <HeadSaleforce
            title="Amazon"
            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras iaculis augue neque, ac egestas ante elementum et. Nulla non arcu sagittis, condimentum magna facilisis, condimentum enim."
            image=""
        />
        </BiContainer>
        <BackgroundSaleforceImage/>
        <BiContainer>
        <CompetenceSaleforce 
            title={content['section_1_-_title']}
            content={content['section_1_-_content']}
            title_2={content['section_2_-_title']}
            content_2={content['section_2_-_content']}
            image={generateLink(content['section_2_-_image'])}
            items={competencies}
        />
        <CertificateIconsSaleforce items={certifications}/>
        <HowCanHelp 
            title={content['section_3_-_title']}
            content={content['section_3_-_content']}
            items={helps}
        />
        <CaseStudies 
            title="Case studies including Saleforce"
            items={caseStudy}
        />
        <Contact
            title="Skontaktuj się z nami i umów spotkanie"
            content="Chętnie porozmawiamy o twoich potrzebach biznesowych"
            button_text="Kontakt"
        />
        </BiContainer>

    </>
}
