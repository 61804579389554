import React, {FunctionComponent} from "react";
import { WrapperJobOfferList } from "./styles";
import { IJobOfferList } from "./interface";

export const JobOfferList: FunctionComponent<IJobOfferList> = ({title, items}) => {
    return <>
        <WrapperJobOfferList>
            <p>{title}</p>
            {items && items.length > 0 &&<ul>
                {items.map(item => (
                    <li>{item}</li>
                ))}
            </ul>}
        </WrapperJobOfferList>
    </>
}
