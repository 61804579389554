import styled from "styled-components";
import {media} from "../../ui/atoms/theme";
import IconArrowDown from "../../../assets/icons/arrow-down.svg";
import IconArrowUP from "../../../assets/icons/arrow-up.svg";

export const Wrapper = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  ${media.down("md")} {
    padding: 0 20px;
  }
`

export const WrapperContentDesc = styled.div`
  width: 100%;

  p {
    font-size: 14px;
  }

  ${media.up("lg")} {
    width: 66%;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;

    ul {
      padding-left: 20px;
    }
  }
`
export const ContainerPrivacyPolicy = styled.div`
  margin-bottom: 60px;

  ${media.up("lg")} {
    display: flex;
    flex-direction: row;
    margin-bottom: 120px;
    margin-top: 60px;
  }
`

export const WrapperMenu = styled.div`
  display: none;

  ${media.up("lg")} {
    display: block;
    width: 23%;
    position: relative;

    ul {
      list-style: none;
      padding-left: 0;
      margin: 0;

      a {
        font-size: 18px;
        line-height: 26px;
        color: black;
        display: block;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        margin-bottom: 20px;
      }

      .is-current {
        text-decoration: underline;
      }
    }

    .fixed {
      position: sticky;
      top: 113px;
    }
  }
`

export const ItemArea = styled.div`


  details > summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColorMain};
    margin-bottom: 14px;
    padding-bottom: 14px;
  }


  summary {

    &::-webkit-details-marker {
      display: none
    }

    &:after {
      content: url(${IconArrowDown});
    }
  }

  details[open] summary {
    border-bottom: unset;

    h3 {
      color: ${(props) => props.theme.colors.primaryOrange};
   
    }

    &:after {
      content: url(${IconArrowUP});
    }
  }

  h3 {
    margin: 16px 0;
    font-weight: 400;
    padding-right: 38px;
  }

  ${media.up('lg')} {
    width: 100%;
    &:first-child {
      padding-top: 0;
    }
    padding-top: 100px;

    h3 {
      margin: 0px 0 30px 0;
      padding-right: 0;
    }

    details > summary {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    details[open] summary {
      h3 {
        color: black;
      }

      &:after {
        content: "";
      }
    }

    details {
      pointer-events: none;
      summary {
        border-bottom: unset;

        &::marker, &:after { {
          content: "";
        }
        }
      }
    }
  }
`