import React, {FunctionComponent} from "react";
import { Link, useHistory } from 'react-router-dom';
import {ArrowRightIcon} from '../../ui/atoms/arrow-right-icon'
import {WrapperBreadcrumb} from '../styles'

export const JobOffersBreadcrumb: FunctionComponent = () => {
    const history = useHistory();

    return <>
        <WrapperBreadcrumb>
            <Link to="/" onClick={() => { history.push('/');}}>Billennium</Link>
            <ArrowRightIcon />
            <Link to="/career" onClick={() => { history.push('/career');}}>Career</Link>
            <ArrowRightIcon />
            <Link to="/job-offers" onClick={() => { history.push('/job-offers');}}>Job Offers</Link>
        </WrapperBreadcrumb>
    </>
}
