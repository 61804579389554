import styled from "styled-components";
import {media} from "../theme";
import {IBackgroundImage} from "../banner-full/interface";

export const ImgBackgroundInside = styled.div<IBackgroundImage>`
  background-image: url(${(props) => props.BackgroundMobile});
  background-repeat: no-repeat;
  width: 100%;
  height: 250px;
  background-size: cover;
  margin: 60px 0;

  ${media.up('md')} {

    background-image: url(${(props) => props.BackgroundDesktop});
    height: 488px;
    margin: 160px 0 80px 0;
  }
`
