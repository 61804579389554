import React, {FunctionComponent} from "react";

import {ActionArrowAtom} from "../../atoms/action-arrow";
import {BackgroundIndustry, IndustryItem, WrapperIndustry, WrapperIndustryDesc} from "./styles";
import {industriesItem} from "./industriesItem";
import { IList } from "./interface";
import generateLink from "../../../../core/api/assets";

export const IndustriesList: FunctionComponent<IList> = ({items}) => {

    return <>

        <WrapperIndustry>
            {items.map(item => (
                <IndustryItem key={item.id}>
                    <BackgroundIndustry IndustryBackground={generateLink(item.data['banner'])} IndustryBackgroundMobile={generateLink(item.data['banner'])}/>
                    <WrapperIndustryDesc>
                        <div className="wrapper-name-item">
                            <ActionArrowAtom link={`/industries/${item.slug}`} text={item.data['title']} white={true}/>
                        </div>

                        <p className="description-industry">{item.data['intro']}</p>
                    </WrapperIndustryDesc>
                </IndustryItem>
            ))}
        </WrapperIndustry>
    </>

}
