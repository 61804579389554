import axios from 'axios';
import React, {FunctionComponent, useEffect, useState, useRef} from "react";
import {WrapperLinksHeader} from './styles';
import { Link, useHistory } from 'react-router-dom';
import useOutsideClick from '../../organisms/header/useOutsideClick';
import { useTranslation } from "react-i18next";
import { useGlobalLang } from '../lang-header/useGlobalLang';
import { SoloLink } from '../../atoms/solo-link';
import { links } from './data'
import { getApiRoutesIndustries } from '../../../../core/router/routes'

export const LinksHeader: FunctionComponent = () => {
    const history = useHistory();
    const [isNavItemOpen, setOpenNavItem] = useState(false);
    const [industries, setIndustries] = useState({});
    const dropdownRef = useRef<HTMLHeadingElement>(null);
    const { t } = useTranslation();
    const [lang, setLang] = useGlobalLang();
    let routes = links;

    useOutsideClick(dropdownRef, () => {
      if(isNavItemOpen !== false){
        setOpenNavItem(prev => !prev)
      }
    });

    useEffect(() => {
      const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
      axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection-industries`)
      .then(response => {
          let pages = [
            {
              id: 1,
                row: [
                    {
                      id: 1,
                      links: []
                    },
                ]
            }
          ];
          let links = {};
          Object.keys(response.data).map((keyName, i) => (
              links = {
                ...links,
                [response.data[keyName].id]: {
                  id: response.data[keyName].id,
                  title: response.data[keyName].data['title'],
                  slug: '/industries/' + response.data[keyName].slug,
                }
              }
          ))
          pages[0].row[0].links = Object.values(links)
          routes[1].children = Object.values(pages)
      }) 
      .catch(err => {
          console.log(err);
      })
      axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection-case_study_category`)
      .then(response => {
          let pages = [
            {
              id: 1,
                row: [
                    {
                      id: 1,
                      links: []
                    },
                ]
            }
          ];
          let links = {};
          Object.keys(response.data).map((keyName, i) => (
              links = {
                ...links,
                [response.data[keyName].id]: {
                  id: response.data[keyName].id,
                  title: response.data[keyName].data['title'],
                  slug: '/case-studies-category/' + response.data[keyName].slug,
                }
              }
          ))
          pages[0].row[0].links = Object.values(links)
          routes[3].children = Object.values(pages)
      }) 
      .catch(err => {
          console.log(err);
      })
    }, [lang])

    return <>
        <WrapperLinksHeader>
        {routes.map(link => (
            <ul key={link.id}>
              {link.children && link.children.length > 0 ?
                <SoloLink 
                  title={link.title}
                  under_title={link.under_title}
                  slug={link.slug}
                  items={link.children}
                />                
              :<li>
                <Link to={link.slug}>{link.title}</Link>
              </li>}
            </ul>
        ))}
        </WrapperLinksHeader>
    </>
}
