import styled from "styled-components";
import {media} from "../theme";

export const WrapperBorder = styled.div`
  width: 100%;
  border-top: 6px solid ${(props) => props.theme.colors.primaryOrange};
  border-right: 6px solid ${(props) => props.theme.colors.primaryOrange};

  ${media.up('lg')} {
    width: 50%;
    height: 235px;
    position: absolute;
    top: 30px;
    right: 0;
  }

  ${media.up('xl')} {
    width: 65%;
  }
`
