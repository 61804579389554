import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperLogoInperly = styled.div`
  position: absolute;
  right: 210px;
  top: 103px;
  img{
    width: 171px;
  }
  ${media.down('xl')} {
    right: 60px;
  }
  ${media.down('lg')} {
    position: static;
    right: auto;
    width: auto;
    margin-top: 40px;
    img{
      widht: 142px;
    }
  }
`
