import styled from "styled-components";
import {media} from "../../atoms/theme";

export const Container = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    border: 6px solid #D1D8DB;
    padding: 120px 200px;
    text-align: center;
    position: relative;
    margin-bottom: 120px;
    p{
        max-width: 793px;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: center;
        color: #25282B;
        display: inline-block;
        margin: 0;
    }
    ${media.down('lg')} {
        padding: 40px 27px;
        p{
            font-size: 16px;
            line-height: 24px;
        }
    }
`

export const IconQuotes = styled.div`
    width: 160px;
    height: 85px;
    background: #fff;
    position: absolute;
    top: -42.5px;
    left: calc(50% - 80px);
    img{
        height: 85px;
        width: 85px;
        display: block;
        margin: 0 auto;
    }
    ${media.down('lg')} {
        width: 140px;
        height: 80px;
        top: -40px;
        left: calc(50% - 70px);
        img{
            height: 80px;
            width: 80px;
        }
    }
`

export const Person = styled.div`
    width: 100%;
    margin: 40px 0 0 0;
    p{
        display: block;
        max-width: 100%;
        margin: 0;
        color: #25282B;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: center;
    }
    ${media.down('md')} {
        margin-top: 10px;
        p{
            font-size: 16px;
            line-height: 24px;
        }
    }
`