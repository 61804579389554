import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperContentCountry = styled.div`
          display: flex;
          flex-direction: column;
          margin-top: 44px;
          margin-bottom: 44px;
          overflow-x: hidden;
          white-space: pre-wrap;
  
          ${media.up("lg")} {
            margin-top: 60px;
            margin-bottom: 120px;
            flex-direction: row;
            justify-content: space-between;
          }
          ${media.down("md")} {
            padding-left: 20px;
            padding-right: 20px;
          }
    `,
    WrapperTitle = styled.div`
      h2 {
        margin: 0;
      }

      ${media.up("xl")} {


        max-width: 341px;
      }

    `,
    WrapperDescription = styled.div`
      margin-top: 20px;

      p {
        margin: 0;
      }

      ${media.up("md")} {
        max-width: 742px;
        padding-right: 50px;
      }

      ${media.up("xl")} {
        max-width: 800px;
        padding-right: 100px;
      }
    `
;