import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperJobOffers = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: table;
    padding-bottom: 120px;
    ${media.down('md')} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const JobOffersTop = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 0 20px;
    padding-bottom: 60px;
    border-bottom: 6px solid #FF5A00;
    margin-bottom: 70px;
    select{
        flex: 1 0 33%;
        max-width: calc((100% / 3) - 20px);
        border: 1px solid #B3BEC4;
        height: 60px;
        padding: 0 20px;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: left;
        color: #495963;
    }
    ${media.down('lg')} {
        gap: 10px 0;
        select{
            flex: 1 0 100%;
            max-width: 100%;
        }
    }
    ${media.down('md')} {
        display: table;
        box-sizing: border-box;
        select{
            width: 100%;
            margin-bottom: 20px;
        }
    }
`

export const WrapperItems = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    margin-bottom: 40px;
    .paginationBttns{
        width: 100%;
        text-align: center;
        margin: 120px 0 160px 0;
        li{
          display: inline-block;
          padding: 6px;
          border-radius: 3px;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0.02em;
          text-align: center;
          color: #667C89;
          min-width: 35px;
          margin: 0 5px;
          cursor: pointer;
        }
        .activeBttn{
          background: #FF5A00;
          color: #fff;
        }
        .paginationDisabled{
          display: none;
        }
      }
`

export const SoloItem = styled.div`
    background: #EDEFF1;
    border-radius: 5px;
    display: table;
    box-sizing: border-box;
    padding: 60px;
    margin: 0 0 20px 0;
    width: 100%;
    p{
        max-width: 347px;
        min-width: 347px;
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;
        float: left;
        margin: 0 101px 0 0;
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        max-width: 370px;
        float: left;
        li{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.02em;
            text-align: left;
            max-width: 370px;
            margin: 0 0 10px 0;
            strong{
                color: #94A3AD;
            }
        }
    }
    a{
        float: right;
        display: block;
        padding: 0 26px;
        background: ${(props) => props.theme.colors.primaryOrange};
        font-size: 18px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: 0.02em;
        height: 60px;
        color: #ffffff;
        border-radius: 5px;
        text-decoration: none;
        margin: 19px 0 0 0;
    }
    ${media.down('xl')} {
        p{
            max-width: 100%;
            min-width: 100%;
            margin: 0 0 20px 0;
        }
        ul{
            max-width: 100%;
            min-width: 100%;
        }
        a{
            float: none;
            display: inline-block;
        }
    }
    ${media.down('md')} {
        padding: 20px;
        p{
            font-size: 24px;
            line-height: 30px;
        }
        ul{
            li{
                font-size: 16px;
                line-height: 24px;
            }
        }
        a{
            height: 45px;
            line-height: 45px;
            font-size: 16px;
        }
    }
`