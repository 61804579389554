import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperJobOfferLogos = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100%;
  position: relative;
`

export const List = styled.div`
  max-width: 692px;
  display: table;
  box-sizing: border-box;
  img{
    margin: 0 20px 0 0;
    height: 34px;
    float: left;
  }
  ${media.down('lg')} {
    img{
      margin: 0 20px 17px 0;
    }
  }
`

export const Content = styled.div`
  width: 100%;
  padding: 60px 0;
  display: table;
  box-sizing: border-box;
  p{
    max-width: 692px;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }
  ${media.down('md')} {
    padding: 47px 0 0 0;
    p{
      font-size: 18px;
      line-height: 26px;
    }
  }
`