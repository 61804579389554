import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperJobOffer = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: table;
    padding-bottom: 120px;
    padding-top: 60px;
    ${media.down('md')} {
        padding-right: 20px;
        padding-left: 20px;
    }
`

export const LeftContent = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: table;
    float: left;
`

export const Mobile = styled.div`
    display: none;
    ${media.down('lg')} {
        display: table;
        width: 100%;
    }
`