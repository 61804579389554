import React, {FunctionComponent} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow";
import {useBreakpoints} from "../../atoms/container/hooks";
import { ContainerTeamsInRegion, ItemTeam, WrapperItemsTeam } from "./styles";
import {ITeamsInRegion} from "./interface";


export const TeamsInRegion: FunctionComponent<ITeamsInRegion> = ({teamsInRegion, title}) => {
    const {isXLarge, isXXLarge} = useBreakpoints();
    return <ContainerTeamsInRegion>

        <h2>{title}</h2>
        <WrapperItemsTeam>
            {Object.keys(teamsInRegion).map((keyName, i) => (
                <ItemTeam>
                    <p className="city-name">{teamsInRegion[keyName].data['city']}</p>
                    <p className="name-team">{teamsInRegion[keyName].data['title']}</p>
                    <div className="action-wrapper">
                        <ActionArrowAtom 
                            link={""}
                            text={isXLarge || isXXLarge ? `More about this team` : `More about team`}
                        />
                        <a href={""} className="action">
                            {isXLarge || isXXLarge ? `See job offers` : `Job offers`}
                        </a>
                    </div>
                </ItemTeam>
            ))}
        </WrapperItemsTeam>
    </ContainerTeamsInRegion>
}