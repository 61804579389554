import React, {FunctionComponent} from "react";
import { Container, EmptySpace, SoloItem, SoloItemRight, Number, Title, SoloItemLeft, SoloItemLeftLast } from "./styles";
import HTMLReactParser from "html-react-parser";
import { ISteps } from "./interface";

export const RecSteps: FunctionComponent<ISteps> = ({
    title_1,
    undertitle_1,
    content_1,
    list_1,
    image_1,
    title_2,
    undertitle_2,
    content_2,
    list_2,
    image_2,
    title_3,
    undertitle_3,
    content_3,
    list_3,
    image_3,
    title_4,
    undertitle_4,
    content_4,
    list_4,
    image_4
}) => {
    return <>
        <Container>
            <EmptySpace />
            <SoloItem>
                <SoloItemRight>
                    <Number>
                        <p>1</p>
                    </Number>
                    <Title>
                        <h5>{title_1}</h5>
                        <p>{undertitle_1}</p>
                    </Title>
                </SoloItemRight>
                <SoloItemLeft>
                    <img alt={title_1} src={image_1} />
                    <p>{HTMLReactParser(content_1 ? content_1 : '')}</p>
                    {list_1 && list_1.length > 0 &&<ul>
                        {list_1.map(item => (
                            <li>{item}</li>
                        ))}
                    </ul>}
                </SoloItemLeft>
            </SoloItem>
            <SoloItem>
                <SoloItemRight>
                    <Number>
                        <p>2</p>
                    </Number>
                    <Title>
                        <h5>{title_2}</h5>
                        <p>{undertitle_2}</p>
                    </Title>
                </SoloItemRight>
                <SoloItemLeft>
                    <img alt={title_2} src={image_2} />
                    <p>{HTMLReactParser(content_2 ? content_2 : '')}</p>
                    {list_2 && list_2.length > 0 &&<ul>
                        {list_2.map(item => (
                            <li>{item}</li>
                        ))}
                    </ul>}
                </SoloItemLeft>
            </SoloItem>
            <SoloItem>
                <SoloItemRight>
                    <Number>
                        <p>3</p>
                    </Number>
                    <Title>
                        <h5>{title_3}</h5>
                        <p>{undertitle_3}</p>
                    </Title>
                </SoloItemRight>
                <SoloItemLeft>
                    <img alt={title_3} src={image_3} />
                    <p>{HTMLReactParser(content_3 ? content_3 : '')}</p>
                    {list_3 && list_3.length > 0 &&<ul>
                        {list_3.map(item => (
                            <li>{item}</li>
                        ))}
                    </ul>}
                </SoloItemLeft>
            </SoloItem>
            <SoloItem>
                <SoloItemRight>
                    <Number>
                        <p>4</p>
                    </Number>
                    <Title>
                        <h5>{title_4}</h5>
                        <p>{undertitle_4}</p>
                    </Title>
                </SoloItemRight>
                <SoloItemLeftLast>
                    <img alt={title_4} src={image_4} />
                    <p>{HTMLReactParser(content_4 ? content_4 : '')}</p>
                    {list_4 && list_4.length > 0 &&<ul>
                        {list_4.map(item => (
                            <li>{item}</li>
                        ))}
                    </ul>}
                </SoloItemLeftLast>
            </SoloItem>
        </Container>
    </>
}
