import React, {FunctionComponent} from "react";
import {footerItems} from './footerItems';
import BiContainer from "../../atoms/container";
import {LogoAtom} from "../../atoms/logo/logo-atom";
import {SocialFooterMolecul} from "../../molecules/social-footer";
import {Footer, FooterSocialMedia, FooterSubTitle, FooterTitle, FooterWrapperRow, FooterLogo} from "./styles";


export const FooterComponent: FunctionComponent = () => {
    return <>
        <BiContainer>
            <Footer>
                {footerItems.map(row => <div key={row.id}>
                        <FooterWrapperRow>
                            {row.row.map((element, i) => <div className={element.specialClass} key={i}>
                                {(element.header && element.url) ? (<FooterTitle><strong><a href={element.url}>{element.header}</a></strong></FooterTitle>) 
                                : element.header ? (<FooterTitle><strong>{element.header}</strong></FooterTitle>) : ""}

                                <div>
                                    {element.subitems.map((subitem, j) =>
                                        <div key={j}>
                                            {subitem.subtitle ? (<FooterSubTitle>{subitem.subtitle}</FooterSubTitle>) : ""}
                                            <ul>
                                                {subitem.items.map((value, k) => <li key={k}><a href={value.url}>{value.name}</a></li>)}
                                            </ul>
                                        </div>
                                    )}
                                </div>

                            </div>)}
                        </FooterWrapperRow>
                    </div>
                )}
            </Footer>
            <FooterSocialMedia>
                <FooterLogo>
                    <LogoAtom/>
                </FooterLogo>
                <SocialFooterMolecul/>
            </FooterSocialMedia>
        </BiContainer>
    </>
}
