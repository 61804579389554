import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container";
import { useTranslation } from "react-i18next";
import HeadCompetences from "../../ui/organisms/head-competences";
import { CaseStudies } from "../../ui/organisms/case-studies/case-studies";
import {SoftDevBreadcrumb} from "../../breadcrumbs/soft-dev/soft-dev";
import {ImgBackgroundFull} from "../../ui/atoms/banner-full/styles";
import { OurTeams } from "../../ui/molecules/our-teams";
import { DevelopmentTeamsCircle } from "../../ui/organisms/development-teams-circle/development-teams-circle";
import { UnderBanner } from "../../ui/molecules/under-banner";
import { ContactShort } from "../../ui/molecules/contact-short";
import { FireList } from "../../ui/molecules/fire-list/fire-list";
import { Technologies } from "../../ui/molecules/technolgies/technologies";
import { BeforeFooter } from "../../ui/molecules/before-footer";
import { ContactBoxes } from "../../ui/molecules/contact-boxes";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import generateLink from "../../../core/api/assets"
import getSlug from "../../../core/router/getSlug";

export const SoftDevPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [items, setItems] = useState([]);
    const [caseStudy, setCaseStudy] = useState([]);
    const [technologies, setTechnologies] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    console.log(content);
    
    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/fire_list/page/${pageid}`)
                .then(response => {
                    setItems(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/technologies/page/${pageid}`)
                .then(response => {
                    setTechnologies(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/case_study/page/${pageid}`)
                .then(response => {
                    setCaseStudy(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    return <>
        <BiContainer>
            <SoftDevBreadcrumb title={content['title']} slug={slug}/>
            <HeadCompetences 
                title={content['header_-_title']}
            />
        </BiContainer>
        <ImgBackgroundFull BackgroundMobile={generateLink(content['header_-_banner'])} BackgroundDesktop={generateLink(content['header_-_banner'])} />
        <UnderBanner
            content={content['section_1_-_content']}
        />
        <BiContainer >
            <DevelopmentTeamsCircle 
                title={content['section_2_-_title']}
                content={content['section_2_-_content']}
                circle={content['circle_turn']}
            />
            <FireList 
                title={content['section_3_-_title']}
                items={items}
            />
            <Technologies
                title={content['section_4_-_title']}
                content={content['section_4_-_content']}
                items={technologies}
            />
            <ContactShort title={content['section_5_-_title']} />
            <OurTeams 
                title={content['section_6_-_title']}
                content={content['section_6_-_content']}
                banner={generateLink(content['section_6_-_image'])}
            />
            <CaseStudies 
                title={content['section_7_-_title']}
                items={caseStudy}
            />
            <ContactBoxes 
                title={content['contact_title']}
                box_1_title={content['contact_box_1_-_title']}
                box_1_name={content['contact_box_1_-_person_name']}
                box_1_position={content['contact_box_1_-_position']}
                box_1_avatar={content['contact_box_1_-_avatar']}
                box_1_content={content['contact_box_1_-_content']}
                box_2_title={content['contact_box_2_-_title']}
                box_2_name={content['contact_box_2_-_person_name']}
                box_2_position={content['contact_box_2_-_position']}
                box_2_avatar={content['contact_box_2_-_avatar']}
                box_2_content={content['contact_box_2_-_content']}
                box_3_title={content['contact_box_3_-_title']}
                box_3_name={content['contact_box_3_-_person_name']}
                box_3_position={content['contact_box_3_-_position']}
                box_3_avatar={content['contact_box_3_-_avatar']}
                box_3_content={content['contact_box_3_-_content']}
            />
        </BiContainer>
        <BeforeFooter
            title={content['before_footer_-_title']}
            content={content['before_footer_-_content']}
            item_1={content['before_footer_-_title_link_1']}
            item_1_url={content['before_footer_-_link_1']}
            item_2={content['before_footer_-_title_link_2']}
            item_2_url={content['before_footer_-_link_2']}
            item_3={content['before_footer_-_title_link_3']}
            item_3_url={content['before_footer_-_link_3']}
        />
        <ContactPersonPopup
            title={content['contact_popup_title']}
            place={content['contact_popup_position']}
            content={content['contact_popup_content']}
            name={content['contact_popup_person_name']}
            image={generateLink(content['contact_popup_avatar'])}
            send_to={content['contact_send_to']}
            pardot_link={content['pardot_link']}
        />
    </>
}
