import styled from "styled-components";
import {media} from "../../atoms/theme";

export const Wrapper = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  margin: 40px 0 80px 0;
  padding: 0 20px;
  h1{
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -0.015em;
    text-align: left;
    color: #000000;
    marign: 0 0 40px 0;
  }
  ${media.down('lg')} {
    margin: 10px 0 40px 0;
    h1{
      font-size: 36px;
      line-height: 48px;
    }
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${media.down('lg')} {
    display: table;
  }
`

export const SoloArticle = styled.div`
  max-width: 692px;
  display: table;
  box-sizing: border-box;
  a{
    text-decoration: none;
    color: #000000;
  }
  img{
    max-width: 692px;
    margin: 0 0 40px 0;
  }
  .solo-article-content{
      width: 100%;
      display: table;
      box-sizing: border-box;
      p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0 0 10px 0;
        color: #000000;
      }
      h6{
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0 0 10px 0;
        color: #000000;
      }
      .article-info{
        width: 100%;
        display: table;
        box-sizing: border-box;
        p{
            float: left;
            margin: 0 10px 0 0;
        }
      }
  }
  ${media.down('lg')} {
    max-width: 100%;
    width: 100%;
    img{
      max-width: 100%;
      width: 100%;
      margin-bottom: 20px;
    }
    .solo-article-content{
      p{
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 5px 0;
      }
      h6{
        font-size: 24px;
        line-height: 30px;
        margin: 0 0 5px 0;
      }
    }
  }
`

export const ListArticles = styled.div`
    max-width: 387px;
    display: table;
    box-sizing: border-box;
    .list-solo-article{
        width: 100%;
        display: table;
        box-sizing: border-box;
        a{
          width: 100%;
          display: table;
          box-sizing: border-box;
          padding-bottom: 30px;
          text-decoration: none;
          color: #000000;
        }
        h6{
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 34px;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;
            margin: 0 0 10px 0;
        }
        .article-info{
            width: 100%;
            display: table;
            box-sizing: border-box;
            p{
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                letter-spacing: 0.02em;
                text-align: left;
                color: #000000;
                float: left;
                margin: 0 10px 0 0;
            }
        }
    }
    ${media.down('lg')} {
      max-width: 100%;
      widht: 100%;
      .list-solo-article{
        margin-top: 40px;
        widht: 100%;
        background: rgba(237, 239, 241, 1);
        padding: 20px;
        border-radius: 5px;
        a{
          padding-bottom: 0;
        }
        h6{
          font-size: 22px;
          line-height: 26px;
        }
        .article-info{
          p{
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
`