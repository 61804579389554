import React, {FunctionComponent} from "react";
import { Link } from "react-router-dom";
import {ActionArrowAtom} from "../../atoms/action-arrow";
import {LogoService, ServiceItem, WrapperServices, WrapperServicesDesc} from "./styles";
import { IServices } from "./interface";
import generateLink from "../../../../core/api/assets";

export const ServicesPs: FunctionComponent<IServices> = ({title, content, services}) => {

    return <>
        <WrapperServicesDesc>
            <h2>{title}</h2>
            <p>{content}</p>
        </WrapperServicesDesc>
        <WrapperServices>
            {Object.keys(services).map((keyName, i) => (
                <ServiceItem key={services[keyName].id}>
                    <Link to={services[keyName].data['link']}>
                        <LogoService ServiceLogo={generateLink(services[keyName].data['image'])}/>
                        <p className="name-service">{services[keyName].data['title']}</p>
                    </Link>
                    <ActionArrowAtom link={services[keyName].data['link']} text={""}/>
                </ServiceItem>
            ))}
        </WrapperServices>
    </>

}
