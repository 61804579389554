import React, {FunctionComponent} from "react";
import {WrapperDescriptionPaperless} from "./styles";
import { useTranslation } from "react-i18next";
import { BIHeadDesc} from "./interfaces";

export const HeadDescriptionPaperless: FunctionComponent<BIHeadDesc> = ({title, under_title}) => {
    const { t } = useTranslation();

    return <>
        <WrapperDescriptionPaperless>
            <h1>{title}</h1>
            <h2>{under_title}</h2>
        </WrapperDescriptionPaperless>
    </>
}
