import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container/BiContainer";
import { AboutBreadcrumb } from "../../breadcrumbs/about/about";
import { HeadCompetences } from "../../ui/organisms/head-competences/head-competences";
import { ImgBackgroundFull } from "../../ui/atoms/banner-full/styles";
import { Map } from "../../ui/organisms/map/map";
import { AboutMore } from "../../ui/molecules/about-more";
import { HomeCompetences } from "../../ui/organisms/home-competences/home-competences";
import { HomeIndustries } from "../../ui/organisms/home-industries/home-industries";
import { Projects } from "../../ui/organisms/projects/projects";
import { Consulting } from "../../ui/organisms/consulting/consulting";
import tech from "../../../assets/icons/tech.svg";
import human from "../../../assets/icons/human.svg";
import { SoloHeader } from "../../ui/organisms/solo-header/solo-header";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import generateLink from "../../../core/api/assets"
import getSlug from "../../../core/router/getSlug";

export const AboutPage: FunctionComponent = () => {
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [projects, setProjects] = useState([]);
    const [specialists, setSpecialists] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [competencies, setCompetencies] = useState([]);
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));
    const [lang, setLang] = useGlobalLang();

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/projects/page/${pageid}`)
                .then(response => {
                    setProjects(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/specialists/page/${pageid}`)
                .then(response => {
                    setSpecialists(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/competencies/page/${pageid}`)
                .then(response => {
                    setCompetencies(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/industries/page/${pageid}`)
                .then(response => {
                    setIndustries(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    return <>
        <BiContainer>
            <AboutBreadcrumb title={content['title']} slug={slug}/>
            <HeadCompetences 
                title={content['header_-_title']}
            />
        </BiContainer>
        <ImgBackgroundFull BackgroundMobile={generateLink(content['header_-_banner'])} BackgroundDesktop={generateLink(content['header_-_banner'])} />
        <BiContainer>
            <Projects
                image={tech}
                title={content['section_1_-_title']}
                content={content['section_1_-_content']}
                items={projects}
            />
            <Consulting 
                banner={generateLink(content['section_2_-_image'])}
                title={content['section_2_-_title']}
                content={content['section_2_-_content']}
            />
            <Projects
                image={human}
                title={content['section_3_-_title']}
                content={content['section_3_-_content']}
                items={specialists}
            />
            <HomeIndustries
                title={content['section_4_-_title']}
                items={industries}
            />
            <HomeCompetences 
                title={content['section_5_-_title']}
                content={content['section_5_-_content']}
                items={competencies}
            />
            <SoloHeader title={content['section_6_-_title']} margin={80}/>
            <Map 
                content={content['section_6_-_content']}
            />
        </BiContainer>
        <AboutMore 
            title={content['next_page_-_title']}
            link={content['next_page_-_link']}
            link_text={content['next_page_-_link_text']}
        />
        <ContactPersonPopup
            title={content['contact_popup_title']}
            place={content['contact_popup_position']}
            content={content['contact_popup_content']}
            name={content['contact_popup_person_name']}
            image={generateLink(content['contact_popup_avatar'])}
            send_to={content['contact_send_to']}
            pardot_link={content['pardot_link']}
        />
    </>
}
