import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerItems = styled.div`
  display: table;
  box-sizing: border-box;
  margin: 0 0 100px 0;
  width: 100%;
  h4{
    font-size: 46px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 120px 0;
  }
  ${media.down('lg')} {
    margin: 0;
    h4{
      font-size: 30px;
      line-height: 38px;
      margin: 0 0 40px 0;
    }
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const SoloItem = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin: 0 0 60px 0;
    .number{
        min-width: 160px;
        max-width: 160px;
        font-size: 100px;
        font-weight: 600;
        line-height: 75px;
        letter-spacing: 0em;
        margin: 4px 87px 0 0;
        color: #FF5A00;
    }
    .title{
        min-width: 210px;
        max-width: 210px;
        font-size: 34px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0 95px 0 0;
    }
    .content{
        min-width: 590px;
        max-width: 590px;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 3px 0 0 0;
    }
    ${media.down('xl')} {
      .content{
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin-top: 20px;
      }
    }
    ${media.down('lg')} {
      .number{
        font-size: 60px;
        line-height: 60px;
        min-width: 80px;
        max-width: 80px;
        margin: 0 27px 0 0;
      }
      .title{
        min-width: 150px;
        max-width: 150px;
        font-size: 24px;
        line-height: 30px;
        margin: 0;
      }
      .content{
        font-size: 16px;
        line-height: 24px;
      }
    }
`