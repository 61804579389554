import React, {FunctionComponent} from "react";
import {ICountryDataDesc} from "./interfaces";
import {WrapperDescription, WrapperTitle } from "./styles";
import { WrapperContentCountry } from "./styles";
import HTMLReactParser from "html-react-parser";

export const AboutBilleniumCountry: FunctionComponent<ICountryDataDesc> = ({descriptionCountry, countryTitle}) => {
    return <>
        <WrapperContentCountry>
            <WrapperTitle>
                <h2>{countryTitle}</h2>
            </WrapperTitle>
            <WrapperDescription>
                <p>{HTMLReactParser(descriptionCountry)}</p>
            </WrapperDescription>
        </WrapperContentCountry>
    </>
}