import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperIndustriesDesc = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 140px 0;
  ${media.down('md')} {
    margin-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperIndustriesHead = styled.div`
    display: table;
    box-sizing: border-box;
    float: left;
    max-width: 422px;
    h4{
        font-size: 46px;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0;
    }
    ${media.down('xl')} {
        max-width: 300px;
    }
    ${media.down('lg')} {
        max-width: 100%;
        margin-bottom: 20px;
    }
    ${media.down('md')} {
        h4{
            font-size: 30px;
            line-height: 38px;
        }
    }
`

export const WrapperIndustriesContent = styled.div`
    display: table;
    box-sizing: border-box;
    float: right;
    max-width: 613px;
    p{
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0 0 20px 0;
    }
    ${media.down('xl')} {
        max-width: 400px;
    }
    ${media.down('lg')} {
        max-width: 100%;
    }
    ${media.down('md')} {
        p{
            font-size: 16px;
            line-height: 24px;
        }
    }
`