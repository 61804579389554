import styled from "styled-components";
import {media} from "../../atoms/theme";
import {WrapperBorder, WrapperHeadPowerApp} from "../head-power-app/styles";
import AwstImage from "../../../../assets/images/salesforce/logo.png";
import { ILogo } from "./interface";
export const WrapperBorderSaleforce = styled(WrapperBorder)`
  ${media.up('lg')} {
    width: 77%;
    height: auto;
  }
  ${media.down('xl')} {
    display: none;
  }
`

export const WrapperHeadSaleforce = styled(WrapperHeadPowerApp)`
  position: relative;
  margin-bottom: 120px;
  margin-top: 60px;
  h1{
    display: inline-block;
    width: auto;
    position: relative;
    margin-top: -15px;
    background: #ffffff;
    z-index: 10;
    padding-right: 40px;
  }

  ${media.up('lg')} {
    p {
      font-size: 22px;
      max-width: 692px;
      margin-top: 40px;
    }
  }
  ${media.down('xl')} {
    width: 100%;
    border-bottom: 6px solid #FF5A00;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const LogoSaleforce = styled.div<ILogo>`
  background-image: url("${(props) => props.image}");
  background-repeat: no-repeat;
  width: 208px;
  height: 146px;
  background-size: 208px;
  margin-bottom: 506px;
  margin-top: 80px;
  display: none;
  float: right;
  margin-right: 30px;

  ${media.up('sm')} {

  }

  ${media.up('md')} {
    margin-right: 100px;
  }

  ${media.up('lg')} {
    display: inherit;
    width: 208px;
    height: 146px;
    background-size: 208px;
    float: right;
    margin-right: 120px;
  }
`
