import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperHeaderJobOffer = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100%;
  margin: 60px 0 0 0;
  border-bottom: 6px solid ${(props) => props.theme.colors.primaryOrange};
  padding-bottom: 40px;
  h1{
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -0.015em;
    text-align: left;
    margin: 0;
  }
  ${media.down('md')} {
    padding-bottom: 20px;
    h1{
      font-size: 36px;
      line-height: 48px;
      margin: 0;
    }
  }
`