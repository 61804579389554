import React, {FunctionComponent} from "react";
import { ContainerConsulting, DescConsulting, ImageConsulting } from "./styles";
import { IConsulting } from "./interface";

export const Consulting: FunctionComponent<IConsulting> = ({banner, title, content}) => {
    return <>
        <ContainerConsulting>
            <DescConsulting>
                <h2>{title}</h2>
                <p>{content}</p>
            </DescConsulting>
            <ImageConsulting>
                <img alt="Banner" src={banner} />
            </ImageConsulting>
        </ContainerConsulting>
    </>
}
