import React, {FunctionComponent} from "react";
import { ContainerDevelopmentTeams, DescDevelopmentTeams, ImageDevelopmentTeams} from "./styles";
import {IDevelopmentTeams} from './intereface';
import ReactHtmlParser from 'react-html-parser';

export const DevelopmentTeams: FunctionComponent<IDevelopmentTeams> = ({title, content, circle}) => {
    return <>
        <div id="development-teams">
            <ContainerDevelopmentTeams>
                    <DescDevelopmentTeams>
                        <h2>{ReactHtmlParser(title)}</h2>
                        <ImageDevelopmentTeams>
                            <img alt="Circle" src={circle} />
                        </ImageDevelopmentTeams>
                        <p>{ReactHtmlParser(content)}</p>
                    </DescDevelopmentTeams>
            </ContainerDevelopmentTeams>
        </div>
    </>
}
