import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerItems = styled.div`
  display: table;
  box-sizing: border-box;
  margin: 0 0 160px 0;
  width: 100%;
  h4{
    font-size: 46px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 40px 0;
  }
  p{
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;   
    margin: 0 0 120px 0;
    max-width: 613px;
  }
  ${media.down('lg')} {
    margin: 0 0 60px 0;
    h4{
      font-size: 30px;
      line-height: 38px;
      margin: 0 0 20px 0;
    }
    p{
      margin: 0 0 40px 0;
    }
  }
`

export const WrapperItems = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 60px 122px;
  ${media.down('lg')} {
    gap: 40px 0;
  }
`

export const WrapperSoloItem = styled.div`
  flex: 1 0 calc(100% / 2);
  max-width: calc((100% / 2) - 122px);
  min-width: calc((100% / 2) - 122px);
  display: table;
  box-sizing: border-box;
  img{
      height: 48px;
      margin-bottom: 40px;
  }
  p{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0 0 60px 0;
  }
  ${media.down('lg')} {
    flex: 1 0 100%;
    max-width: 100%;
    min-width: 100%;
    img{
      height: 35px;
      margin-bottom: 20px;
    }
    p{
      margin: 0 0 20px 0;
    }
  }
`