import {FunctionComponent, useState} from "react";
import BiContainer from "../../ui/atoms/container";
import { useTranslation } from "react-i18next";
import HeadCompetences from "../../ui/organisms/head-competences";
import { CaseStudies } from "../../ui/organisms/case-studies/case-studies";
import {DevOpsBreadcrumb} from "../../breadcrumbs/devops/devops";
import {ImgBackgroundFull} from "../../ui/atoms/banner-full/styles";
import { OurTeams } from "../../ui/molecules/our-teams";
import { DevelopmentTeams } from "../../ui/organisms/development-teams/development-teams";
import Banner from "../../../assets/images/devops/banner.png";
import Banner_second from "../../../assets/images/soft-dev/banner-two.png";
import circle from '../../../assets/images/devops/circle.png'
import { UnderBanner } from "../../ui/molecules/under-banner";
import { ContactShort } from "../../ui/molecules/contact-short";
import { FireList } from "../../ui/molecules/fire-list/fire-list";
import { Technologies } from "../../ui/molecules/technolgies/technologies";
import {items, technologies} from './data'
import { BeforeFooter } from "../../ui/molecules/before-footer";
import { ContactBoxes } from "../../ui/molecules/contact-boxes";

export const DevOpsPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [caseStudy, setCaseStudy] = useState([]);

    return <>
        <BiContainer>
            <DevOpsBreadcrumb />
            <HeadCompetences 
                title="DevOps & Clouds"
            />
        </BiContainer>
        <ImgBackgroundFull BackgroundMobile={Banner} BackgroundDesktop={Banner} />
        <UnderBanner
            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras iaculis augue neque, ac egestas ante elementum et. Nulla non arcu sagittis, condimentum magna facilisis, condimentum enim."
        />
        <BiContainer >
            <DevelopmentTeams 
                title="Create unique<br /> software to deliver<br /> required targets"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras iaculis augue neque, ac egestas ante elementum et. Nulla non arcu sagittis, condimentum magna facilisis, condimentum enim. Suspendisse ligula nibh, tincidunt vel est quis, faucibus maximus eros. Duis volutpat, arcu at ornare iaculis, erat nisl ultricies risus, a pulvinar sapien odio non lacus."
                circle={circle}
            />
            <FireList 
                title="What is hot in software development"
                items={items}
            />
            <Technologies
                title="Technologies"
                content="Our goal is constant development. Our specialists have many certificates confirming their skills. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras iaculis augue neque, ac egestas ante elementum et."
                items={technologies}
            />
            <ContactShort title="What can we do for you?" />
            <OurTeams 
                title="Our teams"
                content="Management is the key to deliver succesful product. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras iaculis augue neque, ac egestas ante."
                banner={Banner_second}
            />
            <CaseStudies 
                title="Software development case studies"
                items={caseStudy}
            />
        </BiContainer>
        <BeforeFooter
            title="Check out our other services"
            content="Info about competencies, how Billennium works etc.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras iaculis augue neque."
            item_1="DevOps & Clouds"
            item_2="UX & U Design"
            item_3="Document Management"
        />
    </>
}
