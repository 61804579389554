import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container";
import { useTranslation } from "react-i18next";
import {CompanyCultureBreadcrumb} from "../../breadcrumbs/company-culture/company-culture";
import { AboutMore } from "../../ui/molecules/about-more";
import { HeaderHistory } from "../../ui/molecules/header-history/header-history";
import { ContentHistory } from "../../ui/molecules/content-history/content-history";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import generateLink from "../../../core/api/assets"
import getSlug from "../../../core/router/getSlug";

export const CompanyHistoryPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    return <>
        <BiContainer >
            <CompanyCultureBreadcrumb title={content['title']} slug={slug}/>
            <HeaderHistory
                title={content['header_-_title']}
                content={content['header_-_content']}
            />
            <ContentHistory 
                section_1_year={content['history_section_1_-_year']}
                section_1_content={content['history_section_1_-_content']}
                section_1_image={generateLink(content['history_section_1_-_image'])}
                section_2_year={content['history_section_2_-_year']}
                section_2_content={content['history_section_2_-_content']}
                section_2_image={generateLink(content['history_section_2_-_image'])}
                section_3_year={content['history_section_3_-_year']}
                section_3_content={content['history_section_3_-_content']}
                section_3_list={content['history_section_3_-_list']}
                section_4_year={content['history_section_4_-_year']}
                section_4_content={content['history_section_4_-_content']}
                section_4_image={generateLink(content['history_section_4_-_image'])}
                section_5_year={content['history_section_5_-_year']}
                section_5_content={content['history_section_5_-_content']}
                section_6_year={content['history_section_6_-_year']}
                section_6_content={content['history_section_6_-_content']}
                section_7_year={content['history_section_7_-_year']}
                section_7_content={content['history_section_7_-_content']}
                section_7_image={generateLink(content['history_section_7_-_image'])}
                out_section_1_title={content['section_1_-_title']}
                out_section_1_content={content['section_1_-_content']}
            />
        </BiContainer>
        <AboutMore 
            title={content['next_page_-_title']}
            link={content['next_page_-_link']}
            link_text={content['next_page_-_link_text']}
        />
        <ContactPersonPopup
            title={content['contact_popup_title']}
            place={content['contact_popup_position']}
            content={content['contact_popup_content']}
            name={content['contact_popup_person_name']}
            image={generateLink(content['contact_popup_avatar'])}
            send_to={content['contact_send_to']}
            pardot_link={content['pardot_link']}
        />
    </>
}
