import React, {FunctionComponent} from "react";
import { ContainerTiger, List, Content, SoloItem} from "./styles";
import { ICompanyTiger } from "./interface";
import HTMLReactParser from "html-react-parser";

export const CompanyTigerbig: FunctionComponent<ICompanyTiger> = ({title, content, image, letters}) => {
    return <>
        <ContainerTiger>
            <Content>
                <h4>{title}</h4>
                <p>{content}</p>
                <img src={image} />
            </Content>
            <List>
                {Object.keys(letters).map((keyName, i) => (
                    <SoloItem>
                        <span>{letters[keyName].data['title']}</span>
                        <div className="content">
                            <p className="content-title">{letters[keyName].data['undertitle']}</p>
                            <p className="content-content">{HTMLReactParser(letters[keyName].data['content'])}</p>
                        </div>
                    </SoloItem>
                ))}
            </List>
        </ContainerTiger>
    </>
}
