import React, {FunctionComponent} from "react";
import {WrapperCareerWhy, DescCareerWhy, WrapperItems, WrapperSoloItem} from './styles'
import { ICareerWhy } from "./interface";
import { ActionArrowAtom } from "../../atoms/action-arrow";

export const CareerWhy: FunctionComponent<ICareerWhy> = ({title, content, items, url, text_link}) => {
    return <>
        <WrapperCareerWhy>
            <DescCareerWhy>
                <h4>{title}</h4>
                <p>{content}</p>
            </DescCareerWhy>
            <WrapperItems>
                {Object.keys(items).map((keyName, i) => (
                    <WrapperSoloItem>
                        <p className="title">{items[keyName].data['title']}</p>
                        <p className="content">{items[keyName].data['content']}</p>
                    </WrapperSoloItem>
                ))}
            </WrapperItems>
            {url && text_link &&<ActionArrowAtom link={url} text={text_link} />}
        </WrapperCareerWhy>
    </>
}
