import React, {FunctionComponent} from "react";
import { Link, useHistory } from 'react-router-dom';
import {ArrowLeftIcon} from '../../ui/atoms/arrow-left-icon'
import {WrapperBreadcrumb} from '../styles'

export const JobOfferBreadcrumb: FunctionComponent = () => {
    const history = useHistory();

    return <>
        <WrapperBreadcrumb>
            <ArrowLeftIcon />
            <Link to="/job-offers" onClick={() => { history.push('/job-offers');}}>Back to offers</Link>
        </WrapperBreadcrumb>
    </>
}