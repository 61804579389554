import React, {FunctionComponent} from "react";
import { WrapperInperlySections, Title, Sections, SoloSection, ContentLeft, ContentRight, ImageLeft, ImageRight, Content} from "./styles";
import { IInperlySections } from "./interface";
import generateLink from "../../../../core/api/assets";

export const InperlySections: FunctionComponent<IInperlySections> = ({title, content, items}) => {
    return <>
        <WrapperInperlySections>
            <Title>
                <h4>{title}</h4>
                <p>{content}</p>
            </Title>
            <Sections>
                {Object.keys(items).map((keyName, i) => (
                    <div>
                        {i % 2 === 0 || i === 0 ?
                        <SoloSection>
                            <ImageLeft>
                                <img alt={items[keyName].data['title']} src={generateLink(items[keyName].data['image'])} />
                            </ImageLeft>
                            <ContentRight>
                                <Content>
                                    <h6>{items[keyName].data['title']}</h6>
                                    <p>{items[keyName].data['content']}</p>
                                    {items[keyName].data['list'] &&<ul>
                                        {items[keyName].data['list'].map(underitem => (
                                            <li>{underitem}</li>
                                        ))}
                                    </ul>}
                                </Content>
                            </ContentRight>
                        </SoloSection>:
                        <SoloSection>
                            <ImageRight>
                            <img alt={items[keyName].data['title']} src={generateLink(items[keyName].data['image'])} />
                            </ImageRight>
                            <ContentLeft>
                                <Content>
                                    <h6>{items[keyName].data['title']}</h6>
                                    <p>{items[keyName].data['content']}</p>
                                    {items[keyName].data['list'] &&<ul>
                                        {items[keyName].data['list'].map(underitem => (
                                            <li>{underitem}</li>
                                        ))}
                                    </ul>}
                                </Content>
                            </ContentLeft>
                        </SoloSection>
                        }
                    </div>
                ))}
            </Sections>
        </WrapperInperlySections>
    </>
}
