import styled from "styled-components";
import {media} from "../../ui/atoms/theme";

export const PressContentBox = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    margin-bottom: 120px;
    ${media.down('lg')} {
        margin-bottom: 60px;
    }
`