import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerDevelopmentTeams = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 160px;
  ${media.down('lg')} {
    margin-bottom: 60px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const DescDevelopmentTeams = styled.div`
    max-width: 488px;
    display: table;
    box-sizing: border-box;
    float: left;
    min-height: 538px;
    h2{
        font-size: 46px;
        line-height: 56px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 40px;
    }
    p{
        font-size: 18px;
        line-height: 26px;
        margin: 0;
        margin-bottom: 20px;
    }
    ${media.down('lg')} {
        min-height: auto;
        h2{
            font-size: 30px;
            line-height: 38px; 
        }
        p{
            font-size: 16px;
            line-height: 24px;
        }
    }
`

export const ImageDevelopmentTeams = styled.div`
    width: 538px;
    display: table;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 0;
    img{
        width: 100%;
    }
    ${media.down('xl')} {
        width: 250px;
    }
    ${media.down('lg')} {
        position: static;
        width: 100%;
        max-width: 100%;
        margin: 30px 0 40px 0;
    }
`