import styled from "styled-components";
import FacebookImage from "../../../../assets/icons/facebook.svg";

export const IconFacebook = styled.div`
  background-image: url("${FacebookImage}");
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  background-size: 32px;
  margin: 0 7.5px;
`
