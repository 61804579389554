import styled from "styled-components";
import {media} from "../../atoms/theme";
import linkedin from '../../../../assets/icons/icon-linkedin.svg';

export const ContainerRecruters = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: table;
  margin-bottom: 160px;
  h4{
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 60px 0;
  }
  ${media.down('md')} {
    h4{
        font-size: 30px;
        line-height: 38px;
        margin: 0 0 40px 0;
    }
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const RecrutersContent = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: table;
`

export const RecruterImage = styled.div`
    box-sizing: border-box;
    display: table;
    float: left;
    width: 387px;
    margin: 0 121px 0 0;
    img{
        width: 100%;
    }
    ${media.down('lg')} {
        width: 100%;
        margin: 0;
    }
`

export const RecruterContent = styled.div`
    box-sizing: border-box;
    display: table;
    float: left;
    max-width: 542px;
    span{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 10px 0 5px 0;
    }
    h5{
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0 0 20px 0;
    }
    p{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0 0 20px 0;
    }
    ${media.down('md')} {
        width: 100%;
        span{
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 5px 0;
        }
        h5{
            font-size: 24px;
            line-height: 30px;
            margin: 0 0 10px 0;
        }
        p{
            margin: 0 0 10px 0;
        }
    }
`

export const ContactOn = styled.div`
    box-sizing: border-box;
    display: table;
    margin: 0 0 67px 0;
    span{
        margin: 0 0 10px 0;
    }
    a{
        display: block;
        text-decoration: none;
        color: #25282B;
        cursor: pointer;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        &:after{
            content: '';
            width: 24px;
            height: 24px;
            display: inline-block;
            background-image: url("${linkedin}");
            background-size: 24px;
            margin: 5px 0 -5px 5px;
        }
    }
    ${media.down('md')} {
        margin: 0 0 20px 0;
        p{
            font-size: 16px;
        }
    }
`

export const List = styled.div`
    box-sizing: border-box;
    display: table;
    img{
        cursor: pointer;
        width: 48px;
        height: 48px;
        overflow: hidden;
        float: left;
        display: block;
        margin: 0 10px 10px 0;
        border-radius: 48px;
    }
    .active{
        border: 4px solid #FF5A00;
        border-radius: 48px;
    }
`