import styled from "styled-components";
import {media} from "../../atoms/theme";
import Check from "../../../../assets/icons/check-green.svg";

export const ContainerItems = styled.div`
  display: table;
  box-sizing: border-box;
  margin: 0 0 160px 0;
  h4{
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #25282B;
    margin: 0 0 120px 0;
    max-width: 681px;
  }
  ${media.down('lg')} {
    margin-bottom: 60px;
    h4{
      font-size: 30px;
      line-height: 38px;
      color: #000000;
      margin-bottom: 40px;
    }
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperItems = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 0 105px;
  ${media.down('xl')} {
    gap: 20px 105px;
  }
  ${media.down('lg')} {
    gap: 20px 0;
  }
`

export const WrapperSoloItem = styled.div`
  flex: 1 0 calc((100% / 3) - 105px);
  max-width: calc(100% / 3);
  display: table;
  box-sizing: border-box;
  .title{
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 39px;
    position: relative;
    margin: 0;
    &:before{
      content: "";
      width: 24px;
      height: 24px;
      display: inline-block;
      background-image: url("${Check}");
      background-size: 24px;
      margin: 1px 15px -4px 0;
      position: absolute;
      left: 0;
      top: 5px;
    }
  }
  .content{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 10px 0 0 0;
  }
  ${media.down('xl')} {
    flex: 1 0 calc((100% / 2) - 105px);
    max-width: calc(100% / 2);
  }
  ${media.down('lg')} {
    flex: 1 0 100%;
    max-width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid #D1D8DB;
    .title{
      font-size: 22px;
      line-height: 26px;
      &:before{
        top: 1px;
      }
    }
  }
`
