import React, { FunctionComponent } from "react";
import { Wrapper, ListCategories, SoloCategory } from "./styles";
import { IBlogCategories } from "./interface";
import icon from '../../../../assets/icons/category-icon.png'
import { Link } from "react-router-dom";

export const BlogCategories: FunctionComponent<IBlogCategories> = ({title}) => {
    
    return <>
        <Wrapper>
            <h4>{title}</h4>
            <ListCategories>
                <SoloCategory>
                    <Link to={'/blog/category/development'}>
                        <img src={icon} />
                        <p>Developmnet</p>
                    </Link>
                </SoloCategory>
                <SoloCategory>
                    <Link to={'/blog/category/data'}>
                        <img src={icon} />
                        <p>Data</p>
                    </Link>
                </SoloCategory>
                <SoloCategory>
                    <Link to={'/blog/category/design'}>
                        <img src={icon} />
                        <p>Design</p>
                    </Link>
                </SoloCategory>
                <SoloCategory>
                    <Link to={'/blog/category/project-management'}>
                        <img src={icon} />
                        <p>Project Management</p>
                    </Link>
                </SoloCategory>
                <SoloCategory>
                    <Link to={'/blog/category/hr'}>
                        <img src={icon} />
                        <p>HR</p>
                    </Link>
                </SoloCategory>
                <SoloCategory>
                    <Link to={'/blog/category/business'}>
                        <img src={icon} />
                        <p>Business</p>
                    </Link>
                </SoloCategory>
            </ListCategories>
        </Wrapper>
    </>
}
