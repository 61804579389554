import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container/BiContainer";
import { CompetencesBreadcrumb } from "../../breadcrumbs/competences/competences";
import { Technology } from "../../ui/organisms/technology/technology";
import { PartnerShip } from "../../ui/organisms/partnership/partnership";
import { Teams } from "../../ui/organisms/teams/teams";
import { teams_second } from "./teams";
import { teams } from "./teams";
import { BusinessTeams } from "../../ui/organisms/business-teams/business-teams";
import { DevelopmentTeams } from "../../ui/organisms/development-teams/development-teams";
import { ImgBackgroundFull } from "../../ui/atoms/banner-full/styles";
import imgBackground from "../../../assets/images/competences/banner.png";
import HeadCompetences from "../../ui/organisms/head-competences";
import circle from '../../../assets/images/competences/nav.png'
import { PopupContact } from "../../ui/molecules/popup-contact";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import generateLink from "../../../core/api/assets"
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import getSlug from "../../../core/router/getSlug";

export const CompetencesPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [servicesTeam, setServicesTeam] = useState([]);
    const [developmentTeam, setDevelopmentTeam] = useState([]);
    const [technologies, setTechnologies] = useState([]);
    const [partnerships, setPartnerships] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/teams/page/${pageid}/category-services`)
                .then(response => {
                    setServicesTeam(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/teams/page/${pageid}/category-development`)
                .then(response => {
                    setDevelopmentTeam(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/technologies/page/${pageid}`)
                .then(response => {
                    setTechnologies(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/partnerships/page/${pageid}`)
                .then(response => {
                    setPartnerships(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    return <>
        <BiContainer>
        <CompetencesBreadcrumb />
        <HeadCompetences 
            title={content['header_-_title']}
        />
        </BiContainer>
        <ImgBackgroundFull BackgroundMobile={generateLink(content['header_-_banner'])} BackgroundDesktop={generateLink(content['header_-_banner'])} />
        <BiContainer>
        <DevelopmentTeams 
            title={content['section_1_-_title']}
            content={content['section_1_-_content']}
            circle={generateLink(content['section_1_-_image'])}
        />
        <Teams items={developmentTeam}/>
        <BusinessTeams 
            title={content['section_2_-_title']}
            content={content['section_2_-_content']}
            image={generateLink(content['section_2_-_image'])}
        />
        <Teams items={servicesTeam}/>
        <PartnerShip 
            title={content['section_3_-_title']}
            content={content['section_3_-_content']}
            items={partnerships}
        />
        <Technology 
            title={content['section_4_-_title']}
            content={content['section_4_-_content']}
            items={technologies}
        />
        <PopupContact
            content={`<p>${content['contact_title']}</p>`}
            text_link="Contact us"
        />
        <ContactPersonPopup
            title={content['contact_popup_title']}
            place={content['contact_popup_position']}
            content={content['contact_popup_content']}
            name={content['contact_popup_person_name']}
            image={generateLink(content['contact_popup_avatar'])}
            send_to={content['contact_send_to']}
            pardot_link={content['pardot_link']}
        />
        </BiContainer>

    </>
}
