import React, {FunctionComponent} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow/action-arrow-atom";
import { WrapperNews, ContainerNews, ItemNews, SeeMoreWrapper } from "./styles";
import { useTranslation } from "react-i18next";
import { INews } from "./interfaces"

export const News: FunctionComponent<INews> = ({articles, title, show_more}) => {
    const { t } = useTranslation();

    return <>
        {title || articles.length > 0 &&<WrapperNews>
            <h4>{title}</h4>
            <ContainerNews>
                {articles.map(article => (
                    <ItemNews key={article.id}>
                        <p>{article.data.title}</p>
                        <SeeMoreWrapper>
                            <ActionArrowAtom link={`/blog/${article.slug}`} text="Read more"/>
                        </SeeMoreWrapper>
                    </ItemNews>
                ))}
            </ContainerNews>
            {show_more &&<ActionArrowAtom link="/blog" text="View all articles"/>}
        </WrapperNews>}
    </>
}
