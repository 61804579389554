import styled from "styled-components";
import {media} from "../../atoms/theme";
import linkedin from '../../../../assets/icons/icon-linkedin.svg';

export const ContainerItems = styled.div`
  display: table;
  box-sizing: border-box;
  margin: 150px 0 60px 0;
  width: 100%;
  ${media.down('lg')} {
      margin-top: 60px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperItems = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
`

export const WrapperSoloItem = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    margin: 0 0 60px 0;
    ${media.down('md')} {
        margin: 0 0 40px 0;
    }
`

export const ImageWrapper = styled.div`
    width: 387px;
    float: left;
    margin: 0 121px 0 0;
    img{
        width: 100%;
        margin: 0;
    }
    ${media.down('lg')} {
        width: 100%;
        margin: 0;
    }
`

export const ContentWrapper = styled.div`
    width: calc(100% - (387px + 121px));
    display: table;
    box-sizing: border-box;
    float: left;
    div{
        .wrapper-arrow{
            margin: 5px 5px -5px 0;
        }
    }
    ${media.down('lg')} {
        width: 100%;
    }
`

export const Title = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    padding-top: 53px;
    h4{
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0 0 5px 0;
    }
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0 0 21px 0;
    }
    ${media.down('md')} {
        padding-top: 20px;
        h4{
            font-size: 24px;
            line-height: 30px;
        }
        p{
            font-size: 16px;
            line-height: 24px;
        }
    }
`

export const ContactWrap = styled.div`
    width: 100%;
    margin-bottom: 21px;
    display: table;
    box-sizing: border-box;
    padding-top: 21px;
    a{
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0;
        color: #000;
        text-decoration: none;
        &:after{
            content: '';
            width: 24px;
            height: 24px;
            display: inline-block;
            background-image: url("${linkedin}");
            background-size: 24px;
            margin: 5px 0 -5px 5px;
        }
    }
    ${media.down('md')} {
        padding-top: 10px;
        margin-bottom: 10px;
        p{
            font-size: 14px;
            line-height: 20px;
            &:after{
                width: 72px;
                height: 20px;
                background-size: 72px;
            }
        }
    }
`

export const Content = styled.div`
    width: 100%;
    margin-bottom: 20px;
    display: table;
    box-sizing: border-box;
    p{
        max-width: 542px;
        margin: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: left;
    }
    ${media.down('md')} {
        margin-bottom: 10px;
    }
`