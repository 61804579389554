// @ts-ignore
export const Theme = {
  font: 'Billennium',
  fontSize: '18px',
  lineHeight: '26px',
  letterSpacing: '0.02em',
  colors: {
    white: '#ffffff',
    black: '#000000',
    primaryOrange:'#FF5A00',
    primaryGray:'#25282B',
    lightGray:'#EDEFF1',
    borderColorMain:'#D1D8DB',
  },
  desktop: {
    h1: {
      fontSize: '60px',
      fontWeight: '600',
      lineHeight: '80px',
      letterSpacing: '-0.015em'
    },
    h2: {
      fontSize: '46px',
      fontWeight: '600',
      lineHeight: '56px',
      letterSpacing: '-0.01em'
    },
    h3: {
      fontSize: '34px',
      fontWeight: '600',
      lineHeight: '42px',
      letterSpacing: '-0.01em'
    },
  },
  mobile: {
    fontSize: '16px',

    h1: {
      fontSize: '36px',
      fontWeight: '600',
      lineHeight: '48px',
      letterSpacing: '-0.015em'
    },
    h2: {
      fontSize: '30px',
      fontWeight: '600',
      lineHeight: '38px',
      letterSpacing: '-0.01em'
    },
    h3: {
      fontSize: '24px',
      fontWeight: '600',
      lineHeight: '30px',
      letterSpacing: '-0.01em'
    },
  },
};

export type BaseTheme = typeof Theme;
