import React, {FunctionComponent} from "react";
import { ContainerItems, SoloItem } from "./styles";
import { INumberList } from "./interface";

export const NumberList: FunctionComponent<INumberList> = ({title, steps}) => {
    return <>
        <ContainerItems>
            <h4>{title}</h4>
            {Object.keys(steps).map((keyName, i) => (
                <SoloItem>
                    <p className="number">0{i + 1}</p>
                    <p className="title">{steps[keyName].data['title']}</p>
                    <p className="content">{steps[keyName].data['content']}</p>
                </SoloItem>
            ))}
        </ContainerItems>
    </>
}
