import React, {FunctionComponent} from "react";
import { ContainerContentProjects, DescContentProjects, ImageContentProjects } from "./styles";
import { IContentProjects } from "./interaface";

export const ContentCareer: FunctionComponent<IContentProjects> = ({title, content_two, content_one, image}) => {
    return <>
        <ContainerContentProjects>
            <DescContentProjects>
                <h2>{title}</h2>
                <p>{content_one}</p>
                <p>{content_two}</p>
            </DescContentProjects>
            <ImageContentProjects>
                <img src={image} />
            </ImageContentProjects>
        </ContainerContentProjects>
    </>
}
