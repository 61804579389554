import React, {FunctionComponent} from "react";
import { Link, useHistory } from 'react-router-dom';
import {ArrowRightIcon} from '../../ui/atoms/arrow-right-icon'
import {WrapperBreadcrumb} from '../styles'
import { ITitle } from "./interface";
export const CompanyBoardPersonBreadcrumb: FunctionComponent<ITitle> = ({title, slug}) => {
    const history = useHistory();

    return <>
        <WrapperBreadcrumb>
            <Link to="/" onClick={() => { history.push('/');}}>Billennium</Link>
            <ArrowRightIcon />
            <Link to="/company" onClick={() => { history.push('/company');}}>Company</Link>
            <ArrowRightIcon />
            <Link to="/company-board" onClick={() => { history.push('/company-board');}}>Board</Link>
            <ArrowRightIcon />
            <Link to={`/company-board/${slug}`} onClick={() => { history.push(`/company-board/${slug}`);}}>{title}</Link>
        </WrapperBreadcrumb>
    </>
}
