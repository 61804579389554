import React, {FunctionComponent, useState} from "react";
import { SoloItem } from "./styles";
import { ISoloFaq } from "./interface";
import ReactHtmlParser from 'react-html-parser';

export const SoloFaq: FunctionComponent<ISoloFaq> = ({title, content}) => {
    const [isOpen, setOpen] = useState(false);

    return <>
        <SoloItem onClick={() => setOpen(prev => !prev)}>
            <span>{title}</span>
            {isOpen &&<p>{ReactHtmlParser(content ? content : '')}</p>}
        </SoloItem>
    </>
}
