import styled from "styled-components";
import {media} from "../../atoms/theme";
import IconCertificate1 from "../../../../assets/icons/cert1.png";
import { IImage } from "./interface";

export const WrapperIconsCertigicates = styled.div`

  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
  margin-bottom: 36px;
  justify-content: flex-start;
  ${media.up('md')} {
    justify-content: flex-start;
    margin-top: 200px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
  }
`
export const IconCertigicate = styled.div<IImage>`
  background-image: url("${(props) => props.image}");
  background-repeat: no-repeat;
  width: 111px;
  height: 111px;
  background-size: 111px;
  margin-right: 5px;
  margin-top: 5px;
  ${media.up('md')} {
    margin: 0;
    width: 183px;
    height: 183px;
    background-size: 183px;
  }

`
