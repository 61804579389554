import React, {FunctionComponent} from "react";
import { WrapperJobOfferLogos, Content, List } from "./styles";
import { JobOfferApply } from "../job-offer-apply/job-offer-apply";
import logo_1 from '../../../../assets/images/job-offer/net.png'
import logo_2 from '../../../../assets/images/job-offer/salesforce.png'
import logo_3 from '../../../../assets/images/job-offer/azure.png'
import logo_4 from '../../../../assets/images/job-offer/php.png'
import logo_5 from '../../../../assets/images/job-offer/google.png'
import logo_6 from '../../../../assets/images/job-offer/angular.png'
import { IJobContent } from "./interface";
import generateLink from "../../../../core/api/assets";

export const JobOfferLogos: FunctionComponent<IJobContent> = ({content, technologies, team, level, location, link}) => {
    return <>
        <WrapperJobOfferLogos>
            <List>
                {technologies.map(item => (
                    <img alt={item.data.title} src={generateLink(item.data.image)} />
                ))}
            </List>
            <Content>
                <p>{content}</p>
            </Content>
            <JobOfferApply 
                team={team}
                level={level}
                location={location}
                link={link}
            />
        </WrapperJobOfferLogos>
    </>
}
