import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import {WrapperCareerJobs, WrapperItems, SoloItem} from './styles'
import { ICareerJobs } from "./interface";
import { ActionArrowAtom } from "../../atoms/action-arrow";
import { Link, useHistory } from 'react-router-dom';
import { useGlobalLang } from '../../molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export const CareerJobs: FunctionComponent<ICareerJobs> = ({title, items, url, text_link}) => {
    const history = useHistory();
    const { t } = useTranslation()
    const [allJobs, setAllJobs] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [teams, setTeams] = useState([]);
    const [levels, setLevels] = useState([]);
    const [locations, setLocations] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [pageNumber, setPageNumber] = useState(0)

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection-job_offers`)
        .then(response => {
            setAllJobs(response.data);
            setJobs(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/job-offers/teams`)
        .then(response => {
            setTeams({...response.data});
        }) 
        .catch(err => {
            console.log(err);
        })
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/job-offers/levels`)
        .then(response => {
            setLevels({...response.data});
        }) 
        .catch(err => {
            console.log(err);
        })
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/job-offers/locations`)
        .then(response => {
            setLocations({...response.data});
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    const getNameData = (id, type) => {
        if(type == 'teams'){
            const indexEl = Object.keys(teams).find((keyName) => teams[keyName].id == id)
            if(indexEl) return teams[indexEl].data['title']
        }
        if(type == 'levels'){
            const indexEl = Object.keys(levels).find((keyName) => levels[keyName].id == id)
            if(indexEl) return levels[indexEl].data['title']
        }
        if(type == 'locations'){
            const indexEl = Object.keys(locations).find((keyName) => locations[keyName].id == id)
            if(indexEl) return locations[indexEl].data['title']
        }
    }

    const articlesPerPage = 3
    const pagesVisited = pageNumber * articlesPerPage

    const displayArticles = Object.keys(jobs)
        .slice(pagesVisited, pagesVisited + articlesPerPage)
        .map((keyName) => {
            return (<SoloItem>
                <p>{jobs[keyName].data['title']}</p>
                <ul>
                    <li><strong>Team: </strong>{getNameData(jobs[keyName].data['team'], 'teams')}</li>
                    <li><strong>Location: </strong>{getNameData(jobs[keyName].data['location'], 'locations')}</li>
                    <li><strong>Level: </strong>{getNameData(jobs[keyName].data['level'], 'levels')}</li>
                </ul>
                <Link to={`/job-offer/${jobs[keyName].id}`} onClick={() => { history.push(`/job-offer/${jobs[keyName].id}`);}}>Go to job offer</Link>
            </SoloItem>)
        })

    return <>
        <WrapperCareerJobs>
            <h4>{title}</h4>
            <WrapperItems>
                {displayArticles}
            </WrapperItems>
            {url && text_link &&<ActionArrowAtom link={url} text={text_link} />}
        </WrapperCareerJobs>
    </>
}
