import React, {FunctionComponent} from "react";
import {WrapperUnderBanner,UnderBannerIcon,WrapperOut} from './styles'
import { IUnderBanner } from "./interface";

export const UnderBanner: FunctionComponent<IUnderBanner> = ({content}) => {
    return <>
        <WrapperOut>
            <WrapperUnderBanner>
                <UnderBannerIcon />
                <p>{content}</p>
            </WrapperUnderBanner>
        </WrapperOut>
    </>
}
