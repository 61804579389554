import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerItemsProjects = styled.div`
  display: table;
  box-sizing: border-box;
  margin: 0 0 60px 0;
  width: 100%;
  ${media.down('md')} {
    margin: 60px 0;
    padding: 0 20px;
  }
`

export const HeaderProjects = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  margin-bottom: 161px;
  .img-head-projects{
    float: left;
    width: 110px;
    img{
      width: 100%;
    }
  }
  .content-head-projects{
    float: left;
    margin-left: 85px;
    max-width: 587px;
    h4{
      margin: 0;
      margin-bottom: 20px;
      font-size: 34px;
      line-height: 42px;
      font-weight: 400;
    }
    p{
      margin: 0;
      font-size: 22px;
      line-height: 32px;
      font-weight: 400;
    }
  }
  ${media.down('lg')} {
    position: relative;
    .img-head-projects{
      position: absolute;
      top: 0;
      right: 0;
    }
    .content-head-projects{
      margin: 0;
      width: calc(100% - 150px);
    }
  }
  ${media.down('md')} {
    margin-bottom: 55px;
    .img-head-projects{
      width: 55px;
    }
    .content-head-projects{
      width: auto;
      max-width: 315px;
      h4{
        font-size: 30px;
        line-height: 38px;
        max-width: 230px;
        font-weight: 600;
      }
      p{
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`

export const WrapperItemsProjects = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 22px;
`

export const WrapperSoloProjects = styled.div`
  flex: 1 0 31%;
  max-width: calc(100% / 3);
  margin-bottom: 40px;
  border-bottom: 1px solid #D1D8DB;
  min-height: 75px;
  display: table;
  box-sizing: border-box;
  a{
    font-size: 24px;
    line-height: 34px;
    .wrapper-arrow{
      margin: 10px 32px -10px 0;
    }
  }
  ${media.down('xl')} {
    flex: 1 0 50%;
    max-width: calc(50% - 11px);
  }
  ${media.down('lg')} {
    flex: 1 0 100%;
    max-width: calc(100%);
    a{
      .wrapper-arrow{
        margin: 10px 32px -10px 0;
      }
    }
  }
  ${media.down('md')} {
    min-height: 26px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    a{
      font-size: 22px;
      line-height: 26px;
      .wrapper-arrow{
        margin: 6px 16.5px -6px 0;
      }
    }
  }
`
