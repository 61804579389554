import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container";
import { useTranslation } from "react-i18next";
import {JobOffersBreadcrumb} from "../../breadcrumbs/job-offers/job-offers";
import {JobOffersTop} from './styles';
import {jobs} from "./data";
import { JobOffersAll } from "../../ui/organisms/job-offers-all/job-offers-all";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import getSlug from "../../../core/router/getSlug";
import generateLink from '../../../core/api/assets';

export const JobOffersPage: FunctionComponent = () => {
    const { t } = useTranslation()
    const [content, setContent] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [pageid, setPageId] = useState('');
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection-job_offers`)
        .then(response => {
            setJobs(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    return <>
        <BiContainer >
            <JobOffersBreadcrumb />
            <JobOffersTop>
                <h1>{content['header_-_title']}</h1>
            </JobOffersTop>
            <JobOffersAll
                items={jobs}
            />
            <ContactPersonPopup
                title={content['contact_popup_title']}
                place={content['contact_popup_position']}
                content={content['contact_popup_content']}
                name={content['contact_popup_person_name']}
                image={generateLink(content['contact_popup_avatar'])}
                send_to={content['contact_send_to']}
                pardot_link={content['pardot_link']}
            />
        </BiContainer>
    </>
}
