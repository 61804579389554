import React, {FunctionComponent} from "react";
import { Link, useHistory } from 'react-router-dom';
import {ArrowRightIcon} from '../../ui/atoms/arrow-right-icon'
import {WrapperBreadcrumb} from '../styles'
import { ICompany } from "./interface";

export const CompanyCultureBreadcrumb: FunctionComponent<ICompany> = ({title, slug}) => {
    const history = useHistory();

    return <>
        <WrapperBreadcrumb>
            <Link to="/" onClick={() => { history.push('/');}}>Billennium</Link>
            <ArrowRightIcon />
            <Link to="/company" onClick={() => { history.push('/company');}}>Company</Link>
            <ArrowRightIcon />
            <Link to={slug ? '/' + slug : '/company'} onClick={() => { history.push(slug ? '/' + slug : '');}}>{title ? title : ''}</Link>
        </WrapperBreadcrumb>
    </>
}
