import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerItems = styled.div`
  display: table;
  box-sizing: border-box;
  margin: 0 0 160px 0;
  width: 100%;
  h4{
    font-size: 46px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 40px 0;
  }
  p{
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;   
    margin: 0;
    max-width: 613px;
  }
  a{
    margin-top: 40px;
  }
  ${media.down('lg')} {
    margin: 0 0 60px 0;
    h4{
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 20px;
    }
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperItems = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: stretch;
  gap: 60px 22px;
  margin: 120px 0 0 0;
  ${media.down('lg')} {
    gap: 24px 10px;
    margin: 0 0 40px 0;
  }
`

export const WrapperSoloItem = styled.div`
  flex: 1 0 calc(100% / 3);
  max-width: calc((100% / 3) - 22px);
  min-width: calc((100% / 3) - 22px);
  display: table;
  box-sizing: border-box;
  border-left: 6px solid ${(props) => props.theme.colors.primaryOrange};
  padding-left: 50px;
  span{
    font-size: 80px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -0.015em;
    text-align: left;
    margin: 0;
    display: block;
    width: 100%;
  }
  p{
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0;
  }
  ${media.down('lg')} {
    flex: 1 0 calc(100% / 2);
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
    height: 71px;
    padding-left: 20px;
    span{
      font-size: 40px;
      line-height: 40px;
    }
    p{
      font-size: 16px;
      line-height: 20px;
    }
  }
`