import { useState, useEffect } from 'react';
import { breakpoints } from '../../theme';

export const useBreakpoints = () => {
  const [width, setWidth] = useState(document.body.clientWidth);

  useEffect(() => {
    const handleResize = () => setWidth(document.body.clientWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const sortedBreakpoints = breakpoints.sort((a, b) => a.breakpoint - b.breakpoint);
  let size = sortedBreakpoints.find(x => width < x.breakpoint);
  size = size ? size : sortedBreakpoints[sortedBreakpoints.length - 1]
  return {
    size: size,
    isXXLarge: size.name === 'xxl',
    isXLarge: size.name === 'xl',
    isLarge: size.name === 'lg',
    isMedium: size.name === 'md',
    isSmall: size.name === 'sm',
    isXSmall: size.name === 'xs',
    width,
  };
};

export { breakpoints }
