import React, {FunctionComponent} from "react";
import { ContainerAbout, SoloGif, Content, ContentIn, Image } from "./styles";
import { ActionArrowAtom } from "../../atoms/action-arrow";
import { ICompanyAbout } from "./interface";

export const CompanyAbout: FunctionComponent<ICompanyAbout> = ({title, content, link, link_text, image}) => {
    return <>
        <ContainerAbout>
            <Image>
                <img alt="Animation" src={image} />
            </Image>
            <Content>
                <ContentIn>
                    <h4>{title}</h4>
                    <SoloGif>
                        <img alt="Animation" src={image} />
                    </SoloGif>
                    <p>{content}</p>
                    <ActionArrowAtom link={link} text={link_text} />
                </ContentIn>
            </Content>
        </ContainerAbout>
    </>
}
