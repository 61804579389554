import React, {FunctionComponent} from "react";
import {WrapperTechnology, WrapperTechnologyList, SoloTechnology} from "./styles";
import { ITechnology } from './interface'
import generateLink from "../../../../core/api/assets";
import { Link } from "react-router-dom";


export const Technology: FunctionComponent<ITechnology> = ({title, content, items}) => {

    return <>
        <WrapperTechnology>
            <h2>{title}</h2>
            <p className="top-content">{content}</p>
            <WrapperTechnologyList>
                {Object.keys(items).map((keyName, i) => (
                    <SoloTechnology>
                        {items[keyName].data['link'] ?
                        <Link to={items[keyName].data['link']}>
                            <div className="technology-text">
                                <p>{items[keyName].data['title']}</p>
                            </div>
                            <div className="technology-icon">
                                <img  alt="tech1" src={generateLink(items[keyName].data['image'])} />
                            </div>
                        </Link> :
                        <div>
                            <div className="technology-text">
                                <p>{items[keyName].data['title']}</p>
                            </div>
                            <div className="technology-icon">
                                <img  alt="tech1" src={generateLink(items[keyName].data['image'])} />
                            </div>
                        </div>}
                    </SoloTechnology>
                ))}
            </WrapperTechnologyList>
        </WrapperTechnology>
    </>
}
