import React, {FunctionComponent} from "react";
import { Container, Half } from "./styles";
import { IHeaderHistory } from "./interface";

export const HeaderHistory: FunctionComponent<IHeaderHistory> = ({title, content}) => {
    return <>
        <Container>
            <Half>
                <h1>{title}</h1>
                <p>{content}</p>
            </Half>
        </Container>
    </>
}
