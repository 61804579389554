import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperSocial = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  p {
    display: none;

  }

  ${media.up('md')} {
    p {
      display: block;
      padding-right: 26.5px;
    }

  }
`
