import styled from "styled-components";
import {media} from "../../atoms/theme";
import CaseStadiesMobile from "../../../../assets/images/case-stadies.jpg";
import LableStydiesFinaceMobile from "../../../../assets/icons/lable-stydies-finace-mobile.png";
import CaseStadies from "../../../../assets/images/case-stadies.jpg";
import LableStydiesFinace from "../../../../assets/icons/lable-stydies-finace.png";
import { IImage } from "./interface";

export const ContainerCaseStudies = styled.div`
  margin: 0 0 60px 0;
  h2{
    margin: 0 auto 60px 0;
  }
  ${media.up('md')} {
    margin: 0 0 160px 0;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const WrapperItemStudies = styled.div`

`
export const ItemStudies = styled.div`
  display: flex;
  margin-top: 0;
  flex-direction: column;
  align-items: center;

  ${media.up('lg')} {
    flex-direction: row;
    margin-top: 0;
    margin-bottom: 80px;
  }

`
export const ItemStudiesImg = styled.div<IImage>`
  position: relative;
  width: 335px;
  height: 210px;
  background-image: url("${(props) => (props.image) ?  props.image : CaseStadiesMobile}");
  background-repeat: no-repeat;
  background-size: cover;

  span{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    border-radius: 10px;
    background: #ffffff;
    display: block;
    bottom: -20px;
    right: 40px;
    width: 140px;
    text-align: center;
    img{
      display: inline-block;
      margin-bottom: -7px;
      height: 46px;
    }
  }

  ${media.up('lg')} {
    background-image: url("${(props) => (props.image) ?  props.image : CaseStadies}");
    width: 387px;
    height: 387px;
    span{
      right: -20px;
      bottom: calc(50% - 35px);
      img{
        height: 70px;
      }
    }
  }
`

export const ItemStudiesDescription = styled.div`
  padding: 25px 0;

  ${media.up('md')} {
    max-width: 692px;

    p {
      max-width: 542px;
    }
  }

  ${media.up('lg')} {
    margin-left: 120px;
  }

  ${media.up('xl')} {
    max-width: unset;
  }

  .title-studies {
    color: black;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    margin-top: 5px;
    margin-bottom: 10px;

    ${media.up('md')} {
      font-size: 34px;
      line-height: 42px;
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }

  .title-division {
    margin: 0;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;

    ${media.up('md')} {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .two-section {
    display: none;

    ${media.up('md')} {
      display: flex;
      flex-direction: row;
      justify-content: start;
      margin-bottom: 40px;
      gap: 0 10px;
    }
  }

  .label-section {
    margin: 0;
  }

  .list-elements {
    display: flex;
    flex-direction: row;

    a {
      text-decoration: underline;
      margin-right: 10px;
      margin-top: 10px;
      margin-bottom: 0;
      color: #25282B;
    }
  }

  .description {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`
