import React, {FunctionComponent} from "react";
import { Container, Content, SoloContent } from "./styles";
import person_1 from '../../../../assets/images/company-board/person/person_1.png'
import { IContent } from "./interface";

export const PersonContent: FunctionComponent<IContent> = ({title_1, title_2, title_3, title_4, content_1, content_2, content_3, content_4, image}) => {
    return <>
        <Container>
            <img alt="Person" src={image} />
            <Content>
                <SoloContent>
                    <h4>{title_1}</h4>
                    <p>{content_1}</p>
                </SoloContent>
                <SoloContent>
                    <h4>{title_2}</h4>
                    <p>{content_2}</p>
                </SoloContent>
                <SoloContent>
                    <h4>{title_3}</h4>
                    <p>{content_3}</p>
                </SoloContent>
                <SoloContent>
                    <h4>{title_4}</h4>
                    <p>{content_4}</p>
                </SoloContent>
            </Content>
        </Container>
    </>
}
