import React, {FunctionComponent} from "react";
import { Content, SoloContent, ImageContent, Text } from "./styles";
import { IPress } from "./interface";
import HTMLReactParser from "html-react-parser";

export const PressContent: FunctionComponent<IPress> = ({content_1, content_2, content_3, image_2}) => {
    return <>
        <Content>
            <SoloContent>
                <p>{HTMLReactParser(content_1 ? content_1 : '')}</p>
            </SoloContent>
            <ImageContent>
                <img alt="Banner" src={image_2} />
                <Text>
                    <p>{HTMLReactParser(content_2 ? content_2 : '')}</p>
                </Text>
            </ImageContent>
            <SoloContent>
                <p>{HTMLReactParser(content_3 ? content_3 : '')}</p>
            </SoloContent>
        </Content>
    </>
}
