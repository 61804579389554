import React, {FunctionComponent} from "react";
import { Container } from "./styles";
import { ISoloStudyBanner } from "./interface";

export const SoloStudyBanner: FunctionComponent<ISoloStudyBanner> = ({banner}) => {
    return <>
        <Container>
            <img src={banner} />
        </Container>
    </>
}
