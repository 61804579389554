import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperHeaderCaseStudies = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100%;
  margin: 60px 0 0 0;
  border-bottom: 6px solid ${(props) => props.theme.colors.primaryOrange};
  padding-bottom: 60px;
  h1{
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -0.015em;
    text-align: left;
    margin: 0 0 20px 0;
  }
  p{
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    max-width: 692px;
    color: #25282B;
  }
  ${media.down('md')} {
    padding-bottom: 20px;
    h1{
      font-size: 36px;
      line-height: 48px;
      margin: 0 0 10px 0;
    }
    p{
      font-size: 18px;
      line-height: 26px;
    }
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const PositionBoard = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100%;
  p{
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0;
    float: left;
  }
  ${media.down('lg')} {
    p{
      float: none;
      width: 100%;
    }
  }
  ${media.down('md')} {
    p{
      font-size: 24px;
      line-height: 30px;
    }
  }
`
export const Socials = styled.div`
  display: table;
  box-sizing: border-box;
  float: right;
  height: 40px;
  span{
    widht: auto;
    height: 40px;
    float: left;
    display: block;
    margin: 0 11px 0 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: right;
  }
  img{
    widht: 32px;
    height: 32px;
    display: block;
    float: left;
    margin: 0 0 0 20px;
  }
  ${media.down('lg')} {
    margin-top: 20px;
    float: none;
    width: 100%;
  }
  ${media.down('md')} {
    p{
      margin: 0;
    }
  }
`