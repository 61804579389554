import React, {FunctionComponent} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow/action-arrow-atom";
import { ContainerItems, WrapperSoloItem, WrapperItems, ImageWrapper, ContentWrapper, Title } from "./styles";
import { IBoardList } from "./interface";
import { Link } from "react-router-dom";
import generateLink from "../../../../core/api/assets";

export const PersonList: FunctionComponent<IBoardList> = ({items, title}) => {
    return <>
        <ContainerItems>
            <h4>{title}</h4>
            <WrapperItems>
                {Object.keys(items).map((keyName, i) => (
                    <WrapperSoloItem>
                        <Link to={`/company-board/${items[keyName].slug}`}>
                            <ImageWrapper>
                                <img alt="Person Image" src={generateLink(items[keyName].data.image)} />
                            </ImageWrapper>
                        </Link>
                        <ContentWrapper>
                            <Title>
                                <p>{items[keyName].data.title}</p>
                                <span>{items[keyName].data.position}</span>
                            </Title>
                            <ActionArrowAtom link={`/company-board/${items[keyName].slug}`} text="more info" />
                        </ContentWrapper>
                    </WrapperSoloItem>
                ))}
            </WrapperItems>
        </ContainerItems>
    </>
}
