import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerNews = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.up('md')} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 160px;
  }
`
export const ItemNews = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 335px;
  max-width: 335px;
  min-height: 160px;
  background: ${(props) => props.theme.colors.lightGray};
  padding: 20px;
  margin: 10px 0;

  &:hover {
    color: ${(props) => props.theme.colors.primaryOrange};
  }

  p {
    margin: 0 0 20px;
    font-weight: 600;
    font-size: 22px;

  }

  ${media.up('md')} {
    padding: 30px;
    min-width: 387px;
    max-width: 387px;
    min-height: 206px;
  }

`
export const SeeMoreWrapper = styled.div`
  margin-left: auto;
`
