import React, {FunctionComponent} from "react";
import { ContainerTiger, List, Content, SoloItem} from "./styles";
import { ActionArrowAtom } from "../../atoms/action-arrow";
import { ICompanyTiger } from "./interface";

export const CompanyTiger: FunctionComponent<ICompanyTiger> = ({title, content, link, link_text, letters}) => {
    return <>
        <ContainerTiger>
            <Content>
                <h4>{title}</h4>
                <p>{content}</p>
                <ActionArrowAtom link={link} text={link_text} />
            </Content>
            <List>
                {Object.keys(letters).map((keyName, i) => (
                    <SoloItem>
                        <p>{letters[keyName].data['title']}</p>
                        <span>{letters[keyName].data['undertitle']}</span>
                    </SoloItem>
                ))}
            </List>
        </ContainerTiger>
    </>
}
