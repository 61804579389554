import React, {FunctionComponent} from "react";
import {WrapperOurTeams} from './styles'
import { IOurTeams } from "./interface";
import stats from '../../../../assets/images/innovation-lab/loader.png'
import stats_mobile from '../../../../assets/images/stats_mobile.png'

export const OurStats: FunctionComponent<IOurTeams> = ({title, content}) => {
    return <>
        <WrapperOurTeams>
            {title &&<h4>{title}</h4>}
            {content &&<p>{content}</p>}
            <img alt="Banner" src={stats} />
            <img alt="Banner" className="mobile" src={stats_mobile} />
        </WrapperOurTeams>
    </>
}
