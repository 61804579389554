import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import {WrapperTabsMap, WrapperMap, WrapperMainMap} from './styles'
import { AboutBilleniumCountry } from "../../organisms/about-billenium-country";
import { ImgBackgroundInside } from "../../atoms/banner-inside/styles";
import { PercentValuesCountry } from "../percent-values-country/percent-values-country";
import { OfficesAddresses } from "../../organisms/offices-addresses";
import { TeamsInRegion } from "../../organisms/teams-in-region";
import { ContactCv } from "../contact-cv";
import { ILocation } from "./interface";
import { useGlobalLang } from '../lang-header/useGlobalLang';

export const SoloLocation: FunctionComponent<ILocation> = ({id, countryTitle, descriptionCountry, map, banner, value_1, value_2, value_3, title_1, title_2, title_3, office_title, teams_title, contact_content, contact_title}) => {
    const [offices, setOffices] = useState([]);
    const [teams, setTeams] = useState([]);
    const [lang, setLang] = useGlobalLang();

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/offices/location/${id}`)
        .then(response => {
            setOffices(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/teams/location/${id}`)
        .then(response => {
            setTeams(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    return<>
        <WrapperMap>
            <AboutBilleniumCountry 
                countryTitle={countryTitle}
                descriptionCountry={descriptionCountry}
            />
            <WrapperMainMap>
                <img alt="PolandMapImg" src={map}/>
            </WrapperMainMap>
            <ImgBackgroundInside 
                BackgroundDesktop={banner}
                BackgroundMobile={banner}
            />
            <PercentValuesCountry
                value_1={value_1}
                title_1={title_1}
                value_2={value_2}
                title_2={title_2}
                value_3={value_3}
                title_3={title_3}
            />
            <OfficesAddresses 
                title={office_title}
                addresses={offices}
            />
            <TeamsInRegion
                title={teams_title} 
                teamsInRegion={teams}
            />
            <ContactCv
                title={contact_title}
                content={contact_content}
            />
        </WrapperMap>
    </>
}
