import React, {FunctionComponent} from "react";
import {IconFacebookAtom} from "../../atoms/icon-facebook/icon-facebook-atom";
import {IconLinkedinAtom} from "../../atoms/icon-linkdin";
import {IconInstagramAtom} from "../../atoms/icon-instagram";
import {WrapperSocial} from './styles'


export const SocialFooterMolecul: FunctionComponent = () => {
    return <>
        <WrapperSocial>
            <p>Our social media: </p>
            <a target="blank" href="https://www.instagram.com/billennium_com/">
                <IconInstagramAtom/>
            </a>
            <a target="blank" href="https://www.facebook.com/billenniumcom">
                <IconFacebookAtom/>
            </a>
            <a target="blank" href="https://www.linkedin.com/company/billennium/">
                <IconLinkedinAtom/>
            </a>
        </WrapperSocial>
    </>
}
