import React, {FunctionComponent} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow/action-arrow-atom";
import { ContainerItemsTeam, WrapperSoloTeam, WrapperItemsTeam } from "./styles";
import { ITeams } from "./interface";
import { SoloTeam } from "../../molecules/solo-team/solo-team";

export const Teams: FunctionComponent<ITeams> = ({items, link_text, link, title, content, modals}) => {
    return <>
        <ContainerItemsTeam>
            {title &&<h4>{title}</h4>}
            {content &&<p>{content}</p>}
            <WrapperItemsTeam>
                {Object.keys(items).map((keyName, i) => (
                    <WrapperSoloTeam>
                        <ActionArrowAtom  link={items[keyName].data['link'] ? items[keyName].data['link'] : ""} text={items[keyName].data['title']} hover={modals}/>
                    </WrapperSoloTeam>
                ))}
            </WrapperItemsTeam>
            {link_text && link &&<ActionArrowAtom link={link} text={link_text}/>}
        </ContainerItemsTeam>
    </>
}
