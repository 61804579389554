import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperPartnerShip = styled.div`
  width: 100%;
  margin-bottom: 160px;
  h1{
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left; 
    margin: 30px 0 0 0;
  }
  p.top-content{
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    max-width: 692px;
    margin: 0 0 80px 0;
  }

  ${media.down('lg')} {
    margin-bottom: 60px;
    h1{
      font-size: 30px;
      line-height: 38px;
      margin: 20px 0 0 0;
    }
    p.top-content{
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperPartnerShipList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin-top: 51px;
  gap: 20px;
  ${media.down('lg')} {
    margin-top: 40px;
    gap: 10px;
  }
`

export const SoloPartnerShip = styled.div`
  flex: 1 0 31%;
  max-width: 31%;
  margin-bottom: 16px;
  background: #EDEFF1;
  border-radius: 5px;
  a{
    display: block;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }
  img{
      width: 100%;
  }
  ${media.down('lg')} {
    flex: 1 0 50%;
    max-width: calc((100% / 2) - 10px);
  }
`
