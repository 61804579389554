import styled from "styled-components";
import {media} from "../../atoms/theme";
import ArrowIcon from "../../../../assets/icons/small-down-arrow.svg";
import ArrowIconOrange from "../../../../assets/icons/small-down-arrow-orange.svg";

export const WrapperLinksHeader = styled.div`
  height: 40px;
  float: left;
  margin: 20px 0;
  display: none;
  ul{
    display: inline-block;
    padding: 0;
    margin: 0;
    li{
        list-style: none;
        float: left;
        line-height: 40px;
        margin-right: 30px;
        a{
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          letter-spacing: 0em;   
          color: #000;   
          text-decoration: none;
          transition: color 0.2s;
          position: relative;
          cursor: pointer;
          .dropdown-icon{
            content: url("${ArrowIcon}");
            margin-left: 6px;
            font-size: 16px;
            margin-top: 2.5px;
            margin-bottom: -2.5px;
            width: 16px;
            height: 16px;
          }
          &:hover{
            color: ${(props) => props.theme.colors.primaryOrange};
            transition: color 0.2s;
            .dropdown-icon{
              content: url("${ArrowIconOrange}");
            }
          }
        }
        .dropdown-list{
          position: absolute;
          z-index: 20;
          min-width: 378px;
          height: auto;
          box-sizing: border-box;
          display: table;
          padding: 30px 33px;
          background: #FFFFFF;
          box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);
          a{
            box-sizing: border-box;
            display: table;
            min-width: 100%;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 4px;
          }
          .dropdown-list-title{
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.02em;
            margin-bottom: 20px;
          }
          ${media.down('lg')} {
            min-width: 100%;
          }
        }
    }
  }

  ${media.up('nav')} {
    display: table;
  }
`
