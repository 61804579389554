import React, {FunctionComponent} from "react";
import {WrapperLogoPaperless } from "./styles";
import {ILogoPaperless} from './interface'

export const LogoPaperless: FunctionComponent<ILogoPaperless> = ({image}) => {
    return <>
        <WrapperLogoPaperless>
            <img src={image} />
        </WrapperLogoPaperless>
    </>
}
