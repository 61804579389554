import React, {FunctionComponent, useState} from "react";
import {WrapperSoloGroup, SoloGroupLogo, Content, SoloGroupContent, SoloGroupLink, SoloLink, WrapperGroupPopup, WrapperGroupPopupContent, WrapperGroupPopupImage, WrapperGroupPopupContentIn} from "./styles";
import { BISoloGroup } from './interfaces'
import { ActionArrowAtom } from "../../atoms/action-arrow";
import Banner from '../../../../assets/images/company-group/modal.png'

export const SoloGroup: FunctionComponent<BISoloGroup> = ({title, content, image}) => {
    const [isPopupOpen, setOpenPopup] = useState(false);

    return <>
        <WrapperSoloGroup>
            <SoloGroupLogo>
                <img alt="Solo Group Logo" src={image} />
            </SoloGroupLogo>
            <Content>
                <SoloGroupContent>
                    <h5>{title}</h5>
                    <p>{content}</p>
                </SoloGroupContent>
                <SoloGroupLink>
                    <SoloLink onClick={() => setOpenPopup(prev => !prev)}>
                        <ActionArrowAtom link={""} text="View details" />
                    </SoloLink>
                    <SoloLink>
                        <ActionArrowAtom link={""} text="Website" />
                    </SoloLink>
                </SoloGroupLink>
            </Content>
        </WrapperSoloGroup>
        {isPopupOpen &&<WrapperGroupPopup>
            <div className="close-icon" onClick={() => setOpenPopup(prev => !prev)}></div>
            <WrapperGroupPopupContent>
                <WrapperGroupPopupImage>
                    <img alt="Banner" src={Banner} />
                </WrapperGroupPopupImage>
                <WrapperGroupPopupContentIn>
                    <img alt="Group Content image" src={image} />
                    <h6>{title}</h6>
                    <ul>
                        <li>NIP <strong>67821838123</strong></li>
                        <li>KRS <strong>012367821838123</strong></li>
                        <li>Kapitał zakładowy <strong>1 324 000 PLN</strong></li>
                        <li>Regon <strong>67821838123</strong></li>
                        <li>Address <strong>Aleja Niepodległości 167, 00,001 Warszawa</strong></li>
                        <li>Website <strong>www.bilgool.com</strong></li>
                        <li>NIP <strong>67821838123</strong></li>
                        <li>NIP <strong>67821838123</strong></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed tincidunt augue. Ut eu gravida neque, placerat porta nisi. In dictum feugiat tellus eget accumsan. Cras ullamcorper pharetra vestibulum.<br /><br />Sed vehicula, lectus vel rhoncus blandit, leo nunc porta orci, et finibus erat nibh nec libero. Phasellus suscipit sagittis massa, sed egestas ligula finibus sed. Pellentesque a pulvinar justo. Integer felis ex, ultricies eget</p>
                    <a onClick={() => setOpenPopup(prev => !prev)}>Zamknij</a>
                </WrapperGroupPopupContentIn>
            </WrapperGroupPopupContent>
        </WrapperGroupPopup>}
    </>
}