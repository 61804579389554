import React, {FunctionComponent} from "react";
import { Link, useHistory } from 'react-router-dom';
import {ArrowRightIcon} from '../../ui/atoms/arrow-right-icon'
import {WrapperBreadcrumb} from '../styles'
import { ICaseStudy } from "./interface";

export const CaseStudiesDetailsBreadcrumb: FunctionComponent<ICaseStudy> = ({title, slug}) => {
    const history = useHistory();

    return <>
        <WrapperBreadcrumb>
            <Link to="/" onClick={() => { history.push('/');}}>Billennium</Link>
            <ArrowRightIcon />
            <Link to="/case-studies" onClick={() => { history.push('/case-studies');}}>Case studies</Link>
            <ArrowRightIcon />
            <Link to={`/${slug}`} onClick={() => { history.push(`/${slug}`);}}>{title}</Link>
        </WrapperBreadcrumb>
    </>
}
