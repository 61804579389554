import styled from "styled-components";
import {media} from "../../atoms/theme";

export const Wrapper = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    background: #EDEFF1;
    padding: 120px 0;
    text-align: center;
    h4{
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: center;
        color: #25282B;
        margin: 0 0 21px 0;
    }
    p{
        display: inline-block;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
        color: #000000;
        margin: 0 0 21px 0;
        max-width: 613px;
    }
    a{
        padding: 19px 26px;
        background: #FF5A00;
        color: #ffffff;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-align: center;
        display: inline-block;
        border-radius: 5px;
    }
    ${media.down('lg')} {
        padding: 50px 20px 40px 20px;
        h4{
            font-size: 24px;
            line-height: 30px;
            margin: 0 0 10px 0;
        }
        p{
            max-width: 335px;
            margin: 0 0 20px 0;
            font-size: 16px;
            line-height: 24px;
        }
        a{
            padding: 11.5px 20px;
            font-size: 16px;
            line-height: 22px;
        }
    }
`

export const Input = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    margin-bottom: 21px;
    input{
        width: 346px;
        height: 60px;
        background: #ffffff;
        color: #283237;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: left;
        padding: 0 20px;
        border: 1px solid #A4A6A0;
        border-radius: 6px;
    }
    ${media.down('lg')} {
        margin-bottom: 20px;
        input{
            width: 285px;
            height: 40px;
            font-size: 15px;
        }
    }
`