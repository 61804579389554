import styled from "styled-components";
import { media } from "../../atoms/theme/media";

export const WrapperHomeCompetences = styled.div`
    display: table;
    box-sizing: border-box;
    width: 100%;
    margin: 0 0 40px 0;
    ${media.down('md')} {
        padding: 0 20px;
    }
`

export const DescCompetences = styled.div`
    display: table;
    box-sizing: border-box;
    width: 100%;
    h4{
        font-size: 46px;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0 0 40px 0;
        max-width: 631px;
    }
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0 0 120px 0;
        max-width: 692px;
    }
    ${media.down('lg')} {
        h4{
            font-size: 30px;
            line-height: 38px;
            margin: 0 0 20px 0;
        }
        p{
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 60px 0;
        }
    }
`

export const SoloHomeCompetences = styled.div`
    display: table;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 120px;
    .gif-wrapper{
        width: 387px;
        float: left;
        margin-right: 119px;
        img{
            width: 100%;
        }
    }
    .content-competences{
        max-width: 650px;
        display: table;
        box-sizing: border-box;
        h4{
            margin: 0;
            margin-bottom: 30px;
            font-size: 34px;
            line-height: 42px;
            font-weight: 400;
        }
        p{
            margin: 0;
            margin-bottom: 30px;
            font-size: 18px;
            line-height: 26px;
            font-weight: 400;
        }
        ul{
            list-type: dots;
            display: table;
            box-sizing: border-box;
            margin: 0;
            margin-bottom: 30px;
            padding-left: 20px;
            li{
                margin: 0;
                width: 50%;
                float: left;
                font-size: 18px;
                line-height: 26px;
                font-weight: 400;
            }
        }
    }
`