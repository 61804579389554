import React, {FunctionComponent} from "react";
import { WrapperActionArrow } from "./styles";
import { Link } from 'react-router-dom';
import {BIActionArrowProps} from "./interfaces";
export const ActionArrowAtom: FunctionComponent<BIActionArrowProps> = ({text,link,white, hover}) => {
    return <>
        <WrapperActionArrow white={white} hover={hover}>
            {link !== '' ? <Link to={link}>
                <div className="wrapper-arrow">
                    <div className="sliding-arrow"></div>
                </div>
               <span className="text-link">{text}</span>
            </Link> :
            <a>
                <div className="wrapper-arrow">
                    <div className="sliding-arrow"></div>
                </div>
                <span className="text-link">{text}</span>
            </a>
            }
        </WrapperActionArrow>
    </>
}
