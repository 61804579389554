import React, {FunctionComponent, useState} from "react";
import { Wrapper, WrapperSoloItem, Image, Content, Title, Info, ContentIn, WrapperItems } from "./styles";
import { IBlogList } from "./interface";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import thumb from '../../../../assets/images/background-saleforce-mobile.png'
import generateLink from "../../../../core/api/assets";

export const BlogList: FunctionComponent<IBlogList> = ({title, items}) => {
    const [pageNumber, setPageNumber] = useState(0)

    const articlesPerPage = 7
    const pagesVisited = pageNumber * articlesPerPage

    const displayArticles = items
        .slice(pagesVisited, pagesVisited + articlesPerPage)
        .map((article) => {
            return (<WrapperSoloItem>
                <Image>
                    <Link to={`/blog/${article.slug}`}>
                        <img src={generateLink(article.data.thumb)} />
                    </Link>
                </Image>
                <Content>
                    <Link to={`/blog/${article.slug}`}>
                        <Title>
                            <p><Moment format="DD.MM.YYYY">{article.created_at}</Moment></p>
                            <h5><Link to={`/blog/${article.slug}`}>{article.data.title}</Link></h5>
                        </Title>
                        <Info>
                            <p>{article.data.category}</p>
                            <span></span>
                            <p>read in {article.data.time_to_read} minutes</p>
                        </Info>
                        <ContentIn>
                            <p>{(article.data.intro) ? `${article.data.intro.substring(0, 130)} ...` : article.data.intro}</p>
                        </ContentIn>
                    </Link>
                </Content>
            </WrapperSoloItem>)
        })

    const pageCount = Math.ceil(items.length / articlesPerPage)

    const changePage = ({selected}) => {
        setPageNumber(selected)
        window.scrollTo(0, 0);
    }
    
    return <>
        <Wrapper>
            {title &&<h4>{title}</h4>}
            <WrapperItems>
                {displayArticles}
                <ReactPaginate
                    previousLabel={"< Poprzednia"}
                    nextLabel={"Następna >"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={'activeBttn'}
                />
            </WrapperItems>
        </Wrapper>
    </>
}
