import React, {FunctionComponent} from "react";
import { Wrapper, Title, Footer, Info, Socials } from "./styles";
import { IArticleTitle } from "./interface";
import twitter from '../../../../assets/icons/article-twitter.svg'
import linkedin from '../../../../assets/icons/article-linkedin.svg'
import facebook from '../../../../assets/icons/article-facebook.svg'

export const ArticleTitle: FunctionComponent<IArticleTitle> = ({category, title, author, author_position}) => {    
    return <>
        <Wrapper>
            <Title>
                <h2>{category}</h2>
                <h1>{title}</h1>
            </Title>
            <Footer>
                <Info>
                    <p className="author">{author}</p>
                    <p className="position">{author_position}</p>
                </Info>
                <Socials>
                    <p>Share</p>
                    <a>
                        <img src={facebook} />
                    </a>
                    <a>
                        <img src={linkedin} />
                    </a>
                    <a>
                        <img src={twitter} />
                    </a>
                </Socials>
            </Footer>
        </Wrapper>
    </>
}
