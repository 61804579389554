import React, {FunctionComponent} from "react";
import {ContainerHeadInperly } from "./styles";
import {HeadDescriptionInperly} from "../../molecules/head-description-inperly/head-description-inperly";
import {LogoInperly} from "../../molecules/logo-inperly/logo-inperly";
import {BIInperlyDesc} from "./interfaces";
export const HeadInperly: FunctionComponent<BIInperlyDesc> = ({title, under_title, image}) => {
    return <>
        <ContainerHeadInperly >
            <HeadDescriptionInperly 
                title={title}
                under_title={under_title}
            />
            <LogoInperly
                image={image}
            />
        </ContainerHeadInperly>
    </>
}