import React, {FunctionComponent} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow/action-arrow-atom";
import { ContainerItemsProjects, WrapperSoloProjects, WrapperItemsProjects, HeaderProjects } from "./styles";
import { IProjects } from "./interface";

export const Projects: FunctionComponent<IProjects> = ({items, image, title, content}) => {
    return <>
        <ContainerItemsProjects>
            <HeaderProjects>
                <div className="img-head-projects">
                    <img alt="Head Projects" src={image} />
                </div>
                <div className="content-head-projects">
                    <h4>{title}</h4>
                    <p>{content}</p>
                </div>
            </HeaderProjects>
            <WrapperItemsProjects>
                {Object.keys(items).map((keyName, i) => (
                    <WrapperSoloProjects>
                        <ActionArrowAtom  link={items[keyName].data['link']} text={items[keyName].data['title']}/>
                    </WrapperSoloProjects>
                ))}
            </WrapperItemsProjects>
        </ContainerItemsProjects>
    </>
}
