import React, {FunctionComponent} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow/action-arrow-atom";
import { ContainerItems, WrapperSoloItem, WrapperItems, ImageWrapper, ContentWrapper, Title, ContactWrap, Content } from "./styles";
import { IBoardList } from "./interface";
import generateLink from "../../../../core/api/assets";

export const BoardList: FunctionComponent<IBoardList> = ({items}) => {
    console.log(items);

    return <>
        <ContainerItems>
            <WrapperItems>
                {Object.keys(items).map((keyName, i) => (
                    <WrapperSoloItem>
                        <ImageWrapper>
                            <img alt="Board Img" src={generateLink(items[keyName].data.image)} />
                        </ImageWrapper>
                        <ContentWrapper>
                            <Title>
                                <h4>{items[keyName].data.title}</h4>
                                <span>{items[keyName].data.position}</span>
                            </Title>
                            <ContactWrap>
                                <a href={items[keyName].data.linkedin_profile} target="_blank">Connect with me on</a>
                            </ContactWrap>
                            <Content>
                                <p>{items[keyName].data.intro}</p>
                            </Content>
                            <ActionArrowAtom link={`/company-board/${items[keyName].slug}`} text="more info" />
                        </ContentWrapper>
                    </WrapperSoloItem>
                ))}
            </WrapperItems>
        </ContainerItems>
    </>
}
