import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerItems = styled.div`
  display: table;
  box-sizing: border-box;
  margin: 150px 0 60px 0;
  width: 100%;
  ${media.down('lg')} {
      margin-top: 60px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperItems = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 60px 0;
  ${media.down('lg')} {
    gap: 40px 0;
  }
`

export const WrapperSoloItem = styled.div`
  flex: 1 0 100%;
  max-width: 100%;
  display: table;
  box-sizing: border-box;
  span{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0 0 20px 0; 
  }
  h5{
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 10px 0;
  }
  p{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0 0 20px 0;
  }
  div{
    a{
      span{
        font-weight: 600;
      }
    }
  }
  ${media.down('lg')} {
    flex: 1 0 100%;
    max-width: 100%;
  }
  ${media.down('md')} {
    span{
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
    }
    h5{
      font-size: 24px;
      line-height: 30px;
    }
    p{
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }
`

export const LeftContent = styled.div`
    width: 692px;
    display: table;
    box-sizing: border-box;
    float: left;
    ${media.down('xl')} {
        width: 100%;
    }
`

export const RightContent = styled.div`
    width: 387px;
    display: table;
    box-sizing: border-box;
    float: right;
    text-align: center;
    background: #EDEFF1;
    padding: 50px 0;
    img{
        width: 48px;
        display: block;
        margin: 0 auto 20px auto;
    }
    p{
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: center;
        width: 307px;
        display: block;
        margin: 0 auto 20px auto;
    }
    div{
        display: inline-block;
        margin: 0 auto;
    }
    ${media.down('xl')} {
        width: 100%;
        margin: 60px 0 0 0;
    }
    ${media.down('md')} {
        p{
            font-size: 22px;
            line-height: 26px;
        }
    }
`

export const ContentIn = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    .paginationBttns{
      width: 100%;
      text-align: center;
      margin: 120px 0 160px 0;
      li{
        display: inline-block;
        padding: 6px;
        border-radius: 3px;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-align: center;
        color: #667C89;
        min-width: 35px;
        margin: 0 5px;
        cursor: pointer;
      }
      .activeBttn{
        background: #FF5A00;
        color: #fff;
      }
      .paginationDisabled{
        display: none;
      }
    }
`