import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperCareerJobs = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    margin-bottom: 160px;
    h4{
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0 0 80px 0;
    }
    div{
        .wrapper-arrow{
            margin: 5px 5px -5px 0;
        }
    }
    ${media.down('lg')} {
        margin-bottom: 60px;
        h4{
            margin-bottom: 40px;
            font-size: 30px;
            line-height: 38px;
        }
    }
    ${media.down('md')} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const WrapperItems = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    margin-bottom: 40px;
`

export const SoloItem = styled.div`
    background: #EDEFF1;
    border-radius: 5px;
    display: table;
    box-sizing: border-box;
    padding: 60px;
    margin: 0 0 20px 0;
    width: 100%;
    p{
        max-width: 347px;
        min-width: 347px;
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;
        float: left;
        margin: 0 101px 0 0;
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        max-width: 370px;
        float: left;
        li{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.02em;
            text-align: left;
            max-width: 370px;
            margin: 0 0 10px 0;
            strong{
                color: #94A3AD;
            }
        }
    }
    a{
        float: right;
        display: block;
        padding: 0 26px;
        background: ${(props) => props.theme.colors.primaryOrange};
        font-size: 18px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: 0.02em;
        height: 60px;
        color: #ffffff;
        border-radius: 5px;
        text-decoration: none;
        margin: 19px 0 0 0;
    }
    ${media.down('xl')} {
        p{
            max-width: 100%;
            min-width: 100%;
            margin: 0 0 20px 0;
        }
        ul{
            max-width: 100%;
            min-width: 100%;
        }
        a{
            float: none;
            display: inline-block;
        }
    }
    ${media.down('lg')} {
        padding: 20px;
        p{
            font-size: 24px;
            line-height: 30px;
        }
        ul{
            li{
                font-size: 16px;
                line-height: 24px;
            }
        }
        a{
            height: 45px;
            line-height: 45px;
            font-size: 16px;
        }
    }
`