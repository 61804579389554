import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerItemsTeam = styled.div`
  display: table;
  box-sizing: border-box;
  margin: 0 0 160px 0;
  width: 100%;
  a, div a{
    margin-top: 60px;
  }
  h4{
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0 0 40px 0;
  }
  p{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    max-width: 692px;
    margin: 0 0 80px 0;
  }
  div{
    .wrapper-arrow{
        margin: 5px 5px -5px 0;
    }
  }
  ${media.down('lg')} {
    margin-bottom: 60px;
  }
  ${media.down('md')} {
    h4{
      font-size: 30px;
      line-height: 38px;
      margin: 0 0 20px 0;
    }
    p{
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 50px 0;
    }
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperItemsTeam = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 40px 22px;
  ${media.down('lg')} {
    gap: 15px 0;
  }
`

export const WrapperSoloTeam = styled.div`
  flex: 1 0 calc(100% / 3 - 22px);
  max-width: calc(100% / 3 - 22px);
  border-bottom: 1px solid #D1D8DB;
  display: table;
  box-sizing: border-box;
  padding-bottom: 40px;
  position: relative;
  a{
    font-size: 24px;
    line-height: 34px;
    margin-top: 0 !important;
    padding-left: 39px;
    .wrapper-arrow{
      position: absolute;
      left: 0;
      top: 5px;
    }
  }
  ${media.down('xl')} {
    flex: 1 0 50%;
    max-width: calc((100% / 2) - 22px);
  }
  ${media.down('lg')} {
    flex: 1 0 100%;
    max-width: calc(100%);
    min-height: 30px;
    padding-bottom: 15px;
    div a{
      margin-top: 0;
      .wrapper-arrow{
        top: 7px;
      }
    }
  }
`
