import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperJobOfferPerson = styled.div`
  display: table;
  box-sizing: border-box;
  width: 692px;
  ${media.down('xl')} {
    width: 100%;
  }
`

export const Title = styled.div`
    display: table;
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 40px;
    p{
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #25282B;
        margin: 0;
    }
`

export const OrangeBox = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    border: 5px solid #FF5A00;
    padding: 40px;
    .thumb{
        width: 132px;
        float: left;
        margin: 0 40px 0 0;
        img{
            width: 100%;
        }
    }
    .content{
        width: calc(100% - 172px);
        display: table;
        box-sizing: border-box;
        float: left;
    }
`

export const Name = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    padding-bottom: 15px;
    p{
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0;
    }
    span{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0;
    }
    ${media.down('md')} {
        padding: 20px 0 15px 0;
        p{
            font-size: 24px;
            line-height: 30px;
        }
        span{
            font-size: 16px;
            line-height: 24px;
        }
    }
`

export const Contact = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0 0 5px 0px;
    }
    ${media.down('md')} {
        p{
            font-size: 16px;
            line-height: 22px;
        }
    }
`