import styled from "styled-components";
import {WrapperBorder} from "../../atoms/line-border-header/styles";
import {media} from "../../atoms/theme";


export const WrapperBorderProductsServices = styled(WrapperBorder)`
  ${media.up('lg')} {
    width: calc(47% - 66px);
  }
  @media(max-width: 1439px) {
    margin-right: 66px;
  }
`


export const WrapperHeadProductsServices = styled.div`
  position: relative;
  margin-bottom: 20px;
  h1{
    display: inline;
    background: #fff;
    z-index: 4;
    position: relative;
    padding-right: 30px;
  }
  ${media.up('lg')} {
    margin-bottom: 80px;
  }
  ${media.down('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`
