import React, {FunctionComponent} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow";
import {BackgroundProduct, LogoProduct, ProductItem, WrapperProducts, WrapperProductsDesc} from "./styles";
import { useTranslation } from "react-i18next";
import { IProducts } from "./interface";
import generateLink from "../../../../core/api/assets";


export const ProductsPs: FunctionComponent<IProducts> = ({title, content, products}) => {
    const { t } = useTranslation();

    return <>
        <WrapperProductsDesc>
            <h2>{title}</h2>
            <p>{content}</p>
        </WrapperProductsDesc>
        <WrapperProducts>
            {Object.keys(products).map((keyName, i) => (
                <ProductItem>
                    <LogoProduct ProductLogoDesktop={generateLink(products[keyName].data['logo'])} ProductLogoMobile={generateLink(products[keyName].data['logo'])}/>
                    <BackgroundProduct BackgroundProductMobile={generateLink(products[keyName].data['banner'])} BackgroundProductDesktop={generateLink(products[keyName].data['banner'])}/>
                    <ActionArrowAtom link={products[keyName].data['link']} text={t('see_more')}/>
                </ProductItem>
            ))}
        </WrapperProducts>
    </>

}
