import axios from 'axios';
import {FunctionComponent, useState, useEffect} from "react";
import { Wrapper, Container, SoloArticle, ListArticles } from "./styles";
import { IBlogTop } from "./interface";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import thumb from '../../../../assets/images/first-article.png'
import { useGlobalLang } from '../lang-header/useGlobalLang'
import { useTranslation } from "react-i18next";
import generateLink from "../../../../core/api/assets"

export const BlogTop: FunctionComponent<IBlogTop> = ({title}) => {
    const { t } = useTranslation();
    const [first, setFirst] = useState({});
    const [news, setNews] = useState('');
    const [lang, setLang] = useGlobalLang();

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/articles-new`)
        .then(response => {
            setFirst(response.data[0]);
            setNews(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    return <>
        <Wrapper>
            <h1>{title}</h1>
            <Container>
                {first && first['data'] &&<SoloArticle>
                    <Link to={`/blog/${first['slug']}`}>
                        <img src={generateLink(first['data']['thumb'])} />
                        <div className="solo-article-content">
                            <p><Moment format="DD.MM.YYYY">{first['created_at']}</Moment></p>
                            <h6>{first['data']['title']}</h6>
                            <div className="article-info">
                                <p>{first['data']['category']}</p>
                                <p>{first['data']['time_to_read']} min</p>
                            </div>
                        </div>
                    </Link>
                </SoloArticle>}
                <ListArticles>
                    {Object.keys(news).map((keyName, i) => (
                        <div>
                            {i > 0 &&<div className="list-solo-article">
                                <Link to={`/blog/${news[keyName].slug}`}>
                                    <h6>{news[keyName].data['title']}</h6>
                                    <div className="article-info">
                                        <p>{news[keyName].data['category']}</p>
                                        <p>{news[keyName].data['time_to_read']} min</p>
                                    </div>
                                </Link>
                            </div>}
                        </div>
                    ))}
                </ListArticles>
            </Container>
        </Wrapper>
    </>
}
