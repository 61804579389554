import React, {FunctionComponent} from "react";
import { ContainerBusinessTeams, DescBusinessTeams, ImageBusinessTeams, LikeIcon } from "./styles";
import banner from '../../../../assets/images/competences/banner-two.png'
import { IBusinessTeams } from './interface'

export const BusinessTeams: FunctionComponent<IBusinessTeams> = ({title, content, image}) => {
    return <>
        <ContainerBusinessTeams>
            <DescBusinessTeams>
                <h2>{title}</h2>
                <ImageBusinessTeams>
                    <LikeIcon />
                    <img  alt="Banner" src={image} />
                </ImageBusinessTeams>
                <p>{content}</p>
            </DescBusinessTeams>
        </ContainerBusinessTeams>
    </>
}
