import React, {Fragment, FunctionComponent, useEffect, useState} from "react";
import styled from "styled-components";
import {PrivacyPolicyBreadcrumb} from "../../breadcrumbs/privacy-policy";
import BiContainer from "../../ui/atoms/container";
import Scrollspy from 'react-scrollspy';
import { HashLink as Link } from 'react-router-hash-link';
import {dataPrivacyPolicy} from "./data-privacy-policy";
import TextComponent from "../../ui/atoms/text-component/TextComponent";
import {media} from "../../ui/atoms/theme/media";
import IconArrowDown from "../../../assets/icons/arrow-down.svg";
import IconArrowUP from "../../../assets/icons/arrow-up.svg";
import {useBreakpoints} from "../../ui/atoms/container/hooks/useBreakpoints";
import {ContainerPrivacyPolicy, WrapperMenu,WrapperContentDesc, ItemArea, Wrapper } from "./styles";
import { NewsletterBlock } from "../../ui/organisms/newsletter-block/newsletter-block";

export const PrivacyPolicyPage: FunctionComponent = () => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 100);
        });
    }, []);
    const {isMedium, isXSmall, isSmall, isLarge} = useBreakpoints();
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        window.onscroll = () => {
            setOffset(window.pageYOffset)
        }
    }, []);

    console.log(offset);
    return <>
        <BiContainer>
            <PrivacyPolicyBreadcrumb />
            <Wrapper>
                <h1>Privacy Policy</h1>
                <ContainerPrivacyPolicy>
                    <WrapperMenu>
                        <nav className={scroll ? "fixed" : ""}>
                            <Scrollspy
                                items={['introduction', 'data-controller', 'purposes-legal', 'your-rights', 'complaints']}
                                currentClassName="is-current"  >

                                <li><Link to="#top" smooth>Introduction</Link></li>
                                <li><Link to="#data-controller"  smooth>Data Controller</Link></li>
                                <li><Link to="#purposes-legal" smooth>Purposes and legal <br/>basis for processing</Link></li>
                                <li><Link to="#your-rights" smooth>Your rights</Link></li>
                                <li><Link to="#complaints" smooth>Complaints</Link></li>
                            </Scrollspy>
                        </nav>
                    </WrapperMenu>
                    <WrapperContentDesc >
                        {dataPrivacyPolicy.map(item =>
                            <ItemArea id={item.id} >
                                <details open={!isXSmall && !isSmall && !isMedium && !isLarge}>
                                    <summary ><h3>{item.name}</h3></summary>
                                    <section >
                                        <div><TextComponent children={item.description}/></div>
                                    </section>
                                </details>
                            </ItemArea>
                        )}
                    </WrapperContentDesc>
                </ContainerPrivacyPolicy>
            </Wrapper>
        </BiContainer>

        <NewsletterBlock />
    </>

}