import React, {FunctionComponent} from "react";
import {ContainerHeadPaperless } from "./styles";
import {HeadDescriptionPaperless} from "../../molecules/head-description-paperless/head-description-paperless";
import {LogoPaperless} from "../../molecules/logo-paperless/logo-paperless";
import {BIPaperlessDesc} from "./interfaces";
export const HeadPaperless: FunctionComponent<BIPaperlessDesc> = ({title, under_title, image}) => {
    return <>
        <ContainerHeadPaperless >
            <HeadDescriptionPaperless 
                title={title}
                under_title={under_title}
            />
            <LogoPaperless
                image={image}
            />
        </ContainerHeadPaperless>
    </>
}