import React, {FunctionComponent} from "react";
import { ContainerItems, WrapperSoloItem, WrapperItems } from "./styles";
import { ICheckListInperly } from "./interface";

export const CheckListInperly: FunctionComponent<ICheckListInperly> = ({items, title, content}) => {
    return <>
        <ContainerItems>
            {title &&<h4>{title}</h4>}
            {content &&<p>{content}</p>}
            <WrapperItems>
                {Object.keys(items).map((keyName, i) => (
                    <WrapperSoloItem>
                        <p className="title">{items[keyName].data['title']}</p>
                    </WrapperSoloItem>
                ))}
            </WrapperItems>
        </ContainerItems>
    </>
}
