import React, {FunctionComponent} from "react";
import {WrapperOurTeams} from './styles'
import { IOurTeams } from "./interface";
import { ActionArrowAtom } from "../../atoms/action-arrow";

export const OurTeams: FunctionComponent<IOurTeams> = ({title, content, banner, url, text_link}) => {
    return <>
        <WrapperOurTeams>
            {title &&<h4>{title}</h4>}
            {content &&<p>{content}</p>}
            {text_link &&<ActionArrowAtom link={`${url}`} text={text_link} />}
            <img alt="Banner" src={banner} />
        </WrapperOurTeams>
    </>
}
