import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerTeamsInRegion = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;
          h2 {
            margin-top: 0;
            color: ${(props) => props.theme.colors.black};
          }

          ${media.up('md')} {
            h2 {
              margin-bottom: 60px;
            }
          }
          ${media.down("md")} {
            padding-left: 20px;
            padding-right: 20px;
          }
  a {
    text-decoration: unset;
  }
  ${media.up('lg')} {
    margin-bottom: 120px;
  }
    `,
    WrapperItemsTeam = styled.div`
      display: flex;
      flex-direction: row;
      gap: 20px 20px;
      flex-wrap: wrap;
    `,
    ItemTeam = styled.div`
      padding: 20px;
      width: 100%;
      background: #EDEFF1;
      
      &:hover {
        opacity: 0.9;
      }
      
      .action-wrapper {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
      }

      div{
        .wrapper-arrow{
            margin: 5px 5px -5px 0;
        }
      }

      span.text-link {
        font-size: 15px;
      }

      .action {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;

        padding: 11px 20px;
        background: ${(props) => props.theme.colors.primaryOrange};
        border-color: unset;
        border-radius: 5px;
        color: ${(props) => props.theme.colors.white};
        cursor: pointer;
      }
      
      .city-name {
        font-size: 16px;
        line-height: 24px;
        color: ${(props) => props.theme.colors.black};
        margin-bottom: 5px;
        margin-top: 0;
      }
      .name-team {
        color: ${(props) => props.theme.colors.black};
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;
        margin-top: 0;
      }

      ${media.up('md')} {
        padding: 30px;
        width: calc(50% - 20px);

        .action-wrapper {
          flex-direction: row;

        }

        .action {
          font-size: 18px;
          line-height: 22px;
          padding: 19px 26px;
        }
        .city-name {
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 20px;
        }
        .name-team {
          font-size: 34px;
          line-height: 42px;
        }
      }
    `