const industries = [];

export const links = [
    {
        id: 1,
        title: 'Competencies',
        under_title: 'All competencies',
        slug: '/competencies',
        children: [
            {
                id: 1,
                row: [
                    {
                        id: 1,
                        title: 'Core Development Teams',
                        links: [
                            {
                                id: 2,
                                title: 'Software Development',
                                slug: '/software-development'
                            },
                            {
                                id: 3,
                                title: 'Operation Services',
                                slug: '/operation-services'
                            },
                            {
                                id: 4,
                                title: 'Workshops',
                                slug: '/workshops'
                            },
                            {
                                id: 5,
                                title: 'Business Analysis',
                                slug: '/business-analysis'
                            },
                            {
                                id: 6,
                                title: 'UX - Design',
                                slug: '/ux-design'
                            },
                            {
                                id: 7,
                                title: 'Integration',
                                slug: '/integration'
                            },
                            {
                                id: 8,
                                title: 'Automation',
                                slug: '/automation'
                            },
                            {
                                id: 9,
                                title: 'Modern Workplace',
                                slug: '/modern-workplace'
                            },
                            {
                                id: 10,
                                title: 'Development LifeCycle Tools',
                                slug: '/software-lifecycle-tools'
                            },
                            {
                                id: 11,
                                title: 'Project Management & SCRUM',
                                slug: '/project-management-and-scrum'
                            },
                            {
                                id: 12,
                                title: 'Quality Assurance',
                                slug: '/quality-assurance'
                            },
                            {
                                id: 13,
                                title: 'DevOps & Clouds',
                                slug: '/devops-and-cloud'
                            },
                            {
                                id: 14,
                                title: 'Identity Management',
                                slug: '/identity-management'
                            }
                        ]
                    }
                ]
            },
            {
                id: 15,
                row: [
                    {
                        id: 16,
                        title: 'Business Specialization Teams',
                        links: [
                            {
                                id: 17,
                                title: 'Laboratory informatics',
                                slug: '/laboratory-informatics'
                            },
                            {
                                id: 18,
                                title: 'Big Data & AI',
                                slug: '/big-data-and-ai'
                            },
                            {
                                id: 19,
                                title: 'CRM',
                                slug: '/crm'
                            },
                            {
                                id: 20,
                                title: 'BI',
                                slug: '/bi'
                            }
                        ]
                    }
                    /*{
                        id: 21,
                        title: 'Partnerships',
                        links: [
                            {
                                id: 22,
                                title: 'Microsoft Partner of the Year 2021',
                                slug: 'https://microsoft-partnership.billennium.com/',
                                out: true,
                            },
                            {
                                id: 23,
                                title: 'Salesforce Partnership',
                                slug: 'http://salesforce-partnership.billennium.com/',
                                out: true
                            },
                            {
                                id: 24,
                                title: 'MuleSoft Partnership',
                                slug: 'https://front.billenium.forbetterfuture.pl/',
                                out: true
                            }
                        ]
                    }*/
                ]
            },
            {
                id: 25,
                row: [
                    {
                        id: 26,
                        title: 'Technology Teams',
                        links: [
                            {
                                id: 27,
                                title: '.NET',
                                slug: '/net'
                            },
                            {
                                id: 28,
                                title: 'Azure',
                                slug: '/azure'
                            },
                            {
                                id: 29,
                                title: 'Google',
                                slug: '/google'
                            },
                            {
                                id: 30,
                                title: 'AWS',
                                slug: '/aws'
                            },
                            {
                                id: 31,
                                title: 'Salesforce',
                                slug: '/salesforce'
                            },
                            {
                                id: 32,
                                title: 'PHP',
                                slug: '/php'
                            },
                            {
                                id: 33,
                                title: 'Angular',
                                slug: '/angular'
                            },
                            {
                                id: 34,
                                title: 'React',
                                slug: '/react'
                            },
                            {
                                id: 35,
                                title: 'Mobile Development',
                                slug: '/mobile-development'
                            },
                            {
                                id: 36,
                                title: 'Python',
                                slug: '/python'
                            },
                            {
                                id: 65,
                                title: 'Java',
                                slug: '/java'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        id: 37,
        title: 'Industries',
        under_title: 'All industries',
        slug: '/industries',
        children: []
    },
    {
        id: 38,
        title: 'Products and services',
        under_title: 'All products and services',
        slug: '/products-and-services',
        children: [
            {
                id: 39,
                row: [
                    {
                        id: 40,
                        title: 'Products',
                        links: [
                            {
                                id: 41,
                                title: 'Paperless office',
                                slug: '/paperless'
                            },
                            {
                                id: 42,
                                title: 'Inperly',
                                slug: '/inperly'
                            },
                            {
                                id: 43,
                                title: 'Innovation Lab',
                                slug: '/innovation-lab'
                            }
                        ]
                    },
                ]
            },
            {
                id: 44,
                row: [
                    {
                        id: 45,
                        title: 'Services',
                        links: [
                            {
                                id: 46,
                                title: 'Outsourcing',
                                slug: '/outsourcing'
                            },
                            {
                                id: 47,
                                title: 'Consulting',
                                slug: '/consulting'
                            },
                            {
                                id: 48,
                                title: 'Project',
                                slug: '/project'
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: 49,
        title: 'Case studies',
        under_title: 'All Case Studies',
        slug: '/case-studies',
        children: []
    },
    {
        id: 50,
        title: 'Company',
        under_title: 'Overview',
        slug: '/company',
        children: [
            {
                id: 51,
                row: [
                    {
                        id: 52,
                        links: [
                            {
                                id: 53,
                                title: 'About Billennium',
                                slug: '/about-billennium'
                            },
                            {
                                id: 54,
                                title: 'Culture',
                                slug: '/company-culture'
                            },
                            {
                                id: 55,
                                title: 'History',
                                slug: '/company-history'
                            },
                            {
                                id: 56,
                                title: 'Board',
                                slug: '/company-board'
                            },
                            {
                                id: 57,
                                title: 'Press releases',
                                slug: '/company-press'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        id: 58,
        title: 'Career',
        under_title: 'Working at Billennium',
        slug: '/career',
        children: [
            {
                id: 59,
                row: [
                    {
                        id: 60,
                        links: [
                            {
                                id: 61,
                                title: 'Teams',
                                slug: '/career-teams'
                            },
                            {
                                id: 62,
                                title: 'How to join',
                                slug: '/how-to-join'
                            },
                            {
                                id: 63,
                                title: 'Job offers',
                                slug: '/job-offers'
                            },
                            {
                                id: 64,
                                title: 'Locations',
                                slug: '/people-locations'
                            }
                        ]
                    },
                ]
            }
        ]
    }
]