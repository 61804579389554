import styled from "styled-components";
import {media} from "../../atoms/theme";

export const Container = styled.div`
    width: 100%;
    margin-bottom: 120px;
    img{
        width: 100%;
        display: block;
    }
    ${media.down('lg')} {
        margin-bottom: 60px;
    }
`