import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerSort = styled.div`
  width: 100%;
  margin: 60px 0 65px 0;
  box-sizing: border-box;
  display: table;
  p{
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    float: left;
    margin: 0;
    display: block;
    width: 70px;
    float: left;
  }
  ul{
      list-style: none;
      float: left;
      margin: 0;
      margin-right: 20px;
      display: block;
      width: calc(100% - 90px);
      margin-top: -10px;
      li{
          float: left;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;
          margin: 0;
          margin-right: 20px;
          cursor: pointer;
          margin-bottom: -5px;
          display: block;
          border-bottom: 3px solid #fff;
          margin-top: 10px;
      }
      .react-tabs__tab {
        cursor: pointer;
    
        &.react-tabs__tab--selected {
          position: relative;
          color: black;
          border-bottom: 3px solid ${(props) => props.theme.colors.primaryOrange};
          margin-bottom: -5px;
        }
      }
      .active{
        border-bottom: 3px solid ${(props) => props.theme.colors.primaryOrange};
        margin-bottom: 10px;
      }
  }
  ${media.down('md')} {
    margin-top: 20px;
    p{
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      margin-bottom: 10px;
    }
    ul{
      margin: 0;
      padding: 0;
      li{
        font-size: 18px;
        line-height: 22px;

      }
    }
    padding-left: 20px;
    padding-right: 20px;
  }
`