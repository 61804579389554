import styled from "styled-components";
import {media} from "../../atoms/theme";

export const Container = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    margin-top: 120px;
    margin-bottom: 60px;
    img{
        float: right;
        width: 608px;
    }
    ${media.down('xl')} {
        img{
            width: 300px;
        }
    }
    ${media.down('lg')} {
        img{
            width: 100%;
        }
    }
    ${media.down('md')} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const Content = styled.div`
    width: 577px;
    display: table;
    box-sizing: border-box;
    float: left;
    ${media.down('xl')} {
        width: 400px;
    }
    ${media.down('lg')} {
        width: 100%;
        margin-top: 60px;
    }
`

export const SoloContent = styled.div`
    width: 100%;
    display: table;
    box-sizing: border-box;
    margin-bottom: 60px;
    h4{
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #25282B;
        margin: 0 0 17px 0;
    }
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #25282B;
        margin: 0;
    }
    ${media.down('md')} {
        margin-bottom: 20px;
        h4{
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 20px;
        }
        p{
            font-size: 16px;
            line-height: 24px;
        }
    }
`