import React, {FunctionComponent} from "react";
import {WrapperContactShort} from './styles'
import { IContactShort } from "./interface";
import { isOpenModalContact } from "../../atoms/button-header/button-header";

export const ContactShort: FunctionComponent<IContactShort> = ({title}) => {
    const [isOpenModal, setOpenModal] = isOpenModalContact();

    return <>
        <WrapperContactShort>
            <p className="contact-content">{title}</p>
            <a onClick={() => setOpenModal(true)}>Contact us</a>
        </WrapperContactShort>
    </>
}
