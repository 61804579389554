import React, {FunctionComponent} from "react";
import {WrapperCareerProcess, DescCareerProcess, WrapperItems, WrapperSoloItem} from './styles'
import { ICareerProcess } from "./interface";
import { ActionArrowAtom } from "../../atoms/action-arrow";
import Talk from '../../../../assets/images/career/talk.svg';
import Chat from '../../../../assets/images/career/chat.svg';
import Stickers from '../../../../assets/images/career/stickers.svg';
import Party from '../../../../assets/images/career/party.svg';
import { useTranslation } from "react-i18next";

export const CareerProcess: FunctionComponent<ICareerProcess> = ({title, content, link, link_text}) => {
    const { t } = useTranslation();
    
    return <>
        <WrapperCareerProcess>
            <WrapperItems>
                <WrapperSoloItem>
                    <p>01</p>
                    <img  alt="Talk" src={Talk} />
                </WrapperSoloItem>
                <WrapperSoloItem>
                    <p>02</p>
                    <img alt="Chat" src={Chat} />
                </WrapperSoloItem>
                <WrapperSoloItem>
                    <p>03</p>
                    <img alt="Stickers" src={Stickers} />
                </WrapperSoloItem>
                <WrapperSoloItem>
                    <p>04</p>
                    <img alt="Party" src={Party} />
                </WrapperSoloItem>
            </WrapperItems>
            <DescCareerProcess>
                <h4>{title}</h4>
                <p>{content}</p>
                <ActionArrowAtom link={link} text={link_text} />
            </DescCareerProcess>
        </WrapperCareerProcess>
    </>
}
