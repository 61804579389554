import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import Contact from "../../ui/organisms/contact";
import BiContainer from "../../ui/atoms/container/BiContainer";
import { HeaderCaseStudiesDetails } from "../../ui/molecules/header-case-details-studies/header-case-studies-details";
import Logo from "../../../assets/images/case-studies-details/logo.png";
import Banner from "../../../assets/images/case-studies-details/banner.png";
import { CaseContent } from "../../ui/molecules/case-content";
import { TechnologiesCase } from "../../ui/molecules/technolgies-case/technologies-case";
import {technologies, values, articles} from './data'
import { SoloStudyBanner } from "../../ui/molecules/solo-study-banner";
import { PercentValuesCaseStudy } from "../../ui/molecules/percent-values-case-study/percent-values-case-study";
import { OtherCaseStudies } from "../../ui/molecules/other-case-studies/other-case-studies";
import { CaseStudiesDetailsBreadcrumb } from "../../breadcrumbs/case-studies-details/case-studies-details";
import { useLocation } from 'react-router-dom';
import getSlug from "../../../core/router/getSlug";
import generateLink from '../../../core/api/assets';
import { ContactPersonPopup } from '../../ui/organisms/contact-person-popup/contact-person-popup';
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang';
import { useTranslation } from "react-i18next";

export const CaseStudiesDetailsPage: FunctionComponent = () => {
    const { t } = useTranslation()
    const [content, setContent] = useState([]);
    const [categories, setCategories] = useState([]);
    const [people, setPeople] = useState([]);
    const [pageid, setPageId] = useState('');
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/case-study/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection-case_study_category`)
        .then(response => {
            setCategories(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    const getDataCategory = (id, type) => {
        const indexEl = Object.keys(categories).find((keyName) => categories[keyName].id == id)
        if(indexEl) return categories[indexEl].data[type]
    }

    return <>
        <BiContainer>
            <CaseStudiesDetailsBreadcrumb 
                title={content['title']}
                slug={slug}
            />
            <HeaderCaseStudiesDetails
                title_top={getDataCategory(content['category'], 'title')}
                title={content['title']}
                image={content['category_big_logo']}
                banner={generateLink(content['banner'])}
                content={content['content']}
            />
            <CaseContent 
                title={content['section_1_-_title']}
                content={content['section_1_-_content']}
            />
            <CaseContent 
                title={content['section_2_-_title']}
                content={content['section_2_-_content']}
            />
            <CaseContent 
                title={content['section_3_-_title']}
                content={content['section_3_-_content']}
                list={content['section_3_-_list']}
            />
            <TechnologiesCase
                title={content['section_4_-_title']}
                items={content['technologies']}
            />
            <SoloStudyBanner
                banner={generateLink(content['section_4_-_banner'])}
            />
            <CaseContent 
                title={content['section_5_-_title']}
                content=""
                list={content['section_5_-_list']}
                bottom={true}
            />
            <PercentValuesCaseStudy
                value_1={content['box_1_value']}
                title_1={content['box_1_title']}
                value_2={content['box_2_value']}
                title_2={content['box_2_title']}
                value_3={content['box_3_value']}
                title_3={content['box_3_title']}
            />
            <Contact
                title="Let’s talk about your needs"
                content="We usually design systems tailored to your needs but."
                button_text="Send"
            />
            <OtherCaseStudies
                title="Check out our other case studies"
                items={content['other_case_studies']}
            />
            <ContactPersonPopup
                title={content['contact_popup_title']}
                place={content['contact_popup_position']}
                content={content['contact_popup_content']}
                name={content['contact_popup_person_name']}
                image={generateLink(content['contact_popup_avatar'])}
                send_to={content['contact_send_to']}
                pardot_link={content['pardot_link']}
            />
        </BiContainer>

    </>
}
