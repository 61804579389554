import React, {FunctionComponent} from "react";
import { WrapperHeaderCaseStudies, PositionBoard, Socials } from "./styles";
import { IHeaderCaseStudies } from "./interface";
import linkedin from '../../../../assets/icons/icon-linkedin.svg';

export const HeaderCaseStudies: FunctionComponent<IHeaderCaseStudies> = ({title, content, position, link}) => {
    return <>
        <WrapperHeaderCaseStudies>
            <h1>{title}</h1>
            {content &&<p>{content}</p>}
            {position &&<PositionBoard>
                <p>{position}</p>
                <Socials>
                    <span>View profiles<br />on social media</span>
                    <a target="_blank" href={link}><img src={linkedin} /></a>
                </Socials>
            </PositionBoard>}
        </WrapperHeaderCaseStudies>
    </>
}
