export const jobs = [
    {
        'id': 1,
        'title': 'Junior New Business Specialist',
        'team': 'Management & Scrum Master',
        'location': 'Warsaw, Poland or Remotely',
        'level': 'Regular',
    },
    {
        'id': 2,
        'title': '.Net Developer',
        'team': 'Management & Scrum Master',
        'location': 'Warsaw, Poland or Remotely',
        'level': 'Regular',
    },
    {
        'id': 3,
        'title': 'Specjalista ds. wdrożeń',
        'team': 'Management & Scrum Master',
        'location': 'Warsaw, Poland or Remotely',
        'level': 'Regular',
    }
]

export const faqs = [
    {
        'id': 1,
        'title': 'Am I the right person for Billennium?',
        'content': 'Some recruitment cases may include a meeting with the client (this meeting is not mandatory, it depends on the project). This is an opportunity to get to know the project team, discuss the scope of the project in more detail, as well as finally decide whether there is "chemistry" from both sides. We know from experience that this approach guarantees both us and you better satisfaction at work. Some recruitment cases may include a meeting with the client (this meeting is not mandatory, it depends on the project). This is an opportunity to get to know the project team, discuss the scope of the project in more detail, as well as finally decide whether there is "chemistry" from both sides. We know from experience that this approach guarantees both us and you better satisfaction at work.'
    },
    {
        'id': 2,
        'title': 'What technologies do you use in Billennium?',
        'content': 'Some recruitment cases may include a meeting with the client (this meeting is not mandatory, it depends on the project). This is an opportunity to get to know the project team, discuss the scope of the project in more detail, as well as finally decide whether there is "chemistry" from both sides. We know from experience that this approach guarantees both us and you better satisfaction at work. Some recruitment cases may include a meeting with the client (this meeting is not mandatory, it depends on the project). This is an opportunity to get to know the project team, discuss the scope of the project in more detail, as well as finally decide whether there is "chemistry" from both sides. We know from experience that this approach guarantees both us and you better satisfaction at work.'
    },
    {
        'id': 3,
        'title': 'Can I work remotely?',
        'content': 'Some recruitment cases may include a meeting with the client (this meeting is not mandatory, it depends on the project). This is an opportunity to get to know the project team, discuss the scope of the project in more detail, as well as finally decide whether there is "chemistry" from both sides. We know from experience that this approach guarantees both us and you better satisfaction at work. Some recruitment cases may include a meeting with the client (this meeting is not mandatory, it depends on the project). This is an opportunity to get to know the project team, discuss the scope of the project in more detail, as well as finally decide whether there is "chemistry" from both sides. We know from experience that this approach guarantees both us and you better satisfaction at work.'
    },
    {
        'id': 4,
        'title': 'Am I the right person for Billennium?',
        'content': 'Some recruitment cases may include a meeting with the client (this meeting is not mandatory, it depends on the project). This is an opportunity to get to know the project team, discuss the scope of the project in more detail, as well as finally decide whether there is "chemistry" from both sides. We know from experience that this approach guarantees both us and you better satisfaction at work. Some recruitment cases may include a meeting with the client (this meeting is not mandatory, it depends on the project). This is an opportunity to get to know the project team, discuss the scope of the project in more detail, as well as finally decide whether there is "chemistry" from both sides. We know from experience that this approach guarantees both us and you better satisfaction at work.'
    },
    {
        'id': 5,
        'title': 'What technologies do you use in Billennium?',
        'content': 'Some recruitment cases may include a meeting with the client (this meeting is not mandatory, it depends on the project). This is an opportunity to get to know the project team, discuss the scope of the project in more detail, as well as finally decide whether there is "chemistry" from both sides. We know from experience that this approach guarantees both us and you better satisfaction at work. Some recruitment cases may include a meeting with the client (this meeting is not mandatory, it depends on the project). This is an opportunity to get to know the project team, discuss the scope of the project in more detail, as well as finally decide whether there is "chemistry" from both sides. We know from experience that this approach guarantees both us and you better satisfaction at work.'
    },
    {
        'id': 6,
        'title': 'Can I work remotely?',
        'content': 'Some recruitment cases may include a meeting with the client (this meeting is not mandatory, it depends on the project). This is an opportunity to get to know the project team, discuss the scope of the project in more detail, as well as finally decide whether there is "chemistry" from both sides. We know from experience that this approach guarantees both us and you better satisfaction at work. Some recruitment cases may include a meeting with the client (this meeting is not mandatory, it depends on the project). This is an opportunity to get to know the project team, discuss the scope of the project in more detail, as well as finally decide whether there is "chemistry" from both sides. We know from experience that this approach guarantees both us and you better satisfaction at work.'
    }
]