import styled from "styled-components";
import { media } from "../../ui/atoms/theme";

export const JobOffersTop = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: table;
    margin: 60px 0;
    h1{
        margin: 0;
    }
    ${media.down('md')} {
        padding-left: 20px;
        padding-right: 20px;
    }
`