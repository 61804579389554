import React, {FunctionComponent} from "react";

import { IPercentValues } from "./interface";
import {ContainerItemsCountry, WrapperItemsCountry, WrapperSoloItemCountry} from "./styles";

export const PercentValuesCountry: FunctionComponent<IPercentValues> = ({value_1, title_1, value_2, title_2, value_3, title_3}) => {
    return <>
        <ContainerItemsCountry>
            <WrapperItemsCountry>
                <WrapperSoloItemCountry>
                    <span>{value_1}</span>
                    <p>{title_1}</p>
                </WrapperSoloItemCountry>
                <WrapperSoloItemCountry>
                    <span>{value_2}</span>
                    <p>{title_2}</p>
                </WrapperSoloItemCountry>
                <WrapperSoloItemCountry>
                    <span>{value_3}</span>
                    <p>{title_3}</p>
                </WrapperSoloItemCountry>
            </WrapperItemsCountry>
        </ContainerItemsCountry>
    </>
}
