import React, {FunctionComponent} from "react";
import { ContainerItems, WrapperSoloItem, WrapperItems } from "./styles";
import { IFireList } from "./interface";

export const FireList: FunctionComponent<IFireList> = ({items, title}) => {
    return <>
        <ContainerItems>
            <h4>{title}</h4>
            <WrapperItems>
                {Object.keys(items).map((keyName, i) => (
                    <WrapperSoloItem>
                        <p className="title">{items[keyName].data['title']}</p>
                        <p className="content">{items[keyName].data['content']}</p>
                    </WrapperSoloItem>
                ))}
            </WrapperItems>
        </ContainerItems>
    </>
}
