import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperBeforeFooter = styled.div`
    background: #EDEFF1;
    box-sizing: border-box;
    display: table;
    padding: 120px 0;
    width: 100%;
    margin-top: 160px;
    ${media.down('lg')} {
      padding: 60px 0;
    }
    ${media.down('md')} {
      padding-left: 20px;
      padding-right: 20px;
    }
`

export const DesceforeFooter = styled.div`
    width: 442px;
    box-sizing: border-box;
    display: table;
    float: left;
    h4{
        font-size: 46px;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -0.01em;
        text-align: left;
        max-width: 407px;
        margin: 0 0 40px 0;
    }
    p{
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #25282B;
    }
    ${media.down('lg')} {
      width: 100%;
      h4{
        font-size: 30px;
        line-height: 38px;
        margin: 0 0 20px 0;
      }
      p{
        font-size: 16px;
        line-height: 24px;
      }
    }
`

export const ListBeforeFooter = styled.div`
    width: 589px;
    float: right;
    box-sizing: border-box;
    display: table;
    ${media.down('xl')} {
      width: 300px;
    }
    ${media.down('lg')} {
      width: 100%;
      margin-top: 35px;
    }
`

export const WrapperSoloItem = styled.div`
  width: 100%;
  margin-bottom: 40px;
  border-bottom: 1px solid #D1D8DB;
  min-height: 75px;
  display: table;
  box-sizing: border-box;
  a{
    font-size: 24px;
    line-height: 34px;
    .wrapper-arrow{
      margin: 10px 32px -10px 0;
    }
  }
  ${media.down('lg')} {
    min-height: auto;
    padding-bottom: 15px;
    margin-bottom: 15px;
    a{
      font-size: 22px;
      line-height: 26px;
      .wrapper-arrow{
        margin: 6px 16.5px -6px 0;
      }
    }
  }
`