import React, {FunctionComponent} from "react";
import { ContainerFaq, WrapperItems } from "./styles";
import { IFaq } from "./interaface";
import { SoloFaq } from "../solo-faq";

export const Faq: FunctionComponent<IFaq> = ({title, items}) => {
    return <>
        <ContainerFaq>
            <h4>{title}</h4>
            <WrapperItems>
                {Object.keys(items).map((keyName, i) => (
                    <SoloFaq title={items[keyName].data['title']} content={items[keyName].data['content']} />
                ))}
            </WrapperItems>
        </ContainerFaq>
    </>
}
